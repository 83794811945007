
<h1 mat-dialog-title>Deny Program </h1>
<p>Click below to confirm the denying of this program. It is required for you to enter a comment.</p>
<div>
  <div class="row">
    <mat-form-field appearance="fill" class="col-md-12">
      <mat-label>Comments (Required)</mat-label>
      <textarea matInput [(ngModel)]="data.comments" required></textarea>
      <mat-error>Comments required!</mat-error>
    </mat-form-field>
  </div>
</div>
<div>
  <button mat-raised-button class="functional-button-dark" [disabled]="!data.comments" [mat-dialog-close]="data"> Confirm Denial</button>
  <button mat-raised-button class="functional-button" (click)="Cancel()"> Cancel</button>
</div>
