
<h1 *ngIf="!isHavingErrors && !replicateProgram" mat-dialog-title><mat-icon class="matcard-material-warning-icon">warning</mat-icon>Warning Message</h1>
<h1 *ngIf="isHavingErrors && !replicateProgram" mat-dialog-title><mat-icon class="matcard-material-error-icon">error</mat-icon>Error Message</h1>
<h1 *ngIf="replicateProgram" mat-dialog-title>Replicate Program</h1>

<div>
  <mat-card class="warning-card">
    <mat-card-content>
      <div>
        <span class="span-warning-message" *ngIf="replicateProgram"><mat-icon class="matcard-material-warning-icon">warning</mat-icon>Are you sure you want to replicate this program into the next market/calendar year?</span>
        <ul class="message-list">
          <li *ngFor="let message of messages">
            <span class="span-warning-message" *ngIf="!message.error"><mat-icon class="matcard-material-warning-icon">warning</mat-icon>{{message.message}}</span>
            <span class="span-warning-message" *ngIf="message.error"><mat-icon class="matcard-material-error-icon">error</mat-icon>{{message.message}}</span>
          </li>
          <br>
          <li *ngFor="let appUser of appUsers">
            <a href='mailto: {{ appUser.email }}'> {{ appUser.email }} </a>
          </li>
        </ul>
      </div>
      <div>
        <p class="message-text" *ngIf="!isHavingErrors">Do you want to {{continueText}}?</p>
        <p class="message-text" *ngIf="isHavingErrors && showErrorResolveMessage">Please resolve {{messages.length >1? 'errors':'error'}} and continue.</p>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<mat-card-footer>
  <div>
    <button *ngIf="!isHavingErrors && !replicateProgram" mat-raised-button class="functional-button-dark" [mat-dialog-close]="data"> Yes</button>
    <button *ngIf="!isHavingErrors && !replicateProgram" mat-raised-button class="functional-button" (click)="onCancel();"> No</button>
    <button *ngIf="isHavingErrors" mat-raised-button class="functional-button-dark" (click)="onCancel();"> OK</button>
    <button *ngIf="replicateProgram" mat-raised-button class="functional-button-dark" (click)="ReplicateProgram();"> OK</button>
    <button *ngIf="showDetails" mat-raised-button class="details-functional-button-dark" (click)="onShowDetails();"> Show Details</button>
    <button *ngIf="replicateProgram" mat-raised-button class="functional-button" (click)="onCancel();">Cancel</button>
  </div>
</mat-card-footer>
