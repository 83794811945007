export enum DOARoleIds {
  CATLDR = 1,
  COMLDR = 2,
  CULDR = 3,
  FINCANAL = 4,
  LEGAL = 5,
  MKTLDR = 6,
  PROGADMN = 7,
  PROGADMNANAL = 8,
  PROGADMNMGR = 9,
  PROGLDR = 10
}

export enum DOARoleCodes {
  CategoryLeader = 'CATLDR',
  CommercialLeader ='COMLDR',
  CommercialUnitLeader ='CULDR',
  FinancialAnalyst='FINCANAL',
  Legal='LEGAL' ,
  MarketingLeader='MKTLDR',
  ProgramAdmin='PROGADMN',
  ProgramAdminAnalyst='PROGADMNANAL',
  ProgramAdminManager='PROGADMNMGR',
  ProgramLeader='PROGLDR'
}

export enum DOARoleDesc {
  _1 = 'CategoryLeader',
  _2 = 'Commercial Leader',
  _3 = 'Commercial Unit Leader',
  _4 = 'Financial Analyst',
  _5 = 'Legal',
  _6 = 'Marketing Leader',
  _7 = 'Program Admin',
  _8 = 'Program Admin Analyst',
  _9 = 'Program Admin Manager',
  _10 = 'Program Leader'
}

export enum DOARoleType {
  _1 = 'Approver',
  _2 = 'Approver',
  _3 = 'Approver',
  _4 = 'Approver',
  _5 = 'Approver',
  _6 = 'Approver',
  _7 = 'Reviewer',
  _8 = 'Reviewer',
  _9 = 'Approver',
  _10 = 'Approver'
}
