<div>
  <div>
    <mat-toolbar>
      <div class="lblhearder">
        <mat-label>
          Change Program Manager Ownership
        </mat-label>
      </div>
    </mat-toolbar>
  </div>
  <mat-card class="card">
    <mat-card-content>
      <form [formGroup]="programManagerOwnershipForm" (submit)="onUpdate()">
        <div class="row">
          <mat-form-field appearance="fill" class="col-md-2">
            <mat-label>From Program Manager</mat-label>
            <input type="text" placeholder="Search From Program Manager" aria-label="Number" matInput
              formControlName="FromProgramManager" (ngModelChange)="onChangeFromProgramManager();"
              [matAutocomplete]="fromProgramManager">
            <mat-autocomplete autoActiveFirstOption #fromProgramManager="matAutocomplete"
              (optionSelected)="getPrograms($event)" [displayWith]="displayProgramManager">
              <mat-option *ngFor="let programManager of filteredFromProgramManagerList  | async"
                [value]="programManager">
                {{ programManager.userName }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="f.FromProgramManager.errors && f.FromProgramManager.errors.required">
              Program Manager is required!
            </mat-error>
            <mat-error *ngIf="f.FromProgramManager.errors != null">
              From/To Program managers are identical!
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-md-4">
            <mat-label>Search Programs</mat-label>
            <input type="text" (click)="getProgramChange($event)" placeholder="Search Programs" aria-label="Number" matInput formControlName="Program"
              [matAutocomplete]="program">
            <mat-autocomplete autoActiveFirstOption #program="matAutocomplete" [displayWith]="displayPrograms">
              <div *ngIf="showSelectALL">
                <mat-option>
                  <mat-checkbox [checked]="allSelected" [indeterminate]="someSelected"
                    (change)="toggleSelectionSelectAll($event)" (click)="$event.stopPropagation()">Select All
                  </mat-checkbox>
                </mat-option>
                <mat-option *ngFor="let program of filteredProgramList | async" [value]="program.programName"
                  class="multipleLine">
                  <mat-checkbox [checked]="program.selected" (change)="toggleSelection(program, $event)"
                    (click)="$event.stopPropagation()">
                    <div>
                      {{ program.programName }}<br />
                      <p>{{ program.programCode }}</p>
                    </div>
                  </mat-checkbox>
                </mat-option>
              </div>
            </mat-autocomplete>
            <mat-error *ngIf="f.Program.errors && f.Program.errors.required">
              {{showSelectALL? 'Program is required!': 'No programs available!'}}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-md-2">
            <mat-label>To Program Manager</mat-label>
            <input type="text" placeholder="Search To Program Manager" aria-label="Number" matInput
              formControlName="ToProgramManager" (ngModelChange)="onChangeToProgramManager();"
              [matAutocomplete]="toProgramManager">
            <mat-autocomplete autoActiveFirstOption #toProgramManager="matAutocomplete"
              [displayWith]="displayProgramManager">
              <mat-option *ngFor="let programManager of filteredToProgramManagerList  | async" [value]="programManager">
                {{ programManager.userName }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="f.ToProgramManager.errors && f.ToProgramManager.errors.required">
              Program Manager is required!
            </mat-error>
            <mat-error *ngIf="f.ToProgramManager.errors != null">
              From/To Program managers are identical!
            </mat-error>
          </mat-form-field>
          <button mat-raised-button type="submit" class="functional-button-dark"
            [disabled]="!reassignOwnershipEnable">Reassign
            Ownership</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>