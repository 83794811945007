export enum AccrualsMessages{
  _1 ='Product(s) with blank rates. Please add or remove.',
  _2 ='Product(s) with >=100% Accrual Rate. Please update.',
  _3 ='Product(s) calculating a 0% accrual rate.',
  _4 ='Product(s) with a >0% Pay/Volume rate calculating 0% accrual rate.',
  _5 = 'Product(s) existing accrual rates not with in date range will be removed.',
  _6 = 'Any changes that have not been saved or submitted will be discarded.',
  _7 = 'You cannot apply blank values to a product, or product hierarchy level.'
}

export enum ProductManagersAssignedMessages{
  _1 = 'The Product Manager list below has submitted Products and Accruals to your Program. The Product Manager(s) below must remove their Products and Accruals before you can remove the Product Manager Assignment from the Program'
}

export enum FinancialAssumptionErrors{
  _1="Total Exposure dollars is greater than, or equal to Sales dollars is invalid.",
  _2="Total Exposure dollars and Sales dollars are zero.",
  _3="Total Exposure dollars is greater than, or equal to Sales dollars is invalid.",
  _4="Total Exposure dollars and Sales dollars are zero."

}

export enum AttachmentErrors{
  _1 = 'Duplicate attachment name found. Please reupload the file with different name or the attachment will be saved as a copy. File Name: '
}
 
export enum ExternalProgramErrors{
  _1 = 'Externally accruing program was selected, but you did not select the type of externally accruing program.'
}