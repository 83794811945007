import { Component, ElementRef, ViewChild, OnInit, AfterViewInit, Output, OnChanges, Inject } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ProgPortalContextService } from '../../core/services/progportalcontextservice';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventEmitter } from 'protractor';
import { ReviewProgramComponent } from '../review-program/review-program.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';;

export interface DialogData {
  comments: any;
}

@Component({
  selector: 'app-dialog-review-approve',
  templateUrl: './dialog-review-approve.component.html',
  styleUrls: ['./dialog-review-approve.component.scss']
})
export class DialogReviewApproveComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogReviewApproveComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

  Cancel() {
    this.dialogRef.close();
  }

}
