import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/accruals/dialog-accruals-addproducts/dialog-accruals-addproducts.component';

/**
 * @title Tree with flat nodes
 */
@Component({
    selector: 'parent-child',
    templateUrl: 'dailog-parent-child-list.component.html',
    styleUrls: ['./dialog-parent-child-list.component.scss']
})
export class DialogParentChildListComponent {
    dataSource: any;
    allSelected: boolean = false;    
    constructor(
        public dialogRef: MatDialogRef<DialogParentChildListComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.dataSource = this.data; 
        this.selectionChange();
    }

    selectionChange() {
        this.allSelected = this.dataSource['childBundleRoutingProgramList'].filter(cbrpl =>!cbrpl.isParent).every(cbrpl => cbrpl.bundleRoute);
     }

     indeterminateSelection(): boolean {
        return this.dataSource['childBundleRoutingProgramList'].filter(cbrpl => cbrpl.bundleRoute && !cbrpl.isParent).length> 0 && this.dataSource['childBundleRoutingProgramList'].filter(cbrpl => cbrpl.bundleRoute && !cbrpl.isParent).length !==  (this.dataSource['childBundleRoutingProgramList'].length -1);
     }

     selectAll(selected: boolean) {
        this.allSelected = selected;       
        this.dataSource['childBundleRoutingProgramList'].filter(cbrpl => !cbrpl.isParent).forEach(cbrpl => {
            cbrpl.bundleRoute = selected
        });
      }

      onApply() {
        this.dialogRef.close(this.data);
    }
    onCancel() {
        this.data['applyConfirm'] = false;
        this.data['childBundleRoutingProgramList'].filter(cbrpl => !cbrpl.isParent).forEach(cbrpl => {
            cbrpl.bundleRoute = true;
        });        
        this.dialogRef.close(this.data);
    }
}
