export type ProgramPortalPermissions = 'ViewProgram' | 'EditProgram' | 'DeleteProgram' | 'ViewAccruals' | 'EditAccruals' | 'DeleteAccruals' | 'ViewReport' | 'ViewAdministration' | 'ApproveOrDenyProgram';

export const ProgramPortalPermissions = {
    ViewProgram: 'ViewProgram' as ProgramPortalPermissions,
    EditProgram: 'EditProgram' as ProgramPortalPermissions,
    DeleteProgram: 'DeleteProgram' as ProgramPortalPermissions,
    ViewAccrual: 'ViewAccrual' as ProgramPortalPermissions,
    EditAccrual: 'EditAccrual' as ProgramPortalPermissions,
    DeleteAccrual: 'DeleteAccrual' as ProgramPortalPermissions,
    ViewReport: 'ViewReport' as ProgramPortalPermissions,
    ViewAdministration: 'ViewAdministration' as ProgramPortalPermissions,
    ApproveOrDenyProgram: 'ApproveOrDenyProgram' as ProgramPortalPermissions
};