import { Component, OnInit } from '@angular/core';
import { ProgPortalContextService } from 'src/app/core/services/progportalcontextservice';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {
  
  constructor(public context: ProgPortalContextService) { }

  ngOnInit() {
    this.checkFeatures();
  }

  async checkFeatures() {
    await this.context
      .getdatafromService('/api/FeatureFlag')
      .toPromise()
      .then((response) => {
        const data = response;
      });
  }
}
