<div>
    <div>
      <mat-toolbar>
        <div class="lblhearder">
          <mat-label>
            User Access and Roles
          </mat-label>
        </div>
      </mat-toolbar>
    </div>
    <div>
      <mat-card>        
        <div class="searchbar">
          <ul class="navbar-nav toggle-padding">
            <li class="nav-item dropdown">
              <mat-slide-toggle [formControl]="showAllUsers" (change)="statusChange()">Show All Users</mat-slide-toggle>
            </li>
            <li class="nav-item dropdown">
              <mat-slide-toggle [formControl]="showDOAUsers" (change)="statusChange()">Show DOA Users</mat-slide-toggle>
            </li>
          </ul>
               
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <mat-form-field>
                <mat-label>Search</mat-label>
                <input matInput (keyup)="applyFilter()" type="text" [(ngModel)]="SearchUser"
                  [formControl]="searchUsers">
              </mat-form-field>  
            </li>
          </ul>
        </div> 
            
        <mat-chip-list>
          <mat-chip class="mat-chip-color" [selectable]="selectable" *ngIf="showChip"
            [removable]="removable" (removed)="removeFilter()">
            {{SearchUser}}
            <mat-icon [ngStyle]="{'color':'white'}" matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>

        <div class="matgrid-div">
          <div class="table-container">
            <table mat-table #appUsersTable class="mat-grid" [dataSource]="dataSource" #sort="matSort" matSort>               
              
              <ng-container matColumnDef="userLogin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="userLogin"> USER LOGIN </th>
                <td mat-cell *matCellDef="let user"> <a href="javascript:void(0);" 
                  (click)="navigateToUpdateAppUsers(user.userLogin);">{{ user.userLogin }} </a></td>
              </ng-container>

              <ng-container matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="userName"><strong> USER NAME </strong></th>
                <td mat-cell *matCellDef="let user"> {{ user.userName }} </td>
              </ng-container>

              <ng-container matColumnDef="lastName" class="lastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="lastName"> LAST NAME </th>
                <td mat-cell *matCellDef="let user"> {{ user.lastName }} </td>
              </ng-container>
  
              <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName"> FIRST NAME </th>
                <td mat-cell *matCellDef="let user"> {{ user.firstName }} </td>
              </ng-container>

              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="email"> EMAIL </th>
                <td mat-cell *matCellDef="let user"> {{ user.email }} </td>
              </ng-container>
              
              <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="isActive"> IS ACTIVE </th>
                <td mat-cell *matCellDef="let user"> {{ user.active }} </td>
              </ng-container>
              
              <ng-container matColumnDef="isDOAUser">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="isDOAUser"> IS DOA USER </th>
                <td mat-cell *matCellDef="let user"> {{ user.isDOAUser }} </td>
              </ng-container>
              
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <mat-paginator [pageSizeOptions]="[25, 50, 100]" [pageSize]="50" showFirstLastButtons></mat-paginator>
        </div>                
      </mat-card>
      <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white">Please Wait. </p>
      </ngx-spinner>
    </div>