import { HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnInit} from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ProgPortalContextService } from 'src/app/core/services/progportalcontextservice';
import { Router} from '@angular/router';
import { AppComponent } from 'src/app/app.component';


export interface Program {
  programId: number;
  programCode: string;
  programName: string;
  appUserId: number;
  programManager: string;
  selected: boolean;
}

export interface ProgramManager {
  id: number;
  userName: string;
  email: string;
  lastName: number;
  firstName: string;
  active: boolean;
  roleCode: string;
  role: string;
}

export interface ItemProgramManager {
  code: string;
  appUserId: number;
}

@Component({
  selector: 'app-program-manager-ownership',
  templateUrl: './program-manager-ownership.component.html',
  styleUrls: ['./program-manager-ownership.component.scss']
})

export class ProgramManagerOwnershipComponent implements OnInit, AfterViewInit {
  programManagerOwnershipForm: FormGroup;
  programManagerList: ProgramManager[];
  filteredFromProgramManagerList: Observable<ProgramManager[]>;
  filteredToProgramManagerList: Observable<ProgramManager[]>;
  programList: Program[];
  searchFilteredProgramList: Program[];
  selectedSearchFilteredProgramList: Program[];
  filteredProgramList: Observable<Program[]>;
  selectedProgramManager: ProgramManager;
  selectedPrograms: Program[] = [];
  programs: Program[] = [];
  allSelected: boolean = false;
  someSelected: boolean = false;
  cancelSubject = new Subject();
  itemProgramManager: ItemProgramManager[] = [];
  showSelectALL:boolean;
  reassignOwnershipEnable:boolean;
  displayValue: string;

  constructor(public context: ProgPortalContextService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private router: Router,
    public app: AppComponent,
    private matSnackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.programManagerOwnershipForm = this.fb.group({
      FromProgramManager: ['',
        {
          validators: [Validators.required],
          updateOn: 'change'
        }
      ],
      ToProgramManager: ['',
        {
          validators: [Validators.required],
          updateOn: 'change'
        }
      ],
      Program: [[], Validators.required]
    });
  }

  ngAfterViewInit() {
    this.binddropdowns();
  }

  get f() { return this.programManagerOwnershipForm.controls; }

  binddropdowns() {
    this.spinner.show();
    this.context.getdatafromService('/api/Administration/List/ProgramManager').toPromise().then((response) => {
      if (response != null) {
        this.programManagerList = response;
        this.filteredFromProgramManagerList = this.programManagerOwnershipForm.controls.FromProgramManager.valueChanges.pipe(
          startWith(''),
          map(value => this.programManagerFilter(value))
        );
        this.filteredToProgramManagerList = this.programManagerOwnershipForm.controls.ToProgramManager.valueChanges.pipe(
          startWith(''),
          map(value => this.programManagerFilter(value))
        );
        this.spinner.hide();
      }
    });
  }

  programManagerFilter(value: any): any[] {
    if (typeof value === "string") {
      const filterValue = value.toLowerCase();
      return this.programManagerList.filter(programManagerList => programManagerList.userName.toLowerCase().indexOf(filterValue) > -1 );
    } else {
      return this.programManagerList;
    }  
  }

  getPrograms(event: any) {
    this.spinner.show();
    this.showSelectALL = false;
    this.selectedPrograms = [];
    this.programs.forEach(p => {
      p.selected = true
      this.toggleSelection(p, event);
    });
    this.programs = [];
    this.programList = [];
    this.selectedProgramManager = event.option.value;
    this.programManagerOwnershipForm.controls.Program.setValue('');
    this.programManagerOwnershipForm.controls.Program.updateValueAndValidity();
    this.context.getdatafromService('/api/Administration/List/ProgramsAndChangeExceptions').toPromise().then((response) => {
      if (response != null) {
        this.allSelected = false;
        this.someSelected = false;
        response.forEach(r => r.selected = false);
        this.programs = response.filter(r => r.programManager == this.selectedProgramManager.userName);
        this.programList = response.filter(r => r.programManager == this.selectedProgramManager.userName);
        if (this.programList.length > 0) {
          this.showSelectALL = true;
        }
        this.filteredProgramList = this.programManagerOwnershipForm.controls.Program.valueChanges.pipe(
          startWith(''),
          map(value => this.programFilter(value))
        );
      }
      this.spinner.hide();
    });

  }

  getProgramChange(event: any) {
    this.spinner.show();
    this.showSelectALL = false;
    this.selectedPrograms = [];
    this.programs.forEach(p => {
      p.selected = true
      this.toggleSelection(p, event);
    });
    this.programs = [];
    this.programList = [];
    this.context.getdatafromService('/api/Administration/List/ProgramsAndChangeExceptions').toPromise().then((response) => {
      if (response != null) {
        this.allSelected = false;
        this.someSelected = false;
        response.forEach(r => r.selected = false);
        this.programs = response.filter(r => r.programManager == this.selectedProgramManager.userName);
        this.programList = response.filter(r => r.programManager == this.selectedProgramManager.userName);
        if (this.programList.length > 0) {
          this.showSelectALL = true;
        }
        this.filteredProgramList = this.programManagerOwnershipForm.controls.Program.valueChanges.pipe(
          startWith(''),
          map(value => this.programFilter(value))
        );
      }
      this.spinner.hide();
    });

  }

  programFilter(value: any): any[] {
    const filterValue = value.toLowerCase();
    this.searchFilteredProgramList = this.programList.filter(programList => programList.programName.toLowerCase().includes(filterValue) || String(programList.programCode).includes(filterValue));
    if (filterValue !== "") {
      this.selectedSearchFilteredProgramList = this.searchFilteredProgramList;
    }
    return this.searchFilteredProgramList;
  }

  toggleSelection(program: Program, event: any) {
    if (this.allSelected) {
      this.selectedPrograms = this.programs;
    } 
    program.selected = !program.selected;
    if (program.selected) {
      if (!this.selectedPrograms.some(sp => sp.programId === program.programId && sp.programCode === program.programCode && sp.programName === program.programName)) {
        this.selectedPrograms.push(program);
      }
    } else {
      const i = this.selectedPrograms.findIndex(value => value.programName === program.programName);
      this.selectedPrograms.splice(i, 1);
    }

    this.programManagerOwnershipForm.controls.Program.setValue(this.selectedPrograms);
    this.filteredProgramList.subscribe(programList => {
      this.allSelected = programList.every(p => p.selected);
      this.someSelected = programList.filter(t => t.selected).length > 0 && !this.allSelected;
    });
    if (!this.allSelected && !this.someSelected) {
      this.programManagerOwnershipForm.controls.Program.setValue([]);
    }
    if (this.programManagerOwnershipForm.valid && (this.programManagerOwnershipForm.controls.ToProgramManager.value !== '' && this.programManagerOwnershipForm.controls.FromProgramManager.value !== '')) {
      this.reassignOwnershipEnable = true;
    }
    else {
      this.reassignOwnershipEnable = false;
    }
  }

  toggleSelectionSelectAll(event: any) {
    if (event.checked) {
      this.programs = [];
      if (this.selectedSearchFilteredProgramList && this.selectedSearchFilteredProgramList.length > 0) {
        this.searchFilteredProgramList = this.selectedSearchFilteredProgramList;
      }
      this.searchFilteredProgramList.forEach(pl => {
        if (!this.programs.some(p => p.programId === pl.programId && p.programCode === pl.programCode && p.programName === pl.programName)) {
          this.programs.push(pl);
        }
      })
      this.programs.forEach(p => p.selected = true);
      this.filteredProgramList.subscribe(programList => {
        programList.forEach(p => p.selected = true);
      });
      this.programManagerOwnershipForm.controls.Program.setValue(this.programs);
      this.allSelected = true;
      this.someSelected = false;

    } else {
      this.programs.forEach(p => p.selected = false);
      this.filteredProgramList.subscribe(programList => {
        programList.forEach(p => p.selected = false);
      });
      this.programManagerOwnershipForm.controls.Program.setValue([]);
      this.allSelected = false;
      this.someSelected = false;
    }
    if (this.programManagerOwnershipForm.valid && (this.programManagerOwnershipForm.controls.ToProgramManager.value !== '' && this.programManagerOwnershipForm.controls.FromProgramManager.value !== '')) {
      this.reassignOwnershipEnable = true;
    }
    else {
      this.reassignOwnershipEnable = false;
    }
  }

  displayPrograms(value: Program[]): string | undefined {
    this.displayValue = '';
    var filterSelectedPrograms;
    if (value.length > 0) {
      filterSelectedPrograms = value.filter(v => v.selected);
    }    
    if (Array.isArray(filterSelectedPrograms)) {
      filterSelectedPrograms.forEach((program, index) => {
        if (index === 0) {
          this.displayValue = program.programName;
        } else {
          this.displayValue += ', ' + program.programName;
        }
      });
    } else {
      this.displayValue = filterSelectedPrograms;
    }
    return this.displayValue;
  }

  displayProgramManager(value: ProgramManager){
    return value.userName;
  }

  onUpdate(){
    if(this.programManagerOwnershipForm.valid){
      this.itemProgramManager = [];
      this.programManagerOwnershipForm.controls.Program.value.forEach(p => {
          this.itemProgramManager.push({
            code : p.programCode,
            appUserId : this.programManagerOwnershipForm.controls.ToProgramManager.value.id
          });
      });
      const headers = new HttpHeaders().set('content-type', 'application/json');
      this.context.postdatatoservice("/api/Administration/Table/UpdateProgramManager", JSON.stringify(this.itemProgramManager), headers)
      .pipe(takeUntil(this.cancelSubject)).toPromise().then((response) => {
        if (response !== null) {
          this.openSuccessSnackBar('Successfully Updated.', 'x', 3000);
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl('administration/program-manager-ownership');
          });
        }
      },
        msg => { // Error
          this.openErrorSnackBar('Error in Updating.', 'x', 3000);
        })
      .catch(error => this.openErrorSnackBar(error.message, 'x', 3000));
    }
  }

  openSuccessSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['success-snackbar'] });
  }

  openErrorSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['error-snackbar'] });
  }

  programManagerValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return (this.programManagerOwnershipForm.controls.FromProgramManager.value.id == this.programManagerOwnershipForm.controls.ToProgramManager.value.id) ? of({ identicalProgramManager: "true" }) : of(null);
    };
  }

  onChangeFromProgramManager() {
    if (this.programManagerOwnershipForm.controls.FromProgramManager.value.id !== this.programManagerOwnershipForm.controls.ToProgramManager.value.id){
      this.programManagerOwnershipForm.controls.FromProgramManager.setErrors(null);
      this.programManagerOwnershipForm.controls.ToProgramManager.setErrors(null);
    }
    else {
      this.programManagerOwnershipForm.controls.FromProgramManager.setErrors({ errors: true });
    }
    if(this.programManagerOwnershipForm.valid && (this.programManagerOwnershipForm.controls.ToProgramManager.value !== '' && this.programManagerOwnershipForm.controls.FromProgramManager.value !=='') ){
      this.reassignOwnershipEnable = true;
    }
    else{
      this.reassignOwnershipEnable = false;
    }
}

  onChangeToProgramManager() {
    if (this.programManagerOwnershipForm.controls.FromProgramManager.value.id !== this.programManagerOwnershipForm.controls.ToProgramManager.value.id) {
      this.programManagerOwnershipForm.controls.ToProgramManager.setErrors(null);
      this.programManagerOwnershipForm.controls.FromProgramManager.setErrors(null);
    }
    else {
      this.programManagerOwnershipForm.controls.ToProgramManager.setErrors({ errors: true });
    }
    if(this.programManagerOwnershipForm.valid && (this.programManagerOwnershipForm.controls.ToProgramManager.value !== '' && this.programManagerOwnershipForm.controls.FromProgramManager.value !=='') ){
      this.reassignOwnershipEnable = true;
    }
    else{
      this.reassignOwnershipEnable = false;
    }
  }
}
