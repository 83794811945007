<div>
  <h1>Add Comment</h1>
  <p>Select one or more programs below to add comment and click Apply.</p>
</div>
<div>
  <mat-card class="mat-card mat-elevation-z3 card">
    <div class="comment-toggle">
      <mat-slide-toggle class="mat-slide-toggle"
        [style.visibility]="(submittedOrRemovedAccrualPrograms.length === 1) ? 'hidden' : 'visible'"
        [(ngModel)]="showIndividualComments" (change)="onIndividualCommentsToggleChange($event)" name="showIndividualComments">Add Individual Comments</mat-slide-toggle>
    </div>
    <mat-card-content class="mat-card-content-scroll">
      <div class="bullet-list">
        <mat-checkbox [checked]="allSelected" [indeterminate]="someSelected()" (change)="selectAll($event.checked)"
          [disabled]="allRequiredSelected">
          Select All
        </mat-checkbox>
        <ul>
          <li *ngFor="let submittedOrRemovedAccrualProgram of submittedOrRemovedAccrualPrograms">
            <mat-checkbox [(ngModel)]="submittedOrRemovedAccrualProgram.selected" (change)="updateAllSelected()"
              [disabled]="submittedOrRemovedAccrualProgram.disabled">
              {{submittedOrRemovedAccrualProgram.programName}}<span class="red-color"
                *ngIf="submittedOrRemovedAccrualProgram.isCommentRequired">*</span>
            </mat-checkbox>
            <div *ngIf="showIndividualComments">
              <mat-form-field class="col-md-12 comment-field">
                <mat-label>Comment</mat-label>
                <textarea matInput rows="3" [(ngModel)]="submittedOrRemovedAccrualProgram.comment"
                  (ngModelChange)="checkCommentsRequired()" required
                  [disabled]="!checkIndividualProgramSelected(submittedOrRemovedAccrualProgram)"></textarea>
                <mat-error>Comment is required!</mat-error>
              </mat-form-field>
            </div>
          </li>
        </ul>
      </div>
      <div *ngIf="submittedOrRemovedAccrualPrograms.length == 1" class="padding1-above-comment">
      </div>
      <div *ngIf="submittedOrRemovedAccrualPrograms.length == 2" class="padding2-above-comment">
      </div>
      <div *ngIf="!showIndividualComments">
        <mat-form-field class="col-md-12 comment-field">
          <mat-label>Comment</mat-label>
          <textarea matInput rows="3" [(ngModel)]="comment" (ngModelChange)="checkCommentsRequired()" required
            [disabled]="!checkProgramSelected()"></textarea>
          <mat-error>Comment is required!</mat-error>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<mat-card-footer>
  <div>
    <button mat-raised-button class="functional-button-dark" [disabled]="!checkCommentsRequired()"
      (click)="onApply();">Apply</button>
    <button mat-raised-button class="functional-button" (click)="onCancel();"> Cancel</button>
  </div>
</mat-card-footer>