import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AppSettingsService } from './app-settings/app-settings.service';

@Injectable()
export class ProgPortalContextService {
  constructor(public http: HttpClient) { }

   // apiurl = "https://localhost:5001";
   // apiurl = "https://phazq0547appwebapi.azurewebsites.net/api/";
  apiurl = AppSettingsService.variables.clientSide.serverSideBaseUri;

  devapiurl = '';
  features: any;
  appsettings: any;


  getdatafromService(url: any, responseType: any = null): Observable<any> {
    const apiurl = this.apiurl + url;
    const headers = new HttpHeaders().set('content-type', 'text/plain');
    return this.http.get(apiurl, { headers, responseType: responseType }).pipe(
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }
  postdatatoservice(url: any, body: any = null, headers: any = null): Observable<any>  {
    const apiurl = this.apiurl + url;
    return this.http.post(apiurl, body, { headers }).pipe(
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }
  putdatatoservice(url: any, body: any = null, headers: any = null): Observable<any>  {
    const apiurl = this.apiurl + url;
    return this.http.put(apiurl, body, { headers }).pipe(
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }
}
