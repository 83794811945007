
<h1 mat-dialog-title>Assign Product Managers </h1>
<p>Enter one or more product managers to be notified to add accruals for this program</p>
<div [formGroup]="programForm">
<mat-form-field class="example-chip-list">
    <mat-chip-list #chipList aria-label="Productmgr selection">
        <mat-chip
          *ngFor="let Productmgr of data.prodmgrs"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(Productmgr)">
          {{Productmgr}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="Product Manager(s)..."
          #ProductmgrInput
          formControlName="ProductmgrCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" >       
        <mat-option *ngFor="let Productmgr of filteredProductmgrs" [value]="Productmgr">
          {{Productmgr.name}}
        </mat-option>
      </mat-autocomplete>
      

</mat-form-field>


</div>
<div>
    <button mat-raised-button class="functional-button-dark" (click)="onReleaseProgramforAccruals()"> Release Program for Accruals</button>
    <button mat-raised-button class="functional-button" (click)="Cancel()" > Cancel</button>
</div>