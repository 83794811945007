export type ProgramPortalRoles = 'ADMIN' | 'PROGMGR' | 'PRODMGR' | 'PROGLDR' | 'LEGAL' | 'FINCANAL' | 'CATLDR' | 'MKTLDR' | 'CULDR' | 'COMLDR' | 'PROGADMNMGR' | 'PROGADMNANAL' | 'PROGADMN' | 'SYSUSER';

export const ProgramPortalRoles = {
    ADMIN: 'ADMIN' as ProgramPortalRoles,
    PROGMGR: 'PROGMGR' as ProgramPortalRoles,
    PRODMGR: 'PRODMGR' as ProgramPortalRoles,
    PROGLDR: 'PROGLDR' as ProgramPortalRoles,
    LEGAL: 'LEGAL' as ProgramPortalRoles,
    FINCANAL: 'FINCANAL' as ProgramPortalRoles,
    CATLDR: 'CATLDR' as ProgramPortalRoles,
    MKTLDR: 'MKTLDR' as ProgramPortalRoles,
    CULDR: 'CULDR' as ProgramPortalRoles,
    COMLDR: 'COMLDR' as ProgramPortalRoles,
    PROGADMNMGR: 'PROGADMNMGR' as ProgramPortalRoles,
    PROGADMNANAL: 'PROGADMNANAL' as ProgramPortalRoles,
    PROGADMN: 'PROGADMN' as ProgramPortalRoles,
    SYSUSER: 'SYSUSER' as ProgramPortalRoles,
};