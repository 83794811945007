<div>
  <div>
    <mat-toolbar>
      <div class="lblheader">
        <mat-label>
          Programs
        </mat-label>
      </div>
    </mat-toolbar>

  </div>
  <div>
    <mat-card>
      <div class="searchbar">
        <ul class="navbar-nav toggle-padding">
          <li class="nav-item dropdown">
            <mat-slide-toggle [checked]="checked" [formControl]="showAllPrograms" (change)="statusChange()" *ngIf="showAllProgramsToggle">Show All Programs</mat-slide-toggle>
        </li>
        <li class="nav-item dropdown">
          <mat-slide-toggle [formControl]="isHavingChangeException" (change)="statusChange()" *ngIf="!programdropdowns.canEditAccruals || (programdropdowns.canApproveorDeny && programdropdowns.canEditAccruals) ">Show All Change Exceptions</mat-slide-toggle>
      </li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item dropdown">

            <mat-form-field>
              <mat-label>Search Programs</mat-label>
              <!-- <input matInput (keyup)="applyFilter()" type="text" [(ngModel)]="SearchProgram" [formControl]="searchPrograms"> -->
              <input matInput (keyup)="applyFilter()" type="text" [formControl]="searchPrograms"  [(ngModel)]="SearchProgram" [formControl]="searchPrograms">
              <!-- <button mat-button  matSuffix mat-icon-button aria-label="Clear" (click)="SearchProgram = ''">
              <mat-icon>close</mat-icon>
            </button> -->
            </mat-form-field>

          </li>
          <li class="nav-item dropdown">
            <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select [formControl]="status" multiple (ngModelChange)="statusChange()">
                <mat-select-trigger>
                  {{status.value ? status.value[0] : ''}}
                  <span *ngIf="status.value?.length > 1" class="status-selection">
                    (+{{status.value.length - 1}} {{status.value?.length === 2 ? 'other' : 'others'}})
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let statusName of statusList" [value]="statusName">{{statusName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li class="nav-item dropdown">
            <mat-form-field>
              <mat-label>Program Manager</mat-label>
              <mat-select [formControl]="programManager" multiple (ngModelChange)="statusChange()">
                <mat-select-trigger>
                  {{programManager.value ? programManager.value[0] : ''}}
                  <span *ngIf="programManager.value?.length > 1" class="programManager-selection">
                    (+{{programManager.value.length - 1}} {{programManager.value?.length === 2 ? 'other' : 'others'}})
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let progNameVal of programManagerList
              " [value]="progNameVal">{{progNameVal}}</mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li class="nav-item dropdown">
            <mat-form-field>
              <mat-label>Channel</mat-label>
              <mat-select [formControl]="channel" multiple (ngModelChange)="statusChange()">
                <mat-select-trigger>
                  {{channel.value ? channel.value[0] : ''}}
                  <span *ngIf="channel.value?.length > 1" class="channel-selection">
                    (+{{channel.value.length - 1}} {{channel.value?.length === 2 ? 'other' : 'others'}})
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let channelName of channelList" [value]="channelName">{{channelName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li class="nav-item dropdown">
            <mat-form-field>
              <mat-label>Business Segment</mat-label>
              <mat-select [formControl]="businessSegment" multiple (ngModelChange)="statusChange()">
                <mat-select-trigger>
                  <span [innerHTML]="businessSegment.value ? businessSegment.value[0] : ''"></span>
                  <span *ngIf="businessSegment.value?.length > 1" class="businessSegment-selection">
                    (+{{businessSegment.value.length - 1}} {{businessSegment.value?.length === 2 ? 'other' : 'others'}})
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let businessSegmentName of businessSegmentList" [value]="businessSegmentName">
                  <span>{{businessSegmentName}}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li class="nav-item dropdown">
            <mat-form-field>
              <mat-label>Year</mat-label>
              <mat-select [formControl]="year" multiple (ngModelChange)="statusChange()">
                <mat-select-trigger>
                  {{year.value ? year.value[0] : ''}}
                  <span *ngIf="year.value?.length > 1" class="year-selection">
                    (+{{year.value.length - 1}} {{year.value?.length === 2 ? 'other' : 'others'}})
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let yearValue of yearList" [value]="yearValue">{{yearValue}}</mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li class="nav-item dropdown">
            <mat-form-field>
              <mat-label>Category</mat-label>
              <mat-select [formControl]="programType" multiple (ngModelChange)="statusChange()">
                <mat-select-trigger>
                  {{programType.value ? programType.value[0] : ''}}
                  <span *ngIf="programType.value?.length > 1" class="year-selection">
                    (+{{programType.value.length - 1}} {{programType.value?.length === 2 ? 'other' : 'others'}})
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let type of programTypelist" [value]="type.value">{{type.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </li>
        </ul>
        <div class="btn-container" [hidden]="!isProgramDownloadButtonVisible">
          <button ><i class="fas fa-download"></i></button>
        </div>
      </div>
      <mat-chip-list>
        <mat-chip class="mat-chip-color" *ngFor="let item of status.value;" [selectable]="selectable"
          [removable]="removable" (removed)="remove(item, 'status');">
          {{item}}
          <mat-icon [ngStyle]="{'color':'white'}" matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <mat-chip class="mat-chip-color" *ngFor="let item of programManager.value;" [selectable]="selectable"
          [removable]="removable" (removed)="remove(item, 'programManager');">
          {{item}}
          <mat-icon [ngStyle]="{'color':'white'}" matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <mat-chip class="mat-chip-color" *ngFor="let item of channel.value;" [selectable]="selectable"
          [removable]="removable" (removed)="remove(item, 'channel');">
          {{item}}
          <mat-icon [ngStyle]="{'color':'white'}" matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <mat-chip class="mat-chip-color" *ngFor="let item of businessSegment.value;" [selectable]="selectable"
          [removable]="removable" (removed)="remove(item, 'businessSegment');">
          <span [innerHTML]="item"></span>
          <mat-icon [ngStyle]="{'color':'white'}" matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <mat-chip class="mat-chip-color" *ngFor="let item of year.value;" [selectable]="selectable"
          [removable]="removable" (removed)="remove(item, 'year');">
          {{item}}
          <mat-icon [ngStyle]="{'color':'white'}" matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>

        <mat-chip class="mat-chip-color" *ngFor="let item of programType.value;" [selectable]="selectable"
          [removable]="removable" (removed)="remove(item, 'programType');">
          {{item}}
          <mat-icon [ngStyle]="{'color':'white'}" matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <div class="matgrid-div">
        <div class="table-container">
          <table mat-table #programsTable class="mat-grid" [dataSource]="dataSource" matSort
            (matSortChange)="sortPrograms($event)" matSortActive="{{(programdropdowns.canApproveorDeny && !core.isAdmin && !programdropdowns.showAllPrograms) ?'':'startDate'}}" matSortDirection="desc">
            <ng-container matColumnDef="expandControl">
              <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
              <td mat-cell *matCellDef="let element" (click)="toggleChildRows(element.code)">
                <mat-icon class="mat-icon-rtl-mirror" *ngIf="element.showExpansionIcon"
                  [style.cursor]="'pointer'" 
                  [style.color]="element.isExpanded ? 'black' : '#1A81E1' ">
                  {{element.isExpanded ? 'arrow_drop_down' : 'arrow_right'}}
                </mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="code">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="code"> <strong>CODE</strong> </th>
              <td mat-cell *matCellDef="let element"> <a href="javascript:void(0);"
                  (click)="navigateToEdit(element.code);">{{element.code}}</a></td>
            </ng-container>

            <ng-container matColumnDef="programName" class="programName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="programName"> PROGRAM NAME </th>
              <td mat-cell *matCellDef="let element"> {{ element.programName }} </td>
            </ng-container>

            <ng-container matColumnDef="programManager">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="programManager"> PROGRAM MANAGER </th>
              <td mat-cell *matCellDef="let element"> {{ element.programManager }} </td>
            </ng-container>

            <ng-container matColumnDef="businessSegment">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="businessSegment"> BUSINESS SEGMENT </th>
              <td mat-cell *matCellDef="let element"
                title="{{element.businessSegment!= null && element.businessSegment.split(',').length > 1 ? element.businessSegment : ''}}">
                <span [innerHTML]="truncateText(element.businessSegment)"></span>
              </td>
            </ng-container>

            <ng-container matColumnDef="channel">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="channel"> CHANNEL </th>
              <td mat-cell *matCellDef="let element"> {{ element.channel }} </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> STATUS </th>
              <td mat-cell *matCellDef="let element"  
              [ngClass]="{'green-color': element.status === statusDefinitionShortDesc._4,'orange-color': element.status === statusDefinitionShortDesc._1 || element.status === statusDefinitionShortDesc._2 || element.status === statusDefinitionShortDesc._3 || element.status === statusDefinitionShortDesc._7,'red-color': element.status === statusDefinitionShortDesc._5 || element.status === statusDefinitionShortDesc._6}"> {{ element.status }} </td>
            </ng-container>

            <ng-container matColumnDef="startDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="startDate"> START DATE </th>
              <td mat-cell *matCellDef="let element"> {{ element.startDate | date: 'MM/dd/yyyy' }} </td>
            </ng-container>

            <ng-container matColumnDef="endDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="endDate"> END DATE </th>
              <td mat-cell *matCellDef="let element"> {{ element.endDate | date: 'MM/dd/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="programYear">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="programYear"> YEAR </th>
              <td mat-cell *matCellDef="let element"> {{ element.programYear }} </td>
            </ng-container>

            <ng-container matColumnDef="totalExposure">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="totalExposure"> EXPOSURE </th>
              <td mat-cell *matCellDef="let element" class="align-right">
                <span>${{ element.totalExposure | number}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="programType">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="programType"> CATEGORY </th>
              <td mat-cell *matCellDef="let element"> {{ element.programType }} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [hidden]="!row.visible" 
              [ngClass]="{'row-colour': row.programType === 'Child' || row.isChangeException}"></tr>

          </table>
        </div>

        <mat-paginator [pageSizeOptions]="[25, 50, 100]" [pageSize]="50" showFirstLastButtons></mat-paginator>


      </div>
    </mat-card>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
      <p style="color: white">Please Wait. </p>
    </ngx-spinner>
  </div>


</div>