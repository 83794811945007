
<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Assign Rates</h1>

<p>Autofill rates for each of the programs below.</p>
<div>
  <mat-card class="card">
    <div class="hierarchy">
      <mat-slide-toggle class="mat-slide-toggle" [style.visibility]="selectedHierarchy === masterMaterialHierarchyLevels['_' + 4] ? 'hidden' : 'visible'" [(ngModel)]="showHierarchyProducts" name="showHierarchyProducts">Show Hierarchy Products</mat-slide-toggle>
    </div>
    <mat-card-content *ngIf="selectedHierarchy !== masterMaterialHierarchyLevels['_' + 4]">
      <table mat-table [dataSource]="hierarchyGroupAccrualsAssignRates" multiTemplateDataRows class="mat-elevation-z0">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.autoFillAssignRates.length > 0">
              {{element[column]}}
            </div>
          </td>
        </ng-container>
        <!-- Expanded Content Column(Hierarchy Column) - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="assignrates">
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.autoFillAssignRates.length > 0">
              <table multiTemplateDataRows mat-table [dataSource]="element.autoFillAssignRates">
                <ng-container matColumnDef="{{innerColumn}}" *ngFor="let innerColumn of innerDisplayedColumns">
                  <div class="mat-label" *ngIf="innerColumn === 'name'">
                    <th mat-header-cell *matHeaderCellDef> {{selectedHierarchy}}</th>
                  </div>
                  <div *ngIf="innerColumn === 'startDate'">
                    <th mat-header-cell *matHeaderCellDef> Start Date </th>
                  </div>
                  <div *ngIf="innerColumn === 'endDate'">
                    <th mat-header-cell *matHeaderCellDef> End Date </th>
                  </div>
                  <div *ngIf="innerColumn === 'volume'">
                    <th mat-header-cell *matHeaderCellDef> Volume % </th>
                  </div>
                  <div *ngIf="innerColumn === 'payRate'">
                    <th mat-header-cell *matHeaderCellDef> Pay Rate % </th>
                  </div>
                  <div *ngIf="innerColumn === 'accrual'">
                    <th mat-header-cell *matHeaderCellDef> Accrual % </th>
                  </div>
                  <td mat-cell *matCellDef="let element">
                    <div class="mat-label" *ngIf="innerColumn == 'name'">
                      <mat-label *ngIf="selectedHierarchy === masterMaterialHierarchyLevels['_' + 0]">{{element['performanceCenterName']}} ({{element['performanceCenterCode']}})</mat-label>
                      <mat-label *ngIf="selectedHierarchy === masterMaterialHierarchyLevels['_' + 1]">{{element['productCenterName']}} ({{element['productCenterCode']}})</mat-label>
                      <mat-label *ngIf="selectedHierarchy === masterMaterialHierarchyLevels['_' + 2]">{{element['plannedProductName']}} ({{element['plannedProductCode']}})</mat-label>
                      <mat-label *ngIf="selectedHierarchy === masterMaterialHierarchyLevels['_' + 3]">{{element['tradeProductName']}} ({{element['tradeProductCode']}})</mat-label>
                    </div>
                    <div *ngIf="(innerColumn === 'volume') || (innerColumn === 'accrual')|| (innerColumn === 'payRate')">
                      <mat-form-field class="input" appearance="fill">
                        <input *ngIf="(selectedHierarchy === masterMaterialHierarchyLevels['_' + 0]) && ((innerColumn === 'volume') || (innerColumn === 'payRate'))" (change)="calculateHierarchyAccrualRate(element['volume'],element['payRate'],element['programId'],element['performanceCenterCode'],selectedHierarchy)" matInput (keypress)="restrictChar($event);" maxlength="7" type="text" [cleave]="{numeral: true,numeralThousandsGroupStyle: 'none', numeralIntegerScale: 3, numeralDecimalScale: 3}" [(ngModel)]="element[innerColumn]">
                        <input *ngIf="(selectedHierarchy === masterMaterialHierarchyLevels['_' + 1]) && ((innerColumn === 'volume') || (innerColumn === 'payRate'))" (change)="calculateHierarchyAccrualRate(element['volume'],element['payRate'],element['programId'],element['productCenterCode'],selectedHierarchy)" matInput (keypress)="restrictChar($event);" maxlength="7" type="text" [cleave]="{numeral: true,numeralThousandsGroupStyle: 'none', numeralIntegerScale: 3, numeralDecimalScale: 3}" [(ngModel)]="element[innerColumn]">
                        <input *ngIf="(selectedHierarchy === masterMaterialHierarchyLevels['_' + 2]) && ((innerColumn === 'volume') || (innerColumn === 'payRate'))" (change)="calculateHierarchyAccrualRate(element['volume'],element['payRate'],element['programId'],element['plannedProductCode'],selectedHierarchy)" matInput (keypress)="restrictChar($event);" maxlength="7" type="text" [cleave]="{numeral: true,numeralThousandsGroupStyle: 'none', numeralIntegerScale: 3, numeralDecimalScale: 3}" [(ngModel)]="element[innerColumn]">
                        <input *ngIf="(selectedHierarchy === masterMaterialHierarchyLevels['_' + 3]) && ((innerColumn === 'volume') || (innerColumn === 'payRate'))" (change)="calculateHierarchyAccrualRate(element['volume'],element['payRate'],element['programId'],element['tradeProductCode'],selectedHierarchy)" matInput (keypress)="restrictChar($event);" maxlength="7" type="text" [cleave]="{numeral: true,numeralThousandsGroupStyle: 'none', numeralIntegerScale: 3, numeralDecimalScale: 3}" [(ngModel)]="element[innerColumn]">
                        <input *ngIf="(innerColumn === 'accrual')" matInput type="text" [cleave]="{numeral: true,numeralThousandsGroupStyle: 'none', numeralIntegerScale: 4, numeralDecimalScale: 3}" [(ngModel)]="element[innerColumn]" readonly>
                      </mat-form-field>
                    </div>
                    <div *ngIf="(innerColumn === 'startDate')">
                      <mat-form-field aria-placeholder="" class="mat-select" appearance="fill">
                        <mat-select [(value)]="element['startColumn']">
                          <mat-option *ngFor="let date of element.dates" [value]="date.value">
                            {{date.viewValue}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngIf="(innerColumn === 'endDate')">
                      <mat-form-field class="mat-select" appearance="fill">
                        <mat-select [(value)]="element['endColumn']">
                          <mat-option *ngFor="let date of element.dates" [value]="date.value">
                            {{date.viewValue}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="assignProducts">
                  <div class="mat-label" *ngIf="showHierarchyProducts">
                    <th mat-header-cell *matHeaderCellDef> Products </th>
                  </div>
                  <td mat-cell [style.visibility]="!showHierarchyProducts ? 'hidden' : 'visible'" colspan="4" *matCellDef="let element" class="mat-cell">
                    <div class="bullet-list">
                      <ul>
                        <li *ngFor="let item of element.assignProducts">
                          <span>{{item.materialName}} ({{item.materialCode}})</span>
                        </li>
                      </ul>
                    </div>
                  </td>
                </ng-container>
                <tr class="mat-row-line" mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                <tr class="mat-row-line" mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
                <tr class="mat-row-line" mat-row *matRowDef="let element; columns: ['assignProducts']" [hidden]="!showHierarchyProducts"></tr>
              </table>
            </div>
          </td>
        </ng-container>
        <tr class="mat-row-line" mat-row *matRowDef="let element; columns: columnsToDisplay;" [hidden]="(element.autoFillAssignRates.length == 0)">
        </tr>
        <tr class="mat-row-line" mat-row *matRowDef="let row; columns: ['assignrates']" [hidden]="(row.autoFillAssignRates.length == 0)"></tr>
      </table>
    </mat-card-content>
    <mat-card-content *ngIf="selectedHierarchy === 'Material'">
      <table mat-table [dataSource]="accrualsAssignRates" multiTemplateDataRows class="mat-elevation-z0">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.autoFillAssignRates.length > 0">
              {{element[column]}}
            </div>
          </td>
        </ng-container>
        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="assignrates">
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.autoFillAssignRates.length > 0">
              <table mat-table [dataSource]="element.autoFillAssignRates">
                <ng-container matColumnDef="{{innerColumn}}" *ngFor="let innerColumn of innerDisplayedColumns">
                  <div class="mat-label" *ngIf="innerColumn === 'name'">
                    <th mat-header-cell *matHeaderCellDef> Material</th>
                  </div>
                  <div *ngIf="innerColumn === 'startDate'">
                    <th mat-header-cell *matHeaderCellDef> Start Date </th>
                  </div>
                  <div *ngIf="innerColumn === 'endDate'">
                    <th mat-header-cell *matHeaderCellDef> End Date </th>
                  </div>
                  <div *ngIf="innerColumn === 'volume'">
                    <th mat-header-cell *matHeaderCellDef> Volume % </th>
                  </div>
                  <div *ngIf="innerColumn === 'payRate'">
                    <th mat-header-cell *matHeaderCellDef> Pay Rate % </th>
                  </div>
                  <div *ngIf="innerColumn === 'accrual'">
                    <th mat-header-cell *matHeaderCellDef> Accrual % </th>
                  </div>
                  <td mat-cell *matCellDef="let element">
                    <div class="mat-label" *ngIf="innerColumn === 'name'">
                      <mat-label>{{element['materialName']}} ({{element['materialCode']}})</mat-label>
                    </div>
                    <div *ngIf="(innerColumn === 'volume') || (innerColumn === 'accrual')|| (innerColumn === 'payRate')">
                      <mat-form-field class="input" appearance="fill">
                        <input *ngIf="(innerColumn === 'volume') || (innerColumn === 'payRate')" (change)="calculateAccrualRate(element['volume'],element['payRate'],element['programId'],element['materialCode'])" matInput (keypress)="restrictChar($event);" maxlength="7" type="text" [cleave]="{numeral: true,numeralThousandsGroupStyle: 'none', numeralIntegerScale: 3, numeralDecimalScale: 3}" [(ngModel)]="element[innerColumn]">
                        <input *ngIf="(innerColumn === 'accrual')" matInput type="text" [cleave]="{numeral: true,numeralThousandsGroupStyle: 'none', numeralIntegerScale: 4, numeralDecimalScale: 3}" [(ngModel)]="element[innerColumn]" readonly>
                      </mat-form-field>
                    </div>
                    <div *ngIf="(innerColumn === 'startDate')">
                      <mat-form-field aria-placeholder="" class="mat-select" appearance="fill">
                        <mat-select [(value)]="element['startColumn']">
                          <mat-option *ngFor="let date of element.dates" [value]="date.value">
                            {{date.viewValue}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngIf="(innerColumn === 'endDate')">
                      <mat-form-field class="mat-select" appearance="fill">
                        <mat-select [(value)]="element['endColumn']">
                          <mat-option *ngFor="let date of element.dates" [value]="date.value">
                            {{date.viewValue}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </td>
                </ng-container>
                <tr class="mat-row-line" mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                <tr class="mat-row-line" mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
              </table>
            </div>
          </td>
        </ng-container>
        <tr class="mat-row-line" mat-row *matRowDef="let element; columns: columnsToDisplay;" [hidden]="(element.autoFillAssignRates.length == 0)">
        </tr>
        <tr class="mat-row-line" mat-row *matRowDef="let row; columns: ['assignrates']" [hidden]="(row.autoFillAssignRates.length == 0)"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>
<mat-card-footer>
  <div>
    <button mat-raised-button class="modal-functional-button-dark" (click)="onApply();"> Apply</button>
    <button mat-raised-button class="modal-functional-button" (click)="onCancel();"> Cancel</button>
  </div>
</mat-card-footer>



