import { Component, OnInit, AfterViewInit, ViewChild, OnChanges, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProgPortalContextService } from '../core/services/progportalcontextservice';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { RouteParamsService } from '../core/services/route-params.service';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpHeaders } from '@angular/common/http';
import { CoreRepository } from '../core/core.repository';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-accruals',
  templateUrl: './accruals.component.html',
  styleUrls: ['./accruals.component.scss']


})
export class AccrualsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('paginator', { read: MatPaginator }) paginator: MatPaginator;
  @ViewChild('paginator_tab2', { read: MatPaginator }) paginator_tab2: MatPaginator;
  @ViewChild('paginator_tab3', { read: MatPaginator }) paginator_tab3: MatPaginator;
  @ViewChild('sort', { read: MatSort }) sort: MatSort;
  @ViewChild('sort_tab2', { read: MatSort }) sort_tab2: MatSort;
  @ViewChild('sort_tab3', { read: MatSort }) sort_tab3: MatSort;

  checked: boolean;
  displayedColumns: string[] = ['select', 'code', 'programName', 'programManager', 'startDate', 'endDate'];
  dataSourceMyAccrualPrograms: MatTableDataSource<ProgramsItem>;
  dataSourceAllAccrualPrograms: MatTableDataSource<ProgramsItem>;
  dataSourceAssignedToMeAccrualPrograms: MatTableDataSource<ProgramsItem>;

  activeGroup: number;
  groupTemplateList: string[] = ['My Programs', 'Available Programs', 'Action Required'];
  postDataItem: PostItem[] = [];
  SearchProgram: any;
  searchPrograms = new FormControl();
  year = new FormControl();
  yearList: any;
  yearDropdown: string[] = [];
  filterValues = [];
  cancelSubject = new Subject();
  pendingAccrualsCount: number;
  completedAccrualsCount: number;
  removable = true;
  selectable = true;
  selection = new SelectionModel<ProgramsItem>(true, []);
  selectCounter = 0;
  isButtonEnable: boolean = true;
  ids: number[] = [];
  id: any;
  tabIndex: number = 2;
  isLinkId = 'false';
  isBackEditAccrual = 'false';
  adminSelectedProductManager:string = '';
  allProductManagers: any[] = [];
  selectedProductManager:any;
  selectedYear :string[];
  isInitialLoad = false;
  bindTableIndex: number = 0;

  constructor(public context: ProgPortalContextService,
    private spinner: NgxSpinnerService,
    public core: CoreRepository,
    public app: AppComponent,
    private routeParamsService: RouteParamsService
  ) {

    this.selection.changed.subscribe(item => {
      this.isButtonEnable = this.selection.selected.length == 0;
      this.selectCounter = this.selection.selected.length
      this.ids = [];

      this.selection.selected.forEach(element => {
        this.ids.push(element.id);
      })
      this.id = JSON.stringify(this.ids).substring(1, JSON.stringify(this.ids).length - 1)
    })

    this.routeParamsService.getAccrualsTabIndexRouteParams().subscribe(param => {
      if (param) {
        this.tabIndex = parseFloat(param);
      }
      else {
        this.tabIndex = 2;
      }
    });

    this.routeParamsService.getIsLinkIdRouteParams().subscribe(param => {
      if (param) {
        this.isLinkId = param;
      }
      else {
        this.isLinkId = 'false';
      }
    });

    this.routeParamsService.getIsEditAccrualsRouteParams().subscribe(param => {
      if (param) {
        this.isBackEditAccrual = param;
      }
      else {
        this.isBackEditAccrual = 'false';
      }
    });
    
    if(this.isAdmin){

      this.routeParamsService.getAdminSelectedProductManagerRouteParams().subscribe(param => {
        if (param) {
          this.adminSelectedProductManager = param;
        }
        else {
          this.adminSelectedProductManager = '';
        }
      });
    }    

    this.routeParamsService.getProgramIdsRouteParams().subscribe(params => {
      if (params && this.ids.length === 0) {
        this.id = params;
        var arrayIds = params.split(',');
        arrayIds.forEach(arr => {
          this.ids.push(Number(arr));
        })
      }
    });

    this.dataSourceMyAccrualPrograms = new MatTableDataSource([]);
    this.dataSourceAllAccrualPrograms = new MatTableDataSource([]);
    this.dataSourceAssignedToMeAccrualPrograms = new MatTableDataSource([]);
    var getCurrentYear = new Date().getFullYear();
    var getPreviousYear = new Date().getFullYear() - 1;
    var currentMarketYear = getPreviousYear.toString().substring(2, 4) + "-" + getCurrentYear.toString().substring(2, 4);
    this.selectedYear = [currentMarketYear];
    var getNextYear = new Date().getFullYear() + 1;
    var nextMarketYear = getCurrentYear.toString().substring(2, 4) + "-" + getNextYear.toString().substring(2, 4);
    this.selectedYear.push(nextMarketYear);
  }


  ngOnInit(): void {
    this.spinner.show();
    if (this.isBackEditAccrual === "false") {
      this.routeParamsService.clearProgramIdsRouteParams();
      this.id = "";
      this.ids = [];
      this.tabIndex = 2;
    }
    this.checked = true;
    this.isInitialLoad = true;
  }

  ngAfterViewInit() {
    this.bindtabledata();
    this.binddropdowns();
    this.isInitialLoad = false;
    if(this.isAdmin()){
      this.bindProductManagers();
    }
  }

  ngOnDestroy() {
    if (this.isBackEditAccrual === "true") {
      this.routeParamsService.clearIsEditAccrualsRouteParams();
    }
    else {
      this.tabIndex = 2;
    }
  }

  ngOnChanges() {
    this.bindPagination();
  }

  bindProductManagers() {
    this.context.getdatafromService("/api/Program/Option/GetProductManagers").subscribe(response => {
      if(response!= null){
        this.allProductManagers = response;
        if(this.isAdmin){
          this.selectedProductManager = this.allProductManagers.filter(row => row.email === this.adminSelectedProductManager)[0];
        }
      }
    });
  }

  bindPagination() {
    switch (this.tabIndex) {
      case 0:
        this.dataSourceMyAccrualPrograms.paginator = this.paginator;
        this.dataSourceMyAccrualPrograms.sort = this.sort;
        break;
      case 1:
        this.dataSourceAllAccrualPrograms.paginator = this.paginator_tab2;
        this.dataSourceAllAccrualPrograms.sort = this.sort_tab2;
        break;
      case 2:
        this.dataSourceAssignedToMeAccrualPrograms.paginator = this.paginator_tab3;
        this.dataSourceAssignedToMeAccrualPrograms.sort = this.sort_tab3;
        break;
    }
  }

  canEditAccrual(): boolean {
    return this.core.canEditAccrual;
  }

 isAdmin(): boolean {
    return this.core.isAdmin;
  }

  canViewAccrual(): boolean {
    return this.core.canViewAccrual;
  }

  bindtabledata() {
    if (!this.isInitialLoad || this.bindTableIndex == 0)
    {
      this.bindTableIndex++;
      this.spinner.show();
      this.postDataItem = [];
      if (this.yearDropdown.length > 0) {
        this.postDataItem.push({ yearList: this.yearDropdown, tabIndex: this.tabIndex,productManager: this.adminSelectedProductManager });
      }
      else {
        this.postDataItem.push({ yearList: null, tabIndex: this.tabIndex,productManager: this.adminSelectedProductManager });
      }

    const headers = new HttpHeaders().set('content-type', 'application/json');
    this.context.postdatatoservice("/api/Accrual/Dashboard/List/CompletedAccrualPrograms", JSON.stringify(this.postDataItem), headers)
      .pipe(takeUntil(this.cancelSubject)).toPromise().then((response) => {
        if (response != null) {
          this.completedAccrualsCount = response.length;
          if (this.SearchProgram !== undefined) {
            this.applyFilter();
          }
        }
      });

    this.context.postdatatoservice("/api/Accrual/Dashboard/List/PendingAccrualPrograms", JSON.stringify(this.postDataItem), headers)
      .pipe(takeUntil(this.cancelSubject)).toPromise().then((response) => {
        if (response != null) {
          this.pendingAccrualsCount = response.length;
          if (this.SearchProgram !== undefined) {
            this.applyFilter();
          }
        }
      });

      this.context.postdatatoservice("/api/Accrual/Dashboard/List/AccrualPrograms", JSON.stringify(this.postDataItem), headers)
        .pipe(takeUntil(this.cancelSubject)).toPromise().then((response) => {
          if (response != null) {
            switch (this.tabIndex) {
              case 0:
                this.dataSourceMyAccrualPrograms = new MatTableDataSource(response);
                this.completedAccrualsCount = response.length;
                this.dataSourceMyAccrualPrograms.paginator = this.paginator;
                this.dataSourceMyAccrualPrograms.sort = this.sort;
                break;
              case 1:
                this.dataSourceAllAccrualPrograms = new MatTableDataSource(response);
                this.dataSourceAllAccrualPrograms.paginator = this.paginator_tab2;
                this.dataSourceAllAccrualPrograms.sort = this.sort_tab2;
                break;
              case 2:
                this.dataSourceAssignedToMeAccrualPrograms = new MatTableDataSource(response);
                this.pendingAccrualsCount = response.length;
                this.dataSourceAssignedToMeAccrualPrograms.paginator = this.paginator_tab3;
                this.dataSourceAssignedToMeAccrualPrograms.sort = this.sort_tab3;
                break;
            }
            this.reSelectforBack();

            this.spinner.hide();
            if (this.SearchProgram !== undefined) {
              this.applyFilter();
            }
          }
        });
    }
  }

  binddropdowns() {
    this.context.getdatafromService('/api/Program/Filter/ProgramYear').toPromise().then((response) => {
      if (response != null) {
        this.yearList = response;
      }
    });
  }

  applyFilter() {
    this.selection.clear();

    const filterValue = this.SearchProgram;
    switch (this.tabIndex) {
      case 0:
        this.dataSourceMyAccrualPrograms.filter = filterValue.trim().toLowerCase();
        break;
      case 1:
        this.dataSourceAllAccrualPrograms.filter = filterValue.trim().toLowerCase();
        break;
      case 2:
        this.dataSourceAssignedToMeAccrualPrograms.filter = filterValue.trim().toLowerCase();
        break;
    }
  }

  statusChange() {
    this.filterValues = [];
    if (this.year.value != null && this.year.value.length > 0) { this.yearDropdown = this.year.value; }
    this.bindtabledata();
    this.selection.clear();
  }

  groupTabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.tabIndex = tabChangeEvent.index.valueOf()
    this.selection.clear();
    this.bindtabledata();
    this.binddropdowns();
    this.bindPagination();
  }

  remove(item: string, select: string): void {
    const yearIndex = this.year.value.indexOf(item);
    if (yearIndex >= 0) {
      this.year.value.splice(yearIndex, 1);
      this.year.setValue(this.year.value);
    }
    this.selection.clear();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let numRows = 0;
    switch (this.tabIndex) {
      case 0:
        numRows = this.dataSourceMyAccrualPrograms.data.length;
        break;
      case 1:
        numRows = this.dataSourceAllAccrualPrograms.data.length;
        break;
      case 2:
        numRows = this.dataSourceAssignedToMeAccrualPrograms.data.length;
        break;
    }
    return numSelected === numRows;
  } 

  reSelectforBack() {
    if (this.isLinkId === 'false') {
      switch (this.tabIndex) {
        case 0:
          this.ids.forEach(id => {
            if(!this.dataSourceMyAccrualPrograms.data.some(element => element.id === id)){
              this.removeStringValue(this.id,id);              
            }
          });
            this.reAssignIds();
          this.ids.forEach(id => {
            this.dataSourceMyAccrualPrograms.data.forEach(row => {
              if (id === row.id) {
                this.selection.select(row);
              }
            })
          })
          break;
        case 1:
          this.ids.forEach(id => {
            if(!this.dataSourceAllAccrualPrograms.data.some(element => element.id === id)){
              this.removeStringValue(this.id,id);              
            }
          });
            this.reAssignIds();  
          this.ids.forEach(id => {
            this.dataSourceAllAccrualPrograms.data.forEach(row => {
              if (id === row.id) {
                this.selection.select(row);
              }
            })
          })
          break;
        case 2:
          this.ids.forEach(id => {
            if(!this.dataSourceAssignedToMeAccrualPrograms.data.some(element => element.id === id)){
              this.removeStringValue(this.id,id);              
            }
          });
            this.reAssignIds();  
            this.ids.forEach(id => {
              this.dataSourceAssignedToMeAccrualPrograms.data.forEach(row => {
                if (id === row.id) {
                  this.selection.select(row);
                }
              })
            });             
          break;
      }
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    switch (this.tabIndex) {
      case 0:
        this.isAllSelected() ? this.selection.clear() : this.dataSourceMyAccrualPrograms.data.forEach(row => this.selection.select(row));
        break;
      case 1:
        this.isAllSelected() ? this.selection.clear() : this.dataSourceAllAccrualPrograms.data.forEach(row => this.selection.select(row));
        break;
      case 2:
        this.isAllSelected() ? this.selection.clear() : this.dataSourceAssignedToMeAccrualPrograms.data.forEach(row => this.selection.select(row));
        break;
    }
  }

  checkboxLabel(row?: ProgramsItem): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  productManagerChange(productManager: MatSelectChange) {
     if(productManager.value){
       if(this.core.getUsername() === productManager.value.email){
        this.adminSelectedProductManager = '';
       }
       else{
        this.adminSelectedProductManager = productManager.value.email;
      }
      this.selection.clear();

      this.bindtabledata();
    }
  }

  sendToRouteParam(): void {
    // send program Ids, tabIndex and IsLinkId to subscribers via observable subject
    this.routeParamsService.clearProgramIdsSettings();
    this.routeParamsService.sendProgramIdsRouteParams(this.id);
    this.routeParamsService.clearAdminSelectedProductManagerSettings();
    this.routeParamsService.sendAdminSelectedProductManagerRouteParams(this.adminSelectedProductManager)
    this.routeParamsService.sendAccrualsTabIndexRouteParams(this.tabIndex.toString());
  }

  sendMultipleProgramIds(): void {
    this.sendToRouteParam();
    this.routeParamsService.sendIsLinkIdRouteParams('false');
  }

  sendProgramId(programId): void {
    this.id = String(programId);
    this.sendToRouteParam();
    this.routeParamsService.sendIsLinkIdRouteParams('true');
  }
  removeStringValue(stringValue, value) {
    stringValue = stringValue.split(',');
    stringValue.splice(stringValue.indexOf(value), 1);
    return stringValue.join(',');
  }
   reAssignIds(){
    this.routeParamsService.clearProgramIdsRouteParams();
    this.sendToRouteParam();
    this.routeParamsService.getProgramIdsRouteParams().subscribe(params => {
      if (params && this.ids.length === 0) {
        this.id = params;
        var arrayIds = params.split(',');
        arrayIds.forEach(arr => {
          this.ids.push(Number(arr));
        })
      }
    });
   }
}

export interface PostItem {
  yearList?: string[];
  tabIndex: number;
  productManager: string;
}

export interface ProgramsItem {
  id: number;
  code: string;
  programName: string;
  programManager: string;
  startDate: string;
  endDate: string;
  status: string;
  year: string;
}
