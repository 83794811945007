import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { CoreRepository } from '../core.repository';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { ErrorDialogComponent } from 'src/app/shared/dialogs/error-dialog.component';


@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private coreRepository: CoreRepository, private dialog: MatDialog) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.coreRepository.getAccessToken().pipe(
      take(1),
      switchMap(accessToken =>
        next
          .handle(
            req.clone({
              headers: req.headers.set('Authorization', `Bearer ${accessToken}`)
            })
          )
          .pipe(
            catchError((response: HttpErrorResponse) => {
              if (response.status === 403) {
                return ErrorDialogComponent.open(this.dialog, { title: 'Unauthorized', description: 'You do not have access to perform this action' }).pipe(
                  map(() => new HttpResponse({})) // this will never be returned since modal can't be closed
                );
              } else {
                return throwError(response);
              }
            })
          )
      )
    );
  }
}
