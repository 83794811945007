
<h1 mat-dialog-title>Accruals Assign Rates Error and Warning Details</h1>

<p>Accruals errors and warnings for each of the programs below.</p>
<div>
  <mat-card class="card">
    <mat-card-content *ngIf="selectedHierarchy !== masterMaterialHierarchyLevels['_' + 4]">
      <table mat-table [dataSource]="hierarchyGroupAccrualsErrorAndWarnings" multiTemplateDataRows class="mat-elevation-z0">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.accrualsHierarchyErrorAndWarnings.length > 0">
              {{element[column]}}
            </div>
          </td>
        </ng-container>
        <!-- Expanded Content Column(Hierarchy Column) - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="assignrates">
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.accrualsHierarchyErrorAndWarnings.length > 0">
              <table multiTemplateDataRows mat-table [dataSource]="element.accrualsHierarchyErrorAndWarnings">
                <ng-container matColumnDef="{{innerColumn}}" *ngFor="let innerColumn of innerDisplayedColumns">
                  <div *ngIf="innerColumn === 'name'">
                    <th mat-header-cell *matHeaderCellDef> {{selectedHierarchy}}</th>
                  </div>
                  <td mat-cell *matCellDef="let element">
                    <div *ngIf="innerColumn == 'name'">
                      <mat-label *ngIf="selectedHierarchy === masterMaterialHierarchyLevels['_' + 0]">{{element['performanceCenterName']}} ({{element['performanceCenterCode']}})</mat-label>
                      <mat-label *ngIf="selectedHierarchy === masterMaterialHierarchyLevels['_' + 1]">{{element['productCenterName']}} ({{element['productCenterCode']}})</mat-label>
                      <mat-label *ngIf="selectedHierarchy === masterMaterialHierarchyLevels['_' + 2]">{{element['plannedProductName']}} ({{element['plannedProductCode']}})</mat-label>
                      <mat-label *ngIf="selectedHierarchy === masterMaterialHierarchyLevels['_' + 3]">{{element['tradeProductName']}} ({{element['tradeProductCode']}})</mat-label>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="assignProducts">
                  <div>
                    <th mat-header-cell *matHeaderCellDef> Products </th>
                  </div>
                  <td mat-cell *matCellDef="let element" class="mat-cell">
                    <div class="bullet-list">
                      <ul>
                        <li *ngFor="let item of element.assignProducts">
                          <span>
                            {{item.materialName}} ({{item.materialCode}})
                            <mat-icon *ngIf="item.isHavingError" matTooltipPosition="above" matTooltipClass="mat-tooltip-error" [matTooltip]="item.errorMessage" class="matcard-material-error-icon">error</mat-icon>
                            <mat-icon *ngIf="item.isHavingWarning" matTooltipPosition="above" matTooltipClass="mat-tooltip-warning" [matTooltip]="item.warningMessage" class="matcard-material-warning-icon">warning</mat-icon>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </td>
                </ng-container>
                <tr class="mat-row-line" mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                <tr class="mat-row-line" mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
                <tr class="mat-row-line" mat-row *matRowDef="let element; columns: ['assignProducts']"></tr>
              </table>
            </div>
          </td>
        </ng-container>
        <tr class="mat-row-line" mat-row *matRowDef="let element; columns: columnsToDisplay;" [hidden]="(element.accrualsHierarchyErrorAndWarnings.length == 0)">
        </tr>
        <tr class="mat-row-line" mat-row *matRowDef="let row; columns: ['assignrates']" [hidden]="(row.accrualsHierarchyErrorAndWarnings.length == 0)"></tr>
      </table>
    </mat-card-content>
    <mat-card-content *ngIf="selectedHierarchy === masterMaterialHierarchyLevels['_' + 4]">
      <table mat-table [dataSource]="accrualsErrorAndWarnings" multiTemplateDataRows class="mat-elevation-z0">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.accrualsHierarchyErrorAndWarnings.length > 0">
              {{element[column]}}
            </div>
          </td>
        </ng-container>
        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="assignrates">
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.accrualsHierarchyErrorAndWarnings.length > 0">
              <table mat-table [dataSource]="element.accrualsHierarchyErrorAndWarnings">
                <ng-container matColumnDef="{{innerColumn}}" *ngFor="let innerColumn of innerDisplayedColumns">
                  <div *ngIf="innerColumn === 'name'">
                    <th mat-header-cell *matHeaderCellDef> Material</th>
                  </div>
                  <td mat-cell *matCellDef="let element">
                    <div *ngIf="innerColumn === 'name'">
                      <span>
                        {{element['materialName']}} ({{element['materialCode']}})
                        <mat-icon *ngIf="element.isHavingError" matTooltipPosition="above" matTooltipClass="mat-tooltip-error" [matTooltip]="element['errorMessage']" class="matcard-material-error-icon">error</mat-icon>
                        <mat-icon *ngIf="element.isHavingWarning" matTooltipPosition="above" matTooltipClass="mat-tooltip-warning" [matTooltip]="element['warningMessage']" class="matcard-material-warning-icon">warning</mat-icon>
                      </span>
                    </div>
                  </td>
                </ng-container>
                <tr class="mat-row-line" mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                <tr class="mat-row-line" mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
              </table>
            </div>
          </td>
        </ng-container>
        <tr class="mat-row-line" mat-row *matRowDef="let element; columns: columnsToDisplay;" [hidden]="(element.accrualsHierarchyErrorAndWarnings.length == 0)">
        </tr>
        <tr class="mat-row-line" mat-row *matRowDef="let row; columns: ['assignrates']" [hidden]="(row.accrualsHierarchyErrorAndWarnings.length == 0)"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>
<mat-card-footer>
  <div>
    <button mat-raised-button class="functional-button-dark" [mat-dialog-close]="data"> OK</button>
  </div>
</mat-card-footer>


