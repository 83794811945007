export enum ProgramTableTypeIds{
    Program = 1,
    NonAccruingProgram = 2,
    ExternalAccruingProgram = 3
  }
  
  export enum ProgramTableType{
    _1 = 'Program',
    _2 ='NonAccruingProgram',
    _3 = 'ExternalAccruingProgram'
  }