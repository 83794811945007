import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router, NavigationEnd} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { CoreRepository } from '../core/core.repository';
import { ProgramCategories } from '../core/enums/programCategoryEnums';
import { ProgramTableTypeIds } from '../core/enums/programTableTypeEnums';
import { StatusDefinitionIds, StatusDefinitionShortDesc, StatusDefinitionUIShortDesc } from '../core/enums/statusDefinitionEnums';
import { ProgPortalContextService } from '../core/services/progportalcontextservice';
import { User } from '../core/user/User';
import { UrlService } from 'src/app/core/services/url-service.service';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProgramsComponent implements OnInit, AfterViewInit, OnDestroy {
  selectable = true;
  removable = true;
  status = new FormControl();
  programManager = new FormControl();
  channel = new FormControl();
  businessSegment = new FormControl();
  year = new FormControl();
  programType = new FormControl();
  SearchProgram: any;
  searchPrograms = new FormControl();
  programdropdowns = new ProgramDropdowns();
  cancelSubject = new Subject();
  isHavingChangeException = new FormControl();
  showAllPrograms = new FormControl();
  userName:string;
  displayedColumns: string[] = ['expandControl', 'code', 'programName', 'programType',
    'programManager', 'businessSegment',
    'channel', 'status', 'startDate', 'endDate', 'programYear', 'totalExposure'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('programsTable') matTable: MatTable<ProgramsItem>;
  filterValues = [];
  statusval: any;
  checked: boolean;
  statusList: any;
  programManagerList: any;
  channelList: any;
  yearList: any;
  businessSegmentList: any;
  user: User;
  programTypelist: any[];
  programTableTypeIds = ProgramTableTypeIds;
  dataSource: MatTableDataSource<ProgramsItem>;
  programItems: ProgramsItem[] = [];
  expandedElement: any | null;
  isProgramDownloadButtonVisible = false;
  showAllProgramsToggle: boolean = false;
  statusDefinitionShortDesc = StatusDefinitionShortDesc;
  programsRawData: ProgramsItem[];
  previousUrl: string = "";
  currentUrl: string = "";

  constructor(public context: ProgPortalContextService,
    private router: Router,
    private spinner: NgxSpinnerService,
    public app: AppComponent,
    public core: CoreRepository,
    private urlService: UrlService) {
      this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {        
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.urlService.setPreviousUrl(this.previousUrl);
      };
    });
  }
  applyFilter() {
    const filterValue = this.SearchProgram;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  ngOnInit(): void {
    this.spinner.show();
    this.checked = true;
  }
  ngAfterViewInit() {
    this.binddropdowns();
    this.programTypelist = [{ value: 'Standard' }, { value: 'Parent-Child' }, { value: 'Route only' }, { value: 'Expense P&A' }];
  }

  canEditProgram(): boolean {
    return this.core.canEditProgram;
  }

  navigateToEdit(programCode, status) {
    if (status == StatusDefinitionUIShortDesc._1) {
      var parentProgram = this.programsRawData.filter(x => x.childProgramCode == programCode);
      var parentProgramStatusDefinitionId;
      if (parentProgram != null) {
        parentProgram.forEach((prg)=>{
          if(prg.programCode!=null){
             parentProgramStatusDefinitionId = this.programsRawData.filter(x => x.programCode == prg.programCode && x.programType=="Parent")[0].status;
          }
        })
       
        if ((parentProgramStatusDefinitionId == StatusDefinitionShortDesc._7 || parentProgramStatusDefinitionId == StatusDefinitionShortDesc._1 || parentProgramStatusDefinitionId == StatusDefinitionShortDesc._2) && this.canEditProgram()) {
          this.router.navigate(['programs/edit-program/' + programCode]);
        } else {
          this.router.navigate(['programs/review-program/' + programCode]);
        }
      }
    } else
      if ((status == StatusDefinitionShortDesc._7 || status == StatusDefinitionShortDesc._1 || status == StatusDefinitionShortDesc._2) && this.canEditProgram()) {
        this.router.navigate(['programs/edit-program/' + programCode]);
      } else {
        this.router.navigate(['programs/review-program/' + programCode]);
      }
  }

  remove(item: string, select: string): void {
    // Splice(): removes item from an array at a particular index
    // setValue(): Updates the form control value after removing the chip
    switch (select) {
      case 'status':
        const statusIndex = this.status.value.indexOf(item);
        if (statusIndex >= 0) {
          this.status.value.splice(statusIndex, 1);
          this.status.setValue(this.status.value);
        }
        break;
      case 'programManager':
        const programManagerIndex = this.programManager.value.indexOf(item);
        if (programManagerIndex >= 0) {
          this.programManager.value.splice(programManagerIndex, 1);
          this.programManager.setValue(this.programManager.value);
        }
        break;
      case 'channel':
        const channelIndex = this.channel.value.indexOf(item);
        if (channelIndex >= 0) {
          this.channel.value.splice(channelIndex, 1);
          this.channel.setValue(this.channel.value);
        }
        break;
      case 'businessSegment':
        const businessSegmentIndex = this.businessSegment.value.indexOf(item);
        if (businessSegmentIndex >= 0) {
          this.businessSegment.value.splice(businessSegmentIndex, 1);
          this.businessSegment.setValue(this.businessSegment.value);
        }
        break;
      case 'year':
        const yearIndex = this.year.value.indexOf(item);
        if (yearIndex >= 0) {
          this.year.value.splice(yearIndex, 1);
          this.year.setValue(this.year.value);
        }
        break;
      case 'programType':
        const typeIndex = this.programType.value.indexOf(item);
        if (typeIndex >= 0) {
          this.programType.value.splice(typeIndex, 1);
          this.programType.setValue(this.programType.value);
        }
        break;

      default:
        break;
    }


  }
  statusChange() {
    this.filterValues = [];
    this.programdropdowns = new ProgramDropdowns();
    if (this.status.value != null && this.status.value.length > 0) { this.programdropdowns.statusList = this.status.value; }
    if (this.programManager.value != null && this.programManager.value.length > 0) { this.programdropdowns.programManagerList = this.programManager.value; }
    if (this.channel.value != null && this.channel.value.length > 0) { this.programdropdowns.channelList = this.channel.value; }
    if (this.businessSegment.value != null && this.businessSegment.value.length > 0) {
      this.programdropdowns.businessSegmentList = this.businessSegment.value;
    }
    if (this.year.value != null && this.year.value.length > 0) { this.programdropdowns.yearList = this.year.value; }
    if (this.programType.value != null && this.programType.value.length > 0) {
      this.programdropdowns.programTypesList = this.programType.value;
      if (this.programdropdowns.programTypesList.includes('Parent-Child')) {
        this.programdropdowns.programTypesList = this.programdropdowns.programTypesList.filter((x) => x != 'Parent-Child');
        this.programdropdowns.programTypesList.push('Parent');
        this.programdropdowns.programTypesList.push('Child');

      }
    }
    this.programdropdowns.isHavingChangeException = this.isHavingChangeException.value ? true : false;
    this.programdropdowns.showAllPrograms = this.showAllPrograms.value ? true : false;
    if(!this.showAllPrograms.value && this.programdropdowns.programManagerList.length == 0){
      this.programManagerList.forEach(element => {
        if (element == this.core.getUserAccountName()) {
          this.programdropdowns.programManagerList.push(element)
        }
      }
      );
    }
    this.bindtabledata();
  }

  bindtabledata() {
    this.spinner.show();
    this.programItems = [];
    this.programdropdowns.canEditAccruals = this.core.canEditAccrual;
    this.programdropdowns.canEditProgram = this.core.canEditProgram;
    this.programdropdowns.canApproveorDeny = this.core.canApproveOrDenyProgram;
    const headers = new HttpHeaders().set('content-type', 'application/json');
    this.context.postdatatoservice("/api/Program/List", JSON.stringify(this.programdropdowns), headers)
      .pipe(takeUntil(this.cancelSubject)).toPromise().then((response: ProgramsItem[]) => {
        if (response != null) {
          this.programsRawData = response;
          response.forEach(program => {
            program.code = program.changeExceptionProgramCode ? program.changeExceptionProgramCode : program.childProgramCode ? program.childProgramCode : program.programCode;
            program.totalExposure = Math.trunc(program.totalExposure);
            program.showExpansionIcon = (!program.childProgramCode && !program.changeExceptionProgramCode && program.isHavingChildProgram) || (!program.childProgramCode && !program.changeExceptionProgramCode && program.isHavingChangeException)

            // Add CE and children to original program
            if (!program.changeExceptionProgramCode && !program.childProgramCode) {
              program.visible = true;
              program.isExpanded = true;

              program.childPrograms = [];
              response.filter(pgrm => (pgrm.programCode === program.programCode && pgrm.isChangeException) || (pgrm.programCode === program.programCode && pgrm.programType === 'Child'))
                .forEach(childProgram => {
                  childProgram.visible = true;
                  program.childPrograms.push(childProgram);
                });
              // Adding child str to parent for search
              program.searchProgramStr = JSON.stringify(program.childPrograms);
              // Add Parent
              this.programItems.push(program);
              // Add Children
              const { childPrograms, searchProgramStr, ...plainPrgm } = program;
              program.childPrograms.forEach(childProgram => {
                childProgram.searchProgramStr = JSON.stringify(plainPrgm);
                this.programItems.push(childProgram);
              });
            }
          });

          this.dataSource = new MatTableDataSource(this.programItems);
          this.dataSource.paginator = this.paginator;
          // Commenting default sort for using Custom Sort
          // this.dataSource.sort = this.sort;
          this.sortPrograms(this.sort);
          this.spinner.hide();

          /// APPLY UI SEARCH AND FILTER CRITERIA FUNCTIONALITY
          if (this.SearchProgram !== undefined) {
            this.applyFilter();
          }
          // rerender table
          setTimeout(() => {
            this.matTable.renderRows();
          }, 1000);
        }
        this.spinner.hide();
      });
  }

  sortPrograms(sort: Sort) {
    const sortedParents = this.dataSource.data
      .filter((program: ProgramsItem) => !program.changeExceptionProgramCode && !program.childProgramCode)
      .sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'code': return this.compare(a.code, b.code, isAsc);
          case 'programName': return this.compare(a.programName, b.programName, isAsc);
          case 'programManager': return this.compare(a.programManager, b.programManager, isAsc);
          case 'businessSegment': return this.compare(a.businessSegment, b.businessSegment, isAsc);
          case 'channel': return this.compare(a.channel, b.channel, isAsc);
          case 'status': return this.compare(a.status, b.status, isAsc);
          case 'startDate': return this.compare(a.startDate, b.startDate, isAsc);
          case 'endDate': return this.compare(a.endDate, b.endDate, isAsc);
          case 'programYear': return this.compare(a.programYear, b.programYear, isAsc);
          case 'totalExposure': return this.compare(Math.trunc(a.totalExposure), Math.trunc(b.totalExposure), isAsc);
          default: return 0;
        }
      });

    const sortedPrograms = [];

    sortedParents.forEach(program => {
      sortedPrograms.push(program);
      if (!program.changeExceptionProgramCode && !program.childProgramCode) {
        const sortedChangeException = this.dataSource.data
          .filter((child: ProgramsItem) => child.programCode === program.programCode && child.isChangeException && child.programType !== 'Child')
          .sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
              case 'code': return this.compare(a.code, b.code, false); //sort CE in descending order
              case 'programName': return this.compare(a.programName, b.programName, isAsc);
              case 'programManager': return this.compare(a.programManager, b.programManager, isAsc);
              case 'businessSegment': return this.compare(a.businessSegment, b.businessSegment, isAsc);
              case 'channel': return this.compare(a.channel, b.channel, isAsc);
              case 'status': return this.compare(a.status, b.status, isAsc);
              case 'startDate': return this.compare(a.startDate, b.startDate, isAsc);
              case 'endDate': return this.compare(a.endDate, b.endDate, isAsc);
              case 'programYear': return this.compare(a.programYear, b.programYear, isAsc);
              case 'totalExposure': return this.compare(Math.trunc(a.totalExposure), Math.trunc(b.totalExposure), isAsc);
            }
          });
        const sortedChildren = this.dataSource.data
          .filter((child: ProgramsItem) => child.programCode === program.programCode && child.programType === 'Child')
          .sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
              case 'code': return this.compare(a.code, b.code, false); //sort child programs in descending order
              case 'programName': return this.compare(a.programName, b.programName, isAsc);
              case 'programManager': return this.compare(a.programManager, b.programManager, isAsc);
              case 'businessSegment': return this.compare(a.businessSegment, b.businessSegment, isAsc);
              case 'channel': return this.compare(a.channel, b.channel, isAsc);
              case 'status': return this.compare(a.status, b.status, isAsc);
              case 'startDate': return this.compare(a.startDate, b.startDate, isAsc);
              case 'endDate': return this.compare(a.endDate, b.endDate, isAsc);
              case 'programYear': return this.compare(a.programYear, b.programYear, isAsc);
              case 'totalExposure': return this.compare(Math.trunc(a.totalExposure), Math.trunc(b.totalExposure), isAsc);
            }
          });
        sortedPrograms.push(...sortedChangeException,...sortedChildren);
      }
    });

    this.dataSource.data = sortedPrograms;
    // rerender table
    //this.matTable.renderRows();
    setTimeout(() => {
      this.matTable.renderRows();
    }, 1000);
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  toggleChildRows(programCode: string) {
    this.dataSource.data
      .filter((program: ProgramsItem) => !program.changeExceptionProgramCode && !program.childProgramCode && program.code === programCode)
      .forEach(program => {
        program.isExpanded = !program.isExpanded;
      });

    // Toggle Child
    this.dataSource.data
      .filter((program: ProgramsItem) => (program.programType === "Child" && program.programCode === programCode) || (program.isChangeException && program.programCode === programCode))
      .forEach(childProgram => {
        childProgram.visible = !childProgram.visible;
      });
    // rerender table
    // this.matTable.renderRows();
    setTimeout(() => {
      this.matTable.renderRows();
    }, 1000);
  }

  binddropdowns() {
    const businessSegmentListCleaned = [];
    this.showAllProgramsToggle = true;
    this.programdropdowns.canEditAccruals = this.core.canEditAccrual;
    this.programdropdowns.canEditProgram = this.core.canEditProgram;
    this.programdropdowns.canApproveorDeny = this.core.canApproveOrDenyProgram;
    if ((this.programdropdowns.canEditAccruals&& !this.programdropdowns.canApproveorDeny) || this.core.isAdmin) {
      this.showAllProgramsToggle = false;
    }
    this.context.getdatafromService('/api/Program/Filter').toPromise().then((response) => {
      if (response != null) {
        this.statusList = response.status;
        this.programManagerList = response.programManagers;
        this.channelList = response.channel;
        this.businessSegmentList = response.businessSegment;
        this.businessSegmentList.forEach(item => {
          businessSegmentListCleaned.push(item.replace(/&amp;/g, '&'));
        });
        this.businessSegmentList = businessSegmentListCleaned;
        if ((!this.programdropdowns.showAllPrograms || this.programdropdowns.showAllPrograms == undefined) && this.programdropdowns.programManagerList.length == 0) {
          this.programManagerList.forEach(element => {
            if (element == this.core.getUserAccountName()) {
              this.programdropdowns.programManagerList.push(element)
              this.showAllProgramsToggle = true;
            }
          }
          );
        }
        this.bindtabledata();        
      }
    });
    this.context.getdatafromService('/api/Program/Filter/ProgramYear').toPromise().then((response) => {
      if (response != null) {
        this.yearList = response;
      }
    });
  }

  truncateText(businessSegmentList: string) {
    if (businessSegmentList != null) {
      return businessSegmentList.split(',').length > 1 ? businessSegmentList.split(',')[0] + '...' : businessSegmentList;
    }
    return "";
  }
  ngOnDestroy() {
    this.cancelSubject.next();
  }
}

export interface ProgramsItem {
  code : string;
  programCode : string;
  childProgramCode? : string;
  changeExceptionProgramCode?: string;
  programName: string;
  programManager: string;
  businessSegment: string;
  channel: string;
  status: string;
  startDate?: string;
  endDate?: string;
  programType: string;
  programYear: string;
  totalExposure?: number;
  parentProgramId?: string;
  visible?: boolean;
  isExpanded?: boolean;
  childPrograms?: ProgramsItem[];
  changeExceptionPrograms?: ProgramsItem[];
  searchProgramStr?: string;
  programTableType?: string;
  isHavingChangeException: boolean;
  isHavingChildProgram: boolean;
  isChangeException: boolean;
  insertDate : string;
  showExpansionIcon : boolean;
}


export class ProgramDropdowns {
  statusList: [];
  channelList: [];
  programManagerList: string[]=[];
  businessSegmentList: [];
  yearList: [];
  typeList: [];
  consistentNameList: [];
  functionalAreaList: [];
  programTypesList: string[];
  isHavingChangeException: boolean;
  showAllPrograms : boolean;
  canApproveorDeny : boolean;
  canEditAccruals : boolean;
  canEditProgram : boolean;
}

