import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { MonitoringService } from "./monitoring.service";

@Injectable()
export class MonitoringErrorHandler extends ErrorHandler {
  private monitoringService?: MonitoringService;

    constructor(private injector: Injector) {
        super();
    }

    handleError(error: Error) {
      this.monitoringService = this.monitoringService || this.injector.get(MonitoringService);
      if (this.monitoringService) {
        this.monitoringService.logException(error); // Manually log exception
      }
    }
}