<div>
    <h1>Bundled Routing</h1>
    <p>Select your child programs for bundled routing with the parent.</p>
    <mat-card class="card">
        <mat-card-content  class="mat-card-content-scroll">            
            <div  class="bullet-list">              
                <span class= "parent-padding"><b>{{dataSource.parentProgramCode}} - {{dataSource.parentProgramName}}</b></span>
               <div class="select-all-padding">
                <mat-checkbox [checked]="allSelected"
                [indeterminate]="indeterminateSelection()"
                (change)="selectAll($event.checked)">
                Select all child programs
               </mat-checkbox> 
               </div>
                <ng-container *ngFor="let item of dataSource['childBundleRoutingProgramList']; let i = index" >
                        <ul class = "non-list-style">  
                        <li *ngIf="i!=0">
                            <mat-checkbox [(ngModel)]="item.bundleRoute"
                            (change)="selectionChange()">
                            <span>{{ item.code }} - {{ item.shortName }}</span> 
                        </mat-checkbox>
                        </li>
                        </ul>
                    </ng-container>
              </div>
        </mat-card-content>
    </mat-card>
    <mat-card-footer>
        <div class="footer">
            <button mat-raised-button class="functional-button-dark" (click)="onApply();"> Apply</button>
            <button mat-raised-button class="functional-button" (click)="onCancel();"> Cancel</button>
        </div>
    </mat-card-footer>
</div>