export enum ProgramCategories{
  _1 = 'ExpensePA',
  _2 = 'RoutingOnly',
  _3 = 'ChildProgram',
  _4 = 'ParentProgram',
  _5 = 'StandardProgram'
}

export enum ProgramCategorySetNames{
  ExpensePA = 'Expense P&A',
  RoutingOnly = 'Routing Only',
  ChildProgram = 'Child Program',
  ParentProgram = 'Parent Program',
  StandardProgram = 'Standard Program'
}

export enum ProgramCategoryGetNames{
  ExpenseP_A = 'ExpensePA',
  RoutingOnly = 'RoutingOnly',
  ChildProgram = 'ChildProgram',
  ParentProgram = 'ParentProgram',
  StandardProgram = 'StandardProgram'
}