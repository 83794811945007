import { Component, OnInit, ChangeDetectionStrategy,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpHeaders } from '@angular/common/http';
import { ProgPortalContextService } from 'src/app/core/services/progportalcontextservice';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';


export interface DialogData {
  message: any;
}

@Component({
  selector: 'app-dialog-post-message',
  templateUrl: './dialog-post-message.component.html',
  styleUrls: ['./dialog-post-message.component.scss'],
})


export class DialogPostMessageComponent implements OnInit{

  constructor(public dialogRef: MatDialogRef<DialogPostMessageComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void { 
  }

  Cancel() {
    this.dialogRef.close();
  }
}
