  <div>  
    <h1>Add Products</h1>
    <p *ngIf="verifyAccrualsProducts()">Select one or more products from the hierarchy below and click Apply.</p> 
 </div>
      <div>
        <mat-card class="mat-card mat-elevation-z3" style="overflow-x: auto"> 
        <mat-card-content class="mat-card-container">
          <p *ngIf="!verifyAccrualsProducts()">No product assignments available</p>
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding [matTreeNodePaddingIndent]="20">
              <button mat-icon-button disabled></button>
              <mat-checkbox class="checklist-leaf-node"
                            [checked]="checklistSelection.isSelected(node)"
                            (change)="todoLeafItemSelectionToggle(node)">{{node.product}} ({{node.productHierarchyCode}})
              </mat-checkbox>
            </mat-tree-node>
        
            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="20">
              <button mat-icon-button matTreeNodeToggle>
                <mat-icon class="mat-icon-rtl-mirror"
                 [style.color]="treeControl.isExpanded(node) ? 'black' : '#1A81E1' ">
                  {{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'}}
                </mat-icon>
              </button>
              <mat-checkbox
                            [checked]="descendantsAllSelected(node)"
                            [indeterminate]="descendantsPartiallySelected(node)"
                            (change)="todoItemSelectionToggle(node)">{{node.product}} ({{node.productHierarchyCode}})
              </mat-checkbox>
            </mat-tree-node>
          </mat-tree>
        </mat-card-content>
        </mat-card>
        <ngx-spinner class="spinner" size="default" type="ball-spin-clockwise">  
          <p>Please Wait.</p>  
          </ngx-spinner>                
      </div> 
      <mat-card-footer>
        <div >                      
          <button mat-raised-button class="functional-button-dark" [mat-dialog-close]="data"> Apply</button>
          <button mat-raised-button class="functional-button" (click)= "onCancel();"> Cancel</button>   
          </div> 
       </mat-card-footer>
