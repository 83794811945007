import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProgramsComponent } from './programs/programs.component';
import { AccrualsComponent } from './accruals/accruals.component';
import { AccrualsProductsSummaryComponent } from './accruals/accruals-products-summary/accruals-products-summary.component';
import { AccrualsProductsSummaryComponentV2 } from './accruals/accruals-products-summary/accruals-products-summary-v2.component';
import { ReportsComponent } from './reports/reports.component';
import { CreateProgramComponent } from './programs/create-program/create-program.component';
import { EditProgramComponent } from './programs/edit-program/edit-program.component';
import { EditAccrualsComponent } from './accruals/edit-accruals/edit-accruals.component';
import { ReviewProgramComponent } from './programs/review-program/review-program.component';
import { UserImpersonationComponent } from './user-impersonation/user-impersonation.component';
import { EditProgramGuard } from './programs/edit-program/edit-program.guard';
import { CreateProgramGuard } from './programs/create-program/create-program.guard';
import { ProgramsGuard } from './programs/programs.guard';
import { AccrualsGuard } from './accruals/accruals.guard';
import { AccrualsProductsSummaryGuard } from './accruals/accruals-products-summary/accruals-products-summary.guard';
import { ReportsGuard } from './reports/reports.guard';
import { ReviewProgramGuard } from './programs/review-program/review-program.guard';
import { UserImpersonationGuard } from './user-impersonation/user-impersonation.guard';
import { AdministrationGuard } from './administration/administration.guard';
import { AdministrationComponent } from './administration/administration.component';
import { ProgramManagerOwnershipComponent } from './administration/program-manager-ownership/program-manager-ownership.component';
import { CopyProductsAndAccrualsComponent } from './administration/copy-products-and-accruals/copy-products-and-accruals.component';
import { ViewAppUsersComponent } from './administration/view-app-users/view-app-users.component';
import { SearchProgramsComponent } from './administration/search-programs/search-programs.component';
import { AdminEditProgramComponent } from './administration/admin-edit-program/admin-edit-program.component';

const routes: Routes = [{
  path: 'home',
  component: HomeComponent
}, {
  path: '',
  component: HomeComponent
}, {
  path: 'programs',
  canActivate: [ProgramsGuard],
  component: ProgramsComponent,
}, {
  path: 'accruals',
  canActivate: [AccrualsGuard],
  component: AccrualsComponent
}, {
  path: 'accruals/edit-accruals',
  canActivate: [AccrualsGuard],
  component: EditAccrualsComponent
}, {
  path: 'accruals/accruals-products-summary/:id',
  canActivate: [AccrualsProductsSummaryGuard],
  component: AccrualsProductsSummaryComponent
}, {
  path: 'accruals/accruals-products-summary-v2/:id',
  canActivate: [AccrualsProductsSummaryGuard],
  component: AccrualsProductsSummaryComponentV2
}, {
  path: 'reports',
  canActivate: [ReportsGuard],
  component: ReportsComponent
},{
  path: 'administration',
  canActivate: [AdministrationGuard],
  component: AdministrationComponent
},{
  path: 'administration/program-manager-ownership',
  canActivate: [AdministrationGuard],
  component: ProgramManagerOwnershipComponent
},{
  path: 'administration/copy-products-and-accruals',
  canActivate: [AdministrationGuard],
  component: CopyProductsAndAccrualsComponent
},{
  path: 'administration/view-app-users',
  canActivate: [AdministrationGuard],
  component: ViewAppUsersComponent
},{
  path: 'administration/search-programs',
  canActivate: [AdministrationGuard],
  component: SearchProgramsComponent
},{
  path: 'administration/admin-edit-program/:id',
  canActivate: [AdministrationGuard],
  component: AdminEditProgramComponent
},{
  path: 'programs/create-program',
  canActivate: [CreateProgramGuard],
  component: CreateProgramComponent
}, {
  path: 'programs/edit-program/:id',
  canActivate: [EditProgramGuard],
  component: EditProgramComponent
}, {
  path: 'programs/review-program/:id',
  canActivate: [ReviewProgramGuard],
  component: ReviewProgramComponent
}, {
  path: 'user-impersonation',
  canActivate: [UserImpersonationGuard],
  component: UserImpersonationComponent
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
