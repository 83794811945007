import { Injectable } from '@angular/core';
import { PublicClientApplication } from '@azure/msal-browser';

import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AppSettingsService } from './services/app-settings/app-settings.service';
import { AuthorizationService } from './services/authorization/authorization.service';
import { ProgramPortalPermissions } from './services/authorization/models/program-portal-permissions';
import { ProgramPortalRoles } from './services/authorization/models/program-portal-roles';
import { User } from './user/User';

@Injectable({
  providedIn: 'root'
})
export class CoreRepository {
  user: User;
  canViewProgram = false;
  canEditProgram = false;
  canDeleteProgram = false;
  canViewAccrual = false;
  canEditAccrual = false;
  canDeleteAccrual = false;
  canViewReport = false;
  canApproveOrDenyProgram = false;
  canViewAdministration = false;
  functionalArea: any;
  isAdmin = false;
  impersonatedUsername: string;
  impersonatedUserAccountname: string;
  environment: any;

  constructor(private msalInstance: PublicClientApplication,
    private authorizationService: AuthorizationService) { 
      this.environment = AppSettingsService.variables.clientSide.environmentName;
      this.userImpersonation();
      var appUser = {userName : this.msalInstance.getAllAccounts()[0].username, name: this.msalInstance.getAllAccounts()[0].name}
      localStorage.setItem("AppUser", JSON.stringify(appUser));
    }

  authorize(): Observable<User> {
    return this.authorizationService.initialize();
  }

  getUser() {
   this.authorize().subscribe(userResponse => {
     this.user = userResponse;
     this.checkPermissions();
     return this.user;
   });
  }

  getFunctionalArea():string[]{
    if(this.user !== undefined){
     this.functionalArea = this.user.functionalArea;
    }
    return this.functionalArea;
  }

  checkPermissions() {
    if(this.user !== undefined){

      this.canViewProgram = this.user.permissions.includes(ProgramPortalPermissions.ViewProgram);
      this.canEditProgram = this.user.permissions.includes(ProgramPortalPermissions.EditProgram);
      this.canDeleteProgram = this.user.permissions.includes(ProgramPortalPermissions.DeleteProgram);
      this.canViewAccrual = this.user.permissions.includes(ProgramPortalPermissions.ViewAccrual);
      this.canEditAccrual = this.user.permissions.includes(ProgramPortalPermissions.EditAccrual);
      this.canDeleteAccrual = this.user.permissions.includes(ProgramPortalPermissions.DeleteAccrual);
      this.canViewReport = this.user.permissions.includes(ProgramPortalPermissions.ViewReport);
      this.canApproveOrDenyProgram = this.user.permissions.includes(ProgramPortalPermissions.ApproveOrDenyProgram);
      this.canViewAdministration = this.user.permissions.includes(ProgramPortalPermissions.ViewAdministration);
      this.isAdmin = this.user.roles.includes(ProgramPortalRoles.ADMIN);
    }
  }

  getAccessToken() {
    var scopes = { scopes: [`api://${AppSettingsService.variables.clientSide.appRegistrationClientId}/user_impersonation`] };

    return from(
      this.msalInstance.acquireTokenSilent(scopes)
    ).pipe(
      map(r => r.accessToken),
      catchError(err => {
        this.msalInstance.acquireTokenRedirect(scopes);
        return of([]);
      })
    )
  }

  getUsername() {  
    return (this.impersonatedUsername && this.environment !== "prod") ? this.impersonatedUsername : this.msalInstance.getAllAccounts()[0].username;
  }

  getUserAccountName() {
    return (this.impersonatedUserAccountname && this.environment !== "prod") ? this.impersonatedUserAccountname : this.msalInstance.getAllAccounts()[0].name;
  }

  userImpersonation(){
    if(localStorage.getItem("ImpersonatedUser")){
      var impersonatedUser = JSON.parse(localStorage.getItem("ImpersonatedUser"));
      this.impersonatedUsername = impersonatedUser.email;
      this.impersonatedUserAccountname = impersonatedUser.userName;
    }
  }

}
