
<h1 mat-dialog-title>Approve Program </h1>
<p>Click below to confirm the approval of this program. You can optionally enter a comment.</p>
<div>
    <div class="row">
        <mat-form-field appearance="fill" class="col-md-12">
          <mat-label>Comments (Optional)</mat-label>
          <textarea matInput [(ngModel)]="data.comments"></textarea>
        </mat-form-field>
      </div>
</div>
<div>
  <button mat-raised-button class="functional-button-dark" [mat-dialog-close]="data"> Confirm Approval</button>
  <button mat-raised-button class="functional-button" (click)="Cancel()"> Cancel</button>
</div>
