<div class="main-content">
  <form [formGroup]="DoaApproverForm">
    <h1 mat-dialog-title>Edit Appropriate Approvers </h1>
    <!-- class="main-cards" -->
    <div *ngIf="ProgramLeaderList.length>0">
      <mat-label>Program Leader {{ProgramLeaderRequiredText}}</mat-label><br>
      <div formArrayName="ProgramLeader" class="row">
        <div *ngFor="let val of ProgramLeader.controls;let i=index;">
            <mat-checkbox [formControlName]="i" class="checkbox-margin" (change)="onChange($event,ProgramLeaderList[i],true)" [disabled]="ProgramLeaderList[i].statusDefinitionId == 4 || this.data.statusDefinitionId == 4">
              <div *ngIf="ProgramLeaderList[i].active">
                {{ProgramLeaderList[i]?.userName}}
              </div>
              <div *ngIf="!ProgramLeaderList[i].active" class="inactive">
                {{ProgramLeaderList[i]?.userName}}
              </div>
            </mat-checkbox>
        </div>
      </div>
      <div>
        <mat-error *ngIf="ProgramLeader.hasError('required')">
          Please select at least one
        </mat-error>          
      </div>
    </div>
    <div *ngIf="LegalList.length>0">    
      <mat-label > Legal </mat-label ><br>
      <div formArrayName="Legal" class="row">
        <div *ngFor="let val of Legal.controls;let i=index;">
          <mat-checkbox [formControlName]="i" class="checkbox-margin" (change)="onChange($event,LegalList[i],true)"
            [disabled]="LegalList[i].statusDefinitionId == 4 || this.data.statusDefinitionId == 4">
            <div *ngIf="LegalList[i].active">
              {{LegalList[i]?.userName}}
            </div>
            <div *ngIf="!LegalList[i].active" class="inactive">
              {{LegalList[i]?.userName}}
            </div>
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div *ngIf="FinancialList.length>0">
      <mat-label> Financial Analyst {{FinancialAnalystRequiredText}}</mat-label><br>
      <div formArrayName="FinancialAnalyst" class="row">
        <div *ngFor="let val of FinancialAnalyst.controls;let i=index;">
          <mat-checkbox [formControlName]="i" class="checkbox-margin" (change)="onChange($event,FinancialList[i],true)"
            [disabled]="FinancialList[i].statusDefinitionId == 4 || this.data.statusDefinitionId == 4">
            <div *ngIf="FinancialList[i].active">
              {{FinancialList[i]?.userName}}
            </div>
            <div *ngIf="!FinancialList[i].active" class="inactive">
              {{FinancialList[i]?.userName}}
            </div>
          </mat-checkbox>
        </div>
      </div>
      <div>
        <mat-error *ngIf="FinancialAnalyst.hasError('required')">
          Please select at least one
        </mat-error>          
      </div>
    </div>
    <div *ngIf="CatgLeaderList.length>0">
      <mat-label> Category Leader {{CategoryLeaderRequiredText}}</mat-label><br>
      <div formArrayName="CategoryLeader" class="row">
        <div *ngFor="let val of CategoryLeader.controls;let i=index;">
          <mat-checkbox [formControlName]="i" class="checkbox-margin" (change)="onChange($event,CatgLeaderList[i],true)"
            [disabled]="CatgLeaderList[i].statusDefinitionId == 4 || this.data.statusDefinitionId == 4">
            <div *ngIf="CatgLeaderList[i].active">
              {{CatgLeaderList[i]?.userName}}
            </div>
            <div *ngIf="!CatgLeaderList[i].active" class="inactive">
              {{CatgLeaderList[i]?.userName}}
            </div>
          </mat-checkbox>
        </div>
      </div>
      <div>
        <mat-error *ngIf="CategoryLeader.hasError('required')">
          Please select at least one
        </mat-error>          
      </div>
    </div>
    <div *ngIf="MarketingLeaderList.length>0">
      <mat-label> Marketing Leader {{MarketingLeaderRequiredText}}</mat-label><br>
      <div formArrayName="MarketingLeader" class="row">
        <div *ngFor="let val of MarketingLeader.controls;let i=index;">
          <mat-checkbox [formControlName]="i" class="checkbox-margin" (change)="onChange($event,MarketingLeaderList[i],true)"
            [disabled]="MarketingLeaderList[i].statusDefinitionId == 4 || this.data.statusDefinitionId == 4">
            <div *ngIf="MarketingLeaderList[i].active">
              {{MarketingLeaderList[i]?.userName}}
            </div>
            <div *ngIf="!MarketingLeaderList[i].active" class="inactive">
              {{MarketingLeaderList[i]?.userName}}
            </div>
          </mat-checkbox>
        </div>
      </div>
      <div>
        <mat-error *ngIf="MarketingLeader.hasError('required')">
          Please select at least one
        </mat-error>          
      </div>
    </div>
    <div *ngIf="CommUnitLeaderList.length>0">
      <mat-label>Comm. Unit Leader {{CommUnitLeaderRequiredText}}</mat-label><br>
      <div formArrayName="CommercialUnitLeader" class="row">
        <div *ngFor="let val of CommercialUnitLeader.controls;let i=index;">
          <mat-checkbox [formControlName]="i" class="checkbox-margin" (change)="onChange($event,CommUnitLeaderList[i],true)"
            [disabled]="CommUnitLeaderList[i].statusDefinitionId == 4 || this.data.statusDefinitionId == 4">
            <div *ngIf="CommUnitLeaderList[i].active">
              {{CommUnitLeaderList[i]?.userName}}
            </div>
            <div *ngIf="!CommUnitLeaderList[i].active" class="inactive">
              {{CommUnitLeaderList[i]?.userName}}
            </div>
          </mat-checkbox>
        </div>
      </div>
      <div>
        <mat-error *ngIf="CommercialUnitLeader.hasError('required')">
          Please select at least one
        </mat-error>          
      </div>
    </div>
    <div *ngIf="CommercialLeaderList.length>0">
      <mat-label>Commercial Leader {{CommercialLeaderRequiredText}}</mat-label><br>
      <div formArrayName="CommercialLeader" class="row">
        <div *ngFor="let val of CommercialLeader.controls;let i=index;">
          <mat-checkbox [formControlName]="i" class="checkbox-margin" (change)="onChange($event,CommercialLeaderList[i],true)"
            [disabled]="CommercialLeaderList[i].statusDefinitionId == 4 || this.data.statusDefinitionId == 4">
            <div *ngIf="CommercialLeaderList[i].active">
              {{CommercialLeaderList[i]?.userName}}
            </div>
            <div *ngIf="!CommercialLeaderList[i].active" class="inactive">
              {{CommercialLeaderList[i]?.userName}}
            </div>
          </mat-checkbox>
        </div>
      </div>
      <div>
        <mat-error *ngIf="CommercialLeader.hasError('required')">
          Please select at least one
        </mat-error>          
      </div>
    </div>
    <div *ngIf="ProgramAdminManagerList.length>0">
      <mat-label> Program Admin Manager {{ProgramAdminManagerRequiredText}}</mat-label><br>
      <div formArrayName="ProgramAdminManager" class="row">
        <div *ngFor="let val of ProgramAdminManager.controls;let i=index;">
          <mat-checkbox [formControlName]="i" class="checkbox-margin"
            (change)="onChange($event,ProgramAdminManagerList[i],true)"
            [disabled]="ProgramAdminManagerList[i].statusDefinitionId == 4 || this.data.statusDefinitionId == 4">
            <div *ngIf="ProgramAdminManagerList[i].active">
              {{ProgramAdminManagerList[i]?.userName}}
            </div>
            <div *ngIf="!ProgramAdminManagerList[i].active" class="inactive">
              {{ProgramAdminManagerList[i]?.userName}}
            </div>
          </mat-checkbox>
        </div>
      </div>
      <div>
        <mat-error *ngIf="ProgramAdminManager.hasError('required')">
          Please select at least one
        </mat-error>          
      </div>
    </div>
    <h1 mat-dialog-title>Edit Appropriate Reviewers </h1>
    <div *ngIf="ProgAdminAnalystList.length>0">
      <mat-label>Program Admin Analyst {{ProgAdminAnalystRequiredText}}</mat-label><br>
      <div formArrayName="ProgramAdminAnalyst" class="row">
        <div *ngFor="let val of ProgramAdminAnalyst.controls;let i=index;">
          <mat-checkbox [formControlName]="i" class="checkbox-margin" (change)="onChange($event,ProgAdminAnalystList[i],false)"
            [disabled]="ProgAdminAnalystList[i].statusDefinitionId == 4">
            <div *ngIf="ProgAdminAnalystList[i].active">
              {{ProgAdminAnalystList[i]?.userName}}
            </div>
            <div *ngIf="!ProgAdminAnalystList[i].active" class="inactive">
              {{ProgAdminAnalystList[i]?.userName}}
            </div>
          </mat-checkbox>
        </div>
      </div>
      <div>
        <mat-error *ngIf="ProgramAdminAnalyst.hasError('required')">
          Please select at least one
        </mat-error>          
      </div>
    </div>
    <div [hidden]="ProgramAdminList.length==0">
      <mat-label> Program Admin {{ProgramAdminRequiredText}}</mat-label><br>
      <div formArrayName="ProgramAdmin" class="row">
        <div *ngFor="let val of ProgramAdmin.controls;let i=index;">
          <mat-checkbox [formControlName]="i" class="checkbox-margin" (change)="onChange($event,ProgramAdminList[i],false)"
            [disabled]="ProgramAdminList[i].statusDefinitionId == 4">
            <div *ngIf="ProgramAdminList[i].active">
              {{ProgramAdminList[i]?.userName}}
            </div>
            <div *ngIf="!ProgramAdminList[i].active" class="inactive">
              {{ProgramAdminList[i]?.userName}}
            </div>
          </mat-checkbox>
        </div>
      </div>
      <div>
        <mat-error *ngIf="ProgramAdmin.hasError('required')">
          Please select at least one
        </mat-error>          
      </div>
    </div>
    <div *ngIf="OthersList.length>0">
      <mat-label>Others </mat-label><br>
      <div formArrayName="Others" class="row">
        <div *ngFor="let val of Others.controls;let i=index;">
          <mat-checkbox [formControlName]="i" (change)="onChange($event,OthersList[i],false)" class="checkbox-margin">
          <!--  [disabled]="!OthersList[i].active"> -->
            <div *ngIf="OthersList[i].active">
              {{OthersList[i]?.userName}}
            </div>
            <div *ngIf="!OthersList[i].active" class="inactive">
              {{OthersList[i]?.userName}}
            </div>
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div>
      <button mat-raised-button class="functional-button-dark" (click)="onSubmit(); isSubmitted = true;"
        [disabled]="!DoaApproverForm.valid || !isFormDirty || isSubmitted">
        Submit
      </button>
      <button mat-raised-button class="functional-button" mat-dialog-close>
        {{'Cancel'}}
      </button>
    </div>

    <ngx-spinner class="spinner" size="default" type="ball-spin-clockwise">
      <p>Please Wait.</p>
    </ngx-spinner>
  </form>
</div>
