import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'spmd-error-dialog',
  templateUrl: './error-dialog.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorDialogComponent {
  title?: string;
  description?: string;
  closeText?: string;

  static open(dialog: MatDialog, options: { title: string; description: string; closeText?: string }) {
    const dialogRef = dialog.open(ErrorDialogComponent, {
      disableClose: true,
      panelClass: 'error-dialog'
    });

    dialogRef.componentInstance.title = options.title;
    dialogRef.componentInstance.description = options.description;
    dialogRef.componentInstance.closeText = options.closeText;

    return dialogRef.afterClosed() as Observable<boolean>;
  }
}
