import { HttpHeaders } from '@angular/common/http';
import { Component,AfterViewInit, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CoreRepository } from '../core/core.repository';
import { FilterType, ReportName } from '../core/enums/reportEnums';
import { ProgramPortalRoles } from '../core/services/authorization/models/program-portal-roles';
import { ProgPortalContextService } from '../core/services/progportalcontextservice';
declare var powerbi: any;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, AfterViewInit {
  @ViewChild('embeddedReport') embeddedReport: ElementRef<HTMLInputElement>;
  screenHeight = (window.screen.height);
  reportList : any;
  selectedReport: any;
  config:any;
  filters:any=[];
  filterColumn: any;

  constructor(public context: ProgPortalContextService,
     private spinner: NgxSpinnerService,
     private core: CoreRepository) {}

  ngOnInit(): void {
    this.spinner.show();
  }

  ngAfterViewInit(): void {
    this.context.getdatafromService('/api/Report/List/GetAllInfinityReports').toPromise().then((response) => {
      if (response != null) {
        this.reportList = response;
        this.selectedReport = this.reportList[0];
        this.onReportSelection();
        this.spinner.hide();
      }
    });
  }

  onReportSelection(){
    this.spinner.show();
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const settings = {
      panes: {
        bookmarks: {
            visible: false
        },
        fields: {
            expanded: true
        },
        filters: {
            expanded: true,
            visible: true
        },
        pageNavigation: {
            visible: true
        },
        selection: {
            visible: true
        },
        syncSlicers: {
            visible: true
        },
        visualizations: {
            expanded: true
        }
      }
    };

    this.filters = [];
    if(this.selectedReport.name === ReportName._2){
      var programManagerFilter = {
        filterType: FilterType.Basic,
        target: {
          table: "vw_ProgramAccrualRates", // filter table
          column:  "ProgramManager" // filter column
        },
        operator: "In",
        values: [this.core.getUserAccountName()]   // value need to filter
      };
      var productManagerFilter = {
        filterType: FilterType.Basic,
        target: {
          table: "vw_ProgramAccrualRates", 
          column:  "ProductManager" 
        },
        operator: "In",
        values: [this.core.getUserAccountName()]   
      };
      if(this.core.user.roles.includes(ProgramPortalRoles.PROGMGR)){
        this.filters.push(programManagerFilter);
      }
      if(this.core.user.roles.includes(ProgramPortalRoles.PRODMGR)){
        this.filters.push(productManagerFilter);
      }
    }

    this.context.postdatatoservice(`/api/Report/List/GetReportV2/${this.selectedReport.id}`, headers)
    .toPromise().then((response) =>{
      if (response != null) {
        this.config = response;
        const model = window['powerbi-client'].models;
        const embedConfig = {
          type: 'report',
          id: this.config.id,
          tokenType: model.TokenType.Embed,
          accessToken: this.config.token,             
          embedUrl: this.config.embedUrl,
          permissions: model.Permissions.All,
          viewMode:model.ViewMode.View,
          pageView: "fitToWidth",
          settings: settings,
          filters:  this.filters
        };
        powerbi.embed(this.embeddedReport.nativeElement, embedConfig);
        this.spinner.hide();
      }
    });
  }
}

