import { Component, Inject, Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ProgPortalContextService } from 'src/app/core/services/progportalcontextservice';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { DialogAccrualsCommentsComponent } from '../dialog-accruals-comments/dialog-accruals-comments.component';
import { StatusDefinitionIds } from 'src/app/core/enums/statusDefinitionEnums';

export interface AccrualsRemoveProducts {
  programName: string;
  programId: number;
  removeProducts: RemoveProducts[];
}

export interface RemoveProducts {
  materialCode: string;
  materialName: string;
}

export interface AccrualsAssignRates {
  programId: number;
  programName: string;
  autoFillAssignRates: AutoFillAssignRates[];
}

export interface AutoFillAssignRates {
  programId: number;
  materialCode: string;
  materialName: string;
  isAutoAssign: boolean;
  volume?: number;
  payRate?: number;
  accrual?: number;
  startDate: string;
  endDate: string;
  dates: date[];
  startColumn: number;
  endColumn: number;
}

export interface date {
  value: number;
  viewValue: string;
}

export class ProgramNode {
  id?: number;  
  programName?: string;
  materialHierarchyCommercialId?: number;
  materialCode?: string;
  parentHierarchyCode?: string;
  name: string;
  startDate?: string;
  endDate?: string;
  children?: ProgramNode[];
  isAdded: boolean;
  numberOfColumns?: number;
  isSelected?: boolean;
}

export interface ItemPrograms {
  id: number;
  code: string;
  programName: string;
  programManager: string;
  startDate: string;
  endDate: string;
}

export interface ProgramAccrualsMaterialAssignRates {
  programId: number;
  materialHierarchyCommercialId?: number;
  materialCode: string;
  materialName: string;
  header: boolean;
  assignRateType: string;
  _1stMonthAssignRate: string;
  _2ndMonthAssignRate: string;
  _3rdMonthAssignRate: string;
  _4thMonthAssignRate: string;
  _5thMonthAssignRate: string;
  _6thMonthAssignRate: string;
  _7thMonthAssignRate: string;
  _8thMonthAssignRate: string;
  _9thMonthAssignRate: string;
  _10thMonthAssignRate: string;
  _11thMonthAssignRate: string;
  _12thMonthAssignRate: string;
}

export interface SubmittedOrRemovedAccrualPrograms{
  programId: number;
  Messages?: string;
  programName: string;
  disabled: boolean;
  selected: boolean;
  isCommentRequired: boolean;
}

export interface ProgramAccrualRatesByMonth {
  id?: number;
  programId: number;
  materialHierarchyCommercialId: number;
  year: number;
  month: number;
  volumePercentage: number;
  payRatePercentage: number;
  accrualPercentage: number;
  statusDefinitionId: number;
  monthAndYearColumn?: number;
}

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-dialog-accruals-removeproducts',
  templateUrl: './dialog-accruals-removeproducts.component.html',
  styleUrls: ['./dialog-accruals-removeproducts.component.scss']
})
export class DialogAccrualsRemoveProductsComponent {

  columnsToDisplay = ['programName'];
  innerDisplayedColumns = ['productName'];
  nonUpdatedAccrualsPrograms: ProgramNode[] = [];
  nonUpdatedAssignRates: ProgramAccrualsMaterialAssignRates[] = [];
  nonUpdatedAccrualsAssignRates: AccrualsAssignRates[] = [];
  programsAccrualsRemoveProducts: AccrualsRemoveProducts[] = [];
  assignRates: ProgramAccrualsMaterialAssignRates[] = [];
  accrualsAssignRates: AccrualsAssignRates[] = [];
  masterSelectedHierarchyLevel:number;
  submittedOrRemovedAccrualPrograms: SubmittedOrRemovedAccrualPrograms[] = [];
  deleteProgramAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];
  programAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];
  statusDefinitionIds = StatusDefinitionIds;


  constructor(public context: ProgPortalContextService, private spinner: NgxSpinnerService, private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogAccrualsRemoveProductsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ProgramNode) {
    this.nonUpdatedAccrualsPrograms = JSON.parse(JSON.stringify(data['dataAccrualPrograms']));
    this.nonUpdatedAssignRates = JSON.parse(JSON.stringify(data['dataAssignRates']));
    this.nonUpdatedAccrualsAssignRates = JSON.parse(JSON.stringify(data['dataAccrualsAssignRates']));

    this.programAccrualRatesByMonth = JSON.parse(JSON.stringify(data['dataProgramAccrualRatesByMonth']));
    this.submittedOrRemovedAccrualPrograms = data['submittedOrRemovedAccrualPrograms'];
    this.programsAccrualsRemoveProducts = data['dataProgramsAccrualsRemoveProducts'];
    this.assignRates = data['dataAssignRates'];
    this.accrualsAssignRates = data['dataAccrualsAssignRates'];
    data['dataConfirmRemoval'] = true;
    this.masterSelectedHierarchyLevel = data['dataMasterSelectedHierarchyLevel'];
    this.UpdateProductsRemovalConfirm(data['dataAccrualPrograms'], data['dataAssignRates'], data['dataAccrualsAssignRates']);
    dialogRef.disableClose = true;
  }

  UpdateProductsRemovalConfirm(accrualPrograms, assignRates, accrualsAssignRates) {

    this.programsAccrualsRemoveProducts.forEach(parp => {
      this.searchAndRemoveMaterialFromAccrualsPrograms(accrualPrograms, parp);
      this.searchAndRemoveAssignRates(parp);
      var levelCount = parp.removeProducts.length;
      
      if(levelCount < 6){
        levelCount  = 6;
       }     
      for (var level = 0; level < levelCount ; level++) {
        this.searchAndRemoveProgramsAccrualsAssignRates(parp);
      }     
      
      for (var level = 0; level < levelCount ; level++) {
        this.searchAndRemoveMaterialHierarchysFromAccrualsPrograms(accrualPrograms);
      }
    })
  }
  searchAndRemoveMaterialFromAccrualsPrograms(accrualsPrograms, programsAccrualsRemoveProducts) {
    switch (this.masterSelectedHierarchyLevel) {
      case 0:
        accrualsPrograms.forEach(aprg => {
          if (aprg.id === programsAccrualsRemoveProducts.programId) {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length > 0) {
                      aprgChild.children.forEach(aprgChildChild => {
                        if (typeof aprgChildChild.children === 'object') {
                          if (aprgChildChild.children.length > 0) {
                            aprgChildChild.children.forEach(aprgChildChildChild => {
                              if (typeof aprgChildChildChild.children === 'object') {
                                if (aprgChildChildChild.children.length > 0) {
                                  aprgChildChildChild.children.forEach(aprgChildChildChildChild => {
                                    if (typeof aprgChildChildChildChild.children === 'object') {
                                      if (aprgChildChildChildChild.children.length > 0) {
                                        programsAccrualsRemoveProducts.removeProducts.forEach(rp => {
                                          aprgChildChildChildChild.children.forEach(element => {
                                            if (rp.materialCode === element.materialCode) {
                                              var index = aprgChildChildChildChild.children.indexOf(element);
                                              aprgChildChildChildChild.children.splice(index, 1);
                                            }
                                          })
                                        })
                                      }
                                    }
                                  })
                                }
                              }
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          }
        })
        break;
      case 1:
        accrualsPrograms.forEach(aprg => {
          if (aprg.id === programsAccrualsRemoveProducts.programId) {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length > 0) {
                      aprgChild.children.forEach(aprgChildChild => {
                        if (typeof aprgChildChild.children === 'object') {
                          if (aprgChildChild.children.length > 0) {
                            aprgChildChild.children.forEach(aprgChildChildChild => {
                              if (typeof aprgChildChildChild.children === 'object') {
                                if (aprgChildChildChild.children.length > 0) {
                                  programsAccrualsRemoveProducts.removeProducts.forEach(rp => {
                                    aprgChildChildChild.children.forEach(element => {
                                      if (rp.materialCode === element.materialCode) {
                                        var index = aprgChildChildChild.children.indexOf(element);
                                        aprgChildChildChild.children.splice(index, 1);
                                      }
                                    })
                                  })
                                }
                              }
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          }
        })
        break;
      case 2:
        accrualsPrograms.forEach(aprg => {
          if (aprg.id === programsAccrualsRemoveProducts.programId) {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length > 0) {
                      aprgChild.children.forEach(aprgChildChild => {
                        if (typeof aprgChildChild.children === 'object') {
                          if (aprgChildChild.children.length > 0) {
                            programsAccrualsRemoveProducts.removeProducts.forEach(rp => {
                              aprgChildChild.children.forEach(element => {
                                if (rp.materialCode === element.materialCode) {
                                  var index = aprgChildChild.children.indexOf(element);
                                  aprgChildChild.children.splice(index, 1);
                                }
                              })
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          }
        })
        break;
      case 3:
        accrualsPrograms.forEach(aprg => {
          if (aprg.id === programsAccrualsRemoveProducts.programId) {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length > 0) {
                      programsAccrualsRemoveProducts.removeProducts.forEach(rp => {
                        aprgChild.children.forEach(element => {
                          if (rp.materialCode === element.materialCode) {
                            var index = aprgChild.children.indexOf(element);
                            aprgChild.children.splice(index, 1);
                          }
                        })
                      })
                    }
                  }
                })
              }
            }
          }
        })
        break;
      case 4:
        accrualsPrograms.forEach(aprg => {
          if (aprg.id === programsAccrualsRemoveProducts.programId) {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                programsAccrualsRemoveProducts.removeProducts.forEach(rp => {
                  aprg.children.forEach(element => {
                    if (rp.materialCode === element.materialCode) {
                      var index = aprg.children.indexOf(element);
                      aprg.children.splice(index, 1);
                    }
                  })
                })
              }
            }
          }
        })
        break;
    }
  }

  searchAndRemoveMaterialHierarchysFromAccrualsPrograms(accrualsPrograms) {
    for (var i = 4; i > 0; i--) {
      switch (i) {
        case 4:
          accrualsPrograms.forEach(aprg => {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length > 0) {
                      aprgChild.children.forEach(aprgChildChild => {
                        if (typeof aprgChildChild.children === 'object') {
                          if (aprgChildChild.children.length > 0) {
                            aprgChildChild.children.forEach(aprgChildChildChild => {
                              if (typeof aprgChildChildChild.children === 'object') {
                                if (aprgChildChildChild.children.length > 0) {
                                  aprgChildChildChild.children.forEach(aprgChildChildChildChild => {
                                    if (typeof aprgChildChildChildChild.children === 'object') {
                                      if (aprgChildChildChildChild.children.length == 0) {
                                        let index = aprgChildChildChild.children.indexOf(aprgChildChildChildChild);
                                        aprgChildChildChild.children.splice(index, 1);
                                      }
                                    }
                                  })
                                }
                              }
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          })
          break;
        case 3:
          accrualsPrograms.forEach(aprg => {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length > 0) {
                      aprgChild.children.forEach(aprgChildChild => {
                        if (typeof aprgChildChild.children === 'object') {
                          if (aprgChildChild.children.length > 0) {
                            aprgChildChild.children.forEach(aprgChildChildChild => {
                              if (typeof aprgChildChildChild.children === 'object') {
                                if (aprgChildChildChild.children.length == 0) {
                                  let index = aprgChildChild.children.indexOf(aprgChildChildChild);
                                  aprgChildChild.children.splice(index, 1);
                                }
                              }
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          })
          break;
        case 2:
          accrualsPrograms.forEach(aprg => {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length > 0) {
                      aprgChild.children.forEach(aprgChildChild => {
                        if (typeof aprgChildChild.children === 'object') {
                          if (aprgChildChild.children.length == 0) {
                            let index = aprgChild.children.indexOf(aprgChildChild);
                            aprgChild.children.splice(index, 1);
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          })
          break;
        case 1:
          accrualsPrograms.forEach(aprg => {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length === 0) {
                      let index = aprg.children.indexOf(aprgChild);
                      aprg.children.splice(index, 1);
                    }
                  }
                })
              }              
            }
          })
          break;
      }
    }  
  }

  searchAndRemoveProgramsAccrualsAssignRates(programsAccrualsRemoveProducts) {
    this.assignRates.forEach(ar => {
      if (ar.programId === programsAccrualsRemoveProducts.programId) {
        if (typeof ar === 'object') {
          programsAccrualsRemoveProducts.removeProducts.forEach(rp => {
            if (rp.materialCode === ar.materialCode) {             
              var index = this.assignRates.indexOf(ar);
                this.assignRates.splice(index, 1);             
            }
          })
        }
      }
    })
  }
  searchAndRemoveAssignRates(programsAccrualsRemoveProducts) {
    this.accrualsAssignRates.forEach(aar => {
      if (aar.programId === programsAccrualsRemoveProducts.programId) {
        if (typeof aar === 'object') {
          programsAccrualsRemoveProducts.removeProducts.forEach(rp => {
            aar.autoFillAssignRates.forEach(afar => {
              if (rp.materialCode === afar.materialCode) {
                var index = aar.autoFillAssignRates.indexOf(afar);
                aar.autoFillAssignRates.splice(index, 1);
              }
            })
          })
        }
        if (aar.autoFillAssignRates.length === 0) {
          var index = this.accrualsAssignRates.indexOf(aar);
          this.accrualsAssignRates.splice(index, 1);
        }
      }
    })
  }

  setForRemoveProductsCommentDialog() {
    this.submittedOrRemovedAccrualPrograms = [];
    if (this.programAccrualRatesByMonth.length > 0) {
      this.deleteProgramAccrualRatesByMonth = [];
      this.deleteFormatProgramAccrualRatesByMonth();
      this.data['dataAccrualPrograms'].forEach(ap => {
       var submittedDeleteProgramAccrualRatesByMonths;
       if(this.data['productManager'] && this.data['isAdmin']){
        submittedDeleteProgramAccrualRatesByMonths = this.deleteProgramAccrualRatesByMonth.filter(dpar => dpar.programId == ap.id);
      }
      else{
        submittedDeleteProgramAccrualRatesByMonths = this.deleteProgramAccrualRatesByMonth.filter(dpar => dpar.programId == ap.id && dpar.statusDefinitionId === this.statusDefinitionIds.ACCRUALS_COMPLETED);
      }

        if (submittedDeleteProgramAccrualRatesByMonths.length > 0 || this.data['isAdmin']) {
          var isCommentRequired = (ap.isCommentRequired && !this.data['isAdmin']) || (this.data['productManager'] &&this.data['isAdmin']);
          submittedDeleteProgramAccrualRatesByMonths.forEach(element => {
            if(!this.submittedOrRemovedAccrualPrograms.some(srap => srap.programId === element.programId)){
              this.submittedOrRemovedAccrualPrograms.push({
                programId: ap.id,
                programName: ap.name,
                disabled: isCommentRequired,
                selected: isCommentRequired,
                isCommentRequired: isCommentRequired
              });
            }
          });          
        }
      });
    }
  }

   deleteFormatProgramAccrualRatesByMonth() {
    this.deleteProgramAccrualRatesByMonth = [];
    this.programAccrualRatesByMonth.forEach(parbm => {
      if (!(this.assignRates.some(element => element.programId === parbm.programId && element.materialHierarchyCommercialId === parbm.materialHierarchyCommercialId))) {
        this.deleteProgramAccrualRatesByMonth.push(parbm);
      }
    })
  }
  
  onCancel() {
    this.dialogRef.close({ dataAccrualPrograms: this.nonUpdatedAccrualsPrograms, dataAssignRates: this.nonUpdatedAssignRates, dataAccrualsAssignRates: this.nonUpdatedAccrualsAssignRates, dataConfirmRemoval: false });
  }

  onConfirmRemoval() {
    if (this.programAccrualRatesByMonth.length > 0) {
     this.setForRemoveProductsCommentDialog();
     if( this.submittedOrRemovedAccrualPrograms.length > 0){
      this.openRemoveProductsCommentDialog();
     }
     else{
      this.dialogRef.close({ submittedOrRemovedAccrualPrograms :this.submittedOrRemovedAccrualPrograms, dataProgramsAccrualsRemoveProducts: this.programsAccrualsRemoveProducts,dataAccrualPrograms: this.data['dataAccrualPrograms'],dataAssignRates: this.assignRates, dataAccrualsAssignRates: this.accrualsAssignRates, dataConfirmRemoval: true });
     }
    }
    else{
      this.dialogRef.close({ submittedOrRemovedAccrualPrograms :this.submittedOrRemovedAccrualPrograms, dataProgramsAccrualsRemoveProducts: this.programsAccrualsRemoveProducts,dataAccrualPrograms: this.data['dataAccrualPrograms'],dataAssignRates: this.assignRates, dataAccrualsAssignRates: this.accrualsAssignRates, dataConfirmRemoval: true });
    }
  }

  openRemoveProductsCommentDialog(): void {
    const dialog = this.dialog.open(DialogAccrualsCommentsComponent, {
      disableClose: true,
      height: '480px',
      width: '650px',
      data: { submittedOrRemovedAccrualPrograms: this.submittedOrRemovedAccrualPrograms,dataConfirm: true }
    });

    dialog.afterClosed().subscribe(result => {
      if (result != undefined) { 
        if (result['dataConfirm'] === true) {
        this.submittedOrRemovedAccrualPrograms = result["submittedOrRemovedAccrualPrograms"];
        this.dialogRef.close({ submittedOrRemovedAccrualPrograms :this.submittedOrRemovedAccrualPrograms, dataProgramsAccrualsRemoveProducts: this.programsAccrualsRemoveProducts,dataAccrualPrograms: this.data['dataAccrualPrograms'],dataAssignRates: this.assignRates, dataAccrualsAssignRates: this.accrualsAssignRates, dataConfirmRemoval: true });
      }     
    }
    })
  }
}

