<div>
  <mat-toolbar class="top-toolbar">
    <mat-toolbar-row>
      <div>
        <button mat-raised-button class="functional-button" (click)="onBack();">
          <mat-icon title="Back">keyboard_arrow_left</mat-icon> Back
        </button>
      </div>
      <span class="header-page">{{!isChangeException?'Review Program':'Review Change Exception'}}</span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="horizontal-scroll-wrapper">
    <div class="notification">
      <mat-card class="notification-info"
      *ngIf='canApproveOrDenyProgram() && !showApproval && !showRejection && isBannerVisible && !isChildProgramFinalApprover && !bundleRoute'>
        <mat-card-content class="notification-desc">
          <div class="row">
            <div class="col-md-8">
              <label class="notification-header">Action Required<span *ngIf=isAdmin()>&nbsp;On Behalf Of
                  {{nextDoaApprover}}</span></label>
              <p>Review the program and accrual information below and choose to either approve or deny</p>
            </div>
            <div class="col-md-4">
              <button mat-raised-button class="functional-button" (click)="openDenyDialog();">
                Deny <span class="material-icons">
                  thumb_down
                </span>
              </button>
              <button mat-raised-button class="functional-button-dark" (click)="openApproveDialog();">
                Approve <span class="material-icons">
                  thumb_up
                </span>
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="notification-warning" *ngIf='isPriorDoaApproverBannerVisible || isChildProgramFinalApprover'>
        <mat-card-content class="notification-desc">
          <div class="row">
            <div class="col-md-8">
              <p *ngIf='isPriorDoaApproverBannerVisible'>A prior Approver has yet to approve this program.</p>
              <p *ngIf='isChildProgramFinalApprover'>A prior Approver has yet to approve this Child program's Parent.
              </p>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="notification-approved" *ngIf=showApproval>
        <div class="row">
          <div class="col-md-1">
            <mat-icon class="matcard-icon" class="approved-icon" title="Success">
              verified
            </mat-icon>
          </div>
          <div class="col-md-5">
            <label class="approved-header">{{!isChangeException?'Program Approved':'Change Exception Approved'}}</label>
          </div>
          <div class="col-md-6">
            <button mat-raised-button class="functional-button functional-button-edit" (click)="ChangeException()"
              *ngIf="isChangeExceptionVisible">{{!isChangeException?'Edit Program':'Edit Change Exception'}}
            </button>
            <button mat-raised-button class="functional-button-replicate" (click)="ReplicateProgram()"
              *ngIf="!isChangeException && canReplicateProgram">Replicate Program
            </button>
          </div>
        </div>
      </mat-card>
      <mat-card class="notification-denied" *ngIf=showRejection>
        <div class="row">
          <div class="col-md-1">
            <mat-icon class="matcard-icon" class="denied-icon" title="Deny">
              new_releases
            </mat-icon>
          </div>
          <div class="col-md-9">
            <label class="denied-header">{{!isChangeException?'Program Denied':'Change Exception Denied'}}</label>
          </div>
        </div>
      </mat-card>
      <mat-card class="notification-info" *ngIf='isCancelRerouteVisible'>
        <mat-card-content class="notification-desc">
          <div class="row">
            <div class="col-md-4">
              <button mat-raised-button class="functional-button" (click)="cancelReroute()">
                Cancel/Restart DOA Routing
              </button> <br />
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <table>
      <tr>
        <td class="td-column">
          <div class="main-cards">
            <form [formGroup]="programForm">
              <mat-card class="card">
                <span class="span">
                  <mat-icon class="matcard-icon" title="Program Details">assignment</mat-icon>
                  <mat-card-header>{{!isChangeException?'Program Details':'Change Exception Details'}}</mat-card-header>
                </span>
                <mat-card-content>
                  <div class="row">
                    <mat-form-field class="col-md-6 readonly">
                      <mat-label>Program Name</mat-label>
                      <input matInput formControlName="ShortName" readonly>
                    </mat-form-field>
                    <mat-form-field class="col-md-3 readonly">
                      <mat-label>{{!isChangeException?'Program Code':'Change Exception Code'}}</mat-label>
                      <input matInput formControlName="ProgramCode" readonly>
                    </mat-form-field>
                    <mat-form-field class="col-md-3 readonly">
                      <mat-label>Program Manager</mat-label>
                      <input matInput formControlName="ProgramManager" readonly>
                    </mat-form-field>
                  </div>
                  <br>
                  <div class="row">
                    <mat-form-field class="col-md-6 readonly">
                      <mat-label>Consistent Program Name</mat-label>
                      <input matInput formControlName="ConsistentName" readonly>
                    </mat-form-field>
                    <mat-form-field class="col-md-3 readonly">
                      <mat-label>{{!isChangeException?'Program Status':'Change Exception Status'}}</mat-label>
                      <input matInput formControlName="ProgramStatus" readonly>
                    </mat-form-field>
                  </div>
                  <br>
                  <div class="row" *ngIf="!isChangeException">
                    <mat-form-field class="col-md-12  readonly">
                      <mat-label>Program Description</mat-label>
                      <textarea matInput formControlName="ProgramDesc" readonly></textarea>
                    </mat-form-field>
                  </div>
                  <div class="row" *ngIf="isChangeException">
                    <mat-form-field class="col-md-12 readonly">
                      <mat-label>Change Exception Explanation</mat-label>
                      <textarea matInput formControlName="Explanation" readonly></textarea>
                    </mat-form-field>
                  </div>
                  <br>
                  <div class="dash"></div>
                  <br>
                  <div class="row">
                    <div class="{{changeExceptionProgramsList.length>0&&programsAssociated?'col-md-4':'col-md-6'}}"
                      *ngIf="programCategory">
                      <label>{{programCategory}}</label>
                    </div>
                    <div class="{{programCategory&&changeExceptionProgramsList.length>0?'col-md-4':'col-md-6'}}"
                      *ngIf="programsAssociated">
                      <label>{{programsAssociated}}</label>
                      <div *ngIf="displayChildren">
                        <span *ngFor="let childProgramCode of childProgramCodes; let isLast=last">
                          <a href="javascript:void(0);"
                            (click)="navigateToProgram(childProgramCode.code, childProgramCode.statusDefinitionId);">
                            {{childProgramCode.code}}{{isLast ? '' : ', '}}
                          </a>
                        </span>
                      </div>
                      <span *ngIf="displayParent"> <a href="javascript:void(0);" (click)="gotoParent()">
                          {{parentProgramCode}}
                        </a> </span>
                    </div>
                  </div>
                  <div>
                    <div *ngIf="(changeExceptionProgramsList.length>0||isChangeException)">
                      <br>
                      <div class="dash"></div>
                      <br>
                    </div>
                    <div class="row">
                      <div class="{{!programCategory||!programsAssociated?'col-md-6':'col-md-4'}}"
                        *ngIf="changeExceptionProgramsList.length>0">
                        <label>Change Exception List</label>
                        <div *ngFor="let changeExceptionProgram of changeExceptionProgramsList"> <a
                            href="javascript:void(0);"
                            (click)="navigateToProgram(changeExceptionProgram.code, changeExceptionProgram.statusDefinitionId);">
                            {{changeExceptionProgram.code}} {{statusDefinitionShortDesc['_' +
                            changeExceptionProgram.statusDefinitionId]}}
                            {{changeExceptionProgram.approvedDate}}</a> </div>
                      </div>
                      <div class="col-md-4" *ngIf="isChangeException">
                        <label>Master Program</label>
                        <div> <a href="javascript:void(0);"
                            (click)="navigateToProgram(masterProgramCode,statusDefinitionIds.APPROVED);">{{masterProgramCode}}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
              <mat-card class="card">
                <span class="span">
                  <mat-icon class="matcard-icon" title="Program Attributes">apartment</mat-icon>
                  <mat-card-header>Program Attributes </mat-card-header>
                </span>
                <mat-card-content>
                  <div class="row">
                    <mat-form-field class="col-md-6 readonly">
                      <mat-label>Company Name</mat-label>
                      <input matInput formControlName="CompanyCode" readonly>
                    </mat-form-field>
                    <mat-form-field class="col-md-6 readonly">
                      <mat-label>Program Channel</mat-label>
                      <input matInput formControlName="Channel" readonly>
                    </mat-form-field>
                  </div> <br>
                  <div class="row">
                    <mat-form-field class="col-md-6 readonly">
                      <mat-label>Business Segment</mat-label>
                      <input matInput formControlName="BusinessSegments" readonly>
                    </mat-form-field>
                    <mat-form-field class="col-md-6 readonly">
                      <mat-label>Program Type</mat-label>
                      <input matInput formControlName="TypeDefinition" readonly>
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </mat-card>
              <mat-card class="card">
                <span class="span">
                  <mat-icon class="matcard-icon" title="Program Dates">date_range</mat-icon>
                  <mat-card-header>{{!isChangeException?'Program Dates':'Change Exception Dates'}}</mat-card-header>
                </span>  
                <mat-card-content>
                  <div class="row">
                    <mat-form-field color="#1A81E1" class="col-lg-4 readonly">
                      <mat-label>Start Date</mat-label>
                      <input matInput [matDatepicker]="picker1" formControlName="BeginDate" readonly>
                      <mat-datepicker #picker1 color="#1A81E1"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field color="#1A81E1" class="col-lg-4 readonly">
                      <mat-label>End Date</mat-label>
                      <input matInput [matDatepicker]="picker2" formControlName="EndDate" readonly>
                      <mat-datepicker #picker2 color="#1A81E1"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="col-lg-4 readonly">
                      <mat-label>Program Year</mat-label>
                      <input matInput formControlName="ProgramYear" readonly>
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </mat-card>
              <mat-card class="card">
                <span class="span">
                  <mat-icon class="matcard-icon" title="Financial Assumptions">account_balance</mat-icon>
                  <mat-card-header *ngIf="!showParentProgram">{{!isChangeException?'Financial Assumptions':'Change Exception Financial Assumptions'}}</mat-card-header>
                  <mat-card-header *ngIf="showParentProgram">Parent Program Financial Assumptions </mat-card-header>
                  <label class="lblparentprog" *ngIf="showParentProgram">The Parent program (<a [routerLink]="[]"
                      (click)="gotoParent()">{{parentProgramCode}} </a>) is in <b>{{parentProgramStatus}}</b>
                    status</label>
                </span>
                <div class="col-md-12">                 
                  <div class="financialcard-view">
                    <mat-card-content *ngIf="!financialAssumptionsExists">
                      <div>
                        No Total Sales and Total Exposure values available for this program.
                      </div>
                    </mat-card-content>
                    <mat-card-content *ngIf="financialAssumptionsExists">
                      <div class="row">
                        <div  class="col-md-4">                       
                          <mat-checkbox
                          *ngIf="programTableType === programTableTypeIds.Program" formControlName="CalculatedTotals">
                          Update Total Sales and Total Exposure</mat-checkbox>
                        </div>
                        <div class="col-md-3"></div>
                        <div  class="col-md-5" [hidden]="!(isStandardOrParentTypeProgram && !showParentProgram) || programTableType !== programTableTypeIds.Program">                          
                          <a [routerLink]="[]" (click)="getProductsAndAccrualsSummary()">Products and
                          Accruals Summary </a>
                        </div>
                      </div>
                      <div class="row first-row" [ngClass]="{'green-border-color': statusDefinitionId === statusDefinitionIds.APPROVED}" *ngIf="statusDefinitionId === statusDefinitionIds.APPROVED">
                       
                        <div class="col-md-4">                        
                            <mat-form-field class="readonly">
                              <mat-label>Total Sales (Local Currency)</mat-label>
                              <input type="text" [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput
                                formControlName="TotalSales" readonly>
                                <span matPrefix>&nbsp;&nbsp;$&nbsp;</span>
                            </mat-form-field>                        
                        </div>
                          <div class="col-md-4">                          
                              <mat-form-field class="readonly">
                                <mat-label>Total Exposure (Local Currency)</mat-label>
                                <input type="text" [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput
                                  formControlName="TotalExposure" readonly>
                                  <span matPrefix>&nbsp;&nbsp;$&nbsp;</span>
                              </mat-form-field>
                            </div>
                          <div class="col-md-4">                         
                              <mat-form-field class="readonly" *ngIf="isCEFinancialAssumptionsVisible">
                                <mat-label>Total Exposure Difference</mat-label>
                                <input type="text" [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput
                                  formControlName="TotalExposureDifference" readonly>
                                  <span matPrefix>$&nbsp;</span>
                              </mat-form-field>                         
                          </div>                        
                      </div>
                      <div class="row first-row" [ngClass]="{'orange-border-color': statusDefinitionId === statusDefinitionIds.ACCRUALS_PENDING || statusDefinitionId === statusDefinitionIds.ACCRUALS_COMPLETED || statusDefinitionId === statusDefinitionIds.APPROVAL_ROUTING || statusDefinitionId === statusDefinitionIds.DRAFT}" *ngIf="statusDefinitionId === statusDefinitionIds.ACCRUALS_PENDING || statusDefinitionId === statusDefinitionIds.ACCRUALS_COMPLETED || statusDefinitionId === statusDefinitionIds.APPROVAL_ROUTING || statusDefinitionId === statusDefinitionIds.DRAFT">
                       
                        <div class="col-md-4">                        
                            <mat-form-field class="readonly">
                              <mat-label>Total Sales (Local Currency)</mat-label>
                              <input type="text" [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput
                                formControlName="TotalSales" readonly>
                                <span matPrefix>&nbsp;&nbsp;$&nbsp;</span>
                            </mat-form-field>                        
                        </div>
                          <div class="col-md-4">                          
                              <mat-form-field class="readonly">
                                <mat-label>Total Exposure (Local Currency)</mat-label>
                                <input type="text" [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput
                                  formControlName="TotalExposure" readonly>
                                  <span matPrefix>&nbsp;&nbsp;$&nbsp;</span>
                              </mat-form-field>
                            </div>
                          <div class="col-md-4">                         
                              <mat-form-field class="readonly" *ngIf="isCEFinancialAssumptionsVisible">
                                <mat-label>Total Exposure Difference</mat-label>
                                <input type="text" [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput
                                  formControlName="TotalExposureDifference" readonly>
                                  <span matPrefix>$&nbsp;</span>
                              </mat-form-field>                         
                          </div>                        
                      </div>
                      <div class="row first-row" [ngClass]="{'red-border-color': statusDefinitionId === statusDefinitionIds.CANCELED || statusDefinitionId === statusDefinitionIds.DENIED}" *ngIf="statusDefinitionId === statusDefinitionIds.CANCELED || statusDefinitionId === statusDefinitionIds.DENIED">
                       
                        <div class="col-md-4">                        
                            <mat-form-field class="readonly">
                              <mat-label>Total Sales (Local Currency)</mat-label>
                              <input type="text" [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput
                                formControlName="TotalSales" readonly>
                                <span matPrefix>&nbsp;&nbsp;$&nbsp;</span>
                            </mat-form-field>                        
                        </div>
                          <div class="col-md-4">                          
                              <mat-form-field class="readonly">
                                <mat-label>Total Exposure (Local Currency)</mat-label>
                                <input type="text" [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput
                                  formControlName="TotalExposure" readonly>
                                  <span matPrefix>&nbsp;&nbsp;$&nbsp;</span>
                              </mat-form-field>
                            </div>
                          <div class="col-md-4">                         
                              <mat-form-field class="readonly" *ngIf="isCEFinancialAssumptionsVisible">
                                <mat-label>Total Exposure Difference</mat-label>
                                <input type="text" [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput
                                  formControlName="TotalExposureDifference" readonly>
                                  <span matPrefix>$&nbsp;</span>
                              </mat-form-field>                         
                          </div>                        
                      </div>                       
                      <div class="row child-row" 
                      *ngFor="let item of totalSalesAndTotalExposureList; let i=index;"
                      [ngClass]="{'green-border-color': item.status === statusDefinitionIds.APPROVED,'orange-border-color': item.status === statusDefinitionIds.ACCRUALS_PENDING || item.status === statusDefinitionIds.ACCRUALS_COMPLETED || item.status === statusDefinitionIds.APPROVAL_ROUTING || item.status === statusDefinitionIds.DRAFT,'red-border-color': item.status === statusDefinitionIds.CANCELED || item.status === statusDefinitionIds.DENIED}"> 
                        <div class="col-md-4">
                              <div class="row">
                                <mat-label class="child-labels">Total Sales (Local Currency)</mat-label> 
                              </div>
                              <div class="row">
                                <mat-label class="childcurrency">{{item.totalSales | currency: 'USD':'symbol':'1.0-0'}}</mat-label>
                              </div>                                              
                        </div>
                        <div class="col-md-4">    
                          <div class="row">
                            <mat-label class="child-labels exposurealignment">Total Exposure (Local Currency)</mat-label>
                          </div>
                          <div class="row">
                            <mat-label class="childcurrency lblexposure">{{item.totalExposure | currency: 'USD':'symbol':'1.0-0'}}</mat-label>    
                          </div>                             
                                                   
                        </div>
                        
                        <div class="col-md-4">                         
                                <mat-label>{{item.programType}}-</mat-label>
                                <mat-label>{{item.programCode}}</mat-label>                           
                        </div>
                      </div>
                    </mat-card-content>
                  </div>
                    <mat-card-content *ngIf="financialAssumptionsExists">
                    <div
                    [hidden]="!(isStandardOrParentTypeProgram && !showParentProgram) || programTableType !== programTableTypeIds.Program ">
                    <br>
                    <div class="dash" *ngIf="emailNotifications.length > 0">
                    </div>
                                        <br>
                    <div class="matgrid-div">
                      <div class="table-container" [hidden]="emailNotifications.length === 0">
                        <table mat-table class="mat-grid" [dataSource]="dataSourceEmailNotifications" matSort>
                          <ng-container matColumnDef="productManager">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> PRODUCT MANAGER </th>
                            <td mat-cell *matCellDef="let element"> {{ element.productManager }} </td>
                          </ng-container>
                          <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>EMAIL</th>
                            <td mat-cell *matCellDef="let element"><a href='mailto: {{ element.email }}'> {{
                                element.email }} </a> </td>
                          </ng-container>
                          <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> STATUS </th>
                            <td mat-cell *matCellDef="let element"> {{ element.status }} </td>
                          </ng-container>
                          <ng-container matColumnDef="daysOldSinceEmailNotification">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> DAYS OLD SINCE EMAIL NOTIFICATION
                            </th>
                            <td mat-cell class="mat-cell-align-center" *matCellDef="let element"> {{
                              element.daysOldSinceEmailNotification }} </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                          </tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                      </div>
                      <mat-paginator #paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons
                        [hidden]="emailNotifications.length === 0"></mat-paginator>
                    </div>
                  </div>
                  </mat-card-content> 
                </div>
              </mat-card>
              <mat-card class="card">
                <span class="span">
                  <mat-icon class="matcard-icon" title="Attachments">attach_file</mat-icon>
                  <mat-card-header>{{!isChangeException?'Attachments':'Change Exception Attachments'}}
                  </mat-card-header>
                </span>
                <mat-card-content>
                  <div *ngIf="childProgramCodes && childProgramCodes.length > 0">
                    <div>
                      View file attachments for the child programs
                      (<span *ngFor="let childProgramCode of childProgramCodes; let isLast=last">
                        <a [routerLink]="[]"
                          (click)="navigateToProgram(childProgramCode.code, childProgramCode.statusDefinitionId);">
                          {{childProgramCode.code}}{{isLast ? '' : ', '}}
                        </a>
                      </span>)
                    </div>
                    <br>
                    <div class="dash"></div>
                    <br>
                  </div>
                  <div *ngIf="parentProgramFileDetailsList && parentProgramFileDetailsList.length>0">
                    <span>View file attachments below for the parent program (<a [routerLink]="[]"
                        (click)="navigateToProgram(parentProgramCode,parentProgramStatus);">{{ parentProgramCode
                        }}</a>).</span>
                    <table class="attachment-table">
                      <tr>
                        <th>File Name</th>
                        <th>Last Modified Date</th>
                      </tr>
                      <tbody>
                        <tr *ngFor="let file of parentProgramFileDetailsList" class="fileList">
                          <td>
                            <a [routerLink]="[]"
                              (click)="downloadFile(parentProgramCode,file.fileName);">{{file.fileName}}</a>
                          </td>
                          <td>
                            {{file.fileLastModified | date: 'MM/dd/yyyy'}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="dash" *ngIf="parentProgramFileDetailsList && parentProgramFileDetailsList.length>0"></div>
                  <table class="attachment-table" *ngIf="(fileDetailsList.length > 0)">
                    <tr>
                      <th>File Name</th>
                      <th>Last Modified Date</th>
                    </tr>
                    <tbody>
                      <tr *ngFor="let file of fileDetailsList" class="fileList">
                        <td>
                          <a [routerLink]="[]" (click)="downloadFile(progCode,file.fileName);">{{file.fileName}}</a>
                        </td>
                        <td>
                          {{file.fileLastModified | date: 'MM/dd/yyyy'}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br>
                  <div class="save-attachment-button">
                    <button *ngIf="isProgramOwner" mat-button class="functional-button-dark"
                      (click)="onAttachmentSave();">
                      Save Attachments
                    </button>
                  </div>
                  <app-file-upload *ngIf="isProgramOwner" formControlName="FileUpload"
                    (change)="checkAttachmentFilesChange($event)">
                  </app-file-upload>
                </mat-card-content>
              </mat-card>
            </form>
          </div>
        </td>
        <td class="td-history-approvals">
          <div class="position-relative">
            <mat-card class="sub-cards">
              <mat-tab-group class="mat-stretched-tabs">
                <mat-tab>
                  <ng-template mat-tab-label>
                    Approvals
                  </ng-template>
                  <mat-card-content *ngIf="doaApprovers.length==0"
                    class=" mat-card-content-padding mat-card-align-notext">
                    <p>No Approvals.</p>
                  </mat-card-content>
                  <mat-card-content class="mat-card-content-padding mat-card-align" *ngIf="doaApprovers.length>0" >
                    <div class="mat-card-scroll-approvals">
                      <button mat-raised-button class="functional-button-dark button-padding"
                        (click)="editDoaApprovers();" *ngIf="isEditDoaApproversVisible ">Edit Doa Approvers</button>
                      <mat-list role="list"
                        *ngIf="statusDefinitionId === statusDefinitionIds.APPROVED || routingCanceledDate == null">
                        <mat-list-item role="listitem" class="text-bold mat-list-item-routing">Routing Started
                        </mat-list-item>
                        <mat-list-item role="listitem" class="mat-list-item-routing" *ngIf="routingStartDate != null">
                          {{routingStartDate | date: 'MMMM d, y'}} </mat-list-item>
                      </mat-list>
                      <mat-grid-list cols="4" rowHeight="5:1"
                        *ngIf="statusDefinitionId != statusDefinitionIds.APPROVED && routingCanceledDate != null">
                        <mat-grid-tile [colspan]="2" [rowspan]="1" class="text-bold mat-list-item-routing"
                          *ngIf="routingStartDate != null">
                          Routing Started
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="2" [rowspan]="1" class="text-bold mat-list-item-routing"
                          *ngIf="routingCanceledDate != null">
                          Canceled/Restart
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="2" [rowspan]="1" class="doaApprover-notificationDate"
                          *ngIf="routingStartDate != null">
                          {{routingStartDate | date: 'MMMM d, y'}}
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="2" [rowspan]="1" *ngIf="routingCanceledDate != null">
                          {{routingCanceledDate | date: 'MMMM d, y'}}
                        </mat-grid-tile>
                      </mat-grid-list>
                      <mat-divider></mat-divider>
                      <div class="bullet-list">
                        <ol>
                          <li *ngFor="let doaApprover of doaApprovers" class="list-padding-top ">
                            <mat-grid-list cols="4" rowHeight="5:1">
                              <mat-grid-tile *ngIf="doaApprover.active" [colspan]="2" [rowspan]="1" class="text-bold">
                                {{doaApprover.userName}}
                              </mat-grid-tile>
                              <mat-grid-tile *ngIf="!doaApprover.active" [colspan]="2" [rowspan]="1" class="text-bold-inactive">
                                {{doaApprover.userName}}
                              </mat-grid-tile>
                              <mat-grid-tile [colspan]="2" [rowspan]="2">
                                <mat-chip-list>
                                  <mat-chip
                                    *ngIf="doaApprover.statusDefinitionId === statusDefinitionIds.APPROVED && !doaApprover.adminStatusUpdate"
                                    class="mat-chip-approved mat-chip-list-stacked">Approved {{
                                    doaApprover.dateCompleted
                                    |
                                    date: 'M/d/yyyy'}}</mat-chip>
                                  <mat-chip
                                    *ngIf="doaApprover.statusDefinitionId === statusDefinitionIds.APPROVED && doaApprover.adminStatusUpdate"
                                    class="mat-chip-approved mat-chip-list-stacked admin">Admin Approved {{
                                    doaApprover.dateCompleted |
                                    date: 'M/d/yyyy'}}</mat-chip>
                                  <mat-chip
                                    *ngIf="doaApprover.statusDefinitionId === statusDefinitionIds.DENIED && !doaApprover.adminStatusUpdate"
                                    class="mat-chip-denied">Denied {{ doaApprover.dateCompleted | date: 'M/d/yyyy'}}
                                  </mat-chip>
                                  <mat-chip
                                    *ngIf="doaApprover.statusDefinitionId === statusDefinitionIds.DENIED && doaApprover.adminStatusUpdate"
                                    class="mat-chip-denied admin">Admin Denied {{ doaApprover.dateCompleted | date:
                                    'M/d/yyyy'}}
                                  </mat-chip>
                                  <mat-chip *ngIf="doaApprover.statusDefinitionId == null && showApprovalsPendingChip && doaApprover.approver"
                                    class="mat-chip-pending">Pending</mat-chip>
                                  <mat-chip class="mat-chip-reviewers mat-chip-list-stacked" *ngIf="!doaApprover.approver">Reviewer</mat-chip>
                                </mat-chip-list>
                              </mat-grid-tile>
                              <mat-grid-tile [colspan]="2" [rowspan]="1">
                                <div mat-line class="doaApprover-role">{{doaApprover.doaRole}}</div>
                              </mat-grid-tile>
                              <mat-grid-tile [colspan]="2" [rowspan]="1">
                                <div mat-line class="doaApprover-notificationDate">
                                  {{doaApprover.emailNotificationInsertDate
                                  | date: 'MMMM d, y'}}
                                </div>
                              </mat-grid-tile>
                              <mat-grid-tile [colspan]="2" [rowspan]="1"
                                *ngIf="doaApprover.daysWaiting != null && doaApprover.approver && doaApprover.statusDefinitionId != statusDefinitionIds.APPROVED && statusDefinitionId != statusDefinitionIds.DENIED && showDaysWaiting">
                                <div class="doaApprover-notificationDate"> Days Waiting : &nbsp;</div>
                                <div class="text-bold ">{{doaApprover.daysWaiting}}</div>
                              </mat-grid-tile>
                            </mat-grid-list>
                            <mat-divider *ngIf="doaApprover.approvalOrder != doaMaxApprovalOrder"></mat-divider>
                          </li>
                      
                        </ol>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    History
                  </ng-template>
                  <mat-card-content *ngIf="statusesList.length==0" class=" mat-card-content-padding mat-card-align-notext">
                    <mat-list role="list" *ngIf="createdDate != null && !isChangeException">
                      <mat-list-item role="listitem" class="text-bold mat-list-item-routing">Program Created
                      </mat-list-item>
                      <mat-list-item role="listitem" class="mat-list-item-routing"> {{createdDate | date: 'MMMM d, y
                        h:mm:ss a'}} </mat-list-item>
                    </mat-list>
                    <mat-list role="list" *ngIf="changeExceptionCreatedDate != null && isChangeException">
                      <mat-list-item role="listitem" class="text-bold mat-list-item-routing">Change Exception Program Created
                      </mat-list-item>
                      <mat-list-item role="listitem" class="mat-list-item-routing"> {{changeExceptionCreatedDate | date: 'MMMM d, y
                        h:mm:ss a'}} </mat-list-item>
                    </mat-list>
                    <mat-divider></mat-divider><br>
                    <p>No History.</p>
                  </mat-card-content>
                  <mat-card-content class="mat-card-align mat-card-content-padding mat-card-scroll-history">
                    <div *ngIf="statusesList.length>0">
                      <ul class="status-history">
                        <mat-list role="list" *ngIf="createdDate != null && !isChangeException">
                          <mat-list-item role="listitem" class="text-bold mat-list-item-routing">Program Created
                          </mat-list-item>
                          <mat-list-item role="listitem" class="mat-list-item-routing"> {{createdDate | date: 'MMMM d, y
                            h:mm:ss a'}} </mat-list-item>
                        </mat-list>
                        <mat-list role="list" *ngIf="changeExceptionCreatedDate != null && isChangeException">
                          <mat-list-item role="listitem" class="text-bold mat-list-item-routing">Change Exception Program Created
                          </mat-list-item>
                          <mat-list-item role="listitem" class="mat-list-item-routing"> {{changeExceptionCreatedDate | date: 'MMMM d, y
                            h:mm:ss a'}} </mat-list-item>
                        </mat-list>
                        <mat-divider></mat-divider><br>
                        <li *ngFor="let statusItem of statusesList" class="status-history-list">
                          <mat-grid-list cols="2" rowHeight="8:1">
                            <mat-grid-tile [colspan]="2" [rowspan]="1" class="text-bold">
                              {{statusItem.statusDefinition}}
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="2" [rowspan]="1">
                              <div mat-line class="status-username-role">{{statusItem.appUser}}, {{statusItem.role}}
                              </div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="2" [rowspan]="1">
                              <div mat-line class="status-insertDate">{{statusItem.insertDate | date: 'MMMM d, y h:mm:ss
                                a'}}</div>
                            </mat-grid-tile>
                          </mat-grid-list>
                        </li>
                      </ul>
                    </div>
                  </mat-card-content>
                </mat-tab>
              </mat-tab-group>
            </mat-card>
            <mat-card class="sub-cards message-card-padding">
              <mat-list class="comments-board-alignment">
                <mat-grid-list cols="6" rowHeight="2:1">
                  <mat-grid-tile [colspan]="3" [rowspan]="1">
                    <div class="post-header list-item-height">Comments Board</div>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="3" [rowspan]="1">
                    <div class="{{ showPostButton ?'buttons-alignment':'mat-icon-expand-padding'}}">
                      <button mat-raised-button class="mat-icon-expand " (click)="openAllPanels()"
                        matTooltip="Expand Comments" matTooltipPosition="above" *ngIf="showExpandButton ">
                        <mat-icon>expand_more</mat-icon>
                      </button>
                      <button mat-raised-button class="mat-icon-expand" (click)="closeAllPanels()"
                        matTooltip="Collapse Comments" matTooltipPosition="above" *ngIf="showExpandButton ">
                        <mat-icon>expand_less</mat-icon>
                      </button>                    
                      <button mat-raised-button class="functional-button-dark  post-comment-button-alignment-right"
                        (click)="postMessage();" *ngIf="showPostButton">
                        <mat-icon class="icon-fontsize"> chat</mat-icon>Post
                      </button>
                    </div>
                  </mat-grid-tile>
                </mat-grid-list>
                <mat-divider></mat-divider>
                <mat-card-content *ngIf="messageList.length==0" class="mat-card-align">
                  <p>No Comments.</p>
                </mat-card-content>
                <mat-card-content class="mat-card-content-overflow" *ngIf="messageList.length>0">
                  <div class="message-card">
                    <mat-accordion [multi]="true" #accordion="matAccordion" class="headers-align">
                      <mat-expansion-panel class="mat-accordion-margin" *ngFor="let message of messageList"
                        #mapanel="matExpansionPanel">
                        <mat-expansion-panel-header
                          class="{{ comapreDate(routingStartDate,message.insertDate) ?'mat-accordion-panel-header-routeCanceled':'mat-accordion-panel-header'}}">
                          <mat-panel-title class="mat-panel-title-name">{{message.updateBy}}</mat-panel-title>
                          <mat-panel-title class="mat-panel-title-role">{{message.doaRole}}
                          </mat-panel-title>
                          <mat-panel-title class="mat-panel-title-date">{{message.insertDate | date: 'MMMM d, y'}}
                          </mat-panel-title>
                          <mat-icon
                            *ngIf="(message.statusDefinitionId == statusDefinitionIds.APPROVED) ||(message.statusDefinitionId ==statusDefinitionIds.DENIED )"
                            class="{{message.statusDefinitionId == statusDefinitionIds.APPROVED ? 'mat-icon-green' :'mat-icon-red'}}">
                            chat</mat-icon>
                          <mat-icon
                            *ngIf="(message.statusDefinitionId != statusDefinitionIds.APPROVED) && (message.statusDefinitionId !=statusDefinitionIds.DENIED )"
                            class="{{ comapreDate(routingStartDate,message.insertDate) ?'mat-icon-grey':'mat-icon-white'}}">
                            chat</mat-icon>
                        </mat-expansion-panel-header>
                        <p
                          class=" {{ comapreDate(routingStartDate,message.insertDate) ?'mat-accordion-panel-header-routeCanceled':'mat-accordion-panel-header'}} p-word-break">
                          {{message.message}}
                        </p>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </mat-card-content>
              </mat-list>
                    </mat-card>
                    </div>
                    </td>
                    </tr>
                    </table>
                    </div>
                    <ngx-spinner class="spinner" size="default" type="ball-spin-clockwise">
                      <p>Please Wait.</p>
                    </ngx-spinner>
                    </div>
