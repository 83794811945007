import { Component, ChangeDetectorRef, DoCheck, OnInit, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Token } from './core/services/authorization/models/token.model';
import { ProgPortalContextService } from './core/services/progportalcontextservice';
import { Observable } from 'rxjs';
import { CoreRepository } from './core/core.repository';
import { User } from './core/user/User';
import { AppSettingsService } from './core/services/app-settings/app-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  static userDetails;
  public token: Token;
  user: User;
  mobileQuery: MediaQueryList;
  isMenuOpen = false;
  title = 'ProgramPortal';
  routeLoaded$!: Observable<boolean>;
  username: string;
  todayDate = new Date();
  isffUserImpersonationPageEnabled: boolean = false;
  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);

  private mobileQueryListener: () => void;
 
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public context: ProgPortalContextService,
    private core: CoreRepository
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
    this.core.getUser();
    console.log(`${new Date()}: app.component.constructor`);
    if (!localStorage.getItem("ImpersonatedUser") && this.core.environment !== "prod") {
      this.context.postdatatoservice(`/api/UserImpersonation/Table/RemoveImpersonatedUser/${this.core.getUsername()}`).toPromise().then((response) => {
        if (response != null) {
          var data = response;
        }
      });
    }
  }

  ngOnInit() {
    console.log(`${new Date()}: app.component.ngOnInit`);
    this.username = this.core.getUsername();
    this.CheckFeatures();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  OnMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
  }


  CheckFeatures() {
    this.context
      .getdatafromService('/api/FeatureFlag')
      .toPromise()
      .then((response) => {
        const data = response;
        this.isffUserImpersonationPageEnabled = data.filter(x => x.featureFlagName === "UserImpersonationPage")[0].enabled;

        if (localStorage.getItem("ImpersonatedUser") && this.canViewUserImpersonation()) {
          const impersonatedUserDetails = JSON.parse(localStorage.getItem("ImpersonatedUser"));
          this.username = impersonatedUserDetails.email;
        }
      });
  }

  canViewUserImpersonation() {
    return this.core.environment !== "prod" && this.isffUserImpersonationPageEnabled;
  }

  canViewProgram() {
    return this.core.canViewProgram;
  }

  canViewAccrual() {
    return this.core.canViewAccrual;
  }

  canViewReport() {
    return this.core.canViewReport;
  }

  canViewAdministration() {
    return this.core.canViewAdministration;
  }
 }
