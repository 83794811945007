export enum EmailDefinitionIds{
    PROGRAMMGR_TO_PRODUCTMGR = 1,
    PROGRAMMGR_TO_DOAAPPROVER = 2,
    PROGRAMMGR_TO_DOAREVIEWER = 3,
    PRODUCTMGR_TO_PROGRAMMGR = 4,
    DOAAPPROVER_TO_DOAAPPROVER = 5,
    DOAAPPROVER_TO_PROGRAMMGR = 6,
    DOAAPPROVER_TO_DOAREVIEWER = 7,
    ADMIN_TO_PRODUCTMGR = 8,
    ADMIN_TO_PROGRAMMGR =9
  }

  export enum EmailDefinitionShortDesc{
    _1 = 'ProgramMgr To ProductMgr',
    _2 = 'ProgramMgr To DOAApprover',
    _3 = 'ProgramMgr To DOAReviewer',
    _4 = 'ProductMgr To ProgramMgr',
    _5 = 'DOAApprover To DOAApprover',
    _6 = 'DOAApprover To ProgramMgr',
    _7 = 'DOAApprover To DOAReviewer',
    _8 = 'Admin To ProductMgr',
    _9 = 'Admin To ProgramMgr'
  }
