<h1 mat-dialog-title>Change Exception Explanation </h1>
<div>
<div class="row">
    <mat-form-field *ngIf="data.changeExceptionProgramCode" class="col-md-3 readonly">
        <mat-label>Change Exception Code</mat-label>
        <input matInput [(ngModel)]="data.changeExceptionProgramCode" readonly>
      </mat-form-field>
</div>
    <div class="row">
        <mat-form-field appearance="fill" class="col-md-12">
          <mat-label>Explanation</mat-label>
          <textarea matInput rows="8" [(ngModel)]="data.explanation" [required]="data.required"></textarea>
          <mat-error>Explanation is required!</mat-error>
        </mat-form-field>
      </div>
</div>
<div>
  <button mat-raised-button class="functional-button-dark" [mat-dialog-close]="data" [disabled]="data.required && !data.explanation"> Save</button>
  <button mat-raised-button class="functional-button" (click)="onCancel()"> Cancel</button>
</div>