<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Remove Products</h1>
  <mat-label>The following product(s) will be removed. Do you want to continue?</mat-label> 
 <div>       
  <mat-card class="card" >
    <mat-card-content >     
      <table mat-table [dataSource]="programsAccrualsRemoveProducts" multiTemplateDataRows class="mat-elevation-z0">
      <ng-container [style.padding-left.px] = "5" matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">	
        <td  mat-cell *matCellDef="let element"> {{element[column]}} </td>
      </ng-container>
    
      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="removeproducts">
        <td mat-cell *matCellDef="let element" class="mat-cell" >          
          <div class="bullet-list">
            <ul>
              <li *ngFor="let item of element.removeProducts">
                <span>{{item.materialName}} ({{item.materialCode}})</span> 
              </li>
            </ul>     
          </div>             
          </td>
      </ng-container>     
      <tr class="mat-row-line" mat-row *matRowDef="let element; columns: columnsToDisplay;" >
      </tr>
      <tr class="mat-row-line" mat-row *matRowDef="let row; columns: ['removeproducts']"></tr>
    </table>     
    </mat-card-content>
</mat-card>
</div>
<mat-card-footer>
  <div >                      
    <button mat-raised-button class="functional-button-dark"(click)= "onConfirmRemoval();"> Confirm Removal</button>
    <button mat-raised-button class="functional-button" (click)= "onCancel();"> Cancel</button>   
  </div> 
</mat-card-footer>  
 