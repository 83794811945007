import { Component, ElementRef, ViewChild, OnInit, AfterViewInit, Output, OnChanges, Inject } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ProgPortalContextService } from 'src/app/core/services/progportalcontextservice';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventEmitter } from 'protractor';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { HttpHeaders } from '@angular/common/http';
import { DialogWarningMessageComponent, Messages } from 'src/app/dialogs/dialog-warning-message/dialog-warning-message.component';
import { ProductManagersAssignedMessages } from 'src/app/core/enums/messageEnums';



export interface DialogData {
  prodmgrs: any[];
  prodmgrids: any[];
  prodmgremails: any[];
  programId: number;
  updatedProdmgrids: any[];
  confirmSubmit: boolean;
  changeExceptionId?:number;
  isChangeException:boolean;
}
export interface ProgramToProductManagerAssoc {
  id?: number;
  programId: number;
  productManagerId: number;
  openEnrolled: boolean;
  updateBy: string;
  changeException: boolean;
  changeExceptionId?:number;
}


@Component({
  selector: 'app-dialog-program',
  templateUrl: './dialog-program.component.html',
  styleUrls: ['./dialog-program.component.scss']
})
export class DialogProgramComponent implements AfterViewInit {

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredProductmgrs = [];
  filteredProductmgrsBackup = [];
  programToProductManagerAssoc: ProgramToProductManagerAssoc[] = [];
  Productmgrs: string[] = [];
  allProductmgrs: any[] = [];
  productManagersAssignedMessages = ProductManagersAssignedMessages;
  warningMessages: Messages[] = [];
  nonUpdatedProdmgrs: any = [];
  nonUpdatedProdmgrids: any = [];
  nonUpdatedProdmgrEmails: any = [];
  changeExceptionId?:number;
  isChangeException:boolean;
  programForm = this.fb.group({
    ProductmgrCtrl: ['']
  })
  Productmgrids: any;
  deleteRistrictedProdmgrids: any = [];
  deleteNonRistrictedProdmgrids: any = [];
  deleteRistrictedProdmgrs: any = [];
  deleteNonRistrictedProdmgrs: any = [];
  appUsers: any = [];
  @ViewChild('ProductmgrInput') ProductmgrInput: ElementRef<HTMLInputElement>;

  constructor(public context: ProgPortalContextService, private spinner: NgxSpinnerService, private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogProgramComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    this.nonUpdatedProdmgrs = JSON.parse(JSON.stringify(data['prodmgrs']));
    this.nonUpdatedProdmgrids = JSON.parse(JSON.stringify(data['prodmgrids']));;
    this.nonUpdatedProdmgrEmails = JSON.parse(JSON.stringify(data['prodmgremails']));;
    this.changeExceptionId = data['changeExceptionId'];
    this.isChangeException = data['isChangeException'];
    dialogRef.disableClose = true;

    this.programForm.controls.ProductmgrCtrl.valueChanges.subscribe(
      (searchText) => {
        this.filteredProductmgrs = this.filteredProductmgrsBackup.filter(Productmgr => Productmgr.name.toLowerCase().indexOf(searchText) > -1 && this.data.prodmgrs.indexOf(Productmgr.name) <= -1);
      }
    );
  }

  ngAfterViewInit(): void {
    this.bindProductmgrs();
  }

  bindProductmgrs() {
    this.context.getdatafromService("/api/Program/Option/GetProductManagers").subscribe(response => {
      this.allProductmgrs = response;
      this.BuildManagerDropdowns();
    });
  }

  BuildManagerDropdowns() {
    if (this.allProductmgrs) {
      this.allProductmgrs.forEach(element => {
        let prodmgrdata = {
          name: element.firstName + ' ' + element.lastName,
          id: element.id,
          email: element.email
        };
        this.filteredProductmgrs.push(prodmgrdata);
        this.filteredProductmgrsBackup.push(prodmgrdata);
      });
    }
  }

  onReleaseProgramforAccruals() {    
    this.warningMessages = [];
    this.deleteRistrictedProdmgrids = [];
    if (this.programToProductManagerAssoc.length > 0) {
      const headers = new HttpHeaders().set('content-type', 'application/json');

      this.context.postdatatoservice("/api/Accrual/Details/GetProductMangerAccrualsStatus", JSON.stringify(this.programToProductManagerAssoc), headers)
        .toPromise().then((response) => {
          if (response != null) {
            if (response.length > 0) {
              this.appUsers = response;
              this.appUsers.forEach(element => {
                this.deleteRistrictedProdmgrids.push(element.id);
                this.deleteRistrictedProdmgrs.push(element.firstName + ' ' + element.lastName)
              })
              this.warningMessages.push({ messageId: 1, message: this.productManagersAssignedMessages._1, error: true });
              this.openProductManagerWarningMessageDialog(this.warningMessages, this.appUsers)
            }
            else {
              this.data.confirmSubmit = true;
              this.dialogRef.close(this.data);
            }
          }
        });
    }
    else {
      this.data.confirmSubmit = true;
      this.nonUpdatedProdmgrids.forEach(element => {
        if (!this.data.updatedProdmgrids.some(prdmgid => prdmgid === element)) {
          this.programToProductManagerAssoc.forEach(ppma => {
            if (ppma.productManagerId === element) {
              this.programToProductManagerAssoc.splice(this.programToProductManagerAssoc.indexOf(ppma), 1);
            }
          })
        }
      });
      this.dialogRef.close(this.data);
    }
  }


  Cancel() {
    this.data.updatedProdmgrids = [];
    this.deleteRistrictedProdmgrids = [];
    this.dialogRef.close({ prodmgrs: this.nonUpdatedProdmgrs, prodmgrids: this.nonUpdatedProdmgrids, prodmgremails: this.nonUpdatedProdmgrEmails, updatedProdmgrids: [], confirmSubmit: false });
  }


  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our Productmgr
    if ((value || '').trim()) {
      this.Productmgrs.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.programForm.controls.ProductmgrCtrl.setValue(null);
  }


  remove(Productmgr: string): void {
    const index = this.data.prodmgrs.indexOf(Productmgr);

    if (index >= 0) {
      if (this.data.programId !== undefined) {
        this.programToProductManagerAssoc.push({ id: null, programId: this.data.programId, productManagerId: this.data.prodmgrids[index], openEnrolled: false, updateBy: '' ,changeExceptionId:null,changeException:false})
      }
      if (!this.data.updatedProdmgrids.some(prdmgid => prdmgid === this.data.prodmgrids[index])) {
        this.data.updatedProdmgrids.push(this.data.prodmgrids[index]);
      }
      else {
        this.data.updatedProdmgrids.splice(this.data.updatedProdmgrids.indexOf(this.data.prodmgrids[index]), 1);
      }

      this.deleteNonRistrictedProdmgrids.push(this.data.prodmgrids[index]);
      this.deleteNonRistrictedProdmgrs.push(this.data.prodmgrs[index]);
      this.data.prodmgrs.splice(index, 1);
      this.data.prodmgrids.splice(index, 1);
      this.data.prodmgremails.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.data.prodmgrids.some(prdmgid => prdmgid === event.option.value.id)) {
      this.data.prodmgrs.push(event.option.value.name);
      this.data.prodmgrids.push(event.option.value.id);
      this.data.prodmgremails.push(event.option.value.email);
    }

    this.ProductmgrInput.nativeElement.value = '';
    this.programForm.controls.ProductmgrCtrl.setValue(null);
    if (!this.data.updatedProdmgrids.some(prdmgid => prdmgid === event.option.value.id)) {
      this.data.updatedProdmgrids.push(event.option.value.id);
    }
    else {
      this.data.updatedProdmgrids.splice(this.data.updatedProdmgrids.indexOf(event.option.value.id), 1);
    }

    if (this.deleteNonRistrictedProdmgrids.some(prdmgid => prdmgid === event.option.value.id)) {
      this.deleteNonRistrictedProdmgrids.splice(this.deleteNonRistrictedProdmgrids.indexOf(event.option.value.id), 1);
    }
    if (this.deleteNonRistrictedProdmgrs.some(prdmgid => prdmgid === event.option.value.id)) {
      this.deleteNonRistrictedProdmgrs.splice(this.deleteNonRistrictedProdmgrs.indexOf(event.option.value.id), 1);
    }
    this.programToProductManagerAssoc.forEach(element => {
      if (element.productManagerId === event.option.value.id) {
        this.programToProductManagerAssoc.splice(this.programToProductManagerAssoc.indexOf(element), 1);
      }
    })

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allProductmgrs.filter(Productmgr => Productmgr.toLowerCase().indexOf(filterValue) === 0);
  }
  openProductManagerWarningMessageDialog(warningMessage: any, productManagers: any[]): void {
    const dialog = this.dialog.open(DialogWarningMessageComponent, {
      disableClose: true,
      height: '340px',
      width: '800px',
      data: { dataMessage: warningMessage, dataAppUsers: productManagers, dataContinueText: 'Save and Release', dataConfirm: false, dataShowErrorResolveMessage: false }
    });

    dialog.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result['dataConfirm'] === true) {
          this.data.confirmSubmit = true;
          this.dialogRef.close(this.data);
        }
        else {
          this.nonUpdatedProdmgrs.forEach(element => {
            if (!this.data.prodmgrs.some(prdmg => prdmg === element)) {
              this.data.prodmgrs.push(element);
            }
          });
          let itemCount = this.data.updatedProdmgrids.length;
          for (var item = 0; item < itemCount; item++) {
            this.data.updatedProdmgrids.forEach(element => {
              if (this.deleteRistrictedProdmgrids.some(drprdmgid => drprdmgid === element)) {
                this.data.updatedProdmgrids.splice(this.data.updatedProdmgrids.indexOf(element), 1);
              }
            });
          }
          itemCount = this.deleteRistrictedProdmgrids.length;
          for (var item = 0; item < itemCount; item++) {
            this.deleteRistrictedProdmgrids.forEach(element => {
              if (this.deleteNonRistrictedProdmgrids.some(dnrprdmgid => dnrprdmgid === element)) {
                this.deleteNonRistrictedProdmgrids.splice(this.deleteNonRistrictedProdmgrids.indexOf(element), 1);
              }
            });
          }

          itemCount = this.deleteNonRistrictedProdmgrids.length;
          for (var item = 0; item < itemCount; item++) {
            this.deleteNonRistrictedProdmgrids.forEach(element => {
              if (this.data.prodmgrids.some(pid => pid === element)) {
                this.data.prodmgrids.splice(this.data.prodmgrids.indexOf(element), 1);
              }
            });
          }
          itemCount = this.deleteRistrictedProdmgrs.length;
          for (var item = 0; item < itemCount; item++) {
            this.deleteRistrictedProdmgrs.forEach(element => {
              if (this.deleteNonRistrictedProdmgrs.some(dnrprdmgid => dnrprdmgid === element)) {
                this.deleteNonRistrictedProdmgrs.splice(this.deleteNonRistrictedProdmgrs.indexOf(element), 1);
              }
            });
          }

          itemCount = this.deleteNonRistrictedProdmgrs.length;
          for (var item = 0; item < itemCount; item++) {
            this.deleteNonRistrictedProdmgrs.forEach(element => {
              if (this.data.prodmgrs.some(pid => pid === element)) {
                this.data.prodmgrs.splice(this.data.prodmgrs.indexOf(element), 1);
              }
            });
          }

          this.nonUpdatedProdmgrids.forEach(element => {
            if (!this.data.prodmgrids.some(prdmgid => prdmgid === element)) {
              this.data.prodmgrids.push(element);
              this.programToProductManagerAssoc.forEach(ppma => {
                if (ppma.productManagerId === element) {
                  this.programToProductManagerAssoc.splice(this.programToProductManagerAssoc.indexOf(ppma), 1);
                }
              })
            }
          });

          this.nonUpdatedProdmgrEmails.forEach(element => {
            if (!this.data.prodmgremails.some(prdmg => prdmg === element)) {
              this.data.prodmgremails.push(element);
            }
          });
        }
      }
    });
  }
}
