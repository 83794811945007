import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    required: boolean;
    explanation: any;
    changeExceptionProgramCode: string;
    dataConfirm: boolean;
}

@Component({
    templateUrl: './dialog-changeexception-explanation.component.html',
    styleUrls: ['./dialog-changeexception-explanation.component.scss'],
    selector: 'app-dialog-changeexception-explanation'
})
export class DialogChangeExceptionExplanationComponent implements OnInit {
    nonUpdatedExplanation: any;
    constructor(public dialogRef: MatDialogRef<DialogChangeExceptionExplanationComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { 
        this.nonUpdatedExplanation = JSON.parse(JSON.stringify(data['explanation']));
    }

    ngOnInit(): void {
    }
    
  onCancel() { 
      this.data.explanation = this.nonUpdatedExplanation;
      this.data.dataConfirm = false;
    this.dialogRef.close(this.data);
  }
}