import { Component, OnInit } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { ProgPortalContextService } from '../core/services/progportalcontextservice';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { CoreRepository } from '../core/core.repository';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DatePipe]
})
export class HomeComponent implements OnInit {

  notifications: any;
  modifiedResponse: any;
  dataSource: any;
  displayedColumns: string[] = ['eventName', 'date'];



  constructor(public context: ProgPortalContextService, private spinner: NgxSpinnerService,private core: CoreRepository) {

    this.notifications = [
      {id: 0, icon: 'info', details: 'Notification information', color: '#464a54', visible: true},
      {id: 1, icon: 'check_circle', details: 'Notification success', color: '#06BF6F', visible: true},
      {id: 2, icon: 'report_problem', details: 'Notification Warning', color: '#FFD300', visible: true},
      {id: 3, icon: 'error', details: 'Notification Error', color: '#FC4C02', visible: true}
  ];
  }

  ngOnInit(): void {
    this.GetNotifications();
    this.GetUpcomingEvents();
  }

  GetNotifications() {
    this.spinner.show();
    this.context
      .getdatafromService('/api/Home/GetNotifications')
      .toPromise()
      .then((response) => {
        const notificationRes = response;

        this.modifiedResponse =  notificationRes.map(item => {
        if (item.sortOrder === this.notifications[item.sortOrder].id) {
            const newObj = Object.assign(item, this.notifications[item.sortOrder]);
            return newObj;
        }
       });
        this.spinner.hide();
      });

  }
  GetUpcomingEvents() {
    this.spinner.show();
    this.context
      .getdatafromService('/api/Home/GetUpcomingEvents')
      .toPromise()
      .then((response) => {
        this.dataSource = response;
        this.spinner.hide();
      });
  }
}
