<div class="main-content">
<form [formGroup]="ApprovalForm"> 
        <h1 mat-dialog-title>Select Appropriate Approvers </h1>
            <!-- class="main-cards" -->
            <div *ngIf="ProgramLeaderList.length>0">
                <mat-label>Program Leader {{ProgramLeaderRequiredText}}</mat-label><br>
                <mat-form-field>
                    <mat-select formControlName="ProgramLeader" multiple>
                        <mat-option *ngFor="let val of ProgramLeaderList" [value]="val?.userId">{{val?.userName}}</mat-option>
                    </mat-select>
                    <mat-error>Please select at least one</mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="LegalList.length>0">
                <mat-label> Legal </mat-label><br>
                <mat-form-field>
                    <mat-select formControlName="Legal" multiple>
                        <mat-option *ngFor="let val of LegalList" [value]="val.userId">{{val?.userName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="FinancialList.length>0">
                <mat-label> Financial Analyst {{FinancialAnalystRequiredText}}</mat-label><br>
                <mat-form-field>
                    <mat-select formControlName="FinancialAnalyst" multiple [required]="FinancialList.length>0">
                        <mat-option *ngFor="let val of FinancialList
            " [value]="val?.userId">{{val?.userName}}</mat-option>
                    </mat-select>
                    <mat-error>Please select at least one</mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="CatgLeaderList.length>0">
                <mat-label> Category Leader {{CategoryLeaderRequiredText}}</mat-label><br>
                <mat-form-field>
                    <mat-select formControlName="CategoryLeader" multiple [required]="CatgLeaderList.length>0">
                        <mat-option *ngFor="let val of CatgLeaderList
            " [value]="val?.userId">{{val?.userName}}</mat-option>
                    </mat-select>
                    <mat-error>Please select at least one</mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="MarketingLeaderList.length>0">
                <mat-label> Marketing Leader {{MarketingLeaderRequiredText}}</mat-label><br>
                <mat-form-field>
                    <mat-select formControlName="MarketingLeader" multiple [required]="MarketingLeaderList.length>0">
                        <mat-option *ngFor="let val of MarketingLeaderList
            " [value]="val?.userId">{{val?.userName}}</mat-option>
                    </mat-select>
                    <mat-error>Please select at least one</mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="CommUnitLeaderList.length>0">
                <mat-label>Comm. Unit Leader {{CommUnitLeaderRequiredText}}</mat-label><br>
                <mat-form-field>
                    <mat-select formControlName="CommercialUnitLeader" multiple [required]="CommUnitLeaderList.length>0">
                        <mat-option *ngFor="let val of CommUnitLeaderList" [value]="val?.userId">{{val?.userName}}</mat-option>
                    </mat-select>
                    <mat-error>Please select at least one</mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="CommercialLeaderList.length>0">
                <mat-label>Commercial Leader {{CommercialLeaderRequiredText}}</mat-label><br>
                <mat-form-field>
                    <mat-select formControlName="CommercialLeader" multiple [required]="CommercialLeaderList.length>0">
                        <mat-option *ngFor="let val of CommercialLeaderList" [value]="val?.userId">{{val?.userName}}</mat-option>
                    </mat-select>
                    <mat-error>Please select at least one</mat-error>
                </mat-form-field>
            </div>

            <div *ngIf="ProgramAdminManagerList.length>0">
                <mat-label> Program Admin Manager {{ProgramAdminManagerRequiredText}}</mat-label><br>
                <mat-form-field>
                    <mat-select formControlName="ProgramAdminManager" multiple [required]="ProgramAdminManagerList.length>0">
                    <mat-option *ngFor="let val of ProgramAdminManagerList " [value]="val?.userId">{{val?.userName}}</mat-option>
                    </mat-select>
                    <mat-error>Please select at least one</mat-error>
                </mat-form-field>
            </div>

            <h1 mat-dialog-title>Select Appropriate Reviewers </h1>
            
            <div *ngIf="ProgAdminAnalystList.length>0">
                <mat-label>Program Admin Analyst {{ProgAdminAnalystRequiredText}}</mat-label><br>
                <mat-form-field>
                    <mat-select formControlName="ProgramAdminAnalyst" multiple [required]="ProgAdminAnalystList.length>0">
                        <mat-option *ngFor="let val of ProgAdminAnalystList
            " [value]="val?.userId">{{val?.userName}}</mat-option>
                    </mat-select>
                    <mat-error>Please select at least one</mat-error>
                </mat-form-field>
            </div>

            <div [hidden]="ProgramAdminList.length==0">
                <mat-label> Program Admin {{ProgramAdminRequiredText}}</mat-label><br>
                <mat-form-field>
                    <mat-select formControlName="ProgramAdmin" multiple [required]="ProgramAdminList.length==0">
                        <mat-option *ngFor="let val of ProgramAdminList" [value]="val?.userId">{{val?.userName}}</mat-option>
                    </mat-select>
                    <mat-error>Please select at least one</mat-error>
                </mat-form-field>
            </div>
            
            <div *ngIf="OthersList.length>0">
                <mat-label>Others </mat-label><br>
                <mat-form-field>
                    <mat-select formControlName="Others" multiple>
                        <mat-option *ngFor="let val of OthersList" [value]="val?.userId">{{val?.userName}}</mat-option>
                    </mat-select>
                    <mat-error>Please select at least one</mat-error>
                </mat-form-field>
            </div>
            <div>
                <button mat-raised-button class="functional-button-dark" (click)="onSubmit();" [disabled]="!ApprovalForm.valid">
                    Submit</button>
                <button mat-raised-button class="functional-button" mat-dialog-close> {{'Cancel'}}
                </button>
            </div>

        <ngx-spinner class="spinner" size="default" type="ball-spin-clockwise">
            <p>Please Wait.</p>
        </ngx-spinner>
</form>
</div>