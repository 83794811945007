import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreRepository } from '../core/core.repository';
import { ProgramPortalPermissions } from '../core/services/authorization/models/program-portal-permissions';

@Injectable({
  providedIn: 'root'
})
export class AdministrationGuard implements CanActivate {

  constructor(private core: CoreRepository,private router : Router){}
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    //To handle page refresh
    if(!this.router.navigated){
      return this.core.authorize().pipe(
        map(response => {
          return response.permissions.includes(ProgramPortalPermissions.ViewAdministration) ? true :this.router.createUrlTree(['home']);
        })       
        );  
    }

    return this.core.canViewAdministration ? true :this.router.createUrlTree(['home']);
  }
  
}