<div class="color-bar"></div>
<nav>
  <img src="../assets/img/growth-ribbon-bg.png" class="growth-ribbon">
  <button mat-icon-button>
    <!--<mat-icon (click)="sidenav.toggle()" style="color: white;">menu</mat-icon>-->
    <mat-icon (click)="OnMenuToggle()" style="color: white;">menu</mat-icon>
  </button>
  <div class="brand">
    <div class="brand_icon"><img src="../assets/img/corteva-icon.png" height="24" /></div>
    <div class="brand_name">
      <h1><b>Program</b>Portal</h1>
    </div>
  </div>
  <span class="spacer"></span>
  <span class="user">{{username}} {{todayDate | date:'mediumDate' :'UTC'}}</span>
</nav>

<mat-sidenav-container autosize>
  <mat-sidenav #sidenav id="sidenav" mode="side"
  [class.menu-open] = "isMenuOpen"
  [class.menu-close] = "!isMenuOpen" opened>
    <mat-nav-list style="overflow-x: hidden;">
      <div class="side-nav-items">
        <a mat-list-item *ngIf="canViewUserImpersonation()" [routerLink]="'/user-impersonation'" routerLinkActive="active-list-item" (click)="isMenuOpen && OnMenuToggle()">
          <mat-icon title="person">person</mat-icon>&nbsp;&nbsp; <span *ngIf="isMenuOpen">User Impersonation</span>
        </a>
        <a mat-list-item [routerLink]="'/home'" routerLinkActive="active-list-item active-list-items" (click)="isMenuOpen && OnMenuToggle()">
          <mat-icon title="Home">home</mat-icon>&nbsp;&nbsp; <span *ngIf="isMenuOpen">Home</span> 
        </a>
        <a mat-list-item *ngIf="canViewProgram()" [routerLink]="'/programs'" routerLinkActive="active-list-item active-list-items" (click)="isMenuOpen && OnMenuToggle()">
          <mat-icon title="Programs">assignment</mat-icon>&nbsp;&nbsp; <span *ngIf="isMenuOpen">Programs</span>
        </a>
        <a mat-list-item *ngIf="canViewAccrual()" [routerLink]="'/accruals'" routerLinkActive="active-list-item" (click)="isMenuOpen && OnMenuToggle()">
          <mat-icon title="Accruals">attach_money</mat-icon>&nbsp;&nbsp; <span *ngIf="isMenuOpen">Accruals</span>
        </a>
        <a mat-list-item *ngIf="canViewReport()" [routerLink]="'/reports'" routerLinkActive="active-list-item" (click)="isMenuOpen && OnMenuToggle()">
          <mat-icon title="Reports">description</mat-icon>&nbsp;&nbsp; <span *ngIf="isMenuOpen">Reports</span>
        </a>
        <a mat-list-item *ngIf="canViewAdministration()" [routerLink]="'/administration'" routerLinkActive="active-list-item" (click)="isMenuOpen && OnMenuToggle()">
          <mat-icon title="Administration">border_all</mat-icon>&nbsp;&nbsp; <span *ngIf="isMenuOpen">Administration</span>
        </a> 
      </div>

    </mat-nav-list>

    <footer class="footer"> 
      <!--<button mat-icon-button>
        <mat-icon (click)="sidenav.toggle()" style="color: white;">menu</mat-icon>
      </button>-->
      <div class="color-bar"></div>
    </footer>
  </mat-sidenav>
  <mat-sidenav-content>
    <div style="height:91.5vh;">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
