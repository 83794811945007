<div>
    <mat-toolbar class="top-toolbar">
      <mat-toolbar-row>
        <div>
          <a><button mat-raised-button class="functional-button" (click)="onBack();"><mat-icon title="Back">keyboard_arrow_left</mat-icon> Back</button></a>
        </div>
        <span class="header-page"> Products and Accruals Summary</span>
        <span class="header-spacer"></span>
      </mat-toolbar-row>
    </mat-toolbar>
    <div>
    <mat-card>
      <div *ngIf="dataSource.data.length === 0">
        <mat-label> <b>No products and accruals submitted</b></mat-label>
        <br>
      </div>    
      <div summary-mat-line>Program Name: {{program.name}}</div>
      <div summary-mat-line>Program Code: {{ program.code}}</div>
      <div summary-mat-line>Status: {{ program.status}}</div>
      <div summary-mat-line>Total Sales: {{ program.totalSales | currency:'USD':'symbol':'1.2-4'}}</div>
      <div summary-mat-line>Total Exposure: {{ program.totalExposure | currency:'USD':'symbol':'1.2-4'}}</div>
      <div summary-mat-line>Calculated Totals: {{ calculatedTotals}}</div>
      <div *ngIf="dataSource.data.length > 0">
        <mat-form-field class="searchBar" >
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter()" type="text" [(ngModel)]="search"
                 [formControl]="searchFilter">
        </mat-form-field>
        <div class="btn-container">
          <button (click)="onDownloadSummary();"><i class="fas fa-download"></i></button>
        </div>                
      </div>
      
      <div class="matgrid-div">
        <div class="table-container">
          <table mat-table class="mat-grid" [dataSource]="dataSource" #sort="matSort" matSort>
  
            <ng-container matColumnDef="programCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Program Code </th>
              <td mat-cell *matCellDef="let element"> {{ element.programCode }} </td>
            </ng-container>
  
            <ng-container matColumnDef="programName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Program Name</th>
              <td mat-cell *matCellDef="let element">{{ element.programName }}</td>
            </ng-container>
  
            <ng-container matColumnDef="programStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Program Status</th>
              <td mat-cell *matCellDef="let element">{{ element.programStatus }} </td>
            </ng-container>

            <ng-container matColumnDef="programManager">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Program Manager </th>
              <td mat-cell *matCellDef="let element"> {{ element.programManager }} </td>
            </ng-container>
  
            <ng-container matColumnDef="productManager">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Manager </th>
              <td mat-cell *matCellDef="let element"> {{ element.productManager }} </td>
            </ng-container>
  
            <ng-container matColumnDef="channel">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Channel </th>
              <td mat-cell *matCellDef="let element"> {{ element.channel }} </td>
            </ng-container>

            <ng-container matColumnDef="programYear">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Program Year </th>
              <td mat-cell class="mat-cell-center-align" *matCellDef="let element"> {{ element.programYear }} </td>
            </ng-container>

            <ng-container matColumnDef="startDate">
              <th mat-header-cell class="mat-cell-date-align" *matHeaderCellDef mat-sort-header> Start Date </th>
              <td mat-cell class="mat-cell-center-date-align" *matCellDef="let element"> {{ element.startDate| date: 'MM/dd/yyyy' }} </td>
            </ng-container>
  
            <ng-container matColumnDef="endDate">
              <th mat-header-cell class="mat-cell-date-align" *matHeaderCellDef mat-sort-header> End Date </th>
              <td mat-cell  class="mat-cell-center-date-align" *matCellDef="let element"> {{ element.endDate| date: 'MM/dd/yyyy' }} </td>
            </ng-container>
            
            <ng-container matColumnDef="businessSegmentCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Business Segment Code </th>
              <td mat-cell class="mat-cell-center-align" *matCellDef="let element"> {{ element.businessSegmentCode }} </td>
            </ng-container>

            <ng-container matColumnDef="businessSegmentName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Business Segment Name </th>
              <td mat-cell class="mat-cell-center-align" *matCellDef="let element"> {{ element.businessSegmentName }} </td>
            </ng-container>
              
            <ng-container matColumnDef="performanceCenterCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Performance Center Code </th>
              <td mat-cell class="mat-cell-center-align" mat-cell *matCellDef="let element"> {{ element.performanceCenterCode }} </td>
            </ng-container>
  
            <ng-container matColumnDef="performanceCenterName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Performance Center Name </th>
              <td mat-cell *matCellDef="let element"> {{ element.performanceCenterName }} </td>
            </ng-container>
  
            <ng-container matColumnDef="productCenterCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Center Code </th>
              <td mat-cell *matCellDef="let element"> {{ element.productCenterCode }} </td>
            </ng-container>
  
            <ng-container matColumnDef="productCenterName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Center Name </th>
              <td mat-cell *matCellDef="let element"> {{ element.productCenterName }} </td>
            </ng-container>

            <ng-container matColumnDef="plannedProductCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Planned Product Code </th>
              <td mat-cell class="mat-cell-center-align" mat-cell *matCellDef="let element"> {{ element.plannedProductCode }} </td>
            </ng-container>
  
            <ng-container matColumnDef="plannedProductName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Planned Product Name </th>
              <td mat-cell *matCellDef="let element"> {{ element.plannedProductName }} </td>
            </ng-container>
  
            <ng-container matColumnDef="tradeProductCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Trade Product Code </th>
              <td mat-cell class="mat-cell-center-align" mat-cell *matCellDef="let element"> {{ element.tradeProductCode }} </td>
            </ng-container>
  
            <ng-container matColumnDef="tradeProductName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Trade Product Name </th>
              <td mat-cell *matCellDef="let element"> {{ element.tradeProductName }} </td>
            </ng-container>

            <ng-container matColumnDef="materialCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Material Code </th>
              <td mat-cell *matCellDef="let element"> {{ element.materialCode }} </td>
            </ng-container>
   
            <ng-container matColumnDef="materialName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Material Name </th>
              <td mat-cell *matCellDef="let element"> {{ element.materialName }} </td>
            </ng-container>

            <ng-container matColumnDef="productYear">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Year </th>
              <td mat-cell class="mat-cell-right-align" *matCellDef="let element"> {{ element.productYear }} </td>
            </ng-container>

            <ng-container matColumnDef="productMonth">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Month </th>
              <td mat-cell class="mat-cell-right-align" *matCellDef="let element"> {{ element.productMonth }} </td>
            </ng-container>
  
            <ng-container matColumnDef="salesType">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Volume/Gross Sales Type </th>
              <td mat-cell class="mat-cell-center-align" mat-cell *matCellDef="let element"> {{ element.salesType }} </td>
            </ng-container>
  
            <ng-container matColumnDef="volumeUnits">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Volume Units </th>
              <td mat-cell class="mat-cell-right-align" *matCellDef="let element"> {{ element.volumeUnits | number:'1.0-0' }} </td>
            </ng-container>
            
            <ng-container matColumnDef="localUnitOfMeasure">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Local UOM </th>
              <td mat-cell *matCellDef="let element"> {{ element.localUnitOfMeasure }} </td>
            </ng-container>
    
            <ng-container matColumnDef="marketLetterPrice">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Market Letter Price (ML) </th>
              <td mat-cell class="mat-cell-right-align" *matCellDef="let element"> {{ element.marketLetterPrice| currency:'USD':true:'1.2-4'  }} </td>
            </ng-container>
  
            <ng-container matColumnDef="averageListPrice">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Average List Price (ALP)</th>
              <td mat-cell class="mat-cell-right-align" *matCellDef="let element"> {{ element.averageListPrice | currency:'USD':true:'1.2-4' }} </td>
            </ng-container>
  
            <ng-container matColumnDef="qualifyingVolumePercentage">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Qualifying Volume % </th>
              <td mat-cell class="mat-cell-right-align" *matCellDef="let element"> {{ element.qualifyingVolumePercentage | percent:'1.4-4'  }} </td>
            </ng-container>

            <ng-container matColumnDef="payRatePercentage">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Pay Rate % </th>
              <td mat-cell class="mat-cell-right-align" *matCellDef="let element"> {{ element.payRatePercentage | percent:'1.4-4'}} </td>
            </ng-container>
  
            <ng-container matColumnDef="accrualPercentage">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Accrual % </th>
              <td mat-cell class="mat-cell-right-align" *matCellDef="let element"> {{ element.accrualPercentage | percent:'1.4-4'}} </td>
            </ng-container>

            <ng-container matColumnDef="adjustedGrossSalesMarketLetterPrice">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Adjusted Gross Sales (ML) </th>
              <td mat-cell class="mat-cell-right-align" *matCellDef="let element"> {{ element.adjustedGrossSalesMarketLetterPrice| currency:'USD':true:'1.0-0'  }} </td>
            </ng-container>

            <ng-container matColumnDef="adjustedGrossSalesAverageListPrice">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Adjusted Gross Sales (ALP) </th>
              <td mat-cell class="mat-cell-right-align" *matCellDef="let element"> {{ element.adjustedGrossSalesAverageListPrice| currency:'USD':true:'1.0-0'  }} </td>
            </ng-container>
            
            <ng-container matColumnDef="exposureMarketLetterPrice">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Exposure (ML) </th>
              <td mat-cell class="mat-cell-right-align" *matCellDef="let element"> {{ element.exposureMarketLetterPrice| currency:'USD':true:'1.0-0'  }} </td>
            </ng-container>
            
            <ng-container matColumnDef="exposureAverageListPrice">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Exposure (ALP) </th>
              <td mat-cell class="mat-cell-right-align" *matCellDef="let element"> {{ element.exposureAverageListPrice| currency:'USD':true:'1.0-0'  }} </td>
            </ng-container>
            
            <ng-container matColumnDef="marketExposureVersusAverageExposure">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Exposure (ML) vs Exposure (ALP) </th>
              <td mat-cell class="mat-cell-right-align" *matCellDef="let element"> {{ element.exposureMarketLetterVersusAverageList| currency:'USD':true:'1.0-0'  }} </td>
            </ng-container>
            
            <ng-container matColumnDef="exposureForRouting">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Exposure For Routing </th>
              <td mat-cell class="mat-cell-right-align" *matCellDef="let element"> {{ element.exposureForRouting| currency:'USD':true:'1.0-0'  }} </td>
            </ng-container>
                            
            <ng-container matColumnDef="accrualStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Accrual Status </th>
              <td mat-cell *matCellDef="let element"> {{ element.accrualStatus }} </td>
            </ng-container>

            <ng-container  [style.visibility]="!isProgramRouted ? 'hidden' : ''" matColumnDef="routingStartDate">
              <th mat-header-cell class="mat-cell-date-align" [style.visibility]="!isProgramRouted ? 'hidden' : ''" *matHeaderCellDef mat-sort-header> Routing Start Date</th>
              <td mat-cell class="mat-cell-date-align" [style.visibility]="!isProgramRouted ? 'hidden' : ''"  *matCellDef="let element"> {{ element.routingStartDate | date: 'MM/dd/yyyy'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  
          </table>
        </div>
        <div>
          <mat-paginator #paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons [hidden]="programAccrualsProductsSummary.length === 0"></mat-paginator>
        </div>
      </div>
    </mat-card>
  <ngx-spinner class="spinner" size="default" type="ball-spin-clockwise">
    <p>Please Wait.</p>
  </ngx-spinner>
  </div>
  </div>
  
  