import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import {NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './app-file-upload.component.html',
  styleUrls: ['./app-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ]
})

export class FileUploadComponent implements OnInit {
    /** File extension that accepted, same as 'accept' of <input type="file" />. 
     * 
    */
    accept = '';
    files: File[] = [];
    onChange: Function;


  constructor(private host: ElementRef<HTMLInputElement> ) {}

  ngOnInit() {}

  @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
    if(event){
      for (let i = 0; i < event.length; i++) {
        this.files.push(event.item(i));
      } 
    }
    this.onChange(this.files); 
  }

  cancelFile(file: File) {
      const index = this.files.indexOf(file);
      if (index > -1) {
          this.files.splice(index, 1);
      }
      this.onChange(this.files); 
  }

  writeValue( value: null ) {
    // clear file input
    this.host.nativeElement.value = '';
  }

  registerOnChange( fn: Function ) {
    this.onChange = fn;
  }

  registerOnTouched( fn: Function ) {}

  dropHandler(ev: DragEvent) {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          const file = ev.dataTransfer.items[i].getAsFile();
          this.files.push(file);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        const file = ev.dataTransfer.files[i];
        this.files.push(file);
      }
    }
    this.onChange(this.files); 
  }

  dragOverHandler(ev: DragEvent) {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }
}
