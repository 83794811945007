
<div>
  <mat-toolbar class="top-toolbar">
    <mat-toolbar-row>
      <div>
        <a [routerLink]="'/accruals'"><button mat-raised-button class="functional-button" (click)="onBack();"><mat-icon title="Back">keyboard_arrow_left</mat-icon> Back</button></a>
      </div>
      <span class="header-page">Edit Accruals</span>
      <span class="header-spacer"></span>
      <div>
        <a [routerLink]="'/accruals'"><button mat-raised-button class="functional-button" (click)="onCancel();"> Cancel</button></a>
        <button *ngIf="!removeProducts" mat-raised-button class="functional-button" (click)="onSaveDraft();" [disabled]="!isFormDirty">Save Draft</button>
        <button *ngIf="!removeProducts" mat-raised-button class="functional-button-dark" (click)="onSaveandSubmit();" [disabled]="!checkSaveAndSubmit()"> Save and Submit </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-card>   
    <mat-card-header>
      <div>
          <button *ngIf="!removeProducts" mat-raised-button class="functional-button-dark" (click)="onAddProducts();" [disabled]="isAddProductsButtonEnable"> Add Products <span *ngIf="selectAddProductsCounter > 0">({{selectAddProductsCounter}})</span> </button>
          <button *ngIf="!removeProducts" mat-raised-button class="{{selectAddProductsCounter == 0 ?'adjusted-functional-button-dark':'functional-button-dark'}}" (click)="onAssignRates();" [disabled]="isAssignRatesOrRemoveButtonEnable"> Assign Rates <span *ngIf="selectAssignRatesOrRemoveProductsCounter > 0"> ({{selectAssignRatesOrRemoveProductsCounter}})</span></button>
      </div>       
        <div class = "hierarchy-radio">
          <mat-card class="hierarchy-card">
            <mat-label class="hierarchy">Hierarchy Levels</mat-label>
            <div class = "hierarchy-radio">
              <mat-radio-group name="radioHierarchy"
                               [(ngModel)]="selectedHierarchy"
                               (change)="hierarchyChange($event)">
                <mat-radio-button *ngFor="let hierarchy of hierarchys"
                                  [value]="hierarchy">
                  {{hierarchy}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </mat-card>         
        </div>        
        <div>
          <mat-slide-toggle class="mat-slide-toggle" [(ngModel)]="expandCollapseAllAtHierarchyLevel" name="expandCollapseAllAtHierarchyLevel">Expand/Collapse all at Hierarchy Level</mat-slide-toggle>    
        </div>
        <div>
          <mat-slide-toggle class="mat-slide-remove-toggle"  (change)="onRemoveToggleChange($event)" [(ngModel)]="removeProducts" name="removeProducts">Remove Products</mat-slide-toggle>    
        </div>
        <div>
          <button *ngIf="removeProducts" mat-raised-button class="functional-button-dark" (click)="onRemove();" [disabled]=" isAssignRatesOrRemoveButtonEnable"> Remove <span *ngIf="selectAssignRatesOrRemoveProductsCounter > 0">({{selectAssignRatesOrRemoveProductsCounter}})</span></button>
        </div>
    </mat-card-header>
    <mat-card-content>
      <div class="matgrid-div">
        <div class="mat-table-container">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()"
                              [disabled]="removeProducts">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let data">
                <mat-checkbox *ngIf="data.level === 0" (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(data) : null"
                              [checked]="selection.isSelected(data)"
                              [disabled]="data.isPastEndDate || removeProducts">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>
                <span [style.marginLeft.px]="60">PROGRAM NAME </span>
              </th>
              <td mat-cell *matCellDef="let data">
                <button mat-icon-button
                        [style.visibility]="!data.expandable ? 'hidden' : ''"
                        [style.marginLeft.px]="data.level * 16"
                        (click)="treeControl.isExpanded(data) ? treeControlToggleCollapse(data):treeControlToggleExpand(data)">
                  <mat-icon class="mat-icon-rtl-mirror" [style.color]="treeControl.isExpanded(data) ? 'black' : '#1A81E1' ">
                    {{treeControl.isExpanded(data) ? 'remove' : 'add'}}
                  </mat-icon>
                </button>
                <mat-checkbox [style.marginRight.px]="5" [style.visibility]="data.level === 0 ? 'hidden' : ''"
                              [checked]="checklistSelection.isSelected(data)"
                              [indeterminate]="descendantsPartiallySelected(data)"
                              (change)="todoItemSelectionToggle(data)" 
                              [disabled]="data.isPastEndDate">
                </mat-checkbox>
                <mat-label *ngIf="data.level === 0">
                  {{data.name}}
                  <span *ngIf="programAccrualRatesByMonth.length > 0">
                    <mat-chip *ngIf="checkIsDraftStatus(data, false) && data.isNewMonthAdded && findInProgramAccrualRatesByMonth(data)" class="mat-chip-new-month"> Draft + New Months </mat-chip>
                    <mat-chip *ngIf="!checkIsDraftStatus(data, false)&& !data.isNewMonthAdded && findInProgramAccrualRatesByMonth(data)" class="mat-chip-submitted"> Submitted</mat-chip>
                    <mat-chip *ngIf="checkIsDraftStatus(data, false) && !data.isNewMonthAdded && findInProgramAccrualRatesByMonth(data)" class="mat-chip-pending-draft"> Draft </mat-chip>
                    <mat-chip *ngIf="!checkIsDraftStatus(data, false) && data.isNewMonthAdded && findInProgramAccrualRatesByMonth(data)" class="mat-chip-new-month"> Submitted + New Months</mat-chip>
                  </span>
                </mat-label>
                <mat-label *ngIf="data.level > 0 && data.level < 2">
                  {{data.name}} ({{data.materialCode}})
                  <span>
                    <mat-icon *ngIf="!checkIsExistingProduct(data.id,data.materialHierarchyCommercialId) && !data.expandable" matTooltip="New" matTooltipPosition="above" matTooltipClass="mat-tooltip-add" class="matcard-material-add-icon">add_circle</mat-icon>
                    <mat-icon *ngIf="checkIsDraftStatus(data, true) && checkIsExistingProduct(data.id,data.materialHierarchyCommercialId) && !data.expandable" matTooltipPosition="above" matTooltipClass="mat-tooltip-pending" matTooltip="Draft" class="matcard-material-pending-icon">pending</mat-icon>
                    <mat-icon *ngIf="!checkIsDraftStatus(data, true) && checkIsExistingProduct(data.id,data.materialHierarchyCommercialId) && !data.expandable" matTooltipPosition="above" matTooltipClass="mat-tooltip-submitted" matTooltip="Submitted" class="matcard-material-icon">check_circle</mat-icon>
                  </span>
                </mat-label>
                <mat-label *ngIf="(data.level === 2)">
                  {{data.name}} ({{data.materialCode}})
                  <span>
                    <mat-icon *ngIf="!checkIsExistingProduct(data.id,data.materialHierarchyCommercialId) && !data.expandable" matTooltip="New" matTooltipPosition="above" matTooltipClass="mat-tooltip-add" class="matcard-material-add-icon">add_circle</mat-icon>
                    <mat-icon *ngIf="checkIsDraftStatus(data, true) && checkIsExistingProduct(data.id,data.materialHierarchyCommercialId) && !data.expandable" matTooltipPosition="above" matTooltipClass="mat-tooltip-pending" matTooltip="Draft" class="matcard-material-pending-icon">pending</mat-icon>
                    <mat-icon *ngIf="!checkIsDraftStatus(data, true) && checkIsExistingProduct(data.id,data.materialHierarchyCommercialId) && !data.expandable" matTooltipPosition="above" matTooltipClass="mat-tooltip-submitted" matTooltip="Submitted" class="matcard-material-icon">check_circle</mat-icon>
                    <mat-icon *ngIf="checkForWarningOrError(data.id,data.materialHierarchyCommercialId,true)&& checkIsProductLevel(data.materialHierarchyCommercialId) && !data.expandable" matTooltipPosition="above" matTooltipClass="mat-tooltip-error" [matTooltip]="getToolTipMessages(data.id,data.materialHierarchyCommercialId,true)" class="matcard-material-error-icon">error</mat-icon>
                    <mat-icon *ngIf="checkForWarningOrError(data.id,data.materialHierarchyCommercialId,false)&& checkIsProductLevel(data.materialHierarchyCommercialId) && !data.expandable" matTooltipPosition="above" matTooltipClass="mat-tooltip-warning" [matTooltip]="getToolTipMessages(data.id,data.materialHierarchyCommercialId,false)" class="matcard-material-warning-icon">warning</mat-icon>
                  </span>
                </mat-label>
                <div *ngIf="!data.expandable && data.isAdded" [style.marginLeft.px]="(data.level + 1) * 16 ">
                  <mat-card class="card">
                    <mat-table #table [dataSource]="dataSourceAssignRates" class="mat-elevation-z0">
                      <ng-container matColumnDef="{{innerColumn}}" *ngFor="let innerColumn of innerDisplayedColumns | slice:0:data.numberOfColumns">

              <td mat-cell *matCellDef="let element" [style.border.px]="0">
                <div *ngIf="checkAssignRates(data,element,(assignRatesItems.indexOf(element[innerColumn]) > -1))">
                  <mat-form-field style="width: 77px; margin-right: 10px;" appearance="fill">
                    <input *ngIf="element.assignRateType === 'ACCRUAL %'" matInput type="text" [cleave]="{numeral: true,numeralThousandsGroupStyle: 'none', numeralIntegerScale: 4, numeralDecimalScale: 3}" [(ngModel)]="element[innerColumn]" readonly>
                    <input *ngIf="!element.header && element.assignRateType !== 'ACCRUAL %'" matInput (keypress)="restrictChar($event);" maxlength="7" type="text" [cleave]="{numeral: true,numeralThousandsGroupStyle: 'none', numeralIntegerScale: 3, numeralDecimalScale: 3}" (change)="calculateAccrualRate(element.materialCode,element.header,element.programId,element.assignRateType,innerColumn,element[innerColumn]);" [(ngModel)]="element[innerColumn]" [readonly]="data.isPastEndDate"> 
                  </mat-form-field>
                </div>
                <div class="assign-rates-top-row" *ngIf="checkAssignRatesTopRow(data,element,(assignRatesItems.indexOf(element[innerColumn]) > -1))">
                  <mat-label *ngIf="checkIsNewMonth(data.id,data.materialHierarchyCommercialId,element[innerColumn])" class="matcard-material-newmonth">{{element[innerColumn]}}</mat-label>
                  <mat-label *ngIf="!checkIsNewMonth(data.id,data.materialHierarchyCommercialId,element[innerColumn]) && checkIsDraftMonth(data.id,data.materialHierarchyCommercialId,element[innerColumn])"  class="matcard-material-draftmonth">{{element[innerColumn]}}</mat-label>
                  <mat-label *ngIf="!checkIsNewMonth(data.id,data.materialHierarchyCommercialId,element[innerColumn]) && !checkIsDraftMonth(data.id,data.materialHierarchyCommercialId,element[innerColumn])">{{element[innerColumn]}}</mat-label>
                </div>
              </td>
            </ng-container>
            <mat-row class="mat-row-line" *matRowDef="let row; columns: innerDisplayedColumns  | slice:0:data.numberOfColumns;" [hidden]="!hiddenRows(data,row)"></mat-row>
            </mat-table>
  </mat-card>
</div>
                </td>
            </ng-container>
<ng-container matColumnDef="startDate">
  <th mat-header-cell *matHeaderCellDef> START DATE </th>
  <td mat-cell *matCellDef="let data"> {{data.startDate}} </td>
</ng-container>
<ng-container matColumnDef="endDate">
  <th mat-header-cell *matHeaderCellDef> END DATE </th>
  <td mat-cell *matCellDef="let data"> {{data.endDate}} </td>
</ng-container>
<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
           </table>

         </div>
          </div>
        </mat-card-content>
   </mat-card>
<ngx-spinner class="spinner" size="default" type="ball-spin-clockwise">
  <p>Please Wait.</p>
</ngx-spinner>
 </div>
