import { Component, Inject, Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { ProgPortalContextService } from 'src/app/core/services/progportalcontextservice';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MasterMaterialHierarchyLevels } from 'src/app/core/enums/masterMaterialHierarchyEnums';


export interface AccrualsErrorAndWarnings {
  programId: number;
  programName: string;
  accrualsHierarchyErrorAndWarnings: AccrualsHierarchyErrorAndWarnings[];
}

export interface AccrualsHierarchyErrorAndWarnings {
  programId: number;
  materialHierarchyCommercialId: number;
  materialCode: string;
  materialName: string;
  performanceCenterCode?: string
  productCenterCode?: string
  plannedProductCode?: string
  tradeProductCode?: string;
  performanceCenterName?: string
  productCenterName?: string
  plannedProductName?: string;
  tradeProductName?: string;
  selectedHierarchyLevel?: string;
  isHavingError: boolean;
  isHavingWarning: boolean
  errorMessage?: string;
  warningMessage?: string;
  assignProducts?: AssignProducts[]
}

export interface AssignProducts {
  materialCode: string;
  materialName: string;
  isHavingError: boolean;
  isHavingWarning: boolean
  errorMessage?: string;
  warningMessage?: string;
}
@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-dialog-accruals-error-warning-details',
  templateUrl: './dialog-accruals-error-warning-details.component.html',
  styleUrls: ['./dialog-accruals-error-warning-details.component.scss']
})
export class DialogAccrualsErrorAndWarningDetailsComponent {

  columnsToDisplay = ['programName'];
  innerInnerDisplayedColumns = ['materialName'];
  innerDisplayedColumns = ['name'];
  selectedHierarchy: string;
  masterSelectedHierarchyLevel:number;
  masterMaterialHierarchyLevels = MasterMaterialHierarchyLevels;
  accrualsErrorAndWarnings: AccrualsErrorAndWarnings[] = [];
  hierarchyGroupAccrualsErrorAndWarnings: AccrualsErrorAndWarnings[] = [];
  nonUpdatedAccrualsErrorAndWarnings: AccrualsErrorAndWarnings[] = [];
  hierarchys: string[] = [this.masterMaterialHierarchyLevels._0, this.masterMaterialHierarchyLevels._1, this.masterMaterialHierarchyLevels._2, this.masterMaterialHierarchyLevels._3,this.masterMaterialHierarchyLevels._4];

  constructor(public context: ProgPortalContextService, private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogAccrualsErrorAndWarningDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: AccrualsErrorAndWarnings[]
  ) {
    dialogRef.disableClose = true;
    this.accrualsErrorAndWarnings = data;
    this.nonUpdatedAccrualsErrorAndWarnings = JSON.parse(JSON.stringify(data['dataAccrualsHierarchyErrorAndWarnings']));
    this.masterSelectedHierarchyLevel = data['dataAccrualMasterHierarchySelectedLevel'];
    this.selectedHierarchy = this.masterMaterialHierarchyLevels['_' + this.masterSelectedHierarchyLevel];
    this.setHierarchyLevelSelection(this.selectedHierarchy);
    if(this.selectedHierarchy === this.masterMaterialHierarchyLevels['_' + 4]){
    this.hierarchyGroupAccrualsErrorAndWarnings = [];
    this.accrualsErrorAndWarnings = JSON.parse(JSON.stringify(this.nonUpdatedAccrualsErrorAndWarnings));
  }
}
 

  setHierarchyLevelSelection(selectedHierarchyLevel) {
    this.hierarchyGroupAccrualsErrorAndWarnings = [];
    this.accrualsErrorAndWarnings = [];
    this.hierarchyGroupAccrualsErrorAndWarnings = JSON.parse(JSON.stringify(this.nonUpdatedAccrualsErrorAndWarnings));
    this.accrualsErrorAndWarnings = JSON.parse(JSON.stringify(this.nonUpdatedAccrualsErrorAndWarnings));
    this.accrualsErrorAndWarnings.forEach(paar => {
      paar.accrualsHierarchyErrorAndWarnings.forEach(afar => {
        afar.selectedHierarchyLevel = selectedHierarchyLevel;
      })
    })

    this.hierarchyGroupAccrualsErrorAndWarnings.forEach(hgaar => {
      hgaar.accrualsHierarchyErrorAndWarnings = [];
    })

    this.accrualsErrorAndWarnings.forEach(arr => {
      arr.accrualsHierarchyErrorAndWarnings.forEach(afar => {
        this.hierarchyGroupAccrualsErrorAndWarnings.forEach(hgaar => {
          switch (selectedHierarchyLevel) {
            case this.masterMaterialHierarchyLevels._0:
              if (afar.programId === hgaar.programId) {
                if (!(hgaar.accrualsHierarchyErrorAndWarnings.some(element => element.programId === afar.programId && element.performanceCenterCode === afar.performanceCenterCode))) {
                  afar.assignProducts = [];
                  hgaar.accrualsHierarchyErrorAndWarnings.push(afar);
                  hgaar.accrualsHierarchyErrorAndWarnings.forEach(hafar => {
                    if (hafar.performanceCenterCode === afar.performanceCenterCode && hafar.materialHierarchyCommercialId === afar.materialHierarchyCommercialId) {
                      hafar.assignProducts.push({ materialCode: afar.materialCode, materialName: afar.materialName, isHavingError: afar.isHavingError, errorMessage: afar.errorMessage, isHavingWarning: afar.isHavingWarning, warningMessage: afar.warningMessage });
                    }
                  })
                }
                else {
                  hgaar.accrualsHierarchyErrorAndWarnings.forEach(hafar => {
                    if (hafar.performanceCenterCode === afar.performanceCenterCode) {
                      hafar.assignProducts.push({ materialCode: afar.materialCode, materialName: afar.materialName, isHavingError: afar.isHavingError, errorMessage: afar.errorMessage, isHavingWarning: afar.isHavingWarning, warningMessage: afar.warningMessage });
                    }
                  })
                }
              }
              break;
            case this.masterMaterialHierarchyLevels._1:
              if (afar.programId === hgaar.programId) {
                if (!(hgaar.accrualsHierarchyErrorAndWarnings.some(element => element.programId === afar.programId && element.productCenterCode === afar.productCenterCode))) {
                  afar.assignProducts = [];
                  hgaar.accrualsHierarchyErrorAndWarnings.push(afar);
                  hgaar.accrualsHierarchyErrorAndWarnings.forEach(hafar => {
                    if (hafar.productCenterCode === afar.productCenterCode) {
                      hafar.assignProducts.push({ materialCode: afar.materialCode, materialName: afar.materialName, isHavingError: afar.isHavingError, errorMessage: afar.errorMessage, isHavingWarning: afar.isHavingWarning, warningMessage: afar.warningMessage });
                    }
                  })
                }
                else {
                  hgaar.accrualsHierarchyErrorAndWarnings.forEach(hafar => {
                    if (hafar.productCenterCode === afar.productCenterCode) {
                      hafar.assignProducts.push({ materialCode: afar.materialCode, materialName: afar.materialName, isHavingError: afar.isHavingError, errorMessage: afar.errorMessage, isHavingWarning: afar.isHavingWarning, warningMessage: afar.warningMessage });
                    }
                  })
                }
              }
              break;
            case this.masterMaterialHierarchyLevels._2:
              if (afar.programId === hgaar.programId) {
                if (!(hgaar.accrualsHierarchyErrorAndWarnings.some(element => element.programId === afar.programId && element.plannedProductCode === afar.plannedProductCode))) {
                  afar.assignProducts = [];
                  hgaar.accrualsHierarchyErrorAndWarnings.push(afar);
                  hgaar.accrualsHierarchyErrorAndWarnings.forEach(hafar => {
                    if (hafar.plannedProductCode === afar.plannedProductCode) {
                      hafar.assignProducts.push({ materialCode: afar.materialCode, materialName: afar.materialName, isHavingError: afar.isHavingError, errorMessage: afar.errorMessage, isHavingWarning: afar.isHavingWarning, warningMessage: afar.warningMessage });
                    }
                  })
                }
                else {
                  hgaar.accrualsHierarchyErrorAndWarnings.forEach(hafar => {
                    if (hafar.plannedProductCode === afar.plannedProductCode) {
                      hafar.assignProducts.push({ materialCode: afar.materialCode, materialName: afar.materialName, isHavingError: afar.isHavingError, errorMessage: afar.errorMessage, isHavingWarning: afar.isHavingWarning, warningMessage: afar.warningMessage });
                    }
                  })
                }
              }
              break;
            case this.masterMaterialHierarchyLevels._3:
              if (afar.programId === hgaar.programId) {
                if (!(hgaar.accrualsHierarchyErrorAndWarnings.some(element => element.programId === afar.programId && element.tradeProductCode === afar.tradeProductCode))) {
                  afar.assignProducts = [];
                  hgaar.accrualsHierarchyErrorAndWarnings.push(afar);
                  hgaar.accrualsHierarchyErrorAndWarnings.forEach(hafar => {
                    if (hafar.tradeProductCode === afar.tradeProductCode) {
                      hafar.assignProducts.push({ materialCode: afar.materialCode, materialName: afar.materialName, isHavingError: afar.isHavingError, errorMessage: afar.errorMessage, isHavingWarning: afar.isHavingWarning, warningMessage: afar.warningMessage });
                    }
                  })
                }
                else {
                  hgaar.accrualsHierarchyErrorAndWarnings.forEach(hafar => {
                    if (hafar.tradeProductCode === afar.tradeProductCode) {
                      hafar.assignProducts.push({ materialCode: afar.materialCode, materialName: afar.materialName, isHavingError: afar.isHavingError, errorMessage: afar.errorMessage, isHavingWarning: afar.isHavingWarning, warningMessage: afar.warningMessage });
                    }
                  })
                }
              }
              break;
          }
        })
      })
    })
  }
}
