<div>
    <div>
      <mat-toolbar>
        <div class="lblhearder">
          <mat-label>
            Reports
          </mat-label>
        </div>
      </mat-toolbar>
</div>
<div>
  <mat-card class="card">
    <mat-card-content>
      <mat-form-field>
        <mat-select placeholder="Select a Report" [(ngModel)]="selectedReport" (ngModelChange)="onReportSelection()">
          <mat-option *ngFor="let report of reportList" [value]="report">
            {{ report.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div style=" width: 100%;" [ngStyle]="{ 'height': (screenHeight-150)+ 'px' }" #embeddedReport></div>  
    </mat-card-content>
  </mat-card>
    
  <ngx-spinner class="spinner" size="default" type="ball-spin-clockwise">
    <p>Please Wait.</p>
  </ngx-spinner>
</div>