import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ProgPortalContextService } from 'src/app/core/services/progportalcontextservice';
import { StatusDefinitionIds } from 'src/app/core/enums/statusDefinitionEnums';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators,FormControl } from '@angular/forms';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { DialogWarningMessageComponent } from 'src/app/dialogs/dialog-warning-message/dialog-warning-message.component';
import { ActionMessages } from 'src/app/core/enums/emailEnums';
import { Router} from '@angular/router';
import { EmailDefinitionIds } from 'src/app/core/enums/emailDefinitionEnums';
import { EmailService } from 'src/app/core/services/email/email.service';
import { EmailOptions } from 'src/app/core/services/email/models/email-options.model';
import { CoreRepository } from 'src/app/core/core.repository';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppComponent } from 'src/app/app.component';


export interface StandardProgramDetails {
  programId: number;
  programCode: number;
  shortName: string;
  statusDefinitionId: number;
  productManagerDetails: any[];
  startDate: Date;
  endDate: Date;
}

export interface SourceAndTargetProgramDetails {
  sourceProgramId: number;
  targetProgramId: number;
  isOnlyAssignedProductManagers:boolean;
  newMonthsAddedEmailOptions: any;
  allAssignedProductManagersEmailOptions: any;
}

export interface Messages {
  messageId: number;
  message: string;
  error: boolean;
}

export interface ProgramStatus {
  programId: number;
  changeExceptionProgramId?: number;
  changeException: boolean;
  statusDefinitionId: number;
}

@Component({
  selector: 'app-copy-products-and-accruals',
  templateUrl: './copy-products-and-accruals.component.html',
  styleUrls: ['./copy-products-and-accruals.component.scss']
})

export class CopyProductsAndAccrualsComponent implements OnInit, AfterViewInit {
  copyProductsAccrualsForm: FormGroup;
  approvedStandardProgramDetails: StandardProgramDetails[] = [];
  standardProgramDetails: StandardProgramDetails[] = [];
  filteredApprovedStandardProgramDetails: Observable<any[]>;
  filteredStandardProgramDetails: Observable<any[]>;
  statusDefinitionIds = StatusDefinitionIds;
  sourceAndTargetProgramDetails: SourceAndTargetProgramDetails[] = [];
  sourceProgramId: number;
  sourceProgramCode: number;
  sourceStartDate: Date;
  sourceEndDate: Date;
  sourceProductManagerEmail: any[] = [];
  sourceProgramStatusDefinitionId: any;
  targetProgramId: number;
  targetProgramCode: number;
  targetStartDate: Date;
  targetEndDate: Date;
  targetProductManagerEmail: any[] = [];
  targetProgramStatusDefinitionId: any;
  actionMessages = ActionMessages;
  warningMessages: Messages[] = [];
  programStatus: ProgramStatus[] = [];
  updateProgramsStatus: ProgramStatus[] = [];
  uniqueProgramAndStatusDefinition: ProgramStatus[] = [];
  isOnlyAssignedProductManagers:boolean = false;
  ids: number[] = [];
  emailOptions = new EmailOptions();
  newMonthsAddedEmailOption = new EmailOptions();
  allAssignedProductManagersEmail: any;
  newMonthsAddedEmail: any;
  isffAllowMoreAccrualCopies: boolean = false;

  constructor(private fb: FormBuilder,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private router: Router,
    private core: CoreRepository,
    public emailService: EmailService,
    private matSnackBar: MatSnackBar, public context: ProgPortalContextService,
    private app: AppComponent) {
  }
  
  ngOnInit(): void {
    this.checkFeatures();
    this.copyProductsAccrualsForm = this.fb.group({
      SourceProgram: ['', Validators.required],
      TargetProgram: ['', Validators.required]      
    });
  }
  get f() { return this.copyProductsAccrualsForm.controls; }

  ngAfterViewInit() {
  //uncomment with removal of featureflag '666957_AllowAccrualCopyOnMorePrograms'
    //this.binddropdowns();
  }

  private filterStandardProgram(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.standardProgramDetails.filter(standardProgramDetailsOption => standardProgramDetailsOption.shortName.toLowerCase().includes(filterValue) || String(standardProgramDetailsOption.programCode).toLowerCase().includes(filterValue));
  }
  private filterApprovedStandardProgram(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.approvedStandardProgramDetails.filter(ApprovedStandardProgramDetailsOption => ApprovedStandardProgramDetailsOption.shortName.toLowerCase().includes(filterValue) || String(ApprovedStandardProgramDetailsOption.programCode).toLowerCase().includes(filterValue));
  }

  openSuccessSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['success-snackbar'] });
  }

  openErrorSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['error-snackbar'] });
  }

  binddropdowns() {    
    if (this.isffAllowMoreAccrualCopies)
      {
        this.spinner.show();
        this.context.getdatafromService('/api/Program/Option/GetStandardProgramDetailsV2').toPromise().then((response) => {
          if (response != null) {
            //Change FromProgram list from just approved to not Draft or Accrual Pending, since they wouldn't have accruals.  Story 666957
            this.approvedStandardProgramDetails = response.filter(x => x.statusDefinitionId !== this.statusDefinitionIds.DRAFT && x.statusDefinitionId !== this.statusDefinitionIds.ACCRUALS_PENDING);
            this.standardProgramDetails = response;
            this.filteredApprovedStandardProgramDetails = this.copyProductsAccrualsForm.controls.SourceProgram.valueChanges
              .pipe(
                startWith(''),
                map(value => this.filterApprovedStandardProgram(value))
              );
            this.filteredStandardProgramDetails = this.copyProductsAccrualsForm.controls.TargetProgram.valueChanges
              .pipe(
                startWith(''),
                map(value => this.filterStandardProgram(value))
              );
          }
          this.spinner.hide();
        });        
      } else
      { 
        this.spinner.show();
        this.context.getdatafromService('/api/Program/Option/GetStandardProgramDetails').toPromise().then((response) => {
          if (response != null) {
            this.approvedStandardProgramDetails = response.filter(x => x.statusDefinitionId == this.statusDefinitionIds.APPROVED);
            this.standardProgramDetails = response;
            this.filteredApprovedStandardProgramDetails = this.copyProductsAccrualsForm.controls.SourceProgram.valueChanges
              .pipe(
                startWith(''),
                map(value => this.filterApprovedStandardProgram(value))
              );
            this.filteredStandardProgramDetails = this.copyProductsAccrualsForm.controls.TargetProgram.valueChanges
              .pipe(
                startWith(''),
                map(value => this.filterStandardProgram(value))
              );
          }
          this.spinner.hide();
        });        
      } 
  }

  openCopyProductsAndAccrualsWarningDialog() {
    this.warningMessages = [];
    this.warningMessages.push({ messageId: 1, message: this.actionMessages._6, error: false });
      const dialogRef = this.dialog.open(DialogWarningMessageComponent, {
        height: '340px',
        width: '550px',
        data: {dataContinueText: 'continue', dataMessage: this.warningMessages,}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          if (result.dataConfirm == true) {
          this.copyProductsAndAccrualRates();
          }
        }
      });
  }


  copyProductsAndAccrualRates() {
    this.spinner.show();
    this.sourceAndTargetProgramDetails = [];

    var sourceMonths: number[] = [];
    var targetMonths: number[] = [];

    var sourceStartDateClone = new Date(this.sourceStartDate);
    var targetStartDateClone = new Date(this.targetStartDate);

    while(sourceStartDateClone <= this.sourceEndDate){
      sourceMonths.push(sourceStartDateClone.getMonth()+1);
      sourceStartDateClone.setMonth(sourceStartDateClone.getMonth()+1)
    }

    while(targetStartDateClone <= this.targetEndDate){
      targetMonths.push(targetStartDateClone.getMonth()+1);
      targetStartDateClone.setMonth(targetStartDateClone.getMonth()+1)
    }

    var commonMonths = targetMonths.filter(function(v) { // iterate over the array
      return sourceMonths.indexOf(v) > -1;
    })

    if(JSON.stringify(sourceMonths.sort()) === JSON.stringify(commonMonths.sort()) 
    && JSON.stringify(commonMonths.sort()) !== JSON.stringify(targetMonths.sort())){
      this.newMonthsAddedEmail = this.buildNewMonthsAddedEmail();
    } else{
      this.allAssignedProductManagersEmail = this.buildAllAssignedProductManagerEmail();
    }

    this.ids.push(this.targetProgramId);
    this.sourceAndTargetProgramDetails.push({ sourceProgramId: this.sourceProgramId, targetProgramId: this.targetProgramId, isOnlyAssignedProductManagers :this.isOnlyAssignedProductManagers, newMonthsAddedEmailOptions: this.newMonthsAddedEmail, allAssignedProductManagersEmailOptions: this.allAssignedProductManagersEmail })
    const headers = new HttpHeaders().set('content-type', 'application/json');
    this.context.postdatatoservice("/api/Administration/Table/CopyProductsAndAccruals", JSON.stringify(this.sourceAndTargetProgramDetails), headers)
      .toPromise().then((response) => {
        if (response !== null) {
          this.updateProgramStatus();
          this.openSuccessSnackBar('Successfully Copied Products And Accruals.', 'x', 3000);
          this.spinner.hide();
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl('administration/copy-products-and-accruals');
          });
        }

      },
        msg => { // Error
          this.openErrorSnackBar('Error while Copying Products and Accruals', 'x', 3000);
        })
      .catch(error => this.openErrorSnackBar(error.message, 'x', 3000));
      this.spinner.hide();

  }

  updateProgramStatus() {
    let programPendingStatus: ProgramStatus[] = [];
    let programCompletedStatus: ProgramStatus[] = [];
    this.uniqueProgramAndStatusDefinition = [];
    this.updateProgramsStatus = [];
    this.programStatus = [];
    const headers = new HttpHeaders().set('content-type', 'application/json');

    this.context.postdatatoservice("/api/Accrual/Details/GetProgramAccrualsStatus", JSON.stringify(this.ids), headers)
      .toPromise().then((response) => {
        if (response != null) {
          this.programStatus = response;
          if (this.programStatus.length > 0) {
            this.uniqueProgramAndStatusDefinition = this.programStatus.filter((v, i, a) => a.findIndex(t => (t.programId === v.programId && t.changeExceptionProgramId === v.changeExceptionProgramId && t.changeException === v.changeException && t.statusDefinitionId === v.statusDefinitionId)) === i);
            programPendingStatus = this.uniqueProgramAndStatusDefinition.filter(row => row.statusDefinitionId === this.statusDefinitionIds.ACCRUALS_PENDING);
            programCompletedStatus = this.uniqueProgramAndStatusDefinition.filter(row => row.statusDefinitionId === this.statusDefinitionIds.ACCRUALS_COMPLETED);

            programPendingStatus.forEach(parbmps => {
              this.updateProgramsStatus.push({ programId: parbmps.programId, changeExceptionProgramId: parbmps.changeExceptionProgramId, changeException: parbmps.changeException, statusDefinitionId: parbmps.statusDefinitionId },);
            });
            programCompletedStatus.forEach(parbmcs => {
              if (!this.updateProgramsStatus.some(ps => ps.programId === parbmcs.programId)) {
                this.updateProgramsStatus.push({ programId: parbmcs.programId, changeExceptionProgramId: parbmcs.changeExceptionProgramId, changeException: parbmcs.changeException, statusDefinitionId: parbmcs.statusDefinitionId },);
              }
            });
            const headers = new HttpHeaders().set('content-type', 'application/json');
            this.context.postdatatoservice("/api/Program/Table/StatusUpdate", JSON.stringify(this.updateProgramsStatus), headers)
              .toPromise().then((response) => {
                if (response != null) {
                  this.programStatus = [];
                }
              });
          }
        }
      });
  }

  onSourceProgramSelection() {
    let selectedSourceProgramDetails = this.approvedStandardProgramDetails.filter(x => x.shortName == this.copyProductsAccrualsForm.controls.SourceProgram.value);
    if (selectedSourceProgramDetails.length > 0) {
      this.sourceProgramId = selectedSourceProgramDetails[0].programId;
      this.sourceProgramCode = selectedSourceProgramDetails[0].programCode;
      this.sourceStartDate = new Date(selectedSourceProgramDetails[0].startDate);
      this.sourceEndDate = new Date(selectedSourceProgramDetails[0].endDate);
      this.sourceProductManagerEmail = selectedSourceProgramDetails[0].productManagerDetails;
      this.sourceProgramStatusDefinitionId = selectedSourceProgramDetails[0].statusDefinitionId;
      }
  }

  onTargetProgramSelection() {
    let selectedTargetProgramDetails = this.standardProgramDetails.filter(x => x.shortName == this.copyProductsAccrualsForm.controls.TargetProgram.value);
    if (selectedTargetProgramDetails.length > 0) {
      this.targetProgramId = selectedTargetProgramDetails[0].programId;
      this.targetProgramCode = selectedTargetProgramDetails[0].programCode;
      this.targetStartDate = new Date(selectedTargetProgramDetails[0].startDate);
      this.targetEndDate = new Date(selectedTargetProgramDetails[0].endDate);
      this.targetProductManagerEmail = selectedTargetProgramDetails[0].productManagerDetails;
      this.targetProgramStatusDefinitionId = selectedTargetProgramDetails[0].statusDefinitionId;
    }
  }

  buildNewMonthsAddedEmail() {
    if (!(this.targetProgramCode === undefined || this.targetProgramCode === null)) {
      var description = "An Admin has copied your Products and Accruals to a new program and it is showing new months without accruals <b>" + this.copyProductsAccrualsForm.controls.TargetProgram.value + "</b> (<b>" + this.targetProgramCode + "</b>). These accruals are in draft status and need to be submitted to participate in the program. ";
      var nextSteps = "<li>Click on the link below to add, or edit your products and accruals.</li><li>Release the changes to the Program Manager.</li>";
      var buttonLinkUrl = "accruals";
      var buttonText = "Go to Program Accruals";
      var emailSubject = "Products and Accruals have new months!";
      var toAddress = this.buildEmailRecipients();
      var ccAddress = [this.core.getUsername()];
      this.newMonthsAddedEmailOption = this.emailService.buildActionEmailTemplate(this.actionMessages._1,description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress);
      return JSON.stringify(this.newMonthsAddedEmailOption);
    }    
  }

  buildAllAssignedProductManagerEmail() {
    if (!(this.targetProgramCode === undefined || this.targetProgramCode === null)) {
      var description = "An Admin has copied your Products and Accruals to a new program <b>" + this.copyProductsAccrualsForm.controls.TargetProgram.value + "</b> (<b>" + this.targetProgramCode + "</b>). These accruals are in draft status and need to be submitted to participate in the program. ";
      var nextSteps = "<li>Click on the link below to add, or edit your products and accruals.</li><li>Release the changes to the Program Manager.</li>";
      var buttonLinkUrl = "accruals";
      var buttonText = "Go to Program Accruals";
      var emailSubject = "Products and Accruals have been copied!";
      var toAddress = this.buildEmailRecipients();
      var ccAddress = [this.core.getUsername()];
      this.emailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._1,description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress);
      return JSON.stringify(this.emailOptions);
    }  
  }

  buildEmailRecipients() {
      let assignedSourceProductManagersEmail: string[] = [];

      if(!this.isOnlyAssignedProductManagers){
        if(this.sourceProductManagerEmail != null){
        this.sourceProductManagerEmail.forEach(pmd =>{
          assignedSourceProductManagersEmail.push(pmd.productManagers.email);
        });
        }
        var toAddress = assignedSourceProductManagersEmail;
        toAddress = toAddress.filter((element, i) => i === toAddress.indexOf(element)) //Removing Duplicates if any
      } 
      else {
        if(this.sourceProductManagerEmail != null){
          this.sourceProductManagerEmail.forEach(pmd =>{
            if(!pmd.openEnrolled){
              assignedSourceProductManagersEmail.push(pmd.productManagers.email);
            }
          });
        }     
        var toAddress = assignedSourceProductManagersEmail;
        toAddress = toAddress.filter((element, i) => i === toAddress.indexOf(element)) //Removing Duplicates if any
      }    
    return toAddress;
  }
  
  checkFeatures() {
    this.context
      .getdatafromService('/api/FeatureFlag')
      .toPromise()
      .then((response) => {
        const data = response;
        this.isffAllowMoreAccrualCopies = data.filter(x => x.featureFlagName === "666957_AllowAccrualCopyOnMorePrograms")[0].enabled;
        this.binddropdowns();
      });      
  }
}


