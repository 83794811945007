import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreRepository } from 'src/app/core/core.repository';
import { ProgramPortalPermissions } from 'src/app/core/services/authorization/models/program-portal-permissions';

@Injectable({
  providedIn: 'root'
})
export class CreateProgramGuard implements CanActivate {

  constructor(private core: CoreRepository,
    private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      //To handle page refresh
      if(!this.router.navigated){
        return this.core.authorize().pipe(
          map(response => {
            return response.permissions.includes(ProgramPortalPermissions.EditProgram) ? true :this.router.createUrlTree(['home']);
          })       
         );  
      }
      
      return this.core.canEditProgram ? true : this.router.createUrlTree(['home']);
  }
  
}
