import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CoreRepository } from '../core/core.repository';

@Injectable({
  providedIn: 'root'
})
export class UserImpersonationGuard implements CanActivate {

  constructor(private router: Router,private core: CoreRepository){}
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return (this.core.environment !== "prod") ? true :this.router.createUrlTree(['home']);
  }
  
}
