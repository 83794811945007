import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UrlService {
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();
  private progCode: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public progCode$: Observable<number> = this.progCode.asObservable();
  constructor() { }

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }
  setProgCode(progCode: number) {
    this.progCode.next(progCode);
  }

}

