/**
 * Corteva Reference Architecture Simple Web App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
    HttpEvent,
    HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { ServerSideAppSettings } from '../model/serverSideAppSettings';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsDemoService {
    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * Gets data from application settings and returns them as a strongly-type object for use throughout the app.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appSettingsDemoGet(
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<ServerSideAppSettings>;
    public appSettingsDemoGet(
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<ServerSideAppSettings>>;
    public appSettingsDemoGet(
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<ServerSideAppSettings>>;
    public appSettingsDemoGet(
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(
            httpHeaderAccepts
        );
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<ServerSideAppSettings>(
            `${this.configuration.basePath}/api/AppSettingsDemo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }
}
