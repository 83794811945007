import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProgPortalContextService } from 'src/app/core/services/progportalcontextservice';
import { DialogProgramComponent } from 'src/app/programs/dialog-program/dialog-program.component';
import { DOARoleCodes, DOARoleIds } from 'src/app/core/enums/doaRoleEnums';
import { parse } from 'url';
import { HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProgramTableTypeIds } from 'src/app/core/enums/programTableTypeEnums';
import { StatusDefinitionIds, StatusDefinitionShortDesc } from 'src/app/core/enums/statusDefinitionEnums';
import { AppComponent } from 'src/app/app.component';



export interface options { userId: string; userName: string; roleId: Number;}

@Component({
  templateUrl: "./route-for-approval.html",
  styleUrls: ["./route-for-approval.scss"]
})

export class routeforApprovalComponent {

  ProgramAdminList: options[] = [];
  FinancialList: options[] = [];
  LegalList: options[] = [];
  ProgramAdminManagerList: options[] = [];
  CatgLeaderList: options[] = [];
  MarketingLeaderList: options[] = [];
  CommUnitLeaderList: options[] = [];
  ProgramLeaderList: options[] = [];
  ProgAdminAnalystList: options[] = [];
  CommercialLeaderList: options[] = [];
  OthersList: options[] = [];

  ProgramAdminRequiredText: string;
  FinancialAnalystRequiredText: string;
  ProgramAdminManagerRequiredText: string;
  CategoryLeaderRequiredText: string;
  MarketingLeaderRequiredText: string;
  CommUnitLeaderRequiredText: string;
  ProgramLeaderRequiredText: string;
  ProgAdminAnalystRequiredText: string;
  CommercialLeaderRequiredText: string;
  DOAUsersList: any[] = [];
  dOARoleCodes = DOARoleCodes;
  doaRoleIds = DOARoleIds;
  programIds: any[] = [];
  cancelSubject = new Subject();
  programTableTypeIds = ProgramTableTypeIds;
  statusDefinitionIds = StatusDefinitionIds;

  ApprovalForm = this.fb.group({
    ProgramAdmin: [''],
    FinancialAnalyst: [''],
    Legal: [''],
    ProgramAdminManager: [''],
    CategoryLeader: [''],
    MarketingLeader: [''],
    CommercialUnitLeader: [''],
    ProgramLeader: [''],
    ProgramAdminAnalyst: [''],
    CommercialLeader: [''],
    Others: ['']
  });

  selectedDOAUsers = [];
  constructor(public context: ProgPortalContextService,
    private spinner: NgxSpinnerService, private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogProgramComponent>, public app: AppComponent,
    @Inject(MAT_DIALOG_DATA) public data: RFAData) { //  // need to add once we have datamodel
    this.CheckFeatures();
  }

  async CheckFeatures() {
    await this.context
      .getdatafromService('/api/FeatureFlag')
      .toPromise()
      .then((response) => {
        const data = response;
        if (this.data.programType != undefined && this.data.programType === this.programTableTypeIds.Program) {
          this.programIds.push(this.data.programId);
          this.deleteProgramAccrualRatesByMonth();
        }
        else {
          this.binddropdowns();
          if (this.data.programId != undefined && this.data.programType != undefined) {
            this.prepopulatedropdowns();
          }
        }

      });
  }

  prepopulatedropdowns() {
    let existingProgramAdminList: DoaUserDetails[] = [];
    let existingFinancialAnalystList: DoaUserDetails[] = [];
    let existingProgramAdminManagerList: DoaUserDetails[] = [];
    let existingLegalList: DoaUserDetails[] = [];
    let existingMarketingLeaderList: DoaUserDetails[] = [];
    let existingCategoryLeaderList: DoaUserDetails[] = [];
    let existingCommercialLeaderList: DoaUserDetails[] = [];
    let existingCommercialUnitLeaderList: DoaUserDetails[] = [];
    let existingProgramLeaderList: DoaUserDetails[] = [];
    let existingProgramAdminAnalystList: DoaUserDetails[] = [];
    let existingOthersList: DoaUserDetails[] = [];
    let changeExceptionId = this.data.changeExceptionId == null ? 0 : this.data.changeExceptionId;
    let url = '';
    if (this.data.isChangeException && this.data.changeExceptionId == null) {
      url = '/api/Program/DOA/Detail/GetRoutingDOAUsers/' + this.data.programId + '/' + this.data.programType + '/' + this.data.companyCode + '/' + false + '/' + !this.data.isChangeException + '/' + changeExceptionId + '/' + false;
    }
    else if (this.data.isChangeException && this.data.changeExceptionId != null && (!this.data.existingInActiveRouting || this.data.statusDefinitionId == this.statusDefinitionIds.APPROVED)) {
      if(this.data.isNewChangeException){
        this.data.existingInActiveRouting=true;
      }
      url = '/api/Program/DOA/Detail/GetRoutingDOAUsers/' + this.data.programId + '/' + this.data.programType + '/' + this.data.companyCode + '/' + false + '/' + this.data.isChangeException + '/' + changeExceptionId + '/' + !this.data.existingInActiveRouting;
    }
    else if (this.data.isChangeException && this.data.changeExceptionId != null && this.data.existingInActiveRouting) {
      url = '/api/Program/DOA/Detail/GetRoutingDOAUsers/' + this.data.programId + '/' + this.data.programType + '/' + this.data.companyCode + '/' + true + '/' + this.data.isChangeException + '/' + changeExceptionId + '/' + false;
    }
    else if (!this.data.isChangeException && this.data.changeExceptionId == null) {
      url = '/api/Program/DOA/Detail/GetRoutingDOAUsers/' + this.data.programId + '/' + this.data.programType + '/' + this.data.companyCode + '/' + true + '/' + this.data.isChangeException + '/' + changeExceptionId + '/' + false;
    }

    this.spinner.show();
    this.context.getdatafromService(url).toPromise().then((response) => {
      if (response != null) {
        if (response.length > 0) {
          response = response.filter(v => v.active);
          this.DOAUsersList = response;
          response.forEach(element => {
            if (element.approver) {
              switch (element.role) {
                case this.dOARoleCodes.ProgramAdmin:
                  existingProgramAdminList.push(element.userId);
                  this.ApprovalForm.controls.ProgramAdmin.setValue(existingProgramAdminList);
                  break;
                case this.dOARoleCodes.FinancialAnalyst:
                  existingFinancialAnalystList.push(element.userId);
                  this.ApprovalForm.controls.FinancialAnalyst.setValue(existingFinancialAnalystList);
                  break;
                case this.dOARoleCodes.ProgramAdminManager:
                  existingProgramAdminManagerList.push(element.userId);
                  this.ApprovalForm.controls.ProgramAdminManager.setValue(existingProgramAdminManagerList);
                  break;
                case this.dOARoleCodes.Legal:
                  existingLegalList.push(element.userId);
                  this.ApprovalForm.controls.Legal.setValue(existingLegalList);
                  break;
                case this.dOARoleCodes.MarketingLeader:
                  existingMarketingLeaderList.push(element.userId);
                  this.ApprovalForm.controls.MarketingLeader.setValue(existingMarketingLeaderList);
                  break;
                case this.dOARoleCodes.CategoryLeader:
                  existingCategoryLeaderList.push(element.userId);
                  this.ApprovalForm.controls.CategoryLeader.setValue(existingCategoryLeaderList);
                  break;
                case this.dOARoleCodes.CommercialLeader:
                  existingCommercialLeaderList.push(element.userId);
                  this.ApprovalForm.controls.CommercialLeader.setValue(existingCommercialLeaderList);
                  break;
                case this.dOARoleCodes.CommercialUnitLeader:
                  existingCommercialUnitLeaderList.push(element.userId);
                  this.ApprovalForm.controls.CommercialUnitLeader.setValue(existingCommercialUnitLeaderList);
                  break;
                case this.dOARoleCodes.ProgramLeader:
                  existingProgramLeaderList.push(element.userId);
                  this.ApprovalForm.controls.ProgramLeader.setValue(existingProgramLeaderList);
                  break;
                case this.dOARoleCodes.ProgramAdminAnalyst:
                  existingProgramAdminAnalystList.push(element.userId);
                  this.ApprovalForm.controls.ProgramAdminAnalyst.setValue(existingProgramAdminAnalystList);
                  break;
                default:
                  break;
              }
            }
            else {
                switch (element.role) {
                    case this.dOARoleCodes.ProgramAdmin:
                        existingProgramAdminList.push(element.userId);
                        this.ApprovalForm.controls.ProgramAdmin.setValue(existingProgramAdminList);
                        break;
                    case this.dOARoleCodes.ProgramAdminAnalyst:
                        existingProgramAdminAnalystList.push(element.userId);
                        this.ApprovalForm.controls.ProgramAdminAnalyst.setValue(existingProgramAdminAnalystList);
                        break;
                    default:
                        existingOthersList.push(element.userId);
                        this.ApprovalForm.controls.Others.setValue(existingOthersList);
                        break;
                }
            }
          });
        }

      }
    });
  }
  deleteProgramAccrualRatesByMonth() {
    this.context.getdatafromService('/api/Accrual/Details/GetDeleteProgramAccrualRates/' + this.data.programId + '/' + this.data.startDate + '/' + this.data.endDate).toPromise().then((response) => {
      if (response != null) {
        if (response.length > 0) {
          let deleteProgramAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];
          response.forEach(dparbm => {
            deleteProgramAccrualRatesByMonth.push({ id: dparbm.id, programId: dparbm.programId, materialHierarchyCommercialId: dparbm.materialHierarchyCommercialId, month: dparbm.month, year: dparbm.year, volumePercentage: dparbm.volumePercentage, payRatePercentage: dparbm.payRatePercentage, accrualPercentage: dparbm.accrualPercentage, statusDefinitionId: dparbm.statusDefinitionId })
          });
          const headers = new HttpHeaders().set('content-type', 'application/json');

          this.context.postdatatoservice("/api/Accrual/Table/DeleteProgramAccrualRatesByProgramDate", JSON.stringify(deleteProgramAccrualRatesByMonth), headers)
            .pipe(takeUntil(this.cancelSubject)).toPromise().then((response) => {
              if (response != null) {
                this.updateProgramToProductManagerAssoc();
                this.updateProgramTotalSalesAndTotalExposure();
              }
              else {
                this.binddropdowns();
                if (this.data.programId != undefined && this.data.programType != undefined) {
                  this.prepopulatedropdowns();
                }
              }
            });
        }
        else {
          this.binddropdowns();
          if (this.data.programId != undefined && this.data.programType != undefined) {
            this.prepopulatedropdowns();
          }
        }
      }
    });

  }
  updateProgramToProductManagerAssoc() {
    let programToProductManagerAssoc: ProgramToProductManagerAssoc[] = [];
    let uniqueProgramAndProductManager: ProgramToProductManagerAssoc[] = [];
    const headers = new HttpHeaders().set('content-type', 'application/json');

    this.context.postdatatoservice("/api/Accrual/Details/GetOpenEnrolledProductMangersDelete", JSON.stringify(this.programIds), headers)
      .toPromise().then((response) => {
        if (response != null) {
          programToProductManagerAssoc = response;
          if (programToProductManagerAssoc.length > 0) {
            uniqueProgramAndProductManager = programToProductManagerAssoc.filter((v, i, a) => a.findIndex(t => (t.programId === v.programId && t.productManagerId === v.productManagerId)) === i);
            const headers = new HttpHeaders().set('content-type', 'application/json');
            this.context.postdatatoservice("/api/Program/Table/ProgramToProductManagerAssocUpdate", JSON.stringify(uniqueProgramAndProductManager), headers)
              .toPromise().then((response) => {
                if (response != null) {
                }
              });
          }
          else {
          }
        }
      });
  }
  updateProgramTotalSalesAndTotalExposure() {
    let programTotalSalesAndTotalExposure: ProgramTotalSalesAndTotalExposure[] = []

    const headers = new HttpHeaders().set('content-type', 'application/json');
    this.context.postdatatoservice("/api/Accrual/Details/GetProgramAccrualRatesTotalSalesAndTotalExposure", JSON.stringify(this.programIds), headers)
      .toPromise().then((response) => {
        if (response != null) {
          programTotalSalesAndTotalExposure = response;
          if (programTotalSalesAndTotalExposure.length > 0) {
            const headers = new HttpHeaders().set('content-type', 'application/json');
            this.context.postdatatoservice("/api/Program/Table/TotalSalesAndTotalExposureUpdate", JSON.stringify(programTotalSalesAndTotalExposure), headers)
              .toPromise().then((response) => {
                if (response != null) {
                  this.binddropdowns();
                  if (this.data.programId != undefined && this.data.programType != undefined) {
                    this.prepopulatedropdowns();
                  }
                }
              });
          }
        }
      });
  }

  binddropdowns() {
    let url = '/api/Program/DOA/Detail/' + this.data.totalExposure + '/' + this.data.companyCode;
    this.spinner.show();
    this.context.getdatafromService(url).toPromise().then((response) => {
      if (response != null) {
        response = response.filter(v => v.active);
        this.DOAUsersList = response;
        response.forEach((element: { role: string; userId: any; firstName: string; lastName: string; }) => {
          switch (element.role) {
            case this.dOARoleCodes.ProgramAdmin:
              this.ProgramAdminList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName,roleId:DOARoleIds.PROGADMN});
              this.ApprovalForm.get('ProgramAdmin').setValidators([Validators.required]);
              break;
            case this.dOARoleCodes.FinancialAnalyst:
              this.FinancialList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName ,roleId:DOARoleIds.FINCANAL});
              this.ApprovalForm.get('FinancialAnalyst').setValidators([Validators.required]);
              break;
            case this.dOARoleCodes.ProgramAdminManager:
              this.ProgramAdminManagerList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName ,roleId:DOARoleIds.PROGADMNMGR});
              this.ApprovalForm.get('ProgramAdminManager').setValidators([Validators.required]);
              break;
            case this.dOARoleCodes.Legal:
              this.LegalList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName ,roleId:DOARoleIds.LEGAL});
              this.ApprovalForm.get('Legal')
              break;
            case this.dOARoleCodes.MarketingLeader:
              this.MarketingLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName,roleId:DOARoleIds.MKTLDR });
              this.ApprovalForm.get('MarketingLeader').setValidators([Validators.required]);
              break;
            case this.dOARoleCodes.CategoryLeader:
              this.CatgLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName ,roleId:DOARoleIds.CATLDR});
              this.ApprovalForm.get('CategoryLeader').setValidators([Validators.required]);
              break;
            case this.dOARoleCodes.CommercialLeader:
              this.CommercialLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName ,roleId:DOARoleIds.COMLDR});
              this.ApprovalForm.get('CommercialLeader').setValidators([Validators.required]);
              break;
            case this.dOARoleCodes.CommercialUnitLeader:
              this.CommUnitLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName ,roleId:DOARoleIds.CULDR});
              this.ApprovalForm.get('CommercialUnitLeader').setValidators([Validators.required]);
              break;
            case this.dOARoleCodes.ProgramLeader:
              this.ProgramLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName ,roleId:DOARoleIds.PROGLDR});
              this.ApprovalForm.get('ProgramLeader').setValidators([Validators.required]);
              break;
            case this.dOARoleCodes.ProgramAdminAnalyst:
              this.ProgAdminAnalystList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName ,roleId:DOARoleIds.PROGADMNANAL});
              this.ApprovalForm.get('ProgramAdminAnalyst').setValidators([Validators.required]);
              break;
            default:
              break;

          }
        });

        setTimeout(() => { this.setRequired(); }, 10);
      }
    });
    url = '/api/Program/DOA/Option/DoaUsers/' + this.data.companyCode;
    this.context.getdatafromService(url).toPromise().then((response) => {
      if (response) {
        response = response.filter(v => v.active);
        response.forEach((element: { roleId: number; userId: any; firstName: string; lastName: string; }) => {
          if (!this.OthersList.some(ol => ol.userId == element.userId)) {
            this.OthersList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, roleId: element.roleId });
          }
        })
      };
    });
  }
  setRequired() {
    const required = "(Required)";
    const atleastone = "(Select at least one)";
    if (this.ProgramAdminList.length == 1) {
      this.ProgramAdminRequiredText = required;
      this.ApprovalForm.controls.ProgramAdmin.setValue([this.ProgramAdminList[0].userId]);
    } else {
      this.ProgramAdminRequiredText = atleastone;
    }
    if (this.FinancialList.length == 1) {
      this.FinancialAnalystRequiredText = required;
      this.ApprovalForm.controls.FinancialAnalyst.setValue([this.FinancialList[0].userId]);
    } else {
      this.FinancialAnalystRequiredText = atleastone;
    }
    if (this.ProgramAdminManagerList.length == 1) {
      this.ProgramAdminManagerRequiredText = required;
      this.ApprovalForm.controls.ProgramAdminManager.setValue([this.ProgramAdminManagerList[0].userId]);
    } else { this.ProgramAdminManagerRequiredText = atleastone; } // ProgramAdminManager
    if (this.CatgLeaderList.length == 1) {
      this.CategoryLeaderRequiredText = required;
      this.ApprovalForm.controls.CategoryLeader.setValue([this.CatgLeaderList[0].userId]);
    } else { this.CategoryLeaderRequiredText = atleastone; } // CategoryLeader

    if (this.MarketingLeaderList.length == 1) {
      this.MarketingLeaderRequiredText = required;
      this.ApprovalForm.controls.MarketingLeader.setValue([this.MarketingLeaderList[0].userId]);
    } else { this.MarketingLeaderRequiredText = atleastone; } // MarketingLeader

    if (this.CommercialLeaderList.length == 1) {
      this.CommercialLeaderRequiredText = required;
      this.ApprovalForm.controls.CommercialLeader.setValue([this.CommercialLeaderList[0].userId]);
    } else { this.CommercialLeaderRequiredText = atleastone; } // Commercial Leader
    if (this.ProgramLeaderList.length == 1) {
      this.ProgramLeaderRequiredText = required;
      this.ApprovalForm.controls.ProgramLeader.setValue([this.ProgramLeaderList[0].userId]);
    } else { this.ProgramLeaderRequiredText = atleastone; } // PROGLDR
    if (this.CommUnitLeaderList.length == 1) {
      this.CommUnitLeaderRequiredText = required;
      this.ApprovalForm.controls.CommercialUnitLeader.setValue([this.CommUnitLeaderList[0].userId]);
    } else { this.CommUnitLeaderRequiredText = atleastone; }//Commercial Unit Leader
    if (this.ProgAdminAnalystList.length == 1) {
      this.ProgAdminAnalystRequiredText = required;
      this.ApprovalForm.controls.ProgramAdminAnalyst.setValue([this.ProgAdminAnalystList[0].userId]);
    } else { this.ProgAdminAnalystRequiredText = atleastone; }//PROGADMNANAL
    this.getParentProgramDOAUsers();
  }

  onSubmit() {
    if (this.ProgramAdminList.length > 0) {
      this.ApprovalForm.controls.ProgramAdmin.value?.forEach(element => {
        this.selectedDOAUsers.push({ userId: element, approver: false, roleId:DOARoleIds.PROGADMN });
      });
    }
    if (this.FinancialList.length > 0) {
      this.ApprovalForm.controls.FinancialAnalyst.value?.forEach(element => {
        this.selectedDOAUsers.push({ userId: element, approver: true , roleId:DOARoleIds.FINCANAL });
      });
    }
    if (this.ProgramAdminManagerList.length > 0) {
      this.ApprovalForm.controls.ProgramAdminManager.value?.forEach(element => {
        this.selectedDOAUsers.push({ userId: element, approver: true, roleId:DOARoleIds.PROGADMNMGR });
      });
    }
    if (this.LegalList.length > 0) {
      this.ApprovalForm.controls.Legal.value &&
        this.ApprovalForm.controls.Legal.value?.forEach(element => {
          this.selectedDOAUsers.push({ userId: element, approver: true, roleId:DOARoleIds.LEGAL });
        });
    }
    if (this.MarketingLeaderList.length > 0) {
      this.ApprovalForm.controls.MarketingLeader.value?.forEach(element => {
        this.selectedDOAUsers.push({ userId: element, approver: true, roleId:DOARoleIds.MKTLDR });
      });
    }
    if (this.CatgLeaderList.length > 0) {
      this.ApprovalForm.controls.CategoryLeader.value?.forEach(element => {
        this.selectedDOAUsers.push({ userId: element, approver: true, roleId:DOARoleIds.CATLDR });
      });
    }
    if (this.CommercialLeaderList.length > 0) {
      this.ApprovalForm.controls.CommercialLeader.value?.forEach(element => {
        this.selectedDOAUsers.push({ userId: element, approver: true, roleId:DOARoleIds.COMLDR });
      });
    }
    if (this.CommUnitLeaderList.length > 0) {
      this.ApprovalForm.controls.CommercialUnitLeader.value?.forEach(element => {
        this.selectedDOAUsers.push({ userId: element, approver: true, roleId:DOARoleIds.CULDR });
      });
    }
    if (this.ProgramLeaderList.length > 0) {
      this.ApprovalForm.controls.ProgramLeader.value?.forEach(element => {
        this.selectedDOAUsers.push({ userId: element, approver: true, roleId:DOARoleIds.PROGLDR });
      });
    }
    if (this.ProgAdminAnalystList.length > 0) {
      this.ApprovalForm.controls.ProgramAdminAnalyst.value?.forEach(element => {
        this.selectedDOAUsers.push({ userId: element, approver: false, roleId:DOARoleIds.PROGADMNANAL });
      });
    }
    if (this.OthersList.length > 0) {
      this.ApprovalForm.controls.Others.value &&
        this.ApprovalForm.controls.Others.value?.forEach(element => {
          this.OthersList.forEach(ol => {
            if(element == ol.userId){
              this.selectedDOAUsers.push({ userId: element, approver: false, roleId: ol.roleId });
            }
          });
        });
    }
    if (this.selectedDOAUsers.length > 0) { this.dialogRef.close(this.selectedDOAUsers); }
  }

  getParentProgramDOAUsers() {
    let url = ""
    let parentProgramAdminList = [];
    let parentFinancialList = [];
    let parentProgramAdminManagerList = [];
    let parentLegalList = [];
    let parentMarketingLeaderList = [];
    let parentCatgLeaderList = [];
    let parentCommercialLeaderList = [];
    let parentCommUnitLeaderList = [];
    let parentProgramLeaderList = [];
    let parentProgAdminAnalystList = [];
    let parentOthersList = [];
    if (this.data.bindParentDOR && this.data.parentProgCode && !this.data.isChangeException && !this.data.existingInActiveRouting) {
      url = '/api/Program/DOA/Option/ParentDoaUsers/' + this.data.parentProgCode;
      this.context.getdatafromService(url).toPromise().then((response) => {
        this.spinner.show();
        response.forEach((element: { doaRoleId: any; doaUserId: any; approver: any }) => {
          if (element.approver) {
            switch (element.doaRoleId) {
              case this.doaRoleIds.PROGADMN:
                parentProgramAdminList.push(element.doaUserId);
                break;
              case this.doaRoleIds.FINCANAL:
                parentFinancialList.push(element.doaUserId);
                break;
              case this.doaRoleIds.PROGADMNMGR:
                parentProgramAdminManagerList.push(element.doaUserId);
                break;
              case this.doaRoleIds.LEGAL:
                parentLegalList.push(element.doaUserId);
                break;
              case this.doaRoleIds.MKTLDR:
                parentMarketingLeaderList.push(element.doaUserId);
                break;
              case this.doaRoleIds.CATLDR:
                parentCatgLeaderList.push(element.doaUserId);
                break;
              case this.doaRoleIds.COMLDR:
                parentCommercialLeaderList.push(element.doaUserId);
                break;
              case this.doaRoleIds.CULDR:
                parentCommUnitLeaderList.push(element.doaUserId);
                break;
              case this.doaRoleIds.PROGLDR:
                parentProgramLeaderList.push(element.doaUserId);
                break;
              case this.doaRoleIds.PROGADMNANAL:
                parentProgAdminAnalystList.push(element.doaUserId);
                break;
              default:
                parentOthersList.push(element.doaUserId);
                break;
            }
          } else {
            switch (element.doaRoleId) {
              case this.doaRoleIds.PROGADMN:
                parentProgramAdminList.push(element.doaUserId);
                break;
              case this.doaRoleIds.PROGADMNANAL:
                parentProgAdminAnalystList.push(element.doaUserId);
                break;
              default:
                parentOthersList.push(element.doaUserId);
                break;
            }
          }
        });
        if (this.ProgramAdminList.length > 0 && parentProgramAdminList.length > 0) {
          this.ApprovalForm.controls.ProgramAdmin.setValue(parentProgramAdminList);
        }
        if (this.FinancialList.length > 0 && parentFinancialList.length > 0) {
          this.ApprovalForm.controls.FinancialAnalyst.setValue(parentFinancialList);
        }
        if (this.ProgramAdminManagerList.length > 0 && parentProgramAdminManagerList.length > 0) {
          this.ApprovalForm.controls.ProgramAdminManager.setValue(parentProgramAdminManagerList);
        }
        if (this.LegalList.length > 0 && parentLegalList.length > 0) {
          this.ApprovalForm.controls.Legal.setValue(parentLegalList);
        }
        if (this.CatgLeaderList.length > 0 && parentCatgLeaderList.length > 0) {
          this.ApprovalForm.controls.CategoryLeader.setValue(parentCatgLeaderList);
        }
        if (this.MarketingLeaderList.length > 0 && parentMarketingLeaderList.length > 0) {
          this.ApprovalForm.controls.MarketingLeader.setValue(parentMarketingLeaderList);
        }
        if (this.CommercialLeaderList.length > 0 && parentCommercialLeaderList.length > 0) {
          this.ApprovalForm.controls.CommercialLeader.setValue(parentCommercialLeaderList);
        }
        if (this.ProgramLeaderList.length > 0 && parentProgramLeaderList.length > 0) {
          this.ApprovalForm.controls.ProgramLeader.setValue(parentProgramLeaderList);
        }
        if (this.CommUnitLeaderList.length > 0 && parentCommUnitLeaderList.length > 0) {
          this.ApprovalForm.controls.CommercialUnitLeader.setValue(parentCommUnitLeaderList);
        }
        if (this.ProgAdminAnalystList.length > 0 && parentProgAdminAnalystList.length > 0) {
          this.ApprovalForm.controls.ProgramAdminAnalyst.setValue(parentProgAdminAnalystList);
        }
        if (this.OthersList.length > 0 && parentOthersList.length > 0) {
          this.ApprovalForm.controls.Others.setValue(parentOthersList);
        }
        this.spinner.hide();
      });
    }
    this.spinner.hide();
  }
}
export interface RFAData {
  totalExposure: any;
  companyCode: any;
  bindParentDOR: any;
  parentProgCode: any;
  programType: any;
  programId: any;
  startDate: any;
  endDate: any;
  isChangeException:boolean;
  changeExceptionId?: any;
  existingInActiveRouting: boolean;
  statusDefinitionId:any;
  isNewChangeException :boolean;
}

export interface ProgramAccrualRatesByMonth {
  id?: number;
  programId: number;
  materialHierarchyCommercialId: number;
  year: number;
  month: number;
  volumePercentage: number;
  payRatePercentage: number;
  accrualPercentage: number;
  statusDefinitionId: number;
}
export interface ProgramTotalSalesAndTotalExposure {
  programId: number;
  totalSales: number;
  totalExposure: number;
}
export interface ProgramToProductManagerAssoc {
  programId: number;
  productManagerId: number;
  openEnrolled: boolean;
  updateBy: string;
}
export interface DoaUserDetails{
  userId:string;
}
