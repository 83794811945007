import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProgPortalContextService } from 'src/app/core/services/progportalcontextservice';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Subject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { AppComponent } from 'src/app/app.component';
import { RouteParamsService } from 'src/app/core/services/route-params.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from "@angular/forms";
import { saveAs } from 'file-saver';
import { StatusDefinitionIds } from 'src/app/core/enums/statusDefinitionEnums';
import { CoreRepository } from 'src/app/core/core.repository';


@Component({
  selector: 'app-accruals-products-summary',
  templateUrl: './accruals-products-summary.component.html',
  styleUrls: ['./accruals-products-summary.component.scss']
})
export class AccrualsProductsSummaryComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator', { read: MatPaginator }) paginator: MatPaginator;
  @ViewChild('sort', { read: MatSort }) sort: MatSort;
  displayedColumns: string[] = ['programCode', 'programName', 'programStatus', 'startDate', 'endDate',
    'programManager', 'productManager', 'productMonth', 'productYear',
    'materialCode', 'materialName', 'tradeProductCode', 'tradeProductName', 'plannedProductCode',
    'plannedProductName', 'productCenterCode', 'productCenterName', 'performanceCenterCode', 'performanceCenterName',
    'salesType', 'totalVolume', 'grossSales', 'averageListPrice', 'marketLetterPrice',
    'volumePercentage', 'payRatePercentage', 'accrualPercentage', 'exposureCalculation'];
  cancelSubject = new Subject();
  dataSource: MatTableDataSource<ProgramAccrualsProductsSummary>;
  programAccrualsProductsSummary: ProgramAccrualsProductsSummary[] = [];
  progCode: any;
  isEditNavigation:string;
  programId: number;
  statusDefinitionId: number;
  isProgramRouted = false;
  program: Program = <Program>{};
  searchFilter = new FormControl();
  search: any;
  summaryDownloadFileName: string;
  statusDefinitionIds = StatusDefinitionIds;
  calculatedTotals:string;


  constructor(public context: ProgPortalContextService,
    private spinner: NgxSpinnerService,
    public app: AppComponent,
    public core: CoreRepository,
    private route: ActivatedRoute,
    private routeParamsService: RouteParamsService,
    private router: Router) {
      this.routeParamsService.getProgramEditNavigationRouteParams().subscribe(params => {
        if (params) {
          this.isEditNavigation = params;
          this.routeParamsService.clearProgramEditNavigationSettings();
          this.routeParamsService.setProgramEditNavigationSettings(this.isEditNavigation);
        }
      });
    
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.spinner.show();
    if (this.isEditNavigation === undefined || this.isEditNavigation === '') {
      this.isEditNavigation = this.routeParamsService.getProgramEditNavigationSettings();
    }
    let parmId=this.route.snapshot.paramMap.get('id');
    this.progCode = parmId.toString().includes('-')?parmId:parseInt(parmId);
    this.bindAccrualsProductsSummary();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter() {
    const filterValue = this.search;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  bindAccrualsProductsSummary() {
    this.programAccrualsProductsSummary = [];
    this.context.getdatafromService(`/api/Accrual/Details/GetProgramAccrualRatesSummary/${this.progCode}`).toPromise().then((response) => {
      if (response != null) {
        this.programAccrualsProductsSummary = response;
        if (this.programAccrualsProductsSummary.length > 0) {
          this.dataSource = new MatTableDataSource(this.programAccrualsProductsSummary);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          if (this.search !== undefined) {
            this.applyFilter();
          }
          this.programId = this.programAccrualsProductsSummary[0].programId;
          this.program.name = this.programAccrualsProductsSummary[0].programName;
          this.program.code = this.programAccrualsProductsSummary[0].programCode;
          this.program.status = this.programAccrualsProductsSummary[0].programStatus;
          this.program.totalSales = this.programAccrualsProductsSummary[0].totalSales;
          this.program.totalExposure = this.programAccrualsProductsSummary[0].totalExposure;
          this.statusDefinitionId = this.programAccrualsProductsSummary[0].statusDefinitionId;
          this.calculatedTotals = "Yes";
          if (this.statusDefinitionId > this.statusDefinitionIds.ACCRUALS_COMPLETED && this.statusDefinitionId !== this.statusDefinitionIds.DRAFT) {
            this.displayedColumns.splice(5, 0, 'routingStartDate');
            this.isProgramRouted = true;
          }
        }
        else {
           if(this.progCode.toString().includes('-')){
            this.context.getdatafromService(`/api/Program/ChangeException/Table/GetChangeExceptionProgram/${this.progCode.toString()}`).toPromise().then((response) => {
              if (response !== null) {
                this.programId = response.id;
                this.program.name = response.programName;
                this.program.code = response.code;
                this.statusDefinitionId = response.statusDefinition.id;
                this.program.status = response.statusDefinition.shortDesc;
                this.program.totalSales = response.totalSales;
                this.program.totalExposure = response.totalExposure;
                this.calculatedTotals = "No";
                if (this.statusDefinitionId > this.statusDefinitionIds.ACCRUALS_COMPLETED && this.statusDefinitionId !== this.statusDefinitionIds.DRAFT) {
                  this.isProgramRouted = true;
                }
              }
            });
           }
           else{
            this.context.getdatafromService(`/api/Program/Table/GetProgramByCode/${this.progCode}`).toPromise().then((response) => {
              if (response !== null) {
                this.programId = response.id;
                this.program.name = response.programName;
                this.program.code = response.code;
                this.statusDefinitionId = response.statusDefinition.id;
                this.program.status = response.statusDefinition.shortDesc;
                this.program.totalSales = response.totalSales;
                this.program.totalExposure = response.totalExposure;
                this.calculatedTotals = "No";
                if (this.statusDefinitionId > this.statusDefinitionIds.ACCRUALS_COMPLETED && this.statusDefinitionId !== this.statusDefinitionIds.DRAFT) {
                  this.isProgramRouted = true;
                }
              }
            });
           }
          
        }
        this.spinner.hide();
      }
    });
  } 
  onBack() {
    if (this.isEditNavigation == "Edit" && this.canEditProgram()) {
      this.router.navigateByUrl('programs/edit-program/' + this.program.code);
    } else {
      this.router.navigateByUrl('programs/review-program/' + this.program.code);
    }
  }
 
  canViewAccrual(): boolean {
    return this.core.canViewAccrual;
  }

  canEditProgram(): boolean {
    return this.core.canEditProgram;
  }

  formatDate(date): string {
    var result = '';
    var newDate = new Date(date);
    var dd = String(newDate.getDate()).padStart(2, '0');
    var mm = String(newDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = newDate.getFullYear().toString();
    result = mm + '/' + dd + '/' + yyyy;
    return result;
  }
  onDownloadSummary() {
    this.spinner.show();
    let dateTime = this.formatDate(new Date());
    this.summaryDownloadFileName = this.program.name + "_" + this.program.code + "_ProductsAccrualsSummary_" + dateTime + "_" + new Date().toLocaleTimeString();
    this.context.getdatafromService(`/api/Accrual/Details/ProductsAccrualsSummary/${this.progCode}/`, 'blob').toPromise().then((response) => {
      if (response != null) {
        saveAs(response, this.summaryDownloadFileName);
        this.spinner.hide();
      }
    });
  }
}
export interface ProgramAccrualsProductsSummary {
  programId: number
  programCode: number;
  programName: string;
  programStatus: string;
  startDate: Date;
  endDate: Date;
  totalSales: number;
  totalExposure: number;
  routingStartDate?: Date;
  programManager: string;
  productManager: string;
  productMonth: number;
  productYear: number;
  materialCode: string;
  materialName: string;
  tradeProductCode: string;
  tradeProductName: string;
  plannedProductCode: string;
  plannedProductName: string;
  productCenterCode: string;
  productCenterName: string;
  performanceCenterCode: string;
  performanceCenterName: string;
  salesType: string;
  totalVolume?: number;
  grossSales?: number;
  averageListPrice?: number;
  marketLetterPrice?: number;
  volumePercentage: number;
  payRatePercentage: number;
  accrualPercentage: number;
  statusDefinitionId: number;
  exposureCalculation: number;
}

export interface Program {
  name: string;
  code: number;
  status: string;
  totalSales: number;
  totalExposure: number;
}

