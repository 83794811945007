<div class="main-content">
  <form [formGroup]="AppUsersForm">
    <h1 mat-dialog-title>Update App Users</h1>
    <mat-card class="card">
      <mat-card-content>
        <div class="row">
          <mat-form-field class="col-md-6 ">
            <mat-label>User ID</mat-label>
            <input matInput formControlName="UserLogin" readonly>
          </mat-form-field>
          <mat-form-field class="col-md-6 " appearance="fill" >
            <mat-label>Email</mat-label>
            <input matInput formControlName="Email" required
            [ngClass]="{ 'is-invalid': f.Email.errors}">
            <mat-error *ngIf="f.Email.touched && f.Email.errors ">
              Email is required!</mat-error>
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field class="col-md-6 " appearance="fill">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="FirstName" required
            [ngClass]="{ 'is-invalid': f.FirstName.errors}">
            <mat-error *ngIf="f.FirstName.touched && f.FirstName.errors ">
              First Name is required!</mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6 " appearance="fill">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="LastName" required
            [ngClass]="{ 'is-invalid': f.LastName.errors}">
            <mat-error *ngIf="f.LastName.touched && f.LastName.errors ">
              First Name is required!</mat-error>
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field class="col-md-6" appearance="fill">
            <mat-label>Area/Company Name</mat-label>
            <mat-select formControlName="AppUserToFunctAreaAssoc" multiple required
            [ngClass]="{ 'is-invalid': f.AppUserToFunctAreaAssoc.errors}">
              <ng-container *ngFor="let area of areaList">
                <mat-option type="text" [value]="area.id">
                  <span [innerHTML]="area.desc"></span>
                </mat-option>
              </ng-container>             
            </mat-select>
            <mat-error *ngIf="f.AppUserToFunctAreaAssoc.touched && f.AppUserToFunctAreaAssoc.errors ">
               Area is required!</mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <mat-label>AppUser Role</mat-label>
            <mat-select formControlName="AppUserToRoleAssoc" multiple required
            [ngClass]="{ 'is-invalid': f.AppUserToRoleAssoc.errors}">
              <ng-container *ngFor="let role of roleList">
                <mat-option type="text" [value]="role.id">
                  <span [innerHTML]="role.desc"></span>
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-error *ngIf="f.AppUserToRoleAssoc.touched && f.AppUserToRoleAssoc.errors ">
              Role is required!</mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-slide-toggle class="buttonpadding" formControlName="Active">Active &nbsp; &nbsp; &nbsp;</mat-slide-toggle>
        </div>

      </mat-card-content>
    </mat-card>
    <div>
      <button mat-raised-button class="functional-button-dark" (click)="onSubmit()" [disabled]="!AppUsersForm.valid">
                        Submit</button>
      <button mat-raised-button class="functional-button" mat-dialog-close> Cancel
      </button>
    </div>
    <ngx-spinner class="spinner" size="default" type="ball-spin-clockwise">
      <p>Please Wait.</p>
    </ngx-spinner>
  </form>
</div>