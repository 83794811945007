import { Component} from '@angular/core';
import { ProgPortalContextService } from 'src/app/core/services/progportalcontextservice';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormArray, ValidatorFn } from '@angular/forms';
import { DOARoleCodes,DOARoleIds } from 'src/app/core/enums/doaRoleEnums';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmailOptions } from 'src/app/core/services/email/models/email-options.model';
import { EmailService } from 'src/app/core/services/email/email.service';
import { CoreRepository } from 'src/app/core/core.repository';
import { HttpHeaders } from '@angular/common/http';
import { EmailDefinitionIds } from 'src/app/core/enums/emailDefinitionEnums';
import { ActionMessages } from 'src/app/core/enums/emailEnums';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { StatusDefinitionIds } from 'src/app/core/enums/statusDefinitionEnums';
import { AppComponent } from 'src/app/app.component';


export interface options { userId: string; userName: string; statusDefinitionId: number; roleId: number; active: boolean;}

export interface ProgramApproversEmail {
  programId: number;
  programType: number;
  emailDefinitionId: number;
  programApproverId: number;
  emailOptions?: any;
  changeExceptionId?: any;
  changeException: boolean;
}

export interface DoaUsers {
  doaUserId: number;
  appUserId: number;
  userId: string;
  userName: string;
  lastName: string;
  firstName: string;
  email: string;
  approver: boolean;
}
export interface DOAUsersList {
  userId: string;
  approver: boolean;
  isExisting: boolean;
  deleted: boolean;
  roleId: number;
}
export interface RFAData {
  programId: any;
  companyCode: any;
  programType: any;
  totalExposure: any;
  programCode: any;
  programName: any;
  isChangeException:boolean;
  changeExceptionId?: any;
  existingInActiveChangeExceptionRouting: boolean;
  parentProgramName: any;
  parentProgramCode:any;
  childBundleRoutingProgramList: any;
  ccAddress?: any;
  statusDefinitionId: any;
}
export interface ProgramReviewersEmail {
  programId: number;
  programType: number;
  emailDefinitionId: number;
  programReviewerId: number;
  emailOptions?: any;
}

@Component({
  selector: 'app-edit-program-routeforapproval',
  templateUrl: './edit-program-routeforapproval.component.html',
  styleUrls: ['./edit-program-routeforapproval.component.scss']
})
export class EditProgramRouteforapprovalComponent {
  ProgramAdminList: options[] = [];
  FinancialList: options[] = [];
  LegalList: options[] = [];
  ProgramAdminManagerList: options[] = [];
  CatgLeaderList: options[] = [];
  MarketingLeaderList: options[] = [];
  CommUnitLeaderList: options[] = [];
  ProgramLeaderList: options[] = [];
  ProgAdminAnalystList: options[] = [];
  CommercialLeaderList: options[] = [];
  OthersList: options[] = [];

  ProgramAdminRequiredText: string;
  FinancialAnalystRequiredText: string;
  ProgramAdminManagerRequiredText: string;
  CategoryLeaderRequiredText: string;
  MarketingLeaderRequiredText: string;
  CommUnitLeaderRequiredText: string;
  ProgramLeaderRequiredText: string;
  ProgAdminAnalystRequiredText: string;
  CommercialLeaderRequiredText: string;
  DOAUsersList: DOAUsersList[] = [];
  nonUpdatedDOAUsersList: DOAUsersList[] = [];
  dOARoleCodes = DOARoleCodes;
  isFormDirty: boolean = false;
  approversEmails: any[] = [];
  emailProgramTypeText: string;
  routingApproversEmails: ProgramApproversEmail[] = [];
  routingReviewersEmails: ProgramReviewersEmail[] = [];
  statusDefinitionIds = StatusDefinitionIds
  doaUsers: DoaUsers[] = [];
  doaRoleIds = DOARoleIds;
  approversEmailOptions = new EmailOptions();
  reviewersEmailOptions = new EmailOptions();
  emailDefinitionIds = EmailDefinitionIds;
  actionMessages = ActionMessages;
  cancelSubject = new Subject();
  adminUserName: string;
  isSubmitted: boolean = false;
  isFeatureFlagEnabledFixForInactiveDOA: boolean = false;

  ccAddress: any;


  DoaApproverForm = this.fb.group({
    ProgramAdmin: new FormArray([], this.data.statusDefinitionId != StatusDefinitionIds.APPROVED? minSelectedCheckboxes(1):minSelectedCheckboxes(0)),
    FinancialAnalyst: new FormArray([],this.data.statusDefinitionId != StatusDefinitionIds.APPROVED? minSelectedCheckboxes(1):minSelectedCheckboxes(0)),
    Legal: new FormArray([], minSelectedCheckboxes(0)),
    ProgramAdminManager: new FormArray([], this.data.statusDefinitionId != StatusDefinitionIds.APPROVED? minSelectedCheckboxes(1):minSelectedCheckboxes(0)),
    CategoryLeader: new FormArray([], this.data.statusDefinitionId != StatusDefinitionIds.APPROVED? minSelectedCheckboxes(1):minSelectedCheckboxes(0)),
    MarketingLeader: new FormArray([], this.data.statusDefinitionId != StatusDefinitionIds.APPROVED? minSelectedCheckboxes(1):minSelectedCheckboxes(0)),
    CommercialUnitLeader: new FormArray([],this.data.statusDefinitionId != StatusDefinitionIds.APPROVED? minSelectedCheckboxes(1):minSelectedCheckboxes(0)),
    ProgramLeader: new FormArray([], this.data.statusDefinitionId != StatusDefinitionIds.APPROVED? minSelectedCheckboxes(1):minSelectedCheckboxes(0)),
    ProgramAdminAnalyst: new FormArray([], this.data.statusDefinitionId != StatusDefinitionIds.APPROVED? minSelectedCheckboxes(1):minSelectedCheckboxes(0)),
    CommercialLeader: new FormArray([], this.data.statusDefinitionId != StatusDefinitionIds.APPROVED? minSelectedCheckboxes(1):minSelectedCheckboxes(0)),
    Others: new FormArray([], minSelectedCheckboxes(0)),
  });

  get FinancialAnalyst() {
    return this.DoaApproverForm.controls.FinancialAnalyst as FormArray;
  }
  get ProgramLeader() {
    return this.DoaApproverForm.controls.ProgramLeader as FormArray;
  }
  get Legal() {
    return this.DoaApproverForm.controls.Legal as FormArray;
  }
  get ProgramAdmin() {
    return this.DoaApproverForm.controls.ProgramAdmin as FormArray;
  }
  get ProgramAdminManager() {
    return this.DoaApproverForm.controls.ProgramAdminManager as FormArray;
  }
  get MarketingLeader() {
    return this.DoaApproverForm.controls.MarketingLeader as FormArray;
  }
  get CategoryLeader() {
    return this.DoaApproverForm.controls.CategoryLeader as FormArray;
  }
  get CommercialLeader() {
    return this.DoaApproverForm.controls.CommercialLeader as FormArray;
  }
  get CommercialUnitLeader() {
    return this.DoaApproverForm.controls.CommercialUnitLeader as FormArray;
  }
  get ProgramAdminAnalyst() {
    return this.DoaApproverForm.controls.ProgramAdminAnalyst as FormArray;
  }
  get Others() {
    return this.DoaApproverForm.controls.Others as FormArray;
  }
  constructor(public context: ProgPortalContextService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    public emailService: EmailService,
    private core: CoreRepository,
    public app: AppComponent,
    @Inject(MAT_DIALOG_DATA) public data: RFAData,
    public dialogRef: MatDialogRef<EditProgramRouteforapprovalComponent>) {
    this.CheckFeatures();
  }

  async CheckFeatures() {
    await this.context
      .getdatafromService('/api/FeatureFlag')
      .toPromise()
      .then((response) => {
        const data = response;
        this.isFeatureFlagEnabledFixForInactiveDOA = data.filter(x => x.featureFlagName === "484162_FixForInactiveDOA")[0].enabled;
        this.binddropdowns();
      });
  }

  getRoutingDoaUsers() {
    let changeExceptionId = this.data.changeExceptionId == null ? 0 : this.data.changeExceptionId;
    this.ccAddress = this.data.ccAddress;
    this.spinner.show();
    this.context.getdatafromService('/api/Program/DOA/Detail/GetRoutingDOAUsers/' + this.data.programId + '/' + this.data.programType + '/' + this.data.companyCode + '/' + false + '/' + this.data.isChangeException + '/' + changeExceptionId + '/' + false).toPromise().then((response) => {
      if (response != null) {
        if (response.length > 0) {
          response = response.filter(v => v.firstName != null && v.lastName != null);
          response.forEach(element => {
            this.DOAUsersList.push({ userId: element.userId, approver: element.approver, isExisting: true, deleted: false, roleId: parseInt(DOARoleIds[element.role]) });
            if (element.approver) {
              switch (element.role) {
                case this.dOARoleCodes.FinancialAnalyst:
                  let FinancialAnalystIndex = this.FinancialList.findIndex(x => x.userId == element.userId);
                  this.FinancialAnalyst.controls[FinancialAnalystIndex].setValue(true)
                  this.FinancialList[FinancialAnalystIndex].statusDefinitionId = element.statusDefinitionId;
                  break;
                case this.dOARoleCodes.ProgramAdminManager:
                  let ProgramAdminManagerIndex = this.ProgramAdminManagerList.findIndex(x => x.userId == element.userId);
                  this.ProgramAdminManager.controls[ProgramAdminManagerIndex].setValue(true)
                  this.ProgramAdminManagerList[ProgramAdminManagerIndex].statusDefinitionId = element.statusDefinitionId;
                  break;
                case this.dOARoleCodes.Legal:
                  let LegalIndex = this.LegalList.findIndex(x => x.userId == element.userId);
                  this.Legal.controls[LegalIndex].setValue(true)
                  this.LegalList[LegalIndex].statusDefinitionId = element.statusDefinitionId;
                  break;
                case this.dOARoleCodes.MarketingLeader:
                  let MarketingLeaderIndex = this.MarketingLeaderList.findIndex(x => x.userId == element.userId);
                  this.MarketingLeader.controls[MarketingLeaderIndex].setValue(true)
                  this.MarketingLeaderList[MarketingLeaderIndex].statusDefinitionId = element.statusDefinitionId;
                  break;
                case this.dOARoleCodes.CategoryLeader:
                  let CategoryLeaderIndex = this.CatgLeaderList.findIndex(x => x.userId == element.userId);
                  this.CategoryLeader.controls[CategoryLeaderIndex].setValue(true)
                  this.CatgLeaderList[CategoryLeaderIndex].statusDefinitionId = element.statusDefinitionId;
                  break;
                case this.dOARoleCodes.CommercialLeader:
                  let CommercialLeaderIndex = this.CommercialLeaderList.findIndex(x => x.userId == element.userId);
                  this.CommercialLeader.controls[CommercialLeaderIndex].setValue(true)
                  this.CommercialLeaderList[CommercialLeaderIndex].statusDefinitionId = element.statusDefinitionId;
                  break;
                case this.dOARoleCodes.CommercialUnitLeader:
                  let CommercialUnitLeaderIndex = this.CommUnitLeaderList.findIndex(x => x.userId == element.userId);
                  this.CommercialUnitLeader.controls[CommercialUnitLeaderIndex].setValue(true)
                  this.CommUnitLeaderList[CommercialUnitLeaderIndex].statusDefinitionId = element.statusDefinitionId;
                  break;
                case this.dOARoleCodes.ProgramLeader:
                  let ProgramLeaderIndex = this.ProgramLeaderList.findIndex(x => x.userId == element.userId);
                  this.ProgramLeader.controls[ProgramLeaderIndex].setValue(true)
                  this.ProgramLeaderList[ProgramLeaderIndex].statusDefinitionId = element.statusDefinitionId;
                  break;
                default:
                  break;
              }
            }
            else {
              switch (element.role) {
                case this.dOARoleCodes.ProgramAdmin:
                  let ProgramAdminIndex = this.ProgramAdminList.findIndex(x => x.userId == element.userId);
                  this.ProgramAdmin.controls[ProgramAdminIndex].setValue(true)
                  this.ProgramAdminList[ProgramAdminIndex].statusDefinitionId = element.statusDefinitionId;
                  break;
                case this.dOARoleCodes.ProgramAdminAnalyst:
                  let ProgramAdminAnalystIndex = this.ProgAdminAnalystList.findIndex(x => x.userId == element.userId);
                  this.ProgramAdminAnalyst.controls[ProgramAdminAnalystIndex].setValue(true)
                  this.ProgAdminAnalystList[ProgramAdminAnalystIndex].statusDefinitionId = element.statusDefinitionId;
                  break;
                default:
                  if(!element.active){
                    this.OthersList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                    this.Others.push(new FormControl(true));
                  }
                  if(this.isFeatureFlagEnabledFixForInactiveDOA){
                    let OthersIndex = this.OthersList.findIndex(x => x.userId == element.userId && x.roleId == parseInt(DOARoleIds[element.role]));
                    if(OthersIndex != -1){
                      this.Others.controls[OthersIndex].setValue(true)
                    }
                  }else
                  {
                    let OthersIndex = this.OthersList.findIndex(x => x.userId == element.userId);
                    this.Others.controls[OthersIndex].setValue(true)
                  }
                }
              }
          });
          this.nonUpdatedDOAUsersList = JSON.parse(JSON.stringify(this.DOAUsersList));
        }
        this.spinner.hide();
      }
    });
  }

  binddropdowns() {
    let changeExceptionId = this.data.changeExceptionId == null ? 0 : this.data.changeExceptionId;
    this.spinner.show();
    this.context.getdatafromService('/api/Program/DOA/Detail/GetRoutingDOAUsers/' + this.data.programId + '/' + this.data.programType + '/' + this.data.companyCode + '/' + false + '/' + this.data.isChangeException + '/' + changeExceptionId + '/' + false).toPromise().then((response) => {
      if (response != null) {
        response.forEach(element => {
          if (!element.active) {
            switch (element.role) {
              case this.dOARoleCodes.ProgramAdmin: //Reviewer
                if(this.isFeatureFlagEnabledFixForInactiveDOA){
                  const isDuplicateProgramAdmin = this.ProgramAdminList.filter(f => f.userId === element.userId).length;
                  if (!isDuplicateProgramAdmin) {
                  this.ProgramAdminList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                  this.ProgramAdmin.push(new FormControl(false));
                  }
                }else{
                  this.ProgramAdminList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                  this.ProgramAdmin.push(new FormControl(false));
                }
                break;
              case this.dOARoleCodes.FinancialAnalyst:
                if(this.isFeatureFlagEnabledFixForInactiveDOA){
                  const isDuplicateFinancialAnalyst = this.FinancialList.filter(f => f.userId === element.userId).length;
                  if (!isDuplicateFinancialAnalyst && element.approver) {
                    this.FinancialList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                    this.FinancialAnalyst.push(new FormControl(false));
                  }
                }
                else{
                  this.FinancialList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                  this.FinancialAnalyst.push(new FormControl(false));
                }
                break;
              case this.dOARoleCodes.ProgramAdminManager:
                if(this.isFeatureFlagEnabledFixForInactiveDOA){
                  const isDuplicateProgramAdminManager = this.ProgramAdminManagerList.filter(f => f.userId === element.userId).length;
                  if (!isDuplicateProgramAdminManager && element.approver) {
                    this.ProgramAdminManagerList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                    this.ProgramAdminManager.push(new FormControl(false));
                  }
                }else{
                  this.ProgramAdminManagerList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                  this.ProgramAdminManager.push(new FormControl(false));
                }
                break;
              case this.dOARoleCodes.Legal:
                if(this.isFeatureFlagEnabledFixForInactiveDOA){
                  const isDuplicateLegal = this.LegalList.filter(f => f.userId === element.userId).length;
                  if (!isDuplicateLegal && element.approver) {
                    this.LegalList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                    this.Legal.push(new FormControl(false));
                  }
                }else{
                  this.LegalList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                  this.Legal.push(new FormControl(false));
                }
                break;
              case this.dOARoleCodes.MarketingLeader:
                if(this.isFeatureFlagEnabledFixForInactiveDOA){
                  const isDuplicateMarketLeader = this.MarketingLeaderList.filter(f => f.userId === element.userId).length;
                  if (!isDuplicateMarketLeader && element.approver) {
                     this.MarketingLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                     this.MarketingLeader.push(new FormControl(false));
                  }
                }else{
                  this.MarketingLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                  this.MarketingLeader.push(new FormControl(false));
                }
                break;
              case this.dOARoleCodes.CategoryLeader:
                if(this.isFeatureFlagEnabledFixForInactiveDOA){
                  const isDuplicateCategoryLeader = this.CatgLeaderList.filter(f => f.userId === element.userId).length;
                  if (!isDuplicateCategoryLeader && element.approver) {
                    this.CatgLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                    this.CategoryLeader.push(new FormControl(false));
                  }
                }else{
                  this.CatgLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                  this.CategoryLeader.push(new FormControl(false));
                }
                break;
              case this.dOARoleCodes.CommercialLeader:
                if(this.isFeatureFlagEnabledFixForInactiveDOA){
                  const isDuplicateCommercialLeader = this.CommercialLeaderList.filter(f => f.userId === element.userId).length;
                  if (!isDuplicateCommercialLeader && element.approver) {
                    this.CommercialLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                    this.CommercialLeader.push(new FormControl(false));
                  }
                }else{
                  this.CommercialLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                  this.CommercialLeader.push(new FormControl(false));
                }
                break;
              case this.dOARoleCodes.CommercialUnitLeader:
                if(this.isFeatureFlagEnabledFixForInactiveDOA){
                  const isDuplicateCommercialUnitLeader = this.CommUnitLeaderList.filter(f => f.userId === element.userId).length;
                  if (!isDuplicateCommercialUnitLeader && element.approver) {
                    this.CommUnitLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                    this.CommercialUnitLeader.push(new FormControl(false));
                  }
                }else{
                  this.CommUnitLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                  this.CommercialUnitLeader.push(new FormControl(false));
                }
                break;
              case this.dOARoleCodes.ProgramLeader:
                if(this.isFeatureFlagEnabledFixForInactiveDOA){
                  const isDuplicateProgramLeader = this.ProgramLeaderList.filter(f => f.userId === element.userId).length;
                  if (!isDuplicateProgramLeader && element.approver) {
                    this.ProgramLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                    this.ProgramLeader.push(new FormControl(false));
                  }
                }else{
                  this.ProgramLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                  this.ProgramLeader.push(new FormControl(false));
                }
                break;
              case this.dOARoleCodes.ProgramAdminAnalyst: //Reviewer
               if(this.isFeatureFlagEnabledFixForInactiveDOA){
                const isDuplicateProgramAdminAnalyst = this.ProgAdminAnalystList.filter(f => f.userId === element.userId).length;
                if (!isDuplicateProgramAdminAnalyst) {
                    this.ProgAdminAnalystList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                    this.ProgramAdminAnalyst.push(new FormControl(false));
                  }
                }else{
                  this.ProgAdminAnalystList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: parseInt(DOARoleIds[element.role]), active: element.active });
                  this.ProgramAdminAnalyst.push(new FormControl(false));
                }
                break;
              default:
                break;

            }
          }
        });
      }
    });

    this.spinner.show();
    this.context.getdatafromService('/api/Program/DOA/Detail/' + this.data.totalExposure + '/' + this.data.companyCode).toPromise().then((response) => {
      if (response != null) {
          response = response.filter(v => v.active);
          response.forEach((element: { role: any; userId: any; firstName: string; lastName: string; statusDefinitionId: number; active: boolean; }) => {
            switch (element.role) {
              case this.dOARoleCodes.ProgramAdmin:
                this.ProgramAdminList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId ,roleId:parseInt(DOARoleIds[element.role]), active:  element.active });
                this.ProgramAdmin.push(new FormControl(false));
                break;
              case this.dOARoleCodes.FinancialAnalyst:
                this.FinancialList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId ,roleId:parseInt(DOARoleIds[element.role]), active:  element.active});
                this.FinancialAnalyst.push(new FormControl(false));
                break;
              case this.dOARoleCodes.ProgramAdminManager:
                this.ProgramAdminManagerList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId ,roleId:parseInt(DOARoleIds[element.role]), active:  element.active});
                this.ProgramAdminManager.push(new FormControl(false));
                break;
              case this.dOARoleCodes.Legal:
                this.LegalList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId,roleId:parseInt(DOARoleIds[element.role]), active:  element.active});
                this.Legal.push(new FormControl(false));
                break;
              case this.dOARoleCodes.MarketingLeader:
                this.MarketingLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId ,roleId:parseInt(DOARoleIds[element.role]), active:  element.active});
                this.MarketingLeader.push(new FormControl(false));
                break;
              case this.dOARoleCodes.CategoryLeader:
                this.CatgLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId ,roleId:parseInt(DOARoleIds[element.role]), active:  element.active});
                this.CategoryLeader.push(new FormControl(false));
                break;
              case this.dOARoleCodes.CommercialLeader:
                this.CommercialLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId ,roleId:parseInt(DOARoleIds[element.role]), active:  element.active});
                this.CommercialLeader.push(new FormControl(false));
                break;
              case this.dOARoleCodes.CommercialUnitLeader:
                this.CommUnitLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId ,roleId:parseInt(DOARoleIds[element.role]), active:  element.active});
                this.CommercialUnitLeader.push(new FormControl(false));
                break;
              case this.dOARoleCodes.ProgramLeader:
                this.ProgramLeaderList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId ,roleId:parseInt(DOARoleIds[element.role]), active:  element.active});
                this.ProgramLeader.push(new FormControl(false));
                break;
              case this.dOARoleCodes.ProgramAdminAnalyst:
                this.ProgAdminAnalystList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId ,roleId:parseInt(DOARoleIds[element.role]), active:  element.active});
                this.ProgramAdminAnalyst.push(new FormControl(false));
                break;
              default:
                break;

            }
          });



        setTimeout(() => { this.setRequired(); }, 10);
        this.spinner.hide();
       }
    });
          this.context.getdatafromService('/api/Program/DOA/Option/DoaUsers/' + this.data.companyCode).toPromise().then((response) => {
            if (response) {
             response = response.filter(v => v.active);
              response.forEach((element: { roleId: number; userId: any; firstName: string; lastName: string; statusDefinitionId: number; active: boolean }) => {
                if (!this.OthersList.some(ol => ol.userId == element.userId)) {
                  this.OthersList.push({ userId: element.userId, userName: element.firstName + " " + element.lastName, statusDefinitionId: element.statusDefinitionId, roleId: element.roleId, active: element.active });
                  this.Others.push(new FormControl(false));
                }
              })



            };
            if (this.data.programId != undefined && this.data.programType != undefined) {
              this.getRoutingDoaUsers();
            }
          });
  }

  setRequired() {
    const required = "(Required)";
    const atleastone = "(Select at least one)";
    if (this.ProgramAdminList.length == 1) {
      this.ProgramAdminRequiredText = required;
    } else {
      this.ProgramAdminRequiredText = atleastone;
    }
    if (this.FinancialList.length == 1) {
      this.FinancialAnalystRequiredText = required;
    } else {
      this.FinancialAnalystRequiredText = atleastone;
    }
    if (this.ProgramAdminManagerList.length == 1) {
      this.ProgramAdminManagerRequiredText = required;
    } else { this.ProgramAdminManagerRequiredText = atleastone; }
    if (this.CatgLeaderList.length == 1) {
      this.CategoryLeaderRequiredText = required;
    } else { this.CategoryLeaderRequiredText = atleastone; }

    if (this.MarketingLeaderList.length == 1) {
      this.MarketingLeaderRequiredText = required;
    } else { this.MarketingLeaderRequiredText = atleastone; }

    if (this.CommercialLeaderList.length == 1) {
      this.CommercialLeaderRequiredText = required;
    } else { this.CommercialLeaderRequiredText = atleastone; }
    if (this.ProgramLeaderList.length == 1) {
      this.ProgramLeaderRequiredText = required;
    } else { this.ProgramLeaderRequiredText = atleastone; }
    if (this.CommUnitLeaderList.length == 1) {
      this.CommUnitLeaderRequiredText = required;
    } else { this.CommUnitLeaderRequiredText = atleastone; }
    if (this.ProgAdminAnalystList.length == 1) {
      this.ProgAdminAnalystRequiredText = required;
    } else { this.ProgAdminAnalystRequiredText = atleastone; }
    this.spinner.hide();
  }


  onSubmit() {
    let DOAUsersFilterList = this.DOAUsersList.filter(element => (element.deleted || !element.isExisting));
    let url ='';
    if(this.data.isChangeException && this.data.changeExceptionId != null){
      this.emailProgramTypeText = "change exception";
      url='/api/Program/ChangeException/Table/UpdateRoutingDoaUser/'+this.data.changeExceptionId +'/' +this.data.programType;
    }
    else{
      this.emailProgramTypeText = "program";
      url='/api/Program/Table/UpdateRoutingDoaUser/'+this.data.programId +'/' +this.data.programType;
    }
    this.context.postdatatoservice(url, DOAUsersFilterList)
      .pipe(takeUntil(this.cancelSubject)).toPromise().then((response) => {
        if (response !== null) {
          switch (response.id) {
            case this.statusDefinitionIds.APPROVAL_ROUTING:
              this.createApproversEmailList(this.data.programId, this.data.programCode, this.data.programName.value, this.data.programType, true)
              break;
            case this.statusDefinitionIds.APPROVED:
              this.createAddedReviewerEmailList(this.data.programId, this.data.programCode, this.data.programName.value, this.data.programType, false);
              break;
            case this.statusDefinitionIds.DENIED:
              this.openErrorSnackBar('Updating Denied Program Approvers/Reviewers is not allowed', 'x', 3000);
              this.dialogRef.close();
              break;
            default:
              this.openSuccessSnackBar('Updating the Approvers/Reviewers Successfully', 'x', 3000);
              this.dialogRef.close();
              break;
          }
        }
        else {
          this.openErrorSnackBar('Error in Updating the Approvers/Reviewers', 'x', 3000);
          this.dialogRef.close();
        }
      },
        msg => { // Error;
          this.openErrorSnackBar('Error in Updating the Approvers/Reviewers', 'x', 3000);
          this.dialogRef.close();
        })
      .catch(error => this.openErrorSnackBar(error.message, 'x', 3000));
  }

  createApproversEmailList(programId, programCode, programName, programType, isApprover) {
    this.approversEmails = [];
    this.routingApproversEmails = [];
    const headers = new HttpHeaders().set('content-type', 'application/json');
    this.context.getdatafromService('/api/Program/Routing/Details/' + programId + '/' + programType + '/' + isApprover + '/' + true + '/' + this.data.isChangeException + '/' + programCode.toString()).toPromise().then((response) => {
      if (response != null) {
        this.doaUsers = response;
        this.doaUsers = this.doaUsers.filter((v, i, a) => a.findIndex(t => (t.doaUserId === v.doaUserId && t.appUserId === v.appUserId && t.approver === v.approver)) === i);
        let changeExceptionId = 0;
        changeExceptionId = this.data.changeExceptionId === 0 ? this.data.changeExceptionId = null : this.data.changeExceptionId;
        if (this.doaUsers.length > 0) {
          this.doaUsers.forEach(du => {
            if (this.data.childBundleRoutingProgramList.length > 0) {
              this.buildBundleRoutedApproversEmail(du.email, programCode, programName);
            }
            else {
              this.buildApproversEmail(du.email, programCode, programName);
            }
            this.routingApproversEmails.push({ programId: programId, programType: programType, emailDefinitionId: this.emailDefinitionIds.PROGRAMMGR_TO_DOAAPPROVER, programApproverId: du.appUserId, emailOptions: JSON.parse(JSON.stringify(this.approversEmailOptions)), changeExceptionId: this.data.changeExceptionId, changeException: this.data.isChangeException },);
          })
          if (this.routingApproversEmails.length > 0) {
            const headers = new HttpHeaders().set('content-type', 'application/json');
            this.context.postdatatoservice("/api/Email/Table/ApproverEmail", JSON.stringify(this.routingApproversEmails), headers)
              .toPromise().then((response) => {
                if (response != null) {
                  this.openSuccessSnackBar('Updating the Approvers/Reviewers Successfully', 'x', 3000);
                  this.routingApproversEmails = [];
                  this.dialogRef.close();
                }
              });
          } else {
            this.openSuccessSnackBar('Updating the Approvers/Reviewers Successfully', 'x', 3000);
            this.dialogRef.close();
          }
        }
        else {
          this.openSuccessSnackBar('Updating the Approvers/Reviewers Successfully', 'x', 3000);
          this.dialogRef.close();
        }
      }
    });
  }
  createAddedReviewerEmailList(programId, programCode, programName, programType, isApprover) {
    let reviewersEmails = [];
    this.routingReviewersEmails = [];

    this.context.getdatafromService('/api/Program/Routing/Details/' + programId + '/' + programType + '/' + isApprover + '/' + false + '/' + this.data.isChangeException + '/'+ programCode.toString()).toPromise().then((response) => {
      if (response != null) {
        this.doaUsers = response;
        if (this.doaUsers.length > 0) {
          this.doaUsers.forEach(du => {
            reviewersEmails.push(du.email);
          })
          this.doaUsers.forEach(du => {
            if (this.data.childBundleRoutingProgramList.length > 0) {
              this.buildBundleRoutedReviewerEmail(reviewersEmails, programCode,programName);
            }
            else {
              this.buildAddedReviewerEmail(reviewersEmails, programCode, programName);
            }
            this.routingReviewersEmails.push({ programId: programId, programType: programType, emailDefinitionId: this.emailDefinitionIds.DOAAPPROVER_TO_DOAREVIEWER, programReviewerId: du.appUserId, emailOptions: JSON.parse(JSON.stringify(this.reviewersEmailOptions)) },);
          })
          if (this.routingReviewersEmails.length > 0) {
            const headers = new HttpHeaders().set('content-type', 'application/json');
            this.context.postdatatoservice("/api/Email/Table/ReviewerEmail", JSON.stringify(this.routingReviewersEmails), headers)
              .toPromise().then((response) => {
                if (response != null) {
                  this.openSuccessSnackBar('Updating the Approvers/Reviewers Successfully', 'x', 3000);
                  this.routingReviewersEmails = [];
                  this.dialogRef.close();
                }
              });
          }
          else {
            this.openSuccessSnackBar('Updating the Approvers/Reviewers Successfully', 'x', 3000);
            this.dialogRef.close();
          }
        }
        else {
          this.openSuccessSnackBar('Updating the Approvers/Reviewers Successfully', 'x', 3000);
          this.dialogRef.close();
        }
      }
    });
  }
  buildApproversEmail(approverEmail, programCode, programName) {
    this.approversEmails = [];
    var parentProgram = "";
    this.approversEmails.push(approverEmail);
    var description = "A program manager has released a "+ this.emailProgramTypeText+ " <b>"+ programName  + "</b> (<b>" + programCode + "</b>) so you can Approve, or post questions to the Program Manager.";
    var nextSteps = "<li>Click on the link below to review the " +this.emailProgramTypeText+".</li><li>Post comments to the Program Manager for discussion.</li><li>Click Approve button to continue the approval process.</li>";
    var buttonLinkUrl = 'programs/review-program/' + programCode;;
    var buttonText = "Go to Review page";
    var emailSubject = "New " + this.emailProgramTypeText + " needs your Approval!";
    var toAddress = this.approversEmails;
    var ccAddress = this.ccAddress;

    this.getUserName(this.core.getUserAccountName())
    if (this.core.isAdmin ){
    description = "<b>" + this.adminUserName + " (ADMIN)</b> has released a " + this.emailProgramTypeText + " <b>" + programName + "</b> (<b>" + programCode + "</b>) so you can Approve, or post questions to the Program Manager.";
    }
    if(this.data.parentProgramName){
      parentProgram =this.data.parentProgramName + " (" +  this.data.parentProgramCode + ")";
      this.approversEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._1,description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress,null,null,parentProgram);
    }
     else{
      this.approversEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._1,description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress);
    }
  }

  buildBundleRoutedApproversEmail(approverEmail, programCode,programName) {
    this.approversEmails = [];
    this.approversEmails.push(approverEmail);
    var bundledProgramList = "";
    if(this.data.childBundleRoutingProgramList.length > 0){
      this.data.childBundleRoutingProgramList.forEach(element => {
        if(!element.isParent){
         bundledProgramList += "<li>"+ element.shortName +" (" +element.code + ")</li>";
        }
      });
    }
    var description = "A program manager has released a "+ this.emailProgramTypeText+ " <b>"+ programName + "</b> (<b>" + programCode + "</b>) so you can Approve, or post questions to the Program Manager.";
    var nextSteps = "<li>Click on the link below to review the " +this.emailProgramTypeText+".</li><li>Post comments to the Program Manager for discussion.</li><li>Click Approve button to continue the approval process.</li>";
    var buttonLinkUrl = 'programs/review-program/' + programCode;;
    var buttonText = "Go to Review page";
    var emailSubject = "New " + this.emailProgramTypeText + " needs your Approval!";
    var toAddress = this.approversEmails;
    var ccAddress = this.ccAddress;

    this.getUserName(this.core.getUserAccountName())
    if (this.core.isAdmin ){
    description = "<b>" + this.adminUserName + " (ADMIN)</b> has released a " + this.emailProgramTypeText + " <b>" + programName + "</b> (<b>" + programCode + "</b>) so you can Approve, or post questions to the Program Manager.";
    }
    this.approversEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._1,description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress,null,bundledProgramList);
  }
  getUserName(userName) {
    var splitUserName = userName.split(',')
    this.adminUserName = splitUserName.slice(1, 2) + " " + splitUserName.slice(0, 1)
  }

  buildAddedReviewerEmail(emails, programCode, programName) {
    var parentProgram = "";
    var description = "Review the Approved " +this.emailProgramTypeText+" <b>"+ programName + "</b> (<b>" + programCode + "</b>) for any comments you would like to make.";
    var nextSteps = "<li>Click on the link below to review the " +this.emailProgramTypeText+".</li>";
    var buttonLinkUrl = 'programs/review-program/' + programCode;;
    var buttonText = "Go to Review page";
    var emailSubject = "New " + this.emailProgramTypeText + " Approvals are complete!";
    var toAddress = emails;
    var ccAddress = this.ccAddress;


        if(this.data.parentProgramName){
      parentProgram =this.data.parentProgramName + " (" +  this.data.parentProgramCode + ")";
      this.reviewersEmailOptions = this.emailService.buildActionEmailTemplate(description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress,null,null,parentProgram);
    }
     else{
      this.reviewersEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._2,description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress);
    }
  }

  buildBundleRoutedReviewerEmail(emails, programCode,programName) {
    var bundledProgramList = "";
    if(this.data.childBundleRoutingProgramList.length > 0){
      this.data.childBundleRoutingProgramList.forEach(element => {
        if(!element.isParent){
         bundledProgramList += "<li>"+ element.shortName +" (" +element.code + ")</li>";
        }
      });
    }
    var description = "Review the Approved " +this.emailProgramTypeText+" <b>"+ programName + "</b> (<b>" + programCode + "</b>) for any comments you would like to make.";
    var nextSteps = "<li>Click on the link below to review the " +this.emailProgramTypeText+".</li>";
    var buttonLinkUrl = 'programs/review-program/' + programCode;;
    var buttonText = "Go to Review page";
    var emailSubject = "New " + this.emailProgramTypeText + " Approvals are complete!";
    var toAddress = emails;
    var ccAddress = this.ccAddress;


    this.reviewersEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._2,description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress,null,bundledProgramList);
  }

  formSelectedUsers(formData: any, dataList: options[], approver: boolean) {
    if (dataList.length > 0) {
      const selectedDOAUsers = formData
        .map((checked, i) => checked ? { userId: dataList[i].userId, approver: approver } : null)
        .filter(v => v !== null);
      return selectedDOAUsers;
    }
  }

  openSuccessSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['success-snackbar'] });
  }

  openErrorSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['error-snackbar'] });
  }

  onChange(event: MatCheckboxChange, item, isApprover): void {
    this.isFormDirty = false;
    if (!event.checked) {
      if (this.DOAUsersList.some(element => element.userId === item.userId && element.approver === isApprover && element.roleId == item.roleId)) {
        this.DOAUsersList.forEach(dul => {
          if (dul.userId === item.userId && dul.approver === isApprover && dul.isExisting && dul.roleId == item.roleId) {
            dul.deleted = true;
            this.isFormDirty = true;
          }
          if (dul.userId === item.userId && dul.approver === isApprover && !dul.isExisting && dul.roleId == item.roleId) {
            var index = this.DOAUsersList.indexOf(dul);
            this.DOAUsersList.splice(index, 1);
          }
        })
      }
    }
    else {
      if (this.DOAUsersList.some(element => element.userId === item.userId && element.approver === isApprover && element.roleId == item.roleId)) {
        this.DOAUsersList.forEach(dul => {
          if (dul.userId === item.userId && dul.approver === isApprover && dul.isExisting && dul.roleId == item.roleId) {
            dul.deleted = false;
          }
        })
      }
      else {
        this.DOAUsersList.push({ userId: item.userId, approver: isApprover, isExisting: false, deleted: false, roleId: item.roleId })
        this.isFormDirty = true;
      }
    }
    if (this.DOAUsersList.length !== this.nonUpdatedDOAUsersList.length) {
      this.isFormDirty = true;
    }
    else {
      let exitForEach = false;
      this.nonUpdatedDOAUsersList.forEach(ndul => {
        if (!exitForEach) {
          if (!this.DOAUsersList.some(element => element.userId === ndul.userId && element.approver === ndul.approver && element.isExisting === ndul.isExisting && element.deleted === ndul.deleted && element.roleId == ndul.roleId)) {
            this.isFormDirty = true;
            exitForEach = true;
          }
        }
      })
    }
  }
}
function minSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: FormArray) => {
    const totalSelected = formArray.controls
      .map(control => control.value)
      .reduce((prev, next) => next ? prev + next : prev, 0);
    return totalSelected >= min ? null : (formArray.controls && formArray.controls.length > 0 ? { required: true } : null);
  };

  return validator;
}
