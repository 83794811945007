import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';;

export interface DialogData {
  confirm: boolean;
}

@Component({
  selector: 'app-dialog-confirm-delete-program',
  templateUrl: './dialog-confirm-delete-program.component.html',
  styleUrls: ['./dialog-confirm-delete-program.component.scss']
})
export class DialogConfirmDeleteProgramComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogConfirmDeleteProgramComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

  Confirm() {
    this.data.confirm = true;
    this.dialogRef.close(this.data);
  }
  Cancel() {
    this.data.confirm = false;
    this.dialogRef.close();
  }

}
