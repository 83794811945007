import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgramNode } from '../dialog-accruals-removeproducts/dialog-accruals-removeproducts.component';
import { SubmittedOrRemovedAccrualPrograms } from '../edit-accruals/edit-accruals.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';


@Component({
  selector: 'app-dialog-accruals-comments',
  templateUrl: './dialog-accruals-comments.component.html',
  styleUrls: ['./dialog-accruals-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DialogAccrualsCommentsComponent implements OnInit {
  accrualPrograms: ProgramNode[] = [];
  submittedOrRemovedAccrualPrograms: SubmittedOrRemovedAccrualPrograms[] = [];
  allSelected: boolean = false;
  allRequiredSelected: boolean = false;
  comment: string;
  showIndividualComments: boolean = false;

  constructor(public dialogRef: MatDialogRef<DialogAccrualsCommentsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data) { 
     this.submittedOrRemovedAccrualPrograms = data['submittedOrRemovedAccrualPrograms'];
    this.allRequiredSelected = this.submittedOrRemovedAccrualPrograms != null && this.submittedOrRemovedAccrualPrograms.every(t => t.selected);
     this.updateAllSelected();
    }

  ngOnInit(): void {
    switch (this.submittedOrRemovedAccrualPrograms.length) {
      case 1:
        this.dialogRef.updateSize('650px','480px');
        break;
      case 2:
        this.dialogRef.updateSize('650px','490px');
        break;
      case 3:
        this.dialogRef.updateSize('650px','500px');
        break;
      case 4:
        this.dialogRef.updateSize('650px','510px');
        break;
      default:
        this.dialogRef.updateSize('650px','550px');
        break;
    }
  }

  updateAllSelected() {
     this.allSelected = this.submittedOrRemovedAccrualPrograms != null && this.submittedOrRemovedAccrualPrograms.every(t => t.selected);
  }

  someSelected(): boolean {
    if (this.submittedOrRemovedAccrualPrograms == null) {
      return false;
    }
   return this.submittedOrRemovedAccrualPrograms.filter(t => t.selected && !t.disabled).length > 0 && !this.allSelected;
  }

  selectAll(selected: boolean) {
    this.allSelected = selected;
    if (this.submittedOrRemovedAccrualPrograms == null) {
      return;
    }
    this.submittedOrRemovedAccrualPrograms.forEach(t => {
      if(!t.disabled){
        t.selected = selected
      }
    });
  }

  checkProgramSelected():boolean{
    return this.submittedOrRemovedAccrualPrograms.filter(t => t.selected).length > 0;
  }
  checkIndividualProgramSelected(submittedOrRemovedAccrualProgram):boolean{
    return this.submittedOrRemovedAccrualPrograms.filter(t => t.programId === submittedOrRemovedAccrualProgram.programId && t.selected).length > 0;
  }

  checkCommentsRequired():boolean{
    var enableApply = true;
    if(this.showIndividualComments){
      var individualCommentRequiredPrograms = this.submittedOrRemovedAccrualPrograms.filter(sap => sap.selected);
      if(individualCommentRequiredPrograms.length > 0){
        enableApply = individualCommentRequiredPrograms.every(icrp => icrp.comment !='' && icrp.comment !=null);
      }  
    }
    else{
      var commentRequiredPrograms = this.submittedOrRemovedAccrualPrograms.filter(srap => srap.isCommentRequired || srap.selected);
      if(commentRequiredPrograms.length > 0){
        enableApply = commentRequiredPrograms.every(crp => crp.selected === true) && this.comment != '' && this.comment != undefined;
      }  
    }
    return enableApply;
  }

  onIndividualCommentsToggleChange($event: MatSlideToggleChange) {
   if(!$event.checked){
    this.dialogRef.updateSize('650px','550px');
  }
    else{
      switch (this.submittedOrRemovedAccrualPrograms.length) {
        case 2:
          this.dialogRef.updateSize('650px','600px');
          break;           
        default:
          this.dialogRef.updateSize('650px','680px');
          break;
      }
    }
  } 

  onApply(){    
    if(this.comment){
      this.submittedOrRemovedAccrualPrograms.forEach(sap => {
        if(sap.selected){
           sap.comment = this.comment;
        }
      })
    }
    this.dialogRef.close(this.data);
  }

  onCancel() {
    this.data['dataConfirm'] = false;
    this.dialogRef.close(this.data);
  }
}
