<div class="notification">
<mat-card class="notification-info" *ngFor="let n of modifiedResponse" [style.backgroundColor]="n.color">
    <span class="material-icons" title="Notifications">
      {{n.icon}}
      </span>
      <mat-card-content class="notification-desc">{{n.desc}}</mat-card-content>
  </mat-card>
</div>
<div class="main-cards">
  <mat-card class="card"> 
    <span>
    <mat-card-header>Action Items </mat-card-header> 
    <mat-icon title="Action Items" >playlist_add_check</mat-icon> 
    </span> 
  </mat-card>
  <mat-card class="card"> 
  <span class="help-line">
    <mat-card-header>Need Help?</mat-card-header>
    <mat-icon title="Need Help?">help</mat-icon>
  </span>  
  <mat-card-content>            
    <div div class="row">
      <label class="col-sm-12 col-lg-6"><b>Dan Devine</b></label>
    </div>
    <div div class="row">
      <label class="col-sm-12 col-lg-6"><b>Accruals, Programs & Routing</b></label>
      <label class="col-sm-12 col-lg-6"><b>+1 (317) 337-4982</b></label>
      <a class="a col-lg-12" href="mailto:daniel.devine@corteva.com" >daniel.devine@corteva.com</a>
    </div> 
    <br>  
    <div div class="row">
      <label class="col-sm-12 col-lg-6"><b>Ashley Paddack</b></label>
    </div>
    <div div class="row">
      <label class="col-sm-12 col-lg-6"><b>Marketing Programs Leader</b></label>
      <label class="col-sm-12 col-lg-6"><b>+1 (517) 614-9145</b></label>
      <a class="a col-lg-12" href="mailto:ashley.paddack@corteva.com" >ashley.paddack@corteva.com</a>
    </div>   
    <br>  
    <div div class="row">
      <label class="col-sm-12 col-lg-6"><b>Kari Arens</b></label>
    </div>
    <div div class="row">
      <label class="col-sm-12 col-lg-6"><b>Marketing Programs Financial Analyst</b></label>
      <label class="col-sm-12 col-lg-6"><b>+1 (317) 337-4865</b></label>
      <a class="a col-lg-12" href="mailto:kari.arens@corteva.com" >kari.arens@corteva.com</a>
    </div>       
  </mat-card-content>

  </mat-card>
  <mat-card class="card">
  <span> 
    <mat-card-header>Upcoming Events</mat-card-header>
    <mat-icon title="Upcoming Events">date_range</mat-icon>
  </span> 
  <mat-card-content>
  <div class="row">
  <mat-table [dataSource]="dataSource" *ngIf = "dataSource?.length > 0">
    <!-- Event Column -->
    <ng-container matColumnDef="eventName">
      <mat-header-cell *matHeaderCellDef> Event </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.shortDesc}} </mat-cell>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.beginDate | date: 'MM/dd/yyyy'}} </mat-cell>
    </ng-container>
      
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    
  </mat-table>
</div>
  <div *ngIf="dataSource?.length == 0">
    <label>No Upcoming Events</label>    
  </div>
</mat-card-content>
  </mat-card>
  <ngx-spinner class="spinner" size="default" type="ball-spin-clockwise">  
    <p>Please Wait.</p>  
    </ngx-spinner>
</div>