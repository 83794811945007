import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProgPortalContextService } from '../../core/services/progportalcontextservice';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpHeaders } from '@angular/common/http';
import { CoreRepository } from 'src/app/core/core.repository';
import { EmailService } from 'src/app/core/services/email/email.service';
import { EmailOptions } from 'src/app/core/services/email/models/email-options.model';
import { ActionMessages } from 'src/app/core/enums/emailEnums';



export interface AppUserDetails {
  userLogin: string
  lastName: string;
  firstName: string;
  email: string;
  active: boolean;
  appUserToFunctAreaAssoc: number[];
  appUserToRoleAssoc: number[];
  emailOptions:any;
}
export interface RFAData {
  userLogin: string;
}

@Component({
  selector: 'app-update-app-users',
  templateUrl: './update-app-users.component.html',
  styleUrls: ['./update-app-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class UpdateAppUsersComponent implements OnInit {
  appUserDetails: AppUserDetails;
  userDetails: AppUserDetails;
  roleList:any;
  areaList:any;
  userName: string;
  doaInactiveEmailOptions = new EmailOptions();
  actionMessages = ActionMessages;




  AppUsersForm = this.fb.group({
    UserLogin: [''],
    FirstName: [''],
    LastName: [''],
    Email: [''],
    Active: [''],
    AppUserToFunctAreaAssoc: [[]],
    AppUserToRoleAssoc: [[]],
  });

  constructor(public context: ProgPortalContextService, private fb: FormBuilder
    , private spinner: NgxSpinnerService,
    private matSnackBar: MatSnackBar,
    public emailService: EmailService,
    private core: CoreRepository,
    @Inject(MAT_DIALOG_DATA) public data: RFAData,
    public dialogRef: MatDialogRef<UpdateAppUsersComponent>) { }

  ngOnInit(): void {
    this.spinner.show();
    this.bindtabledata();
  }

  ngAfterViewInit() {
    this.binddropdowns();
  }
  get f() { return this.AppUsersForm.controls; }
  bindtabledata() {
    this.spinner.show();
    this.context.getdatafromService(`/api/Administration/List/AppUser/${this.data.userLogin}`).toPromise().then((response) => {
      if (response != null) {
        this.AppUsersForm.controls.UserLogin.setValue(response.userLogin);
        this.AppUsersForm.controls.FirstName.setValue(response.firstName);
        this.AppUsersForm.controls.LastName.setValue(response.lastName);
        this.AppUsersForm.controls.Email.setValue(response.email);
        this.AppUsersForm.controls.Active.setValue(response.active);
        this.AppUsersForm.controls.AppUserToFunctAreaAssoc.setValue(response.appUserToFunctAreaAssoc);
        this.AppUsersForm.controls.AppUserToRoleAssoc.setValue(response.appUserToRoleAssoc);
        this.spinner.hide();
      }
    });
  }

  binddropdowns()
  {


    this.context.getdatafromService('/api/Administration/List/Role').toPromise().then((response) => {
      if (response != null) {
        this.roleList = response;
      }
    });

    this.context.getdatafromService('/api/Administration/List/FunctionalArea').toPromise().then((response) => {
      if (response != null) {
        this.areaList = response;
      }
    });
  }

  onSubmit() 
  {
    this.spinner.show();
    this.buildInactiveDOAEmail() 
    this.userDetails = {

      userLogin: this.AppUsersForm.controls.UserLogin.value,
      firstName: this.AppUsersForm.controls.FirstName.value,
      lastName: this.AppUsersForm.controls.LastName.value,
      email: this.AppUsersForm.controls.Email.value,
      active: this.AppUsersForm.controls.Active.value,
      appUserToFunctAreaAssoc: this.AppUsersForm.controls.AppUserToFunctAreaAssoc.value,
      appUserToRoleAssoc: this.AppUsersForm.controls.AppUserToRoleAssoc.value,
      emailOptions: this.doaInactiveEmailOptions,
        };

    const headers = new HttpHeaders().set('content-type', 'application/json');
    this.context.postdatatoservice("/api/Administration/Table/UpdateUserDetails", JSON.stringify(this.userDetails), headers)
      .toPromise().then((response) => {
        if (response !== null) {
          this.openSuccessSnackBar('Updating User Info Successfully', 'x', 3000);
          this.dialogRef.close();
          this.spinner.hide();
        }

      },
        msg => { // Error
          this.openErrorSnackBar('Error in updating user', 'x', 3000);
          this.spinner.hide();
        })
      .catch(error => this.openErrorSnackBar(error.message, 'x', 3000));
      


  }

  buildInactiveDOAEmail() {
    var parentProgram = "";
    this.getUserName(this.core.getUserAccountName())
    var UserName = this.userName;
    var description = "Please remove and add a DOA User as needed"
    var nextSteps = "<li>Click on the link below to remove inactive DOA user and Add a new DOA user.</li>";
    var buttonLinkUrl = "programs/review-program/";
    var buttonText = "Go to Review page";
    var emailSubject = "has an inactive DOA in routing for approvals";
    var toAddress = [];
    var ccAddress = [this.core.getUsername()];

      this.doaInactiveEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._1, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress);

  }

  getUserName(userName) {
    var splitUserName = userName.split(',')
    this.userName = splitUserName.slice(1, 2) + " " + splitUserName.slice(0, 1)
  }
  openSuccessSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['success-snackbar'] });
  }

  openErrorSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['error-snackbar'] });
  }

}
