import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { AppComponent } from '../../app.component';
import { CoreRepository } from '../../core/core.repository';
import { ProgPortalContextService } from '../../core/services/progportalcontextservice';
import { AdminAppUser } from 'src/app/core/user/AdminAppUser';
import { MatDialog } from '@angular/material/dialog';
import { UpdateAppUsersComponent } from '../update-app-users/update-app-users.component';


@Component({
  selector: 'app-view-app-users',
  templateUrl: './view-app-users.component.html',
  styleUrls: ['./view-app-users.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ViewAppUsersComponent implements OnInit, OnDestroy {
  selectable = true;
  removable = true;
  showChip = false;
  SearchUser: any;
  searchUsers = new FormControl();
  userdropdowns = new UserDropdowns();
  adminAppUsers: AdminAppUser[];
  cancelSubject = new Subject();
  showAllUsers = new FormControl();
  showDOAUsers = new FormControl();
  userName:string;
  displayedColumns: string[] = [ 'userLogin','userName', 'lastName', 'firstName', 'email', 'isActive', 'isDOAUser'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('sort', { read: MatSort }) sort: MatSort;
  @ViewChild('appUsersTable') matTable: MatTable<AdminAppUser>;
  dataSource: MatTableDataSource<AdminAppUser>;
  appUserItems: AdminAppUser[] = [];

  constructor(public context: ProgPortalContextService,
    private spinner: NgxSpinnerService,
    public app: AppComponent,
    public core: CoreRepository,
    public dialog: MatDialog,) {
  }

  applyFilter() {
    this.showChip = true;
    const filterValue = this.SearchUser;    
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  removeFilter() {
    this.SearchUser = "";
    this.applyFilter();
    this.showChip = false;
  }

  ngOnInit(): void {
    this.spinner.show();
    this.bindtabledata();
  }

  statusChange() {
    var filteredAppUsers = this.appUserItems;

    this.userdropdowns.showAllUsers = this.showAllUsers.value ? this.showAllUsers.value : false;
    this.userdropdowns.showDOAUsers = this.showDOAUsers.value ? this.showDOAUsers.value : false;

    if (!this.userdropdowns.showAllUsers) {
      filteredAppUsers = filteredAppUsers.filter(x => x.active == true);
    }

    if (this.userdropdowns.showDOAUsers) {
      filteredAppUsers = filteredAppUsers.filter(x => x.isDOAUser == true);
    }

    this.dataSource = new MatTableDataSource(filteredAppUsers);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  bindtabledata() {
    this.spinner.show();
    this.appUserItems = [];
    this.context.getdatafromService("/api/Administration/List/AdminAppUsers")
      .toPromise().then((response) => {
        if (response != null) {
            this.appUserItems = response;
            this.statusChange();
          }
        });          
                    
        this.spinner.hide();

        // rerender table
        setTimeout(() => {
          this.matTable.renderRows();
        }, 1000);
  }

  navigateToUpdateAppUsers(userLogin)
  {
    const dialogRef = this.dialog.open(UpdateAppUsersComponent, {
      disableClose: true,
      height: '400px',
      width: '750px',
      data: { userLogin: userLogin}
    });
  }

  ngOnDestroy() {
    this.cancelSubject.next();
  }
}

export class UserDropdowns {
  showAllUsers : boolean;
  showDOAUsers : boolean;
}

