export enum FilterType {
    Advanced = 0,
    Basic = 1,
    Unknown = 2,
    IncludeExclude = 3,
    RelativeDate = 4,
    TopN = 5,
    Tuple = 6
  }

export enum ReportName {
    _1 = 'App Users',
    _2 = 'Program Accrual Rates',
    _3 = 'Net Unit Price',
    _4 = 'DOA Users',
    _5 = 'Programs Summary Report',
    _6 = 'Programs Email Notification',
    _7 = 'Programs Routing',
    _8 = 'Accrual Change Report',
    _9 = 'Crop Protection Accrual Spread',
    _10 = 'Crop Protection Vistex Rates',
    _11 = 'Phytogen Accrual Spread',
    _12 = 'Phytogen Vistex Rates'
}
