import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProgPortalContextService } from '../core/services/progportalcontextservice';
import { CoreRepository } from '../core/core.repository';

@Component({
  selector: 'app-user-impersonation',
  templateUrl: './user-impersonation.component.html',
  styleUrls: ['./user-impersonation.component.scss'],
})
export class UserImpersonationComponent implements OnInit {
  users: any[];
  filteredUsers: any[];
  allRoles: any[];
  searchUser: any;
  searchRole: any;
  showStopImpersonation: boolean;
  appUser: string;
  impersonatedUser: string;


  constructor(public context: ProgPortalContextService,
    private matSnackBar: MatSnackBar) {
      this.showStopImpersonation = localStorage.getItem("ImpersonatedUser") ? true : false;
    }

  ngOnInit(): void {
    this.prepopulateAllUsers();
  }

  applyFilter(){
    this.filteredUsers = this.users.filter(item => {
        return item.userName.toLowerCase().indexOf(this.searchUser.trim().toLowerCase()) > -1;
    });
  }

  roleChange(){
    this.filteredUsers = this.users.filter(item => {
      return item.role.includes(this.searchRole);
    });
  }
 
  prepopulateAllUsers() {
    this.context.getdatafromService(`/api/UserImpersonation/List/AppUser`).toPromise().then((response) => {
      if (response != null) {
        this.users = response;
        this.allRoles = response.map(item => item.role).flat().filter((value, index, self) => self.indexOf(value) === index);
        this.filteredUsers = response;
      }
    });
  }

  startOrStopImpersonation(impersonatedUserDetails, isImpersonated){
    var appUserDetails = JSON.parse(localStorage.getItem("AppUser"));
    this.appUser = appUserDetails.userName;
    if(isImpersonated && impersonatedUserDetails){
      localStorage.setItem("ImpersonatedUser", JSON.stringify(impersonatedUserDetails));
      this.impersonatedUser = impersonatedUserDetails.email;
    }else{
      var impersonatedUserDetails = JSON.parse(localStorage.getItem("ImpersonatedUser"));
      this.impersonatedUser = impersonatedUserDetails.email;
      localStorage.removeItem("ImpersonatedUser"); 
    }

    this.context.postdatatoservice(`/api/UserImpersonation/Table/ImpersonatedUser/${this.appUser}/${this.impersonatedUser}/${isImpersonated}`)
    .toPromise().then((response) => {
      if (response != null) {
        if(isImpersonated){
          this.openSuccessSnackBar('Start User impersonation successful.', 'x', 3000);
        }else{
          this.openSuccessSnackBar('Stop User impersonation successful.', 'x', 3000);
        }
        location.reload();
      }
    },
      msg => { // Error
        this.openErrorSnackBar('Error in User impersonation.Stop existing impersonation', 'x', 3000);
      })
    .catch(error => this.openErrorSnackBar(error.message, 'x', 3000));
       
  }


  openSuccessSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['success-snackbar'] });
  }

  openErrorSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['error-snackbar'] });
  } 

}
