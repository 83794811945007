<div *ngIf="canViewProgram()">
  <mat-toolbar class="top-toolbar">
    <mat-toolbar-row>
      <div>
        <button mat-raised-button class="functional-button" (click)="onBack();"> 
             <mat-icon title="Back">keyboard_arrow_left</mat-icon> Back
           </button>
       </div>
      <span class="header-page">{{!isChangeException || !isExistingChangeException ?'Edit Program':'Edit Change Exception'}}</span>
      <span class="header-page-spacer"></span>
      <div *ngIf="canEditProgram()">
        <a [routerLink]="'../../'"><button mat-raised-button class="functional-button">Cancel</button></a>
        <button mat-raised-button class="functional-button" [disabled]="!programForm.valid || (!programSaveDraft && !masterProgramSave)" (click)="validateTotalExposure(false,isChangeException);">{{(programSaveDraft && masterProgramSave)|| programSaveDraft || (statusDefinitionId === statusDefinitionIds.APPROVED && !masterProgramSave)?'Save Draft':'Save'}}</button>
        <span *ngIf="(companyCode !== companyCodeShortDesc._2)"><button
          *ngIf="(statusDefinitionId === statusDefinitionIds.ACCRUALS_COMPLETED && datesChangeStatusDefinitionId === statusDefinitionIds.ACCRUALS_COMPLETED && ((isChangeException &&  f.Explanation.value?.length > 0) || (!isChangeException))) || (buttonText && programTableType === programTableTypeIds.Program) || (statusDefinitionId === statusDefinitionIds.APPROVED && programTableType === programTableTypeIds.Program && !(masterProgramSave || programSaveDraft ))"
          mat-raised-button class="functional-button-dark" [disabled]="!programForm.valid ||(!programSaveAndRelease)"
          (click)="onSaveAndRelease(true)">Save and Release</button></span>
        <button mat-raised-button class="functional-button-dark" [disabled]="!programForm.valid ||(!programRouteforApproval && buttonText)||(!programSaveAndRelease && !buttonText)"
       *ngIf="validateRouteForApprovalSaveRelease()" (click)="validateTotalExposure_OnSubmitSaveAndReleaseOrRouteForApproval(false)"> {{buttonText || (statusDefinitionId === statusDefinitionIds.APPROVED && programTableType === programTableTypeIds.Program && !(masterProgramSave || programSaveDraft )) ? 'Route for Approval' : 'Save and Release'}}</button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="horizontal-scroll-wrapper">
    <table>
      <tr>        
        <td class="td-column">
          <div class="main-cards">
            <form [formGroup]="programForm">  
              <mat-card class="card">
                <span class="span">
                  <mat-icon class="matcard-icon" title="Program Details">assignment</mat-icon>
                  <mat-card-header>{{!isChangeException || !isExistingChangeException ?'Program Details':'Change Exception Details'}}</mat-card-header>
                </span>
                <mat-card-content>
                  <div class="row">
                    <mat-form-field appearance="fill" class="col-md-6" >
                      <mat-label>Program Name</mat-label>
                      <input matInput maxlength="50"
                        [ngClass]="{ 'is-invalid': (submitted && programForm.controls.ShortName.errors != null) || programForm.controls.ShortName.errors != null}"
                        formControlName="ShortName"(keyup)="formControlChange();" required>
                      <mat-hint>Max 50 characters!</mat-hint>
                      <mat-error
                        *ngIf="f.ShortName.touched && f.ShortName.errors && programForm.controls.ShortName.value?.length ==0">
                        Program Name is required!</mat-error>
                      <mat-error *ngIf="programForm.controls.ShortName.errors != null && programForm.controls.ShortName.value?.length !=0">Program Name already
                        exists!</mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-md-3 readonly">
                      <mat-label>{{!isChangeException || !isExistingChangeException?'Program Code':'Change Exception Code'}}</mat-label>
                      <input matInput formControlName="ProgramCode" readonly>
                    </mat-form-field>
                    <mat-form-field class="col-md-3 readonly">
                      <mat-label>Program Manager</mat-label>
                      <input matInput formControlName="ProgramManager" readonly>
                    </mat-form-field>
                  </div>
                  <br>
                  <div class="row">
                    <mat-form-field appearance="fill" class="col-md-6">
                      <mat-label>Consistent Program Name</mat-label>
                      <input type="text" placeholder="Search Program Name" aria-label="Number" matInput
                        formControlName="ConsistentName" [matAutocomplete]="consistentName" (keyup)="formControlChange();" [(ngModel)]="ConsistentName">
                      <mat-autocomplete autoActiveFirstOption #consistentName="matAutocomplete" (optionSelected)="formControlChange();" >
                        <mat-option *ngFor="let name of filteredconsistentNameList | async" [value]="name">
                          {{name}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="col-md-3 readonly">
                      <mat-label>{{!isChangeException || !isExistingChangeException?'Program Status':'Change Exception Status'}}</mat-label>
                      <input matInput formControlName="ProgramStatus" readonly>
                    </mat-form-field>
                  </div>
                  <br>
                  <div class="row" *ngIf="!isChangeException && statusDefinitionId !== statusDefinitionIds.APPROVED">
                    <mat-form-field appearance="fill" class="col-md-12">
                      <mat-label>Program Description</mat-label>
                      <textarea matInput [ngClass]="{ 'is-invalid': submitted && f.ProgramDesc.errors }"
                        formControlName="ProgramDesc" (keyup)="formControlChange();"></textarea>
                      <mat-error *ngIf="(f.ProgramDesc.value?.length == 0 || f.ProgramDesc.value === null )&& f.ProgramDesc.errors && f.ProgramDesc.errors.required">Program Description is required!</mat-error>
                    </mat-form-field>
                  </div>                  
                  <div class="row" *ngIf="(!isChangeException || isNewExplanation)&& !isExistingChangeException && statusDefinitionId === statusDefinitionIds.APPROVED">
                    <mat-form-field class="col-md-12  readonly">
                      <mat-label>Program Description</mat-label>
                      <textarea matInput formControlName="ProgramDesc" readonly></textarea>
                    </mat-form-field>
                  </div>
                  <div class="row" *ngIf="changeExceptionExplanation && (isExistingChangeException ||!isNewExplanation )&& statusDefinitionId === statusDefinitionIds.APPROVED">
                    <mat-form-field class="col-md-12 readonly">
                      <mat-label>Change Exception Explanation</mat-label>
                      <textarea matInput formControlName="ReadOnlyExplanation" readonly></textarea>
                    </mat-form-field>
                  </div>
                  <div class="row" *ngIf="statusDefinitionId === statusDefinitionIds.APPROVED">
                    <div class="col-md-12" >
                      <mat-checkbox class="new-explanation-checkbox" [checked]="isNewExplanation" (change)="onChangeNewExplanation($event)">New Explanation</mat-checkbox>
                    </div>
                  </div>   
                  <div class="row" *ngIf="changeExceptionExplanation && ((isNewExplanation && statusDefinitionId === statusDefinitionIds.APPROVED) || (!isNewExplanation && statusDefinitionId !== statusDefinitionIds.APPROVED)) ">
                    <mat-form-field appearance="fill" class="col-md-12">
                      <mat-label>{{isNewExplanation ?'New Change Exception Explanation':'Change Exception Explanation'}}</mat-label>
                      <textarea matInput rows="3" (keyup)="formControlChange();"
                        formControlName="Explanation"></textarea>
                    </mat-form-field>
                  </div>
                  <div *ngIf="selected != -1">
                    <br>
                    <div class="dash"></div>
                    <br>
                    <div class="row">
                      <section class="programTypeSection">
                        <mat-checkbox class="col-md-12" *ngIf="selected == 1" [checked]="selected == 1" disabled>This
                          program is externally accruing</mat-checkbox> <br>
                        <span *ngIf="showExternalProgramTypeOptions || selected == 1">
                          <mat-radio-group class="col-md-12" formControlName="ExternalAccruingProgramType" disabled>
                            <mat-radio-button *ngIf="selectedRadioBtn == 2" [checked]="selectedRadioBtn == 2"
                              value="RoutingOnly">Routing Only (Marketing program)</mat-radio-button>
                            <mat-radio-button *ngIf="selectedRadioBtn == 1" [checked]="selectedRadioBtn == 1"
                              value="ExpensePA">Expense P&A (Not a marketing program)</mat-radio-button>
                          </mat-radio-group>
                        </span>
                        <mat-checkbox class="col-md-12 " [checked]="selected == 2" *ngIf="selected == 2"
                          (change)="selected = 2;" disabled>This program accrues under another program</mat-checkbox>
                        <span *ngIf="showAccruesUnderAnotherProgramTypeOptions || selected == 2">
                            <mat-form-field class="accruesUnderAnotherProgramTypeOptionsText readonly">
                              <input matInput formControlName="ProgramTypeOptionsParent" readonly>
                            </mat-form-field>
                        </span>
                      </section>
                    </div>
                    </div>
                    <div>
                      <div *ngIf="changeExceptionProgramsList.length>0||(isChangeException && masterProgramCode !='')">
                        <br>
                        <div class="dash"></div>
                        <br>
                      </div>
                      <div class="row">
                        <div *ngIf="changeExceptionProgramsList.length>0" class="col-md-6">
                          <label>Change Exception List</label>
                          <div *ngFor="let changeExceptionProgram of changeExceptionProgramsList"> <a href="javascript:void(0);"
                              (click)="navigateToProgram(changeExceptionProgram.code, changeExceptionProgram.statusDefinitionId);">
                              {{changeExceptionProgram.code}} {{statusDefinitionShortDesc['_' +
                              changeExceptionProgram.statusDefinitionId]}} {{changeExceptionProgram.approvedDate}}</a> </div>
                        </div>
                        <div class="col-md-6" *ngIf="isChangeException && masterProgramCode !=''">
                          <label>Master Program</label>
                            <div> <a href="javascript:void(0);"
                                (click)="navigateToProgram(masterProgramCode, statusDefinitionIds.APPROVED);">
                                {{masterProgramCode}}</a> </div>
                          </div>
                      </div>
                  </div>
                  
                </mat-card-content>
              </mat-card>
              <mat-card class="card">
                <span class="span">
                  <mat-icon class="matcard-icon" title="Program Attributes">apartment</mat-icon>
                  <mat-card-header>Program Attributes </mat-card-header>
                </span>
                <mat-card-content>
                  <div class="row">
                    <mat-form-field class="col-md-6 readonly">
                      <mat-label>Company Name</mat-label>
                      <input matInput formControlName="CompanyCode" readonly>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="col-md-6">
                      <mat-label>Program Channel</mat-label>
                      <mat-select [ngClass]="{ 'is-invalid': submitted && f.Channel.errors }" formControlName="Channel" (selectionChange)="formControlChange();">
                        <mat-option *ngFor="let channel of channelList" [value]="channel.id">
                          {{channel.shortDesc}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="submitted && f.Channel.errors && f.Channel.errors.required">Program Channel is required!
                      </mat-error>
                    </mat-form-field>
                  </div> <br>
                  <div class="row">
                    <mat-form-field appearance="fill" class="col-md-6">
                      <mat-label>Business Segment</mat-label>
                      <mat-select [ngClass]="{ 'is-invalid': submitted && f.BusinessSegments.errors }"
                        formControlName="BusinessSegments" (selectionChange)="formControlChange();" multiple>
                        <ng-container *ngFor="let businessSeg of businessSegmentList">
                          <mat-option *ngIf="businessSeg.active == true || businessSeg.shortDesc == businessSegmentShortDesc._8" type="text"
                          [value]="businessSeg.id">
                          <span [innerHTML]="businessSeg.shortDesc"></span>
                        </mat-option>
                        </ng-container>                       
                      </mat-select>
                      <mat-error *ngIf="submitted && f.BusinessSegments.errors && f.BusinessSegments.errors.required">
                        Business Segment is required!</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="col-md-6">
                      <mat-label>Program Type</mat-label>
                      <mat-select [ngClass]="{ 'is-invalid': submitted && f.TypeDefinition.errors }"
                        formControlName="TypeDefinition" (selectionChange)="formControlChange();">
                        <mat-option *ngFor="let typeDefinition of programTypeList" [value]="typeDefinition.id">
                          {{typeDefinition.shortDesc}}
                        </mat-option>
                      </mat-select>
                        <mat-error *ngIf="submitted && f.TypeDefinition.errors && f.TypeDefinition.errors.required">Program Type is required!</mat-error>
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </mat-card>
              <mat-card class="card">
                <span class="span">
                  <mat-icon class="matcard-icon" title="Program Dates">date_range</mat-icon>
                  <mat-card-header>{{!isChangeException|| !isExistingChangeException ?'Program Dates':'Change Exception Dates'}}</mat-card-header>
                </span>
                <mat-card-content>
                  <div class="row">
                    <mat-form-field color="#1A81E1" appearance="fill" class="col-md-4">
                      <mat-label>Start Date</mat-label>
                      <input [min]="minStartDate" [max]="maxStartDate" [matDatepickerFilter]="onlyFirstDay" readonly
                        (dateChange)="startDateChange();" matInput [matDatepicker]="picker1"
                        [ngClass]="{ 'is-invalid': (submitted && f.BeginDate.errors) || f.BeginDate.errors }"
                        formControlName="BeginDate" required>
                      <mat-error *ngIf="f.BeginDate.errors?.required">Start Date is required!</mat-error>
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1 color="#1A81E1"></mat-datepicker>
                      <mat-error *ngIf="f.BeginDate.errors?.matDatepickerMin || f.BeginDate.errors?.matDatepickerMax">
                          Start Date is Invalid!</mat-error>
                    </mat-form-field>
                    <mat-form-field color="#1A81E1" appearance="fill" class="col-md-4">
                      <mat-label>End Date</mat-label>
                      <input matInput [min]="minEndDate" [max]="maxEndDate" [matDatepickerFilter]="onlyLastDay" readonly
                        (dateChange)="endDateChange()" [matDatepicker]="picker2"
                        [ngClass]="{ 'is-invalid': (submitted && f.EndDate.errors) || f.EndDate.errors }"
                        formControlName="EndDate" required>
                      <mat-error *ngIf="f.EndDate.errors?.required">End Date is required!</mat-error>
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-datepicker #picker2 color="#1A81E1"></mat-datepicker>
                      <mat-error *ngIf="f.EndDate.errors?.matDatepickerMin || f.EndDate.errors?.matDatepickerMax">End
                          Date is Invalid!</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="col-md-4">
                      <mat-label>Program Year</mat-label>
                      <mat-select [ngClass]="{ 'is-invalid': submitted && f.ProgramYear.errors,'changeExceptionProgramYear':(isChangeException || statusDefinitionId === statusDefinitionIds.APPROVED)}"  [disabled]="(isChangeException || statusDefinitionId === statusDefinitionIds.APPROVED)"
                        formControlName="ProgramYear" required (selectionChange)="formControlChange();">
                        <mat-option *ngFor="let yearValue of yearList" [value]="yearValue.id">
                          {{yearValue.shortDesc}}
                        </mat-option>
                      </mat-select>
                        <mat-error *ngIf="submitted && f.ProgramYear.errors && f.ProgramYear.errors.required">Program Year is required!</mat-error>
                      </mat-form-field>
                  </div>
                </mat-card-content>
              </mat-card>
              <!-- new implemented Financial card start -->
              <mat-card class="card">
                <span class="span">
                  <mat-icon class="matcard-icon" title="Financial Assumptions">account_balance</mat-icon>
                  <mat-card-header *ngIf="!showParentProgram">{{!isChangeException || !isExistingChangeException ?'Financial
                    Assumptions':'Change Exception Financial Assumptions'}}</mat-card-header>
                  <mat-card-header *ngIf="showParentProgram">Parent Program Financial Assumptions </mat-card-header>
                  <label class="lblparentprog" *ngIf="showParentProgram">The Parent program (<a [routerLink]="[]"
                      (click)="gotoParent()">{{parentProgramCode}} </a>) is in <b>{{parentProgramStatus}}</b>
                    status</label>
                </span>
                <div class="row top-row col-md-12">
                  <div class="col-md-5">
                    <mat-checkbox
                      class="{{isChangeException || statusDefinitionId === statusDefinitionIds.APPROVED?'changeexception-financial-override-checkbox':'financial-override-checkbox'}}"
                      (change)="onChangeUpdateTotalSalesAndTotalExposure($event)" formControlName="CalculatedTotals"
                      *ngIf="programTableType === programTableTypeIds.Program">Update Total Sales
                      and Total Exposure</mat-checkbox>
                  </div>
                  <div class="col-md-4" [hidden]="!(isStandardOrParentTypeProgram && !showParentProgram) || programTableType !== programTableTypeIds.Program || emailNotifications.length === 0">
                    <a [routerLink]="[]" (click)="getProductsAndAccrualsSummary()">Products and
                      Accruals Summary </a>
                    </div>
                    <div class="col-md-3">
                      <button mat-button *ngIf="isStandardOrParentTypeProgram && showNonAccruingProgramRefresh && !isCalculatedTotals"
                        class="functional-button-icon" title="Refresh Financial Assumptions" (click)="onRefreshFinancialAssumptions();">
                        <mat-icon title="Refresh Financial Assumptions">refresh</mat-icon> Refresh
                      </button>
                    </div>
                </div>
                <div class="col-md-12 financialcard-view">
                  <mat-card-content>                           
                    <div class="row first-row"
                      [ngClass]="{'green-border-color': statusDefinitionId === statusDefinitionIds.APPROVED,'orange-border-color': statusDefinitionId === statusDefinitionIds.ACCRUALS_PENDING || statusDefinitionId === statusDefinitionIds.ACCRUALS_COMPLETED || statusDefinitionId === statusDefinitionIds.APPROVAL_ROUTING || statusDefinitionId === statusDefinitionIds.DRAFT,'red-border-color': statusDefinitionId === statusDefinitionIds.CANCELED || statusDefinitionId === statusDefinitionIds.DENIED}">
                      <div class="col-md-4">                          
                        <mat-form-field
                          *ngIf="(isReadonly && !isChangeException) &&(statusDefinitionId === statusDefinitionIds.APPROVED || programTableType === programTableTypeIds.Program)"
                          class="readonly col-md-12  padding-0">
                          <mat-label> {{isChangeException || statusDefinitionId === statusDefinitionIds.APPROVED?
                            'Total Sales (Local Currency)':'Total Sales (Local Currency)'}}</mat-label>
                          <input (keypress)="restrictChar($event);" maxlength="13" type="text" (keyup)="formControlChange();"
                          [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput formControlName="TotalSales" readonly >
                            <span matPrefix>&nbsp;&nbsp;$&nbsp;</span>
                        </mat-form-field>
                        <mat-form-field
                          *ngIf="programTableType === programTableTypeIds.ExternalAccruingProgram" class="col-md-12  padding-0" appearance="fill">
                          <mat-label>Total Sales (Local Currency)</mat-label>
                          <input (keypress)="restrictChar($event);" maxlength="13" type="text" (keyup)="formControlChange();"
                            [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput formControlName="TotalSales" >
                          <span matPrefix>$&nbsp;</span>                           
                        </mat-form-field>
                      
                          <mat-form-field  *ngIf="(!isReadonly && isChangeException) && (programTableType === programTableTypeIds.Program  && statusDefinitionId === statusDefinitionIds.APPROVED)" class="col-md-12  padding-0" appearance="fill">
                            <mat-label>Total Sales (Local Currency)</mat-label>
                            <input (keypress)="restrictChar($event);" maxlength="13" type="text" (keyup)="formControlChange();"
                              [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput formControlName="TotalSales"
                              [ngClass]="{ 'is-invalid': submitted && f.TotalSales.errors }">
                            <span matPrefix>$&nbsp;</span>
                              <mat-error *ngIf="submitted && f.TotalSales.errors && f.TotalSales.errors.required">Total Sales is required!</mat-error>
                          </mat-form-field>
                          <mat-form-field  *ngIf="(!isReadonly && !isChangeException) && (programTableType === programTableTypeIds.Program && statusDefinitionId === statusDefinitionIds.APPROVED)" class="col-md-12  padding-0" appearance="fill">
                            <mat-label>Total Sales (Local Currency)</mat-label>
                            <input (keypress)="restrictChar($event);" maxlength="13" type="text" (keyup)="formControlChange();"
                              [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput formControlName="TotalSales"
                              [ngClass]="{ 'is-invalid': submitted && f.TotalSales.errors }">
                            <span matPrefix>$&nbsp;</span>
                              <mat-error *ngIf="submitted && f.TotalSales.errors && f.TotalSales.errors.required">Total Sales is required!</mat-error>
                          </mat-form-field>
                          <mat-form-field  *ngIf="(!isReadonly && !isChangeException) && (programTableType === programTableTypeIds.Program && statusDefinitionId !== statusDefinitionIds.APPROVED)" class="col-md-12  padding-0" appearance="fill">
                            <mat-label>Total Sales (Local Currency)</mat-label>
                            <input (keypress)="restrictChar($event);" maxlength="13" type="text" (keyup)="formControlChange();"
                              [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput formControlName="TotalSales"
                              [ngClass]="{ 'is-invalid': submitted && f.TotalSales.errors }">
                            <span matPrefix>$&nbsp;</span>
                              <mat-error *ngIf="submitted && f.TotalSales.errors && f.TotalSales.errors.required">Total Sales is required!</mat-error>
                          </mat-form-field>

                          <mat-form-field  *ngIf="programTableType === programTableTypeIds.NonAccruingProgram"  class="readonly col-md-12  padding-0" >
                            <mat-label>Total Sales (Local Currency)</mat-label>
                            <input matInput formControlName="TotalSales" [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" readonly>
                            <span matPrefix>$&nbsp;</span>
                          </mat-form-field>
                          <mat-form-field  *ngIf="(!isReadonly && isChangeException) && (programTableType === programTableTypeIds.Program  && statusDefinitionId !== statusDefinitionIds.APPROVED)" class="col-md-12  padding-0" appearance="fill">
                            <mat-label>Total Sales (Local Currency)</mat-label>
                            <input (keypress)="restrictChar($event);" maxlength="13" type="text" (keyup)="formControlChange();"
                              [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput formControlName="TotalSales"
                              [ngClass]="{ 'is-invalid': submitted && f.TotalSales.errors }">
                            <span matPrefix>$&nbsp;</span>
                              <mat-error *ngIf="submitted && f.TotalSales.errors && f.TotalSales.errors.required">Total Sales is required!</mat-error>
                          </mat-form-field>
                           <mat-form-field  *ngIf="(isReadonly && isChangeException) && (programTableType === programTableTypeIds.Program || statusDefinitionId === statusDefinitionIds.APPROVED || programTableType === programTableTypeIds.NonAccruingProgram )" class="readonly col-md-12  padding-0" >
                            <mat-label>Total Sales (Local Currency)</mat-label>
                            <input matInput formControlName="TotalSales" [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" readonly>
                            <span matPrefix>$&nbsp;</span>
                          </mat-form-field>
                      </div>
                      <div class="col-md-4">
                        <mat-form-field
                        *ngIf="programTableType === programTableTypeIds.NonAccruingProgram"
                        class="readonly col-md-12  padding-0">
                        <mat-label>{{isChangeException || statusDefinitionId === statusDefinitionIds.APPROVED?
                          'Total Exposure (Local Currency)':'Total Exposure (Local Currency)'}}</mat-label>
                        <input matInput formControlName="TotalExposure"
                          [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" readonly>
                        <span matPrefix>$&nbsp;</span>
                      </mat-form-field>
                      <mat-form-field  *ngIf="((!isReadonly && isChangeException) && (programTableType === programTableTypeIds.Program && statusDefinitionId === statusDefinitionIds.APPROVED))" class="col-md-12  padding-0" appearance="fill">
                        <mat-label> Total Exposure (Local Currency)</mat-label>
                        <input (keypress)="restrictChar($event);" maxlength="11" type="text"
                          [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput formControlName="TotalExposure"
                          [ngClass]="{ 'is-invalid': submitted && f.TotalExposure.errors }" (keyup)="formControlChange();" >
                        <span matPrefix>$&nbsp;</span>
                          <mat-error *ngIf="submitted && f.TotalExposure.errors && f.TotalExposure.errors.required">Total Exposure is required!</mat-error>
                      </mat-form-field>
                      <mat-form-field  *ngIf="((!isReadonly && isChangeException) && (programTableType === programTableTypeIds.Program && statusDefinitionId !== statusDefinitionIds.APPROVED))" class="col-md-12  padding-0" appearance="fill">
                        <mat-label> Total Exposure (Local Currency)</mat-label>
                        <input (keypress)="restrictChar($event);" maxlength="11" type="text"
                          [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput formControlName="TotalExposure"
                          [ngClass]="{ 'is-invalid': submitted && f.TotalExposure.errors }" (keyup)="formControlChange();" >
                        <span matPrefix>$&nbsp;</span>
                          <mat-error *ngIf="submitted && f.TotalExposure.errors && f.TotalExposure.errors.required">Total Exposure is required!</mat-error>
                      </mat-form-field>

                      <mat-form-field *ngIf="programTableType === programTableTypeIds.ExternalAccruingProgram" class="col-md-12  padding-0" appearance="fill">
                        <mat-label>Total Exposure (Local Currency)</mat-label>
                        <input (keypress)="restrictChar($event);" maxlength="11" type="text"
                          [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput formControlName="TotalExposure"
                           (keyup)="formControlChange();" >
                        <span matPrefix>$&nbsp;</span>
                          
                      </mat-form-field>
                        <mat-form-field *ngIf="(!isReadonly && !isChangeException) && (programTableType === programTableTypeIds.Program && statusDefinitionId !== statusDefinitionIds.APPROVED )" class="col-md-12  padding-0" appearance="fill">
                          <mat-label>Total Exposure (Local Currency)</mat-label>
                          <input (keypress)="restrictChar($event);" maxlength="11" type="text"
                            [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput formControlName="TotalExposure"
                            [ngClass]="{ 'is-invalid': submitted && f.TotalExposure.errors }" (keyup)="formControlChange();" >
                          <span matPrefix>$&nbsp;</span>
                            <mat-error *ngIf="submitted && f.TotalExposure.errors && f.TotalExposure.errors.required">Total Exposure is required!</mat-error>
                        </mat-form-field>
                        <mat-form-field *ngIf="(!isReadonly && !isChangeException) && (programTableType === programTableTypeIds.Program && statusDefinitionId === statusDefinitionIds.APPROVED )" class="col-md-12  padding-0" appearance="fill">
                          <mat-label>Total Exposure (Local Currency)</mat-label>
                          <input (keypress)="restrictChar($event);" maxlength="11" type="text"
                            [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput formControlName="TotalExposure"
                            [ngClass]="{ 'is-invalid': submitted && f.TotalExposure.errors }" (keyup)="formControlChange();" >
                          <span matPrefix>$&nbsp;</span>
                            <mat-error *ngIf="submitted && f.TotalExposure.errors && f.TotalExposure.errors.required">Total Exposure is required!</mat-error>
                        </mat-form-field>
                        <mat-form-field *ngIf="(isReadonly && !isChangeException) && (programTableType === programTableTypeIds.Program || statusDefinitionId === statusDefinitionIds.APPROVED)"  class="readonly col-md-12  padding-0" >
                          <mat-label>Total Exposure (Local Currency)</mat-label>
                          <input matInput formControlName="TotalExposure"
                            [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}"  readonly>
                          <span matPrefix>$&nbsp;</span>
                        </mat-form-field>

                        <mat-form-field *ngIf="(isReadonly && isChangeException) && (programTableType === programTableTypeIds.Program  || statusDefinitionId === statusDefinitionIds.APPROVED)"  class="readonly col-md-12  padding-0" >
                          <mat-label>Total Exposure (Local Currency)</mat-label>
                          <input matInput formControlName="TotalExposure"
                            [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}"  readonly>
                          <span matPrefix>$&nbsp;</span>
                        </mat-form-field>
                      </div>
                      <div class="col-md-4">         
                        <mat-form-field *ngIf="isReadonly && (isChangeException || statusDefinitionId === statusDefinitionIds.APPROVED)"               
                          class="readonly">
                          <mat-label>Total Exposure Difference</mat-label>
                          <input matInput formControlName="DifferenceTotalExposure"
                            [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}"readonly>
                          <span matPrefix>$&nbsp;</span>
                        </mat-form-field>    
                        <mat-form-field *ngIf="!isReadonly && (isChangeException || statusDefinitionId === statusDefinitionIds.APPROVED)"               
                          class="col-md-12  padding-0" appearance="fill">
                          <mat-label>Total Exposure Difference</mat-label>
                          <input (keypress)="restrictDifferenceChar($event);" maxlength="11" type="text"
                            matInput formControlName="DifferenceTotalExposure"
                            [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" [ngClass]="{ 'is-invalid': submitted && f.DifferenceTotalExposure.errors }" (keyup)="formControlChange();" >
                          <span matPrefix>$&nbsp;</span>
                            <mat-error *ngIf="submitted && f.DifferenceTotalExposure.errors && f.DifferenceTotalExposure.errors.required">Difference Exposure is required!</mat-error>
                        </mat-form-field>                     
                      </div>
                    </div>
                    <div class="row child-row"*ngFor="let item of totalSalesAndTotalExposureList; let i=index;"
                      [ngClass]="{'green-border-color': item.status === statusDefinitionIds.APPROVED,'orange-border-color': item.status === statusDefinitionIds.ACCRUALS_PENDING || item.status === statusDefinitionIds.ACCRUALS_COMPLETED || item.status === statusDefinitionIds.APPROVAL_ROUTING || item.status === statusDefinitionIds.DRAFT,'red-border-color': item.status === statusDefinitionIds.CANCELED || item.status === statusDefinitionIds.DENIED}">
                      <div class="col-md-4">
                        <div class="row">
                          <mat-label class="child-labels ">Total Sales (Local Currency)</mat-label>
                        </div>
                        <div class="row">
                          <mat-label class="childcurrency">{{item.totalSales | currency: 'USD':'symbol':'1.0-0'}}</mat-label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="row">
                          <mat-label class="child-labels exposurealignment">Total Exposure (Local Currency)</mat-label>
                        </div>
                        <div class="row">
                          <mat-label  class="childcurrency lblexposure">{{item.totalExposure | currency: 'USD':'symbol':'1.0-0'}}</mat-label>
                        </div>
              
                      </div>                      
                      <div class="col-md-5">
                        <mat-label>{{item.programType}}-</mat-label>
                        <mat-label>{{item.programCode}}</mat-label>
                      </div>
                    </div>
                  </mat-card-content>
                </div>
                <mat-card-content>
                  <div [hidden]="!(isStandardOrParentTypeProgram && !showParentProgram) || programTableType !== programTableTypeIds.Program || emailNotifications.length === 0">
                    <div class="dash" *ngIf="emailNotifications.length > 0">
                    </div>
                    <br>
                    <div class="matgrid-div" [hidden]="emailNotifications.length === 0">
                      <div class="table-container">
                        <table mat-table class="mat-grid" [dataSource]="dataSourceEmailNotifications" #sort="matSort"
                          matSort>

                          <ng-container matColumnDef="productManager">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> PRODUCT MANAGER </th>
                            <td mat-cell *matCellDef="let element"> {{ element.productManager }} </td>
                          </ng-container>

                          <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>EMAIL</th>
                            <td mat-cell *matCellDef="let element"><a href='mailto: {{ element.email }}'> {{
                                element.email }} </a> </td>
                          </ng-container>

                          <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> STATUS </th>
                            <td mat-cell *matCellDef="let element"> {{ element.status }} </td>
                          </ng-container>

                          <ng-container matColumnDef="daysOldSinceEmailNotification">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> DAYS OLD SINCE EMAIL NOTIFICATION
                            </th>
                            <td mat-cell class="mat-cell-align-center" *matCellDef="let element"> {{
                              element.daysOldSinceEmailNotification }} </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                          </tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                        </table>
                      </div>
                      <div>
                        <mat-paginator #paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons
                          [hidden]="emailNotifications.length === 0"></mat-paginator>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
              <mat-card class="card">
                <span class="span">
                  <mat-icon class="matcard-icon" title="Attachments">attach_file</mat-icon>
                  <mat-card-header>{{!isChangeException || !isExistingChangeException ?'Attachments':'Change Exception Attachments'}}</mat-card-header>
                </span>
                <mat-card-content>
                  <div *ngIf="childProgramCodes && childProgramCodes.length > 0">
                    <div>
                      View file attachments for the child programs
                      (<span *ngFor="let childProgramCode of childProgramCodes; let isLast=last">
                        <a [routerLink]="[]"
                          (click)="navigateToProgram(childProgramCode.code, childProgramCode.statusDefinitionId);">
                          {{childProgramCode.code}}{{isLast ? '' : ', '}}
                        </a>
                      </span>)
                    </div>
                    <br>
                    <div class="dash"></div>
                    <br>
                  </div>
                  <div *ngIf="parentProgramFileDetailsList && parentProgramFileDetailsList.length>0">
                    <span>View file attachments below for the parent program (<a [routerLink]="[]"
                        (click)="navigateToProgram(parentProgramCode,parentProgramStatus);">{{ parentProgramCode
                        }}</a>).</span>
                    <table class="attachment-table">
                      <tr>
                        <th>File Name</th>
                        <th>Last Modified Date</th>
                      </tr>
                      <tbody>
                      <tr *ngFor="let file of parentProgramFileDetailsList" class="fileList">                       
                        <td>
                          <a [routerLink]="[]" (click)="downloadFile(parentProgramCode,file.fileName);">{{file.fileName}}</a>
                        </td>
                        <td>
                          {{file.fileLastModified | date: 'MM/dd/yyyy'}}
                        </td>
                      </tr>
                      </tbody>
                    </table>                    
                  </div>                  
                  <div class="dash" *ngIf="parentProgramFileDetailsList && parentProgramFileDetailsList.length>0"></div>                  
                  <table class="attachment-table" *ngIf="(fileDetailsList.length > 0)">
                    <tr>
                      <th>File Name</th>
                      <th>Last Modified Date</th>
                    </tr>
                    <tbody>
                    <tr *ngFor="let file of fileDetailsList" class="fileList">
                      <td>
                        <a [routerLink]="[]" (click)="downloadFile(progCode,file.fileName);">{{file.fileName}}</a>
                        <a *ngIf="statusDefinitionId !== statusDefinitionIds.APPROVED" (click)="cancelFile(file)">
                          <mat-icon>cancel</mat-icon>
                        </a>
                      </td>
                      <td>
                        {{file.fileLastModified | date: 'MM/dd/yyyy'}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <br>                  
                  <app-file-upload formControlName="FileUpload" (change) ="checkAttachmentFilesChange($event)" ></app-file-upload>
                </mat-card-content>
              </mat-card>
            </form>
            
          </div>
        </td>
        <td class="td-history-approvals">
          <div class="position-relative" *ngIf="showHistoryTab">
            <mat-card class="sub-cards">
              <mat-tab-group class="mat-stretched-tabs">
                <mat-tab>
                  <ng-template mat-tab-label>
                    Approvals
                    <!-- <mat-chip class="count">{{approvalsCount}}</mat-chip> -->
                  </ng-template>
                <mat-card-content *ngIf="doaApprovers.length==0" 
                class=" mat-card-content-padding mat-card-align-notext">
                  <p>No Approvals.</p>
                </mat-card-content>
                <mat-card-content class="mat-card-content-padding mat-card-align" *ngIf="doaApprovers.length>0">
                <div  class="mat-card-scroll-approvals">
                  <mat-list role="list"  *ngIf="statusDefinitionId === statusDefinitionIds.APPROVED || routingCanceledDate == null" >
                    <mat-list-item role="listitem" class="text-bold mat-list-item-routing">Routing Started</mat-list-item>
                    <mat-list-item role="listitem" class="mat-list-item-routing" *ngIf="routingStartDate != null">{{routingStartDate  | date: 'MMMM d, y'}} </mat-list-item>
                  </mat-list>

                  <mat-grid-list cols="4" rowHeight="5:1" *ngIf="statusDefinitionId != statusDefinitionIds.APPROVED && routingCanceledDate != null">
                    <mat-grid-tile [colspan]="2" [rowspan]="1" class="text-bold mat-list-item-routing" *ngIf="routingStartDate != null">
                      Routing Started
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1"  class="text-bold mat-list-item-routing" *ngIf="routingCanceledDate != null">
                      Canceled/Restart
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1" class="doaApprover-notificationDate" *ngIf="routingStartDate != null">
                      {{routingStartDate  | date: 'MMMM d, y'}}
                    </mat-grid-tile>    
                    <mat-grid-tile [colspan]="2" [rowspan]="1" *ngIf="routingCanceledDate != null">
                      {{routingCanceledDate  | date: 'MMMM d, y'}}
                    </mat-grid-tile>                        
                  </mat-grid-list>

                  <mat-divider></mat-divider>
                  <div class="bullet-list">
                    <ol>
                      <li *ngFor="let doaApprover of doaApprovers" class="list-padding-top ">
                        <mat-grid-list cols="4" rowHeight="5:1">
                          <mat-grid-tile *ngIf="doaApprover.active" [colspan]="2" [rowspan]="1" class="text-bold ">
                            {{doaApprover.userName}}
                          </mat-grid-tile>
                          <mat-grid-tile *ngIf="!doaApprover.active" [colspan]="2" [rowspan]="1" class="text-bold-inactive">
                            {{doaApprover.userName}}
                          </mat-grid-tile>
                          <mat-grid-tile [colspan]="2" [rowspan]="2">
                            <mat-chip-list>
                              <mat-chip
                                *ngIf="doaApprover.statusDefinitionId === statusDefinitionIds.APPROVED && !doaApprover.adminStatusUpdate"
                                class="mat-chip-approved mat-chip-list-stacked">
                                Approved {{ doaApprover.dateCompleted | date: 'M/d/yyyy'}}
                              </mat-chip>
                              <mat-chip
                                *ngIf="doaApprover.statusDefinitionId === statusDefinitionIds.APPROVED && doaApprover.adminStatusUpdate"
                                class="mat-chip-approved mat-chip-list-stacked admin">Admin Approved {{
                                doaApprover.dateCompleted |
                                date: 'M/d/yyyy'}}
                              </mat-chip>
                              <mat-chip
                                *ngIf="doaApprover.statusDefinitionId === statusDefinitionIds.DENIED && !doaApprover.adminStatusUpdate"
                                class="mat-chip-denied">
                                Denied {{ doaApprover.dateCompleted | date: 'M/d/yyyy'}}
                              </mat-chip>
                              <mat-chip
                                *ngIf="doaApprover.statusDefinitionId === statusDefinitionIds.DENIED && doaApprover.adminStatusUpdate"
                                class="mat-chip-denied admin">Admin Denied {{ doaApprover.dateCompleted | date:
                                'M/d/yyyy'}}
                              </mat-chip>
                              <mat-chip
                                *ngIf="doaApprover.statusDefinitionId == null && statusDefinitionId != statusDefinitionIds.DENIED && doaApprover.approver"
                                class="mat-chip-pending">
                                Pending
                              </mat-chip>
                              <mat-chip class="mat-chip-reviewers mat-chip-list-stacked" *ngIf="!doaApprover.approver">Reviewer</mat-chip>
                            </mat-chip-list>
                          </mat-grid-tile>
                          <mat-grid-tile [colspan]="2" [rowspan]="1">
                            <div mat-line class="doaApprover-role">{{doaApprover.doaRole}}</div>
                          </mat-grid-tile>
                          <mat-grid-tile [colspan]="2" [rowspan]="1">
                            <div mat-line class="doaApprover-notificationDate">{{doaApprover.emailNotificationInsertDate | date: 'MMMM d,
                              y'}}</div>
                          </mat-grid-tile>
                          <mat-grid-tile [colspan]="2" [rowspan]="1"
                            *ngIf="doaApprover.daysWaiting != null && doaApprover.approver && doaApprover.statusDefinitionId != statusDefinitionIds.APPROVED && statusDefinitionId != statusDefinitionIds.DENIED">
                            <div class="doaApprover-notificationDate"> Days Waiting : &nbsp;</div>
                            <div class="text-bold ">{{doaApprover.daysWaiting}}</div>
                          </mat-grid-tile>
                        </mat-grid-list>
                        <mat-divider *ngIf="doaApprover.approvalOrder != doaMaxApprovalOrder"></mat-divider>
                      </li>
                    </ol>
                  </div>
                  </div>
                  </mat-card-content>
                </mat-tab>
                <mat-tab> 
                  <ng-template mat-tab-label>
                    History
                  </ng-template>
                  <mat-card-content *ngIf="statusesList.length==0" class=" mat-card-content-padding mat-card-align-notext">
                    <mat-list role="list" *ngIf="createdDate != null && !isChangeException">
                      <mat-list-item role="listitem" class="text-bold mat-list-item-routing">Program Created
                      </mat-list-item>
                      <mat-list-item role="listitem" class="mat-list-item-routing"> {{createdDate | date: 'MMMM d, y
                        h:mm:ss a'}} </mat-list-item>
                    </mat-list>
                    <mat-list role="list" *ngIf="changeExceptionCreatedDate != null && isChangeException">
                      <mat-list-item role="listitem" class="text-bold mat-list-item-routing">Change Exception Program Created
                      </mat-list-item>
                      <mat-list-item role="listitem" class="mat-list-item-routing"> {{changeExceptionCreatedDate | date: 'MMMM d, y
                        h:mm:ss a'}} </mat-list-item>
                    </mat-list>
                    <mat-divider></mat-divider><br>
                    <p>No History.</p>
                  </mat-card-content>
                  <mat-card-content class="mat-card-align mat-card-content-padding mat-card-scroll-history" >
                  <div *ngIf="statusesList.length>0">
                    <ul class="status-history">
                      <mat-list role="list" *ngIf="createdDate != null && !isChangeException">
                        <mat-list-item role="listitem" class="text-bold mat-list-item-routing">Program Created</mat-list-item>
                        <mat-list-item role="listitem" class="mat-list-item-routing"> {{createdDate | date: 'MMMM d, y h:mm:ss a'}} </mat-list-item>
                      </mat-list>
                      <mat-list role="list" *ngIf="changeExceptionCreatedDate != null && isChangeException">
                        <mat-list-item role="listitem" class="text-bold mat-list-item-routing">Change Exception Program Created</mat-list-item>
                        <mat-list-item role="listitem" class="mat-list-item-routing"> {{changeExceptionCreatedDate | date: 'MMMM d, y h:mm:ss a'}} </mat-list-item>
                      </mat-list>
                      <mat-divider></mat-divider><br>
                      <li *ngFor="let statusItem of statusesList" class="status-history-list">
                        <mat-grid-list cols="2" rowHeight="8:1">
                          <mat-grid-tile [colspan]="2" [rowspan]="1" class="text-bold">
                            {{statusItem.statusDefinition}}
                          </mat-grid-tile>
                          <mat-grid-tile [colspan]="2" [rowspan]="1">
                            <div mat-line class="status-username-role">{{statusItem.appUser}}, {{statusItem.role}}</div>
                          </mat-grid-tile>
                          <mat-grid-tile [colspan]="2" [rowspan]="1">
                            <div mat-line class="status-insertDate">{{statusItem.insertDate | date: 'MMMM d, y h:mm:ss a'}}</div>
                          </mat-grid-tile>
                        </mat-grid-list>
                      </li>
                    </ul>
                  </div>  
                  </mat-card-content>          
                </mat-tab>
              </mat-tab-group>
            </mat-card>

            <mat-card class="sub-cards message-card-padding">
              <mat-list class="comments-board-alignment">
                <mat-grid-list cols="5" rowHeight="2:1">
                  <mat-grid-tile [colspan]="3" [rowspan]="1">
                    <div class="post-header list-item-height">Comments Board</div>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="2" [rowspan]="1">
                    <div class="{{ showPostButton ?'buttons-alignment':'mat-icon-expand-padding'}}">
                      <button mat-raised-button class="mat-icon-expand " (click)="openAllPanels()" matTooltip="Expand Comments"
                      matTooltipPosition="above" *ngIf="showExpandButton ">
                        <mat-icon>expand_more</mat-icon>
                      </button>
                      <button mat-raised-button class="mat-icon-expand" (click)="closeAllPanels()" matTooltip="Collapse Comments"
                      matTooltipPosition="above" *ngIf="showExpandButton ">
                        <mat-icon>expand_less</mat-icon>
                      </button> 
                      </div>                  
                      <button mat-raised-button class="functional-button-dark  post-button-alignment-right" (click)="postMessage();"
                        *ngIf="showPostButton">
                        <mat-icon class="icon-fontsize"> chat</mat-icon>Post
                      </button>                      
                  </mat-grid-tile>
                </mat-grid-list>
                <mat-divider></mat-divider>
                <mat-card-content *ngIf="messageList.length==0" class="mat-card-align">
                  <p>No Comments.</p>
                </mat-card-content>
                <mat-card-content class="mat-card-content-overflow" *ngIf="messageList.length>0">
                  <div class="message-card">
                    <mat-accordion [multi]="true" #accordion="matAccordion" class="headers-align">
                      <mat-expansion-panel class="mat-accordion-margin" *ngFor="let message of messageList"
                                           #mapanel="matExpansionPanel">

                        <mat-expansion-panel-header class="{{ comapreDate(routingStartDate,message.insertDate) ?'mat-accordion-panel-header-routeCanceled':'mat-accordion-panel-header'}}">
                          <mat-panel-title class="mat-panel-title-name">{{message.updateBy}}</mat-panel-title>
                          <mat-panel-title class="mat-panel-title-role">{{message.doaRole}}
                          </mat-panel-title>
                          <mat-panel-title class="mat-panel-title-date">{{message.insertDate | date: 'MMMM d, y'}}
                          </mat-panel-title>
                          <mat-icon *ngIf="(message.statusDefinitionId == statusDefinitionIds.APPROVED) ||(message.statusDefinitionId ==statusDefinitionIds.DENIED )" class="{{message.statusDefinitionId == statusDefinitionIds.APPROVED ? 'mat-icon-green' :'mat-icon-red'}}"> chat</mat-icon>
                          <mat-icon *ngIf="(message.statusDefinitionId != statusDefinitionIds.APPROVED) && (message.statusDefinitionId !=statusDefinitionIds.DENIED )" class="{{ comapreDate(routingStartDate,message.insertDate) ?'mat-icon-grey':'mat-icon-white'}}"> chat</mat-icon>                       
                        </mat-expansion-panel-header>
                        <p class="{{ comapreDate(routingStartDate,message.insertDate) ?'mat-accordion-panel-header-routeCanceled':'mat-accordion-panel-header'}} p-word-break">
                          {{message.message}}
                        </p>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </mat-card-content>
              </mat-list>
            </mat-card>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <ngx-spinner class="spinner" size="default" type="ball-spin-clockwise">
    <p>Please Wait.</p>
  </ngx-spinner>
</div>
