import { Component, Inject, Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogAccrualsErrorAndWarningDetailsComponent, AccrualsHierarchyErrorAndWarnings } from 'src/app/accruals/dialog-accruals-error-warning-details/dialog-accruals-error-warning-details.component';
import { UrlService } from 'src/app/core/services/url-service.service';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';

export interface Messages {
  messageId: number;
  message: string;
  error: boolean;
}

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-dialog-warning-message',
  templateUrl: './dialog-warning-message.component.html',
  styleUrls: ['./dialog-warning-message.component.scss']
})
export class DialogWarningMessageComponent {
  messages: Messages[] = [];
  continueText: string = '';
  isHavingErrors: boolean = false;
  appUsers: any[] = [];
  showDetails: boolean = false;
  accrualsHierarchyErrorAndWarnings: AccrualsHierarchyErrorAndWarnings[] = [];
  accrualMasterHierarchySelectedLevel : number;
  showErrorResolveMessage: boolean;
  progCode: any;
  previousUrl: string = "";
  currentUrl: string = "";
  replicateProgram:boolean=false;

  constructor(private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<DialogWarningMessageComponent>,
    public dialog: MatDialog,
    private urlService: UrlService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.messages = data['dataMessage'];
    this.continueText = data['dataContinueText'];
    this.appUsers = data['dataAppUsers'];
    this.progCode = data['progCode'];
    if(this.progCode != undefined){
      this.replicateProgram = true;
    }
    data['dataConfirm'] = true;
    this.showDetails = data['dataShowDetails'];
    this.accrualsHierarchyErrorAndWarnings = data['dataProgramsAccrualsErrorAndWarningProducts'];
    this.accrualMasterHierarchySelectedLevel = data['dataMasterSelectedHierarchyLevel'];
    this.showErrorResolveMessage = data["dataShowErrorResolveMessage"];
    if(this.messages != undefined){
    this.messages = this.messages.sort(function (t, f) { return Number(t.error) - Number(f.error) }).reverse();
    if (this.messages.some(element => element.error)) {
      this.isHavingErrors = true;
    }
  }
    dialogRef.disableClose = true;
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.urlService.setPreviousUrl(this.previousUrl);
      };
    });
  }
  ngOnInit() {   
    this.urlService.setProgCode( this.progCode)
  }

  onCancel() {
    this.dialogRef.close({ dataMessage: this.messages, dataContinueText: this.continueText, dataConfirm: false });
  }


  onShowDetails() {
    if (this.accrualsHierarchyErrorAndWarnings.length > 0) {
      this.openAccrualsErrorAndWarningDetailsDialog()
    }
    else {
      this.data.confirmSubmit = true;
      this.dialogRef.close(this.data);
    }
  }

  openAccrualsErrorAndWarningDetailsDialog(): void {
    const dialog = this.dialog.open(DialogAccrualsErrorAndWarningDetailsComponent, {
      disableClose: true,
      height: '670px',
      width: '700px',
      data: {dataAccrualsHierarchyErrorAndWarnings:this.accrualsHierarchyErrorAndWarnings,dataAccrualMasterHierarchySelectedLevel:this.accrualMasterHierarchySelectedLevel}
    });

    dialog.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result['dataConfirm'] === true) {
          this.data.confirmSubmit = true;
          this.dialogRef.close(this.data);
        }
      }
    });
  }

  ReplicateProgram() {
    this.dialogRef.close();
    this.router.navigateByUrl('programs/create-program');
  }
}
