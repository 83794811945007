import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable()
export class TitleService {
    headerTitle: string;

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {
        console.log(`${new Date()}: title.service.constructor`);
    }

    getTitle() {
        console.log(`${new Date()}: title.service.getTitle`);

        return this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map(route => route.firstChild),
            switchMap(route => route.data),
            map(data => {
                if (data.title) {
                    return data.title;
                } else {
                    return '';
                }
            })
        );
    }
}
