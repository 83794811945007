<div class="main-content">
    <h1 mat-dialog-title>Post Comment</h1>
    <div>
        <mat-form-field class="col-md-12">
            <mat-label>Post Comment</mat-label>
            <textarea matInput rows="5" [(ngModel)]="data.message" required></textarea>
            <mat-error>Comment is required!</mat-error>
        </mat-form-field>
    </div>
    <div>
        <button mat-raised-button class="functional-button-dark" [disabled]="!data.message"
            [mat-dialog-close]="data">OK</button>
        <button mat-raised-button class="functional-button" (click)="Cancel();"> Cancel</button>
    </div>
</div>
