import { Injectable } from '@angular/core';
import { ProgPortalContextService } from '../progportalcontextservice';
import { User } from '../../user/User';
import { ProgramPortalPermissions } from './models/program-portal-permissions';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MonitoringService } from '../monitoring.service';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {
     httpClient: any;

     constructor(public context: ProgPortalContextService, private monitoringService: MonitoringService) {
         console.log(`${new Date()}: authorization.service.constructor`);
     }


     initialize(): Observable<User>{
        return this.context
           .getdatafromService('/api/Authorization/LoggedInUser').pipe(              
               map(response =>
                {
                    this.monitoringService.setAuthenticatedUserId(response.id);
                    const user = {  id : response.id ,
                                    permissions:response.permissions,
                                    roles:response.roles,
                                    functionalArea: response.functionalArea
                                 }                                 
                    return user;
                })
           )
     }
}
