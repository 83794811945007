<div *ngIf="canViewAccrual()">
  <mat-toolbar class="top-toolbar">
    <div class="lblhearder">
      <mat-label>
        Accruals
      </mat-label>
    </div>
  </mat-toolbar>
  <mat-card>
    <div>
      <div *ngIf="isAdmin()">
        <mat-form-field>
          <mat-label>Select a Product Managers</mat-label>
          <mat-select (selectionChange)="productManagerChange($event);"[(ngModel)]="selectedProductManager" >
            <mat-option *ngFor="let productManager of allProductManagers" [value]="productManager">
              {{productManager.userName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-tab-group [selectedIndex]="tabIndex" (selectedTabChange)="groupTabChanged($event)">
        <div *ngFor="let tabName of groupTemplateList">
          <mat-tab *ngIf="tabName === 'My Programs'">
            <ng-template mat-tab-label>
              {{tabName}}
              <mat-chip class="count">{{completedAccrualsCount}}</mat-chip>
            </ng-template>
            <div class="searchBar">
              <mat-form-field>
                <mat-label>Search Programs</mat-label>
                <input matInput (keyup)="applyFilter()" type="text" [(ngModel)]="SearchProgram"
                       [formControl]="searchPrograms">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Year</mat-label> 
                <mat-select [formControl]="year" multiple (ngModelChange)="statusChange()">
                  <mat-select-trigger>
                    {{year.value ? year.value[0] : ''}}
                    <span *ngIf="year.value?.length > 1" class="year-selection">
                      (+{{year.value.length - 1}} {{year.value?.length === 2 ? 'other' : 'others'}})
                    </span>
                  </mat-select-trigger>
                  <mat-option *ngFor="let yearValue of yearList" [value]="yearValue">{{yearValue}}</mat-option> 
                </mat-select>
              </mat-form-field>
              <mat-chip-list>
                <mat-chip class="mat-chip-color" *ngFor="let item of year.value;" [selectable]="selectable"
                          [removable]="removable" (removed)="remove(item, 'year');">
                  {{item}}
                  <mat-icon [ngStyle]="{'color':'white'}" matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
            <div>
              <a [routerLink]="['edit-accruals']"><button mat-raised-button class="functional-button-dark" (click)="sendMultipleProgramIds()" *ngIf="!isButtonEnable"> Edit Selected ({{selectCounter}})</button></a>
            </div>
            <div class="matgrid-div">
              <div class="table-container">
                <table mat-table class="mat-grid" [dataSource]="dataSourceMyAccrualPrograms" #sort="matSort" matSort>
                  <!-- Checkbox Column -->
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>CODE</strong> </th>
                    <td mat-cell *matCellDef="let element"> <a href="javascript:void(0);" [routerLink]="['edit-accruals']" (click)="sendProgramId(element.id)">{{ element.code }}</a></td>
                  </ng-container>

                  <ng-container matColumnDef="programName" class="programName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> PROGRAM NAME </th>
                    <td mat-cell *matCellDef="let element"> {{ element.programName }} </td>
                  </ng-container>

                  <ng-container matColumnDef="programManager">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> PROGRAM MANAGER </th>
                    <td mat-cell *matCellDef="let element"> {{ element.programManager }} </td>
                  </ng-container>
                  <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> START DATE </th>
                    <td mat-cell *matCellDef="let element"> {{ element.startDate | date: 'MM/dd/yyyy' }} </td>
                  </ng-container>

                  <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> END DATE </th>
                    <td mat-cell *matCellDef="let element"> {{ element.endDate | date: 'MM/dd/yyyy'}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns">
                  </tr>
                </table>
              </div>
              <mat-paginator #paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons></mat-paginator>
            </div>
          </mat-tab>
          <mat-tab label="{{tabName}}" *ngIf="tabName === 'Available Programs'">
            <div class="searchBar">
              <mat-form-field>
                <mat-label>Search Programs</mat-label>
                <input matInput (keyup)="applyFilter()" type="text" [(ngModel)]="SearchProgram"
                       [formControl]="searchPrograms">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Year</mat-label>
                <mat-select [formControl]="year" multiple (ngModelChange)="statusChange()">
                  <mat-select-trigger>
                    {{year.value ? year.value[0] : ''}}
                    <span *ngIf="year.value?.length > 1" class="year-selection">
                      (+{{year.value.length - 1}} {{year.value?.length === 2 ? 'other' : 'others'}})
                    </span>
                  </mat-select-trigger>
                  <mat-option *ngFor="let yearValue of yearList" [value]="yearValue">{{yearValue}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-chip-list>
                <mat-chip class="mat-chip-color" *ngFor="let item of year.value;" [selectable]="selectable"
                          [removable]="removable" (removed)="remove(item, 'year');">
                  {{item}}
                  <mat-icon [ngStyle]="{'color':'white'}" matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
            <div>
              <a [routerLink]="['edit-accruals']"><button mat-raised-button class="functional-button-dark" (click)="sendMultipleProgramIds()" *ngIf="!isButtonEnable"> <span *ngIf="!canEditAccrual()">View Selected</span><span *ngIf="canEditAccrual()">Edit Selected</span> ({{selectCounter}})</button></a>
            </div>
            <div class="matgrid-div">
              <div class="table-container">
                <table mat-table class="mat-grid" [dataSource]="dataSourceAllAccrualPrograms" #sort_tab2="matSort" matSort>
                  <!-- Checkbox Column -->
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>CODE</strong> </th>
                    <td mat-cell *matCellDef="let element"> <a href="javascript:void(0);" [routerLink]="['edit-accruals']" (click)="sendProgramId(element.id)">{{ element.code }}</a></td>
                  </ng-container>

                  <ng-container matColumnDef="programName" class="programName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> PROGRAM NAME </th>
                    <td mat-cell *matCellDef="let element"> {{ element.programName }} </td>
                  </ng-container>

                  <ng-container matColumnDef="programManager">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> PROGRAM MANAGER </th>
                    <td mat-cell *matCellDef="let element"> {{ element.programManager }} </td>
                  </ng-container>
                  <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> START DATE </th>
                    <td mat-cell *matCellDef="let element"> {{ element.startDate | date: 'MM/dd/yyyy' }} </td>
                  </ng-container>

                  <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> END DATE </th>
                    <td mat-cell *matCellDef="let element"> {{ element.endDate | date: 'MM/dd/yyyy'}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns">
                  </tr>
                </table>
              </div>
              <mat-paginator #paginator_tab2 [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons></mat-paginator>
            </div>
          </mat-tab>
          <mat-tab *ngIf="tabName === 'Action Required'">
            <ng-template mat-tab-label>
              {{tabName}}
              <mat-chip class="count">{{pendingAccrualsCount}}</mat-chip>
            </ng-template>
            <div class="searchBar">
              <mat-form-field>
                <mat-label>Search Programs</mat-label>
                <input matInput (keyup)="applyFilter()" type="text" [(ngModel)]="SearchProgram"
                       [formControl]="searchPrograms">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Year</mat-label>
                <mat-select [formControl]="year" multiple (ngModelChange)="statusChange()" [(ngModel)]="selectedYear">
                  <mat-select-trigger>
                    {{year.value ? year.value[0] : ''}}
                    <span *ngIf="year.value?.length > 1" class="year-selection">
                      (+{{year.value.length - 1}} {{year.value?.length === 2 ? 'other' : 'others'}})
                    </span>
                  </mat-select-trigger>
                  <mat-option *ngFor="let yearValue of yearList" [value]="yearValue">{{yearValue}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-chip-list>
                  <mat-chip class="mat-chip-color" *ngFor="let item of year.value;" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(item, 'year');">
                    {{item}}
                    <mat-icon [ngStyle]="{'color':'white'}" matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
              </mat-chip-list>
            </div>
            <div>
              <a [routerLink]="['edit-accruals']"><button mat-raised-button class="functional-button-dark" (click)="sendMultipleProgramIds()" *ngIf="!isButtonEnable"> Edit Selected ({{selectCounter}})</button></a>
            </div>
            <div class="matgrid-div">
              <div class="table-container">
                <table mat-table class="mat-grid" [dataSource]="dataSourceAssignedToMeAccrualPrograms" #sort_tab3="matSort" matSort>
                  <!-- Checkbox Column -->
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>CODE</strong> </th>
                    <td mat-cell *matCellDef="let element"> <a href="javascript:void(0);" [routerLink]="['edit-accruals']" (click)="sendProgramId(element.id)">{{ element.code }}</a></td>
                  </ng-container>

                  <ng-container matColumnDef="programName" class="programName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> PROGRAM NAME </th>
                    <td mat-cell *matCellDef="let element"> {{ element.programName }} </td>
                  </ng-container>

                  <ng-container matColumnDef="programManager">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> PROGRAM MANAGER </th>
                    <td mat-cell *matCellDef="let element"> {{ element.programManager }} </td>
                  </ng-container>

                  <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> START DATE </th>
                    <td mat-cell *matCellDef="let element"> {{ element.startDate | date: 'MM/dd/yyyy' }} </td>
                  </ng-container>

                  <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> END DATE </th>
                    <td mat-cell *matCellDef="let element"> {{ element.endDate | date: 'MM/dd/yyyy'}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns">
                  </tr>
                </table>
              </div>
              <mat-paginator #paginator_tab3 [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons></mat-paginator>
            </div>
          </mat-tab>
        </div>
      </mat-tab-group>
    </div>
  </mat-card>
  <ngx-spinner class="spinner" size="default" type="ball-spin-clockwise">
    <p>Please Wait.</p>
  </ngx-spinner>
</div>
