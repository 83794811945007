import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IClientSideAppSettings } from 'src/app/core/services/app-settings/models/client-side-app-settings.model';

import { ServerSideAppSettings } from '../../server-api';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {
    static variables: IClientSideAppSettings = {} as IClientSideAppSettings;
    static variables2: ServerSideAppSettings = {} as ServerSideAppSettings;

    private httpClient: HttpClient;

    // HttpClient invokes the interceptor too soon. HttpBackend circumvents this to get setting to activate ADAL, etc
    constructor(httpBackend: HttpBackend) {
        console.log(`${new Date()}: app-settings.service.constructor`);

        this.httpClient = new HttpClient(httpBackend);
    }

    load(): Observable<any> {
        console.log(`${new Date()}: app-settings.service.load`);

        if (window.location.hostname === 'localhost') {
            console.log(`${new Date()}: app-settings.service.load - using local.json config`);

            return this.httpClient.get('../../assets/config/local.json').pipe(
                tap(payload => {
                    AppSettingsService.variables = payload as IClientSideAppSettings;
                })
            );
        }

        console.log(`${new Date()}: app-settings.service.load - using deploy.json config`);

        return this.httpClient.get('../../assets/config/deploy.json').pipe(
            tap(payload => {
                AppSettingsService.variables = payload as IClientSideAppSettings;
            })
        );
    }
}
