import { Injectable } from '@angular/core';
import { initialize, LDClient, LDFlagSet, LDUser } from 'launchdarkly-js-client-sdk';
import { PublicClientApplication } from '@azure/msal-browser';
import { Subject } from 'rxjs';

import { AppSettingsService } from './app-settings/app-settings.service';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
    client: LDClient;
    user: LDUser;
    updateFlags: Subject<LDFlagSet> = new Subject<LDFlagSet>();

    constructor(private msalInstance: PublicClientApplication) {
        this.user = { key: this.msalInstance.getAllAccounts()[0].username, anonymous: false };
        this.client = initialize(
            AppSettingsService.variables.clientSide.launchDarklyClientId,
            this.user
        );
        this.client.on('ready', () => {
            this.updateFlags.next(this.client.allFlags());
        });
    }
}
