import { Component, Inject, Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProgPortalContextService } from 'src/app/core/services/progportalcontextservice';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgramNode, ProgramAccrualsMaterialAssignRates, AccrualsAssignRates, ProgramFlatNode } from 'src/app/accruals/edit-accruals/edit-accruals.component';

/**
 * Node for to-do product
 */
export class ProductNode {
  id?: number;
  product: string;
  productHierarchyLevel?: number;
  productHierarchyCode?: string;
  isProductSelected?: boolean;
  isHierarchySelected?: boolean;
  parentHierarchyCode?: string;
  children?: ProductNode[];
}

/** Flat to-do product node with expandable and level information */
export class ProductFlatNode {
  id?: number;
  product: string;
  productHierarchyLevel?: number;
  productHierarchyCode?: string;
  parentHierarchyCode?: string;
  isProductSelected?: boolean;
  level: number;
  expandable: boolean;
  isHierarchySelected?: boolean;
}

export interface MaterialHierarchyCommercial {
  id: number;
  materialCode: string;
  materialName: string;
  tradeProductCode?: string;
  tradeProductName?: string;
  plannedProductCode?: string;
  plannedProductName?: string;
  productCenterCode?: string;
  productCenterName?: string;
  performanceCenterCode?: string;
  performanceCenterName?: string;
}

export interface DialogData {
  productName: string;
}

@Injectable({
  providedIn: 'root'
})


@Component({
  selector: 'app-dialog-accruals-addproducts',
  templateUrl: './dialog-accruals-addproducts.component.html',
  styleUrls: ['./dialog-accruals-addproducts.component.scss']
})
export class DialogAccrualsAddProductsComponent {
  materialHierarchyCommercials: MaterialHierarchyCommercial[] = [];
  selectedProducts: ProductFlatNode[] = [];
  accrualsProducts: ProductNode[] = [];
  assignRates: ProgramAccrualsMaterialAssignRates[] = [];
  accrualsAssignRates: AccrualsAssignRates[] = [];
  filterSelectedProducts: ProductNode[] = [];
  nonUpdatedAccrualsPrograms: ProgramNode[] = [];
  nonUpdatedAssignRates: ProgramAccrualsMaterialAssignRates[] = [];
  nonAccrualsAssignRates: AccrualsAssignRates[] = [];
  nonUpdatedAccrualsProducts: ProductNode[] = [];
  selectedProgramFlatNodeList: ProgramFlatNode[] = [];
  productManager: string;
  isAdmin: boolean = false;
  masterSelectedHierarchyLevel:number;
  masterAccrualsProducts: ProductNode[] = [];


  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<ProductFlatNode, ProductNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<ProductNode, ProductFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: ProductFlatNode | null = null;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: ProductNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.product === node.product
      ? existingNode
      : new ProductFlatNode();
    flatNode.id = node.id;
    flatNode.product = node.product;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    flatNode.productHierarchyCode = node.productHierarchyCode;
    flatNode.productHierarchyLevel = node.productHierarchyLevel;
    flatNode.isProductSelected = node.isProductSelected;
    flatNode.isHierarchySelected = node.isHierarchySelected;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  treeFlattener = new MatTreeFlattener(
    this.transformer, node => node.level,
    node => node.expandable, node => node.children);

  treeControl = new FlatTreeControl<ProductFlatNode>(
    node => node.level, node => node.expandable);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  /** The selection for checklist */
  checklistSelection = new SelectionModel<ProductFlatNode>(true /* multiple */);

  getLevel = (node: ProductFlatNode) => node.level;

  isExpandable = (node: ProductFlatNode) => node.expandable;

  getChildren = (node: ProductNode): ProductNode[] => node.children;

  hasChild = (_: number, _nodeData: ProductFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: ProductFlatNode) => _nodeData.product === '';

  constructor(public context: ProgPortalContextService,
    public dialogRef: MatDialogRef<DialogAccrualsAddProductsComponent>, @Inject(MAT_DIALOG_DATA)
    public dialogdata: DialogData, @Inject(MAT_DIALOG_DATA)
    public data: ProgramNode[],
    private spinner: NgxSpinnerService) {
    this.nonUpdatedAccrualsPrograms = JSON.parse(JSON.stringify(data['dataAccrualPrograms']));
    this.nonUpdatedAssignRates = JSON.parse(JSON.stringify(data['dataAssignRates']));
    this.nonAccrualsAssignRates = JSON.parse(JSON.stringify(data['dataAccrualsAssignRates']));

    this.dataSource.data = this.accrualsProducts;
    this.assignRates = data['dataAssignRates'];
    this.accrualsAssignRates = data['dataAccrualsAssignRates'];
    this.selectedProgramFlatNodeList = data['dataCheckSelectedList'];
    this.productManager = data['dataProductManager'];
    this.isAdmin = data['dataAdmin'];
    this.masterSelectedHierarchyLevel = data['dataMasterSelectedHierarchyLevel'];

    dialogRef.disableClose = true;
    this.checklistSelection.changed.subscribe(item => {
      if (item.removed.length > 0) {
        for (var i = 0; i < item.removed.length; i++) {
          this.removeSingleDeselectedProductAssignRates(item.removed[i]);
        }
      }
      else {
        this.selectedProducts = this.checklistSelection.selected;
        data['dataAccrualPrograms'] = JSON.parse(JSON.stringify(this.nonUpdatedAccrualsPrograms));
        this.accrualsProducts = JSON.parse(JSON.stringify(this.nonUpdatedAccrualsProducts));
        var productHierarchyCodeList = this.selectedProducts.filter(sp => sp.expandable == false);
        if (productHierarchyCodeList.length > 0) {
          productHierarchyCodeList.forEach(ph => {
              this.setProductSelected(this.accrualsProducts, ph.productHierarchyCode);
              this.setIsHierarchySelected(ph);
          })
          productHierarchyCodeList.forEach(ph => {
            this.addAssignRates(data['dataAccrualPrograms'], ph.productHierarchyCode, ph.product, ph.id);
          })
        }
        this.addSelectedProductsToSelectedAccrualsPrograms(data['dataAccrualPrograms'], this.accrualsProducts);
        this.updateAssignRates();
      }
    })  
  }

  addSelectedProductsToSelectedAccrualsPrograms(accrualsPrograms, accrualsProducts) {
    accrualsProducts.forEach(ap => {
      if (ap.isHierarchySelected === true || ap.isProductSelected === true) {
        this.searchAndAddMaterialHierarchytoAccrualsPrograms(accrualsPrograms, ap);
        if (typeof ap.children === 'object') {
          this.addSelectedProductsToSelectedAccrualsPrograms(accrualsPrograms, ap.children);
        }
      }
    });
  }

  searchAndAddMaterialHierarchytoAccrualsPrograms(accrualsPrograms, ap) {
    accrualsPrograms.forEach(aprg => {
      if (aprg.isSelected === true) {
        switch (ap.productHierarchyLevel) {
          case 0:
            if(this.masterSelectedHierarchyLevel === 4){
              if (aprg.children.length > 0) {
                if (!(aprg.children.some(element => element.materialCode === ap.productHierarchyCode && ap.isProductSelected === true))) {
                  aprg.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true, numberOfColumns: aprg.numberOfColumns });
                }
              }
              else {
                aprg.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true, numberOfColumns: aprg.numberOfColumns });
              }
            }
            else{
              if (typeof aprg.children === 'object') {
                if (aprg.children.length > 0) {
                  if (!(aprg.children.some(element => element.materialCode === ap.productHierarchyCode && ap.isHierarchySelected === true))) {
                    aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, children: [] });
                  }
                }
                else {
                  aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, children: [] });
                }
              }
            }
            break;
          case 1:
            if(this.masterSelectedHierarchyLevel === 3){
              if (typeof aprg.children === 'object') {
                if (aprg.children.length > 0) {
                  aprg.children.forEach(aprgChild => {
                    if (typeof aprgChild.children === 'object') {
                      if (aprgChild.materialCode === ap.parentHierarchyCode) {
                        if (aprgChild.children.length > 0) {
                          if (!(aprgChild.children.some(element => element.materialCode === ap.productHierarchyCode && ap.isProductSelected === true))) {
                            aprgChild.children.push({ id: aprgChild.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true, numberOfColumns: aprg.numberOfColumns });
                          }
                        }
                        else {
                          aprgChild.children.push({ id: aprgChild.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true, numberOfColumns: aprg.numberOfColumns });
                        }
                      }
                    }
                  })
                }
              }
            }
             else{
              if (typeof aprg.children === 'object') {
                if (aprg.children.length > 0) {
                  aprg.children.forEach(aprgChild => {
                    if (typeof aprgChild.children === 'object') {
                      if (aprgChild.materialCode === ap.parentHierarchyCode) {
                        if (aprgChild.children.length > 0) {
                          if (!(aprgChild.children.some(element => element.materialCode === ap.productHierarchyCode && ap.isHierarchySelected === true))) {
                            aprgChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, children: [] });
                          }
                        }
                        else {
                          aprgChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, children: [] });
                        }
                      }
                    }
                  })
                }
              }
             }
            break;
          case 2:
            if (this.masterSelectedHierarchyLevel === 2) {
              if (typeof aprg.children === 'object') {
                if (aprg.children.length > 0) {
                  aprg.children.forEach(aprgChild => {
                    if (typeof aprgChild.children === 'object') {
                      if (aprgChild.children.length > 0) {
                        aprgChild.children.forEach(aprgChildChild => {
                          if (typeof aprgChildChild.children === 'object') {
                            if (aprgChildChild.materialCode === ap.parentHierarchyCode) {
                              if (aprgChildChild.children.length > 0) {
                                if (!(aprgChildChild.children.some(element => element.materialCode === ap.productHierarchyCode && ap.isProductSelected === true))) {
                                  aprgChildChild.children.push({ id: aprgChildChild.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true, numberOfColumns: aprg.numberOfColumns });
                                }
                              }
                              else {
                                aprgChildChild.children.push({ id: aprgChildChild.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true, numberOfColumns: aprg.numberOfColumns });
                              }
                            }
                          }
                        })
                      }
                    }
                  })
                }
              }
            }
            else{
              if (typeof aprg.children === 'object') {
                if (aprg.children.length > 0) {
                  aprg.children.forEach(aprgChild => {
                    if (typeof aprgChild.children === 'object') {
                      if (aprgChild.children.length > 0) {
                        aprgChild.children.forEach(aprgChildChild => {
                          if (typeof aprgChildChild.children === 'object') {
                            if (aprgChildChild.materialCode === ap.parentHierarchyCode) {
                              if (aprgChildChild.children.length > 0) {
                                if (!(aprgChildChild.children.some(element => element.materialCode === ap.productHierarchyCode && ap.isHierarchySelected === true))) {
                                  aprgChildChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, children: [] });
                                }
                              }
                              else {
                                aprgChildChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, children: [] });
                              }
                            }
                          }
                        })
                      }
                    }
                  })
                }
              }
            }
            break;
          case 3:
            if (this.masterSelectedHierarchyLevel === 1) {
              if (typeof aprg.children === 'object') {
                if (aprg.children.length > 0) {
                  aprg.children.forEach(aprgChild => {
                    if (typeof aprgChild.children === 'object') {
                      if (aprgChild.children.length > 0) {
                        aprgChild.children.forEach(aprgChildChild => {
                          if (typeof aprgChildChild.children === 'object') {
                            if (aprgChildChild.children.length > 0) {
                              aprgChildChild.children.forEach(aprgChildChildChild => {
                                if (typeof aprgChildChildChild.children === 'object') {
                                  if (aprgChildChildChild.materialCode === ap.parentHierarchyCode) {
                                    if (aprgChildChildChild.children.length > 0) {
                                      if (!(aprgChildChildChild.children.some(element => element.materialCode === ap.productHierarchyCode && ap.isProductSelected === true))) {
                                        aprgChildChildChild.children.push({ id: aprgChildChildChild.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true, numberOfColumns: aprg.numberOfColumns });
                                      }
                                    }
                                    else {
                                      aprgChildChildChild.children.push({ id: aprgChildChildChild.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true, numberOfColumns: aprg.numberOfColumns });
                                    }
                                  }
                                }
                              })
                            }
                          }
                        })
                      }
                    }
                  })
                }
              }
            }
            else{
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length > 0) {
                      aprgChild.children.forEach(aprgChildChild => {
                        if (typeof aprgChildChild.children === 'object') {
                          if (aprgChildChild.children.length > 0) {
                            aprgChildChild.children.forEach(aprgChildChildChild => {
                              if (typeof aprgChildChildChild.children === 'object') {
                                if (aprgChildChildChild.materialCode === ap.parentHierarchyCode) {
                                  if (aprgChildChildChild.children.length > 0) {
                                    if (!(aprgChildChildChild.children.some(element => element.materialCode === ap.productHierarchyCode && ap.isHierarchySelected === true))) {
                                      aprgChildChildChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, children: [] });
                                    }
                                  }
                                  else {
                                    aprgChildChildChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, children: [] });
                                  }
                                }
                              }
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          }
            break;
          case 4:
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length > 0) {
                      aprgChild.children.forEach(aprgChildChild => {
                        if (typeof aprgChildChild.children === 'object') {
                          if (aprgChildChild.children.length > 0) {
                            aprgChildChild.children.forEach(aprgChildChildChild => {
                              if (typeof aprgChildChildChild.children === 'object') {
                                if (aprgChildChildChild.children.length > 0) {
                                  aprgChildChildChild.children.forEach(aprgChildChildChildChild => {
                                    if (typeof aprgChildChildChildChild.children === 'object') {
                                      if (aprgChildChildChildChild.materialCode === ap.parentHierarchyCode) {
                                        if (aprgChildChildChildChild.children.length > 0) {
                                          if (!(aprgChildChildChildChild.children.some(element => element.materialCode === ap.productHierarchyCode && ap.isProductSelected === true))) {
                                            aprgChildChildChildChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true, numberOfColumns: aprg.numberOfColumns });
                                          }
                                        }
                                        else {
                                          aprgChildChildChildChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true, numberOfColumns: aprg.numberOfColumns });
                                        }
                                      }
                                    }
                                  })
                                }
                              }
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
            break;
        }
      }
    });
  }

  setIsHierarchySelected(node: ProductFlatNode) {
    let parent: ProductFlatNode | null = this.getParentNode(node);
    if(parent != null){
      while (parent) {
        if (parent.level < 4) {
          this.searchAndSetHierarchySelected(this.accrualsProducts, parent)
        }
        parent = this.getParentNode(parent);
      }  
    }
  }
  
  ngAfterViewInit(): void {
    this.bindMaterialHierarchyCommercials(this.masterSelectedHierarchyLevel);
  }

  bindMaterialHierarchyCommercials(masterSelectedHierarchyLevel) {
    var url = (this.productManager && this.isAdmin) ? `/api/Accruals/Option/GetMaterialHierarchyCommercial/${this.productManager}` : "/api/Accruals/Option/GetMaterialHierarchyCommercial";

    this.context.getdatafromService(url).toPromise().then((response) => {
      if (response != null) {
        this.materialHierarchyCommercials = response;
      }
      this.BuildMaterialHierarchyCommercials(masterSelectedHierarchyLevel);
      this.dataSource.data = this.accrualsProducts;
    });
  }

  BuildMaterialHierarchyCommercials(masterSelectedHierarchyLevel) {
    this.accrualsProducts = [];  
    if (this.materialHierarchyCommercials.length > 0) {
      switch (masterSelectedHierarchyLevel) {
        case 0:
          this.materialHierarchyCommercials.forEach(m => {
            if (this.accrualsProducts.length === 0) {
              this.accrualsProducts.push({ product: m.performanceCenterName, productHierarchyLevel: 0, productHierarchyCode: m.performanceCenterCode, isHierarchySelected: false, children: [] });
            }
            if (!(this.accrualsProducts.some(element => element.productHierarchyCode === m.performanceCenterCode))) {
              this.accrualsProducts.push({ product: m.performanceCenterName, productHierarchyLevel: 0, productHierarchyCode: m.performanceCenterCode, isHierarchySelected: false, children: [] });
            }
          });
          this.accrualsProducts.forEach(ap => {
            this.materialHierarchyCommercials.forEach(m => {
              if (ap.productHierarchyCode === m.performanceCenterCode) {
                this.searchAndAddMaterialHierarchyCommercial(ap, m);
              }
            });
          });
          break;
        case 1:
          this.materialHierarchyCommercials.forEach(m => {
            if (this.accrualsProducts.length === 0) {
              this.accrualsProducts.push({ product: m.productCenterName, productHierarchyLevel: 0, productHierarchyCode: m.productCenterCode, isHierarchySelected: false, children: [] });
            }
            if (!(this.accrualsProducts.some(element => element.productHierarchyCode === m.productCenterCode))) {
              this.accrualsProducts.push({ product: m.productCenterName, productHierarchyLevel: 0, productHierarchyCode: m.productCenterCode, isHierarchySelected: false, children: [] });
            }
          });
          this.accrualsProducts.forEach(ap => {
            this.materialHierarchyCommercials.forEach(m => {
              if (ap.productHierarchyCode === m.productCenterCode) {
                this.searchAndAddMaterialHierarchyCommercial(ap, m);
              }
            });
          });
          break;
        case 2:
          this.materialHierarchyCommercials.forEach(m => {
            if (this.accrualsProducts.length === 0) {
              this.accrualsProducts.push({ product: m.plannedProductName, productHierarchyLevel: 0, productHierarchyCode: m.plannedProductCode, isHierarchySelected: false, children: [] });
            }
            if (!(this.accrualsProducts.some(element => element.productHierarchyCode === m.plannedProductCode))) {
              this.accrualsProducts.push({ product: m.plannedProductName, productHierarchyLevel: 0, productHierarchyCode: m.plannedProductCode, isHierarchySelected: false, children: [] });
            }
          });
          this.accrualsProducts.forEach(ap => {
            this.materialHierarchyCommercials.forEach(m => {
              if (ap.productHierarchyCode === m.plannedProductCode) {
                this.searchAndAddMaterialHierarchyCommercial(ap, m);
              }
            });
          });
          break;
        case 3:
          this.materialHierarchyCommercials.forEach(m => {
            if (this.accrualsProducts.length === 0) {
              this.accrualsProducts.push({ product: m.tradeProductName, productHierarchyLevel: 0, productHierarchyCode: m.tradeProductCode, isHierarchySelected: false, children: [] });
            }
            if (!(this.accrualsProducts.some(element => element.productHierarchyCode === m.tradeProductCode))) {
              this.accrualsProducts.push({ product: m.tradeProductName, productHierarchyLevel: 0, productHierarchyCode: m.tradeProductCode, isHierarchySelected: false, children: [] });
            }
          });
          this.accrualsProducts.forEach(ap => {
            this.materialHierarchyCommercials.forEach(m => {
              if (ap.productHierarchyCode === m.tradeProductCode) {
                this.searchAndAddMaterialHierarchyCommercial(ap, m);
              }
            });
          });
          break;
        case 4:
          this.materialHierarchyCommercials.forEach(m => {
            if (this.accrualsProducts.length === 0) {
              this.accrualsProducts.push({id: m.id,product: m.materialName, productHierarchyLevel: 0, productHierarchyCode: m.materialCode,parentHierarchyCode:m.tradeProductCode, isProductSelected: false })
            }
            if (!(this.accrualsProducts.some(element => element.productHierarchyCode === m.materialCode))) {
              this.accrualsProducts.push({ id: m.id, product: m.materialName, productHierarchyLevel: 0, productHierarchyCode: m.materialCode,parentHierarchyCode:m.tradeProductCode, isProductSelected: false });
            }
          });
          break;
      }
    }

    this.nonUpdatedAccrualsProducts = JSON.parse(JSON.stringify(this.accrualsProducts));
  }
   
  searchAndAddMaterialHierarchyCommercial(ap, MaterialHierarchyCommercial) {
    var childrenCounter = 0;
    var childrenChildCounter = 0;
    var childrenChildrenChildCounter = 0;
    switch (this.masterSelectedHierarchyLevel) {
      case 0:
        if (ap.productHierarchyLevel === 0) {
          if (ap.productHierarchyCode === MaterialHierarchyCommercial.performanceCenterCode) {
            if (ap.children.length > 0) {
              if (!(ap.children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.productCenterCode))) {
                ap.children.push({ product: MaterialHierarchyCommercial.productCenterName, productHierarchyLevel: 1, productHierarchyCode: MaterialHierarchyCommercial.productCenterCode, parentHierarchyCode: MaterialHierarchyCommercial.performanceCenterCode, isHierarchySelected: false, children: [] })
              }
            }
            else {
              ap.children.push({ product: MaterialHierarchyCommercial.productCenterName, productHierarchyLevel: 1, productHierarchyCode: MaterialHierarchyCommercial.productCenterCode, parentHierarchyCode: MaterialHierarchyCommercial.performanceCenterCode, isHierarchySelected: false, children: [] })
            }
          }
        }
        childrenCounter = ap.children.length;
        if (typeof ap.children[childrenCounter - 1] === 'object') {
          if (ap.children[childrenCounter - 1].productHierarchyLevel === 1) {
            if (ap.children[childrenCounter - 1].productHierarchyCode === MaterialHierarchyCommercial.productCenterCode) {
              if (ap.children[childrenCounter - 1].children.length > 0) {
                if (!(ap.children[childrenCounter - 1].children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.plannedProductCode))) {
                  ap.children[childrenCounter - 1].children.push({ product: MaterialHierarchyCommercial.plannedProductName, productHierarchyLevel: 2, productHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, parentHierarchyCode: MaterialHierarchyCommercial.productCenterCode, isHierarchySelected: false, children: [] })
                }
              }
              else {
                ap.children[childrenCounter - 1].children.push({ product: MaterialHierarchyCommercial.plannedProductName, productHierarchyLevel: 2, productHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, parentHierarchyCode: MaterialHierarchyCommercial.productCenterCode, isHierarchySelected: false, children: [] })
              }
            }
          }
        }
        childrenChildCounter = ap.children[childrenCounter - 1].children.length;
        if (typeof ap.children[childrenCounter - 1].children[childrenChildCounter - 1] === 'object') {
          if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].productHierarchyLevel === 2) {
            if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].productHierarchyCode === MaterialHierarchyCommercial.plannedProductCode) {
              if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.length > 0) {
                if (!(ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.tradeProductCode))) {
                  ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.push({ product: MaterialHierarchyCommercial.tradeProductName, productHierarchyLevel: 3, productHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, parentHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, isHierarchySelected: false, children: [] })
                }
              }
              else {
                ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.push({ product: MaterialHierarchyCommercial.tradeProductName, productHierarchyLevel: 3, productHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, parentHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, isHierarchySelected: false, children: [] })
              }
            }
          }
        }
    
        childrenChildrenChildCounter = ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.length;
        if (typeof ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children[childrenChildrenChildCounter - 1] === 'object') {
          if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children[childrenChildrenChildCounter - 1].productHierarchyLevel === 3) {
            if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children[childrenChildrenChildCounter - 1].productHierarchyCode === MaterialHierarchyCommercial.tradeProductCode) {
              if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children[childrenChildrenChildCounter - 1].children.length > 0) {
                if (!(ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children[childrenChildrenChildCounter - 1].children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.materialCode))) {
                  ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children[childrenChildrenChildCounter - 1].children.push({ id: MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.materialName, productHierarchyLevel: 4, productHierarchyCode: MaterialHierarchyCommercial.materialCode, parentHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, isProductSelected: false })
                }
              }
              else {
                ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children[childrenChildrenChildCounter - 1].children.push({ id: MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.materialName, productHierarchyLevel: 4, productHierarchyCode: MaterialHierarchyCommercial.materialCode, parentHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, isProductSelected: false })
              }
            }
          }
        }
        break;
      case 1:
        if (ap.productHierarchyLevel === 0) {
          if (ap.productHierarchyCode === MaterialHierarchyCommercial.productCenterCode) {
            if (ap.children.length > 0) {
              if (!(ap.children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.plannedProductCode))) {
                ap.children.push({ product: MaterialHierarchyCommercial.plannedProductName, productHierarchyLevel: 1, productHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, parentHierarchyCode: MaterialHierarchyCommercial.productCenterCode, isHierarchySelected: false, children: [] })
              }
            }
            else {
              ap.children.push({ product: MaterialHierarchyCommercial.plannedProductName, productHierarchyLevel: 1, productHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, parentHierarchyCode: MaterialHierarchyCommercial.productCenterCode, isHierarchySelected: false, children: [] })
            }
          }
        }
        childrenCounter = ap.children.length;
        if (typeof ap.children[childrenCounter - 1] === 'object') {
          if (ap.children[childrenCounter - 1].productHierarchyLevel === 1) {
            if (ap.children[childrenCounter - 1].productHierarchyCode === MaterialHierarchyCommercial.plannedProductCode) {
              if (ap.children[childrenCounter - 1].children.length > 0) {
                if (!(ap.children[childrenCounter - 1].children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.tradeProductCode))) {
                  ap.children[childrenCounter - 1].children.push({ product: MaterialHierarchyCommercial.tradeProductName, productHierarchyLevel: 2, productHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, parentHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, isHierarchySelected: false, children: [] })
                }
              }
              else {
                ap.children[childrenCounter - 1].children.push({ product: MaterialHierarchyCommercial.tradeProductName, productHierarchyLevel: 2, productHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, parentHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, isHierarchySelected: false, children: [] })
              }
            }
          }
        }
        childrenChildCounter = ap.children[childrenCounter - 1].children.length;
        if (typeof ap.children[childrenCounter - 1].children[childrenChildCounter - 1] === 'object') {
          if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].productHierarchyLevel === 2) {
            if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].productHierarchyCode === MaterialHierarchyCommercial.tradeProductCode) {
              if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.length > 0) {
                if (!(ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.materialCode))) {
                  ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.push({ id: MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.materialName, productHierarchyLevel: 3, productHierarchyCode: MaterialHierarchyCommercial.materialCode, parentHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, isProductSelected: false })
                }
              }
              else {
                ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.push({ id: MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.materialName, productHierarchyLevel: 3, productHierarchyCode: MaterialHierarchyCommercial.materialCode, parentHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, isProductSelected: false })
              }
            }
          }
        }
        break;
      case 2:
        if (ap.productHierarchyLevel === 0) {
          if (ap.productHierarchyCode === MaterialHierarchyCommercial.plannedProductCode) {
            if (ap.children.length > 0) {
              if (!(ap.children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.tradeProductCode))) {
                ap.children.push({ product: MaterialHierarchyCommercial.tradeProductName, productHierarchyLevel: 1, productHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, parentHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, isHierarchySelected: false, children: [] })
              }
            }
            else {
              ap.children.push({ product: MaterialHierarchyCommercial.tradeProductName, productHierarchyLevel: 1, productHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, parentHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, isHierarchySelected: false, children: [] })
            }
          }
        }
        childrenCounter = ap.children.length;
        if (typeof ap.children[childrenCounter - 1] === 'object') {
          if (ap.children[childrenCounter - 1].productHierarchyLevel === 1) {
            if (ap.children[childrenCounter - 1].productHierarchyCode === MaterialHierarchyCommercial.tradeProductCode) {
              if (ap.children[childrenCounter - 1].children.length > 0) {
                if (!(ap.children[childrenCounter - 1].children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.materialCode))) {
                  ap.children[childrenCounter - 1].children.push({ id: MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.materialName, productHierarchyLevel: 2, productHierarchyCode: MaterialHierarchyCommercial.materialCode, parentHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, isProductSelected: false })
                }
              }
              else {
                ap.children[childrenCounter - 1].children.push({ id: MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.materialName, productHierarchyLevel: 2, productHierarchyCode: MaterialHierarchyCommercial.materialCode, parentHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, isProductSelected: false })
              }
            }
          }
        }
        break;
      case 3:
        if (ap.productHierarchyLevel === 0) {
          if (ap.productHierarchyCode === MaterialHierarchyCommercial.tradeProductCode) {
            if (ap.children.length > 0) {
              if (!(ap.children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.materialCode))) {
                ap.children.push({ id: MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.materialName, productHierarchyLevel: 1, productHierarchyCode: MaterialHierarchyCommercial.materialCode, parentHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, isProductSelected: false })
              }
            }
            else {
              ap.children.push({ id: MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.materialName, productHierarchyLevel: 1, productHierarchyCode: MaterialHierarchyCommercial.materialCode, parentHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, isProductSelected: false })
            }
          }
        }
        break;
    }
  }

  setProductSelected(accrualsProducts, productHierarchyCode) {
    if (this.masterSelectedHierarchyLevel != 4) {
      accrualsProducts.forEach(ap => {
        if (typeof ap.children === 'object') {
          ap.children.forEach(c => {
            if (c.productHierarchyCode === productHierarchyCode) {
              c.isProductSelected = true;
            }
          })
          this.setProductSelected(ap.children, productHierarchyCode);
        }
      })
    }
    else {
      accrualsProducts.forEach(ap => {
            if (ap.productHierarchyCode === productHierarchyCode) {
              ap.isProductSelected = true;
            }
      })
    }
  }

  searchAndSetHierarchySelected(accrualsProducts, node) {
    accrualsProducts.forEach(ap => {
      if (ap.productHierarchyCode === node.productHierarchyCode) {
        ap.isHierarchySelected = true;
      }
      if (typeof ap.children === 'object') {
        ap.children.forEach(c => {
          if (c.productHierarchyCode === node.productHierarchyCode) {
            c.isHierarchySelected = true;
          }
        })
        this.searchAndSetHierarchySelected(ap.children, node);
      }
    })
  }

  addAssignRates(accrualsPrograms, productHierarchyCode, product, materialHierarchyCommercialId) {
    let materialHierarchyCommercial;
    this.materialHierarchyCommercials.forEach(
      element => {
        if (element.id === materialHierarchyCommercialId) {
          materialHierarchyCommercial = element;
        }
      }
    )

    accrualsPrograms.forEach(ap => {
      if (ap.isSelected === true) {
        if (!(this.assignRates.some(element => element.materialCode === productHierarchyCode && element.programId === ap.id))) {
          this.assignRates.push({
            programId: ap.id, materialHierarchyCommercialId: materialHierarchyCommercialId, materialCode: productHierarchyCode, materialName: product, header: true, assignRateType: '', _1stMonthAssignRate: ap.startDate, _2ndMonthAssignRate: this.assignRateNextMonth(ap.startDate, 1, ap.endDate), _3rdMonthAssignRate: this.assignRateNextMonth(ap.startDate, 2, ap.endDate), _4thMonthAssignRate: this.assignRateNextMonth(ap.startDate, 3, ap.endDate)
            , _5thMonthAssignRate: this.assignRateNextMonth(ap.startDate, 4, ap.endDate), _6thMonthAssignRate: this.assignRateNextMonth(ap.startDate, 5, ap.endDate), _7thMonthAssignRate: this.assignRateNextMonth(ap.startDate, 6, ap.endDate), _8thMonthAssignRate: this.assignRateNextMonth(ap.startDate, 7, ap.endDate), _9thMonthAssignRate: this.assignRateNextMonth(ap.startDate, 8, ap.endDate)
            , _10thMonthAssignRate: this.assignRateNextMonth(ap.startDate, 9, ap.endDate), _11thMonthAssignRate: this.assignRateNextMonth(ap.startDate, 10, ap.endDate), _12thMonthAssignRate: this.assignRateNextMonth(ap.startDate, 11, ap.endDate)
          },
            {
              programId: ap.id, materialHierarchyCommercialId: materialHierarchyCommercialId, materialCode: productHierarchyCode, materialName: product, header: false, assignRateType: 'VOLUME %', _1stMonthAssignRate: '', _2ndMonthAssignRate: '', _3rdMonthAssignRate: '', _4thMonthAssignRate: ''
              , _5thMonthAssignRate: '', _6thMonthAssignRate: '', _7thMonthAssignRate: '', _8thMonthAssignRate: '', _9thMonthAssignRate: ''
              , _10thMonthAssignRate: '', _11thMonthAssignRate: '', _12thMonthAssignRate: ''
            },
            {
              programId: ap.id, materialHierarchyCommercialId: materialHierarchyCommercialId, materialCode: productHierarchyCode, materialName: product, header: false, assignRateType: 'PAY RATE %', _1stMonthAssignRate: '', _2ndMonthAssignRate: '', _3rdMonthAssignRate: '', _4thMonthAssignRate: ''
              , _5thMonthAssignRate: '', _6thMonthAssignRate: '', _7thMonthAssignRate: '', _8thMonthAssignRate: '', _9thMonthAssignRate: ''
              , _10thMonthAssignRate: '', _11thMonthAssignRate: '', _12thMonthAssignRate: ''
            },
            {
              programId: ap.id, materialHierarchyCommercialId: materialHierarchyCommercialId, materialCode: productHierarchyCode, materialName: product, header: false, assignRateType: 'ACCRUAL %', _1stMonthAssignRate: '', _2ndMonthAssignRate: '', _3rdMonthAssignRate: '', _4thMonthAssignRate: ''
              , _5thMonthAssignRate: '', _6thMonthAssignRate: '', _7thMonthAssignRate: '', _8thMonthAssignRate: '', _9thMonthAssignRate: ''
              , _10thMonthAssignRate: '', _11thMonthAssignRate: '', _12thMonthAssignRate: ''
            },
          );
        }
        if (!(this.accrualsAssignRates.some(element => element.programId === ap.id))) {
          this.accrualsAssignRates.push({
            programId: ap.id, programName: ap.name,
            autoFillAssignRates: [
              {
                programId: ap.id, materialHierarchyCommercialId: materialHierarchyCommercial.id, performanceCenterCode: materialHierarchyCommercial.performanceCenterCode, performanceCenterName: materialHierarchyCommercial.performanceCenterName, productCenterCode: materialHierarchyCommercial.productCenterCode, productCenterName: materialHierarchyCommercial.productCenterName, plannedProductCode: materialHierarchyCommercial.plannedProductCode, plannedProductName: materialHierarchyCommercial.plannedProductName, tradeProductCode: materialHierarchyCommercial.tradeProductCode, tradeProductName: materialHierarchyCommercial.tradeProductName, materialCode: materialHierarchyCommercial.materialCode, materialName: materialHierarchyCommercial.materialName, isAutoAssign: false, volume: null, payRate: null, accrual: null, startDate: ap.startDate, endDate: ap.endDate, dates: [{ value: 2, viewValue: ap.startDate },
                { value: 3, viewValue: this.assignRateNextMonth(ap.startDate, 1, ap.endDate) }, { value: 4, viewValue: this.assignRateNextMonth(ap.startDate, 2, ap.endDate) }, { value: 5, viewValue: this.assignRateNextMonth(ap.startDate, 3, ap.endDate) },
                { value: 6, viewValue: this.assignRateNextMonth(ap.startDate, 4, ap.endDate) }, { value: 7, viewValue: this.assignRateNextMonth(ap.startDate, 5, ap.endDate) }, { value: 8, viewValue: this.assignRateNextMonth(ap.startDate, 6, ap.endDate) },
                { value: 9, viewValue: this.assignRateNextMonth(ap.startDate, 7, ap.endDate) }, { value: 10, viewValue: this.assignRateNextMonth(ap.startDate, 8, ap.endDate) }, { value: 11, viewValue: this.assignRateNextMonth(ap.startDate, 9, ap.endDate) },
                { value: 12, viewValue: this.assignRateNextMonth(ap.startDate, 10, ap.endDate) }, { value: 13, viewValue: this.assignRateNextMonth(ap.startDate, 11, ap.endDate) }], startColumn: 2, endColumn: this.assignRateEndColumn(ap.startDate, ap.endDate)
              },
            ]
          })
        }
        else {
          this.accrualsAssignRates.forEach(aar => {
            if (aar.programId === ap.id) {
              if (!(aar.autoFillAssignRates.some(element => element.materialCode === materialHierarchyCommercial.materialCode))) {
                aar.autoFillAssignRates.push({
                  programId: ap.id, materialHierarchyCommercialId: materialHierarchyCommercial.id, performanceCenterCode: materialHierarchyCommercial.performanceCenterCode, performanceCenterName: materialHierarchyCommercial.performanceCenterName, productCenterCode: materialHierarchyCommercial.productCenterCode, productCenterName: materialHierarchyCommercial.productCenterName, plannedProductCode: materialHierarchyCommercial.plannedProductCode, plannedProductName: materialHierarchyCommercial.plannedProductName, tradeProductCode: materialHierarchyCommercial.tradeProductCode, tradeProductName: materialHierarchyCommercial.tradeProductName, materialCode: materialHierarchyCommercial.materialCode, materialName: materialHierarchyCommercial.materialName, isAutoAssign: false, volume: null, payRate: null, accrual: null, startDate: ap.startDate, endDate: ap.endDate, dates: [{ value: 2, viewValue: ap.startDate },
                  { value: 3, viewValue: this.assignRateNextMonth(ap.startDate, 1, ap.endDate) }, { value: 4, viewValue: this.assignRateNextMonth(ap.startDate, 2, ap.endDate) }, { value: 5, viewValue: this.assignRateNextMonth(ap.startDate, 3, ap.endDate) },
                  { value: 6, viewValue: this.assignRateNextMonth(ap.startDate, 4, ap.endDate) }, { value: 7, viewValue: this.assignRateNextMonth(ap.startDate, 5, ap.endDate) }, { value: 8, viewValue: this.assignRateNextMonth(ap.startDate, 6, ap.endDate) },
                  { value: 9, viewValue: this.assignRateNextMonth(ap.startDate, 7, ap.endDate) }, { value: 10, viewValue: this.assignRateNextMonth(ap.startDate, 8, ap.endDate) }, { value: 11, viewValue: this.assignRateNextMonth(ap.startDate, 9, ap.endDate) },
                  { value: 12, viewValue: this.assignRateNextMonth(ap.startDate, 10, ap.endDate) }, { value: 13, viewValue: this.assignRateNextMonth(ap.startDate, 11, ap.endDate) }], startColumn: 2, endColumn: this.assignRateEndColumn(ap.startDate, ap.endDate)
                });
              }
            }
          })
        }
      }
    });
  }


  assignRateNextMonth(startDate, addMonths, endDate): string {
    var result = '';
    var startMonth = startDate.substring(0, 2);
    var startYear = startDate.substring(3);
    var endMonth = endDate.substring(0, 2);
    var endYear = endDate.substring(3);

    var d1 = new Date(startMonth + '/01/' + startYear);
    var d2 = new Date(endMonth + '/01/' + endYear);
    d1 = new Date(d1.setMonth(d1.getMonth() + addMonths))

    if (d1 < d2) {
      result = (d1.getMonth() + 1).toString().padStart(2, '0') + '/' + d1.getFullYear().toString();
    }
    if (d1.getTime() === d2.getTime()) {
      result = endDate;
    }
    return result;
  }

  assignRateEndColumn(startDate, endDate): number {
    var result = 0;
    var startMonth = startDate.substring(0, 2);
    var startYear = startDate.substring(3);
    var endMonth = endDate.substring(0, 2);
    var endYear = endDate.substring(3);

    var d1 = new Date(startMonth + '/01/' + startYear);
    var d2 = new Date(endMonth + '/01/' + endYear);
    result = (d2.getMonth() - d1.getMonth() + (12 * (d2.getFullYear() - d1.getFullYear()))) + 2;
    return result;
  }

  updateAssignRates() {
    this.accrualsAssignRates.forEach(aar => {
      aar.autoFillAssignRates.forEach(afar => {
        afar.dates.forEach(d => {
          if (d.viewValue.length === 0) {
            var index = afar.dates.indexOf(d);
            var deleteCount = afar.dates.length - index;
            afar.dates.splice(index, deleteCount);
          }
        })
      })
    })
  }

  removeDeselectedProductsAssignRates(descendants) {
    var desendentsProducts = descendants.filter(d => d.expandable === false);
    desendentsProducts.forEach(dp => {
      if (this.nonAccrualsAssignRates.length > 0) {
        this.nonAccrualsAssignRates.forEach(naar => {
          if (!naar.autoFillAssignRates.some(afar => afar.materialCode === dp.productHierarchyCode)) {
            this.accrualsAssignRates.forEach(aar => {
              while (aar.autoFillAssignRates.some(element => element.materialCode === dp.productHierarchyCode)) {
                aar.autoFillAssignRates.forEach(afar => {
                  if (afar.materialCode === dp.productHierarchyCode) {
                    var index = aar.autoFillAssignRates.indexOf(afar);
                    aar.autoFillAssignRates.splice(index, 1);
                    while (this.assignRates.some(element => element.programId === aar.programId && element.materialCode === dp.productHierarchyCode)) {
                      this.assignRates.forEach(ar => {
                        if (ar.programId === aar.programId && ar.materialCode === afar.materialCode) {
                          let index = this.assignRates.indexOf(ar);
                          this.assignRates.splice(index, 1);
                        }
                      })
                    }
                  }
                })
                if (aar.autoFillAssignRates.length === 0) {
                  let index = this.accrualsAssignRates.indexOf(aar);
                  this.accrualsAssignRates.splice(index, 1);
                }
              }
            })
          }
        })
      }
      else {
        for (var level = 0; level < this.accrualsAssignRates.length; level++) {
          this.accrualsAssignRates.forEach(aar => {
            while (aar.autoFillAssignRates.some(element => element.materialCode === dp.productHierarchyCode)) {
              aar.autoFillAssignRates.forEach(afar => {
                if (afar.materialCode === dp.productHierarchyCode) {
                  var index = aar.autoFillAssignRates.indexOf(afar);
                  aar.autoFillAssignRates.splice(index, 1);
                }
              })
            }
            if (aar.autoFillAssignRates.length === 0) {
              let index = this.accrualsAssignRates.indexOf(aar);
              this.accrualsAssignRates.splice(index, 1);
            }
          })
        }
        while (this.assignRates.some(element => element.materialCode === dp.productHierarchyCode)) {
          this.assignRates.forEach(ar => {
            if (ar.materialCode === dp.productHierarchyCode) {
              let index = this.assignRates.indexOf(ar);
              this.assignRates.splice(index, 1);
            }
          })
        }
      }
    })
  }

  removeSingleDeselectedProductAssignRates(node) {
    let accrualPrograms = this.data['dataAccrualPrograms'];
    if (this.nonAccrualsAssignRates.length > 0) {
      this.nonAccrualsAssignRates.forEach(naar => {
        if (!naar.autoFillAssignRates.some(afar => afar.materialCode === node.productHierarchyCode)) {
          this.accrualsAssignRates.forEach(aar => {
            while (aar.autoFillAssignRates.some(element => element.materialCode === node.productHierarchyCode)) {
              aar.autoFillAssignRates.forEach(afar => {
                if (afar.materialCode === node.productHierarchyCode) {
                  var index = aar.autoFillAssignRates.indexOf(afar);
                  aar.autoFillAssignRates.splice(index, 1);
                  while (this.assignRates.some(element => element.programId === aar.programId && element.materialCode === node.productHierarchyCode)) {
                    this.assignRates.forEach(ar => {
                      if (ar.programId === aar.programId && ar.materialCode === afar.materialCode) {
                        let index = this.assignRates.indexOf(ar);
                        this.assignRates.splice(index, 1);
                      }
                    })
                  }
                }
              })
              if (aar.autoFillAssignRates.length === 0) {
                let index = this.accrualsAssignRates.indexOf(aar);
                this.accrualsAssignRates.splice(index, 1);
              }
            }
          })
          switch (this.masterSelectedHierarchyLevel) {
            case 0:
              accrualPrograms.forEach(aprg => {
                if (typeof aprg.children === 'object') {
                  if (aprg.children.length > 0) {
                    aprg.children.forEach(aprgChild => {
                      if (typeof aprgChild.children === 'object') {
                        if (aprgChild.children.length > 0) {
                          aprgChild.children.forEach(aprgChildChild => {
                            if (typeof aprgChildChild.children === 'object') {
                              if (aprgChildChild.children.length > 0) {
                                aprgChildChild.children.forEach(aprgChildChildChild => {
                                  if (typeof aprgChildChildChild.children === 'object') {
                                    if (aprgChildChildChild.children.length > 0) {
                                      aprgChildChild.children.forEach(aprgChildChildChildChild => {
                                        if (typeof aprgChildChildChildChild.children === 'object') {
                                          if (aprgChildChildChildChild.children.length > 0) {
                                            aprgChildChildChildChild.children.forEach(aprgChildChildChildChildChild => {
                                              if (typeof aprgChildChildChildChildChild.children === 'object') {
                                                if (aprgChildChildChildChildChild.children.length > 0) {
                                                  aprgChildChildChildChildChild.children.forEach(element => {
                                                    if (element.materialCode === node.productHierarchyCode) {
                                                      let index = aprgChildChildChildChildChild.children.indexOf(element);
                                                      aprgChildChildChildChildChild.children.splice(index, 1);
                                                    }
                                                  })
                                                }
                                              }
                                            })
                                          }
                                        }
                                      })
                                    }
                                  }
                                })
                              }
                            }
                          })
                        }
                      }
                    })
                  }
                }
              })
              break;
            case 1:
              accrualPrograms.forEach(aprg => {
                if (typeof aprg.children === 'object') {
                  if (aprg.children.length > 0) {
                    aprg.children.forEach(aprgChild => {
                      if (typeof aprgChild.children === 'object') {
                        if (aprgChild.children.length > 0) {
                          aprgChild.children.forEach(aprgChildChild => {
                            if (typeof aprgChildChild.children === 'object') {
                              if (aprgChildChild.children.length > 0) {
                                aprgChildChild.children.forEach(aprgChildChildChild => {
                                  if (typeof aprgChildChildChild.children === 'object') {
                                    if (aprgChildChildChild.children.length > 0) {
                                      aprgChildChild.children.forEach(aprgChildChildChildChild => {
                                        if (typeof aprgChildChildChildChild.children === 'object') {
                                          if (aprgChildChildChildChild.children.length > 0) {
                                            aprgChildChildChildChild.children.forEach(element => {
                                              if (element.materialCode === node.productHierarchyCode) {
                                                let index = aprgChildChildChildChild.children.indexOf(element);
                                                aprgChildChildChildChild.children.splice(index, 1);
                                              }
                                            })
                                          }
                                        }
                                      })
                                    }
                                  }
                                })
                              }
                            }
                          })
                        }
                      }
                    })
                  }
                }
              })
              break;
            case 2:
              accrualPrograms.forEach(aprg => {
                if (typeof aprg.children === 'object') {
                  if (aprg.children.length > 0) {
                    aprg.children.forEach(aprgChild => {
                      if (typeof aprgChild.children === 'object') {
                        if (aprgChild.children.length > 0) {
                          aprgChild.children.forEach(aprgChildChild => {
                            if (typeof aprgChildChild.children === 'object') {
                              if (aprgChildChild.children.length > 0) {
                                aprgChildChild.children.forEach(element => {
                                  if (element.materialCode === node.productHierarchyCode) {
                                    let index = aprgChildChild.children.indexOf(element);
                                    aprgChildChild.children.splice(index, 1);
                                  }
                                })
                              }
                            }
                          })
                        }
                      }
                    })
                  }
                }
              })
              break;
            case 3:
              accrualPrograms.forEach(aprg => {
                if (typeof aprg.children === 'object') {
                  if (aprg.children.length > 0) {
                    aprg.children.forEach(aprgChild => {
                      if (typeof aprgChild.children === 'object') {
                        if (aprgChild.children.length > 0) {
                          aprgChild.children.forEach(element => {
                            if (element.materialCode === node.productHierarchyCode) {
                              let index = aprgChild.children.indexOf(element);
                              aprgChild.children.splice(index, 1);
                            }
                          })
                        }
                      }
                    })
                  }
                }
              })
              break;
            case 4:
              accrualPrograms.forEach(aprg => {
                if (typeof aprg.children === 'object') {
                  if (aprg.children.length > 0) {
                    aprg.children.forEach(element => {
                      if (element.materialCode === node.productHierarchyCode) {
                        let index = aprg.children.indexOf(element);
                        aprg.children.splice(index, 1);
                      }
                    })
                  }
                }
              })
              break;
          }
        }
      })
    }
    else {
      for (var level = 0; level < this.accrualsAssignRates.length; level++) {
        this.accrualsAssignRates.forEach(aar => {
          while (aar.autoFillAssignRates.some(element => element.materialCode === node.productHierarchyCode)) {
            aar.autoFillAssignRates.forEach(afar => {
              if (afar.materialCode === node.productHierarchyCode) {
                var index = aar.autoFillAssignRates.indexOf(afar);
                aar.autoFillAssignRates.splice(index, 1);
              }
            })
          }
          if (aar.autoFillAssignRates.length === 0) {
            let index = this.accrualsAssignRates.indexOf(aar);
            this.accrualsAssignRates.splice(index, 1);
          }
        })
        while (this.assignRates.some(element => element.materialCode === node.productHierarchyCode)) {
          this.assignRates.forEach(ar => {
            if (ar.materialCode === node.productHierarchyCode) {
              let index = this.assignRates.indexOf(ar);
              this.assignRates.splice(index, 1);
            }
          })
        }
      }
      switch (this.masterSelectedHierarchyLevel) {
        case 0:
          accrualPrograms.forEach(aprg => {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length > 0) {
                      aprgChild.children.forEach(aprgChildChild => {
                        if (typeof aprgChildChild.children === 'object') {
                          if (aprgChildChild.children.length > 0) {
                            aprgChildChild.children.forEach(aprgChildChildChild => {
                              if (typeof aprgChildChildChild.children === 'object') {
                                if (aprgChildChildChild.children.length > 0) {
                                  aprgChildChild.children.forEach(aprgChildChildChildChild => {
                                    if (typeof aprgChildChildChildChild.children === 'object') {
                                      if (aprgChildChildChildChild.children.length > 0) {
                                        aprgChildChildChildChild.children.forEach(aprgChildChildChildChildChild => {
                                          if (typeof aprgChildChildChildChildChild.children === 'object') {
                                            if (aprgChildChildChildChildChild.children.length > 0) {
                                              aprgChildChildChildChildChild.children.forEach(element => {
                                                if (element.materialCode === node.productHierarchyCode) {
                                                  let index = aprgChildChildChildChildChild.children.indexOf(element);
                                                  aprgChildChildChildChildChild.children.splice(index, 1);
                                                }
                                              })
                                            }
                                          }
                                        })
                                      }
                                    }
                                  })
                                }
                              }
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          })
          break;
        case 1:
          accrualPrograms.forEach(aprg => {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length > 0) {
                      aprgChild.children.forEach(aprgChildChild => {
                        if (typeof aprgChildChild.children === 'object') {
                          if (aprgChildChild.children.length > 0) {
                            aprgChildChild.children.forEach(aprgChildChildChild => {
                              if (typeof aprgChildChildChild.children === 'object') {
                                if (aprgChildChildChild.children.length > 0) {
                                  aprgChildChild.children.forEach(aprgChildChildChildChild => {
                                    if (typeof aprgChildChildChildChild.children === 'object') {
                                      if (aprgChildChildChildChild.children.length > 0) {
                                        aprgChildChildChildChild.children.forEach(element => {
                                          if (element.materialCode === node.productHierarchyCode) {
                                            let index = aprgChildChildChildChild.children.indexOf(element);
                                            aprgChildChildChildChild.children.splice(index, 1);
                                          }
                                        })
                                      }
                                    }
                                  })
                                }
                              }
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          })
          break;
        case 2:
          accrualPrograms.forEach(aprg => {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length > 0) {
                      aprgChild.children.forEach(aprgChildChild => {
                        if (typeof aprgChildChild.children === 'object') {
                          if (aprgChildChild.children.length > 0) {
                            aprgChildChild.children.forEach(element => {
                              if (element.materialCode === node.productHierarchyCode) {
                                let index = aprgChildChild.children.indexOf(element);
                                aprgChildChild.children.splice(index, 1);
                              }
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          })
          break;
        case 3:
          accrualPrograms.forEach(aprg => {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length > 0) {
                      aprgChild.children.forEach(element => {
                        if (element.materialCode === node.productHierarchyCode) {
                          let index = aprgChild.children.indexOf(element);
                          aprgChild.children.splice(index, 1);
                        }
                      })
                    }
                  }
                })
              }
            }
          })
          break;
        case 4:
          accrualPrograms.forEach(aprg => {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(element => {
                  if (element.materialCode === node.productHierarchyCode) {
                    let index = aprg.children.indexOf(element);
                    aprg.children.splice(index, 1);
                  }
                })
              }
            }
          })
          break;
      }
    }

    for (var i = 4; i > 0; i--) {
      switch (i) {
        case 4:
          accrualPrograms.forEach(aprg => {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length > 0) {
                      aprgChild.children.forEach(aprgChildChild => {
                        if (typeof aprgChildChild.children === 'object') {
                          if (aprgChildChild.children.length > 0) {
                            aprgChildChild.children.forEach(aprgChildChildChild => {
                              if (typeof aprgChildChildChild.children === 'object') {
                                if (aprgChildChildChild.children.length > 0) {
                                  aprgChildChildChild.children.forEach(aprgChildChildChildChild => {
                                    if (typeof aprgChildChildChildChild.children === 'object') {
                                      if (aprgChildChildChildChild.children.length == 0) {
                                        let index = aprgChildChildChild.children.indexOf(aprgChildChildChildChild);
                                        aprgChildChildChild.children.splice(index, 1);
                                      }
                                    }
                                  })
                                }
                              }
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          })
          break;
        case 3:
          accrualPrograms.forEach(aprg => {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length > 0) {
                      aprgChild.children.forEach(aprgChildChild => {
                        if (typeof aprgChildChild.children === 'object') {
                          if (aprgChildChild.children.length > 0) {
                            aprgChildChild.children.forEach(aprgChildChildChild => {
                              if (typeof aprgChildChildChild.children === 'object') {
                                if (aprgChildChildChild.children.length == 0) {
                                  let index = aprgChildChild.children.indexOf(aprgChildChildChild);
                                  aprgChildChild.children.splice(index, 1);
                                }
                              }
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          })
          break;
        case 2:
          accrualPrograms.forEach(aprg => {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length > 0) {
                      aprgChild.children.forEach(aprgChildChild => {
                        if (typeof aprgChildChild.children === 'object') {
                          if (aprgChildChild.children.length == 0) {
                            let index = aprgChild.children.indexOf(aprgChildChild);
                            aprgChild.children.splice(index, 1);
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          })
          break;
        case 1:
          accrualPrograms.forEach(aprg => {
            if (typeof aprg.children === 'object') {
              if (aprg.children.length > 0) {
                aprg.children.forEach(aprgChild => {
                  if (typeof aprgChild.children === 'object') {
                    if (aprgChild.children.length === 0) {
                      let index = aprg.children.indexOf(aprgChild);
                      aprg.children.splice(index, 1);
                    }
                  }
                })
              }
            }
          })
          break;
      }
    }
  }
   
  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: ProductFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: ProductFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do product selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: ProductFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    if (this.checklistSelection.isSelected(node)) {
      this.checklistSelection.select(...descendants);
    }
    else {
      this.checklistSelection.deselect(...descendants);
      this.removeDeselectedProductsAssignRates(descendants);
    }

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do product selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: ProductFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: ProductFlatNode): void {
    let parent: ProductFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: ProductFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: ProductFlatNode): ProductFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  onCancel() {
    this.dialogRef.close({ dataAccrualPrograms: this.nonUpdatedAccrualsPrograms, dataAssignRates: this.nonUpdatedAssignRates, dataAccrualsAssignRates: this.nonAccrualsAssignRates, dataCheckSelectedList: this.selectedProgramFlatNodeList });
  }
  verifyAccrualsProducts(): boolean {
    if (this.accrualsProducts.length > 0) {
      return true;
    }
    return false;
  }
}
