<div class="dropzone" (drop)="dropHandler($event)" (dragover)="dragOverHandler($event)">
  <mat-icon  title="Upload files" class="upload_icon">cloud_upload</mat-icon> 
  <h4>Drag files here to attach or</h4>
  <label mat-raised-button for="fileUpload"> Browse for file</label>
  <input type="file"  id="fileUpload" name="fileUpload" #fileUpload multiple="multiple" style="display:none;" accept="{{accept}}"/>
  <br/>
  <ul>
    <li *ngFor="let file of files">
      <span id="file-label">
        {{file.name}}
        <a (click)="cancelFile(file)">
          <mat-icon>cancel</mat-icon>
        </a>
      </span>
    </li>
  </ul>
</div>