<div>
  <div>
    <mat-toolbar>
      <div class="lblhearder">
        <mat-label>
          Copy Products and Accruals
        </mat-label>
      </div>
    </mat-toolbar>
  </div>
  <div>
    <mat-card>
      <div class="row">
        <form [formGroup]="copyProductsAccrualsForm" class="form-width">
          <mat-form-field appearance="fill" class="col-md-6">
            <mat-label>From Program</mat-label>
            <input type="text" placeholder="Search Programs" aria-label="Number" matInput
              [matAutocomplete]="sourceProgram" formControlName="SourceProgram"
              (ngModelChange)="onSourceProgramSelection();">
            <mat-autocomplete #sourceProgram="matAutocomplete">
              <mat-option class="multipleLine"
                *ngFor="let ApprovedStandardProgramDetailsOption of filteredApprovedStandardProgramDetails | async"
                [value]="ApprovedStandardProgramDetailsOption.shortName">
                {{ApprovedStandardProgramDetailsOption.shortName}}<br>
                <p>{{ApprovedStandardProgramDetailsOption.programCode}}</p>
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="f.SourceProgram.errors && f.SourceProgram.errors.required">
              Program is required!</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-md-6">
            <mat-label>To Program</mat-label>
            <input type="text" placeholder="Search Programs" aria-label="Number" matInput
              [matAutocomplete]="targetProgram" formControlName="TargetProgram"
              (ngModelChange)="onTargetProgramSelection();">
            <mat-autocomplete #targetProgram="matAutocomplete">
              <mat-option class="multipleLine"
                *ngFor="let StandardProgramDetailsOption of filteredStandardProgramDetails | async"
                [value]="StandardProgramDetailsOption.shortName">
                {{StandardProgramDetailsOption.shortName}}<br>
                <p>{{StandardProgramDetailsOption.programCode}}</p>
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="f.TargetProgram.errors && f.TargetProgram.errors.required">
              Program is required!</mat-error>
          </mat-form-field>
        </form>
        <div>
          <button mat-raised-button class="functional-button-dark" [disabled]="!copyProductsAccrualsForm.valid"
            (click)="openCopyProductsAndAccrualsWarningDialog();">Copy Products and Accruals</button>
            <ul class="toggle">
          <mat-slide-toggle class="mat-slide-toggle" [(ngModel)]="isOnlyAssignedProductManagers"
            name="isOnlyAssignedProductManagers"
            matTooltip="Toggle On will prevent copying over the products and accruals of unassigned product managers into the program.">
            Currently Assigned Only</mat-slide-toggle>
          </ul>
        </div>
      </div>

    </mat-card>
  </div>
  <ngx-spinner class="spinner" size="default" type="ball-spin-clockwise">
    <p>Please Wait.</p>
  </ngx-spinner>
</div>
