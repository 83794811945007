import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreRepository } from 'src/app/core/core.repository';
import { ProgramPortalPermissions } from 'src/app/core/services/authorization/models/program-portal-permissions';
import { ProgPortalContextService } from 'src/app/core/services/progportalcontextservice';

@Injectable({
  providedIn: 'root'
})
export class EditProgramGuard implements CanActivate {
  permission:boolean=false;
  programManager:string;

  constructor(private core: CoreRepository,
    public context: ProgPortalContextService,
    private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {  
    let parmId= next.params['id'];
    const programCode =  parmId.toString();
    //To handle page refresh
    if (!this.router.navigated) {
      var permission = this.core.authorize().pipe(
        map(response => {
          return response.permissions.includes(ProgramPortalPermissions.EditProgram);
        })
      );

      return this.context.getdatafromService(`/api/Program/Option/ProgramManager/${programCode}`).toPromise()
        .then(response => {
          if (permission && (this.core.getUserAccountName() != response)) {
            return this.router.createUrlTree(['programs/review-program/', programCode.toString()]);
          }
          return permission ? true : this.router.createUrlTree(['home']);
        });
    }

    return this.context.getdatafromService(`/api/Program/Option/ProgramManager/${programCode}`).toPromise()
      .then(response => {         
          if(!this.core.isAdmin){
              if (this.core.canEditProgram && (this.core.getUserAccountName() != response)) {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigateByUrl('programs/review-program/' + programCode.toString()); 
            });
          }
        } 
        return this.core.canEditProgram || this.core.isAdmin ? true : this.router.createUrlTree(['home']);
      });
    }
}


