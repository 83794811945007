import { Injectable } from '@angular/core';
import { ProgPortalContextService } from '../progportalcontextservice';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EmailOptions } from './models/email-options.model';
import { AppSettingsService } from '../app-settings/app-settings.service';


@Injectable({
    providedIn: 'root'
})
export class EmailService {
    emailOptions = new EmailOptions();
    
     constructor(public context: ProgPortalContextService) {}
   
     buildActionEmailTemplate(action:string,description: string,nextSteps: string, buttonLinkUrl: string, buttonText: string, emailSubject: string, toAddress: string[], ccAddress: string[], comment?: string,bundledProgramList?:string,parentProgram?:string):EmailOptions {
      var baseUrl = AppSettingsService.variables.clientSide.clientSideBaseUri;
      if(action == 'Action Required!'){
        action = `<tr>
        <td width="5%">&nbsp;</td>
        <td><h1 style="color:#459AFF;margin: 20px auto;font-weight: lighter;">${action}</h1></td>
        <td width="5%">&nbsp;</td>
      </tr>`
      }
      else{
        action = `<tr>
        <td width="5%">&nbsp;</td>
        <td><h3 style="color:#459AFF;margin: 20px auto;font-weight: lighter;">${action}</h3></td>
        <td width="5%">&nbsp;</td>
      </tr>`
      }

      if(comment){
         comment = `<tr>
         <td width="5%">&nbsp;</td>
         <td><div style="margin-bottom:20px;">${comment}</div></td>
         <td width="5%">&nbsp;</td>
       </tr>`
      }else{
        comment = `<tr></tr>`;
      }
      if(bundledProgramList){
        bundledProgramList = `<tr>
        <td width="5%">&nbsp;</td>
        <td><h4 style="text-decoration:underline;margin-bottom:0px;">Bundle Routed Child Programs:</h4></td>
        <td width="5%">&nbsp;</td>
      </tr>
        <tr>
        <td width="5%">&nbsp;</td>
        <td><div><ul style="margin-bottom:20px;margin-top:0px;">${bundledProgramList}</ul></div></td>
        <td width="5%">&nbsp;</td>
      </tr>`
     }else{
      bundledProgramList = `<tr></tr>`;
     }
     if(parentProgram){
      parentProgram = `<tr>
      <td width="5%">&nbsp;</td>
      <td><h4 style="text-decoration:underline;margin-bottom:0px;">Parent Program:</h4></td>
      <td width="5%">&nbsp;</td>
    </tr>
      <tr>
      <td width="5%">&nbsp;</td>
      <td><div><ul style="margin-bottom:20px;margin-top:0px;">${parentProgram}</ul></div></td>
      <td width="5%">&nbsp;</td>
    </tr>`
  }else{
    parentProgram = `<tr></tr>`;
   }

      var emailTemplate = `
      <html lang="en">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <title></title>
        <style type="text/css">
        </style>   
      </head>
      <body style="margin:0; padding:0; background-color:#F2F2F2;">
        <center>
            <table width="100%" align="center" cellpadding="0" cellspacing="0">
              <tr>
                <td bgcolor:#FFFFFF;>
                  <table  width="800" border="0" valign="top" align="center" cellpadding="0" cellspacing="0" style="border: 1px solid #d5dadf; background-color: #FFFFFF;" bgcolor="#FFFFFF">
                    <tr>
                      <td colspan="3">
                        <table border="0" cellpadding="0" cellspacing="0" width="100%">
                            <tr bgcolor="#459AFF" style="line-height: 10px;">
                                <td colspan="4"></td>                          
                                <td><img src="${baseUrl}/assets/img/menu-color-bar-bg.png" /></td>
                            </tr>
                            <tr bgcolor="#0072CE" style="line-height: 40px;">                                                                 
                                <td width="5%"><img src="${baseUrl}/assets/img/growth-ribbon-bg.png" /></td>
                                <td width="5%"><img height="35" src="${baseUrl}/assets/img/corteva-icon.png" /></td>
                                <td><h1 style="color:#FFFFFF;">&nbsp;&nbsp;Program<b style="font-weight:lighter;">Portal</b></h1></td>
                                <td width="5%"></td>
                                <td width="5%"></td>
                            </tr>
                        </table>
                      </td>
                    </tr>
                    ${action}         
                    <tr>
                      <td width="5%">&nbsp;</td>
                      <td><div style="margin-bottom:20px;">${description}</div></td>
                      <td width="5%">&nbsp;</td>
                    </tr>
                    ${parentProgram}
                    ${bundledProgramList}
                    ${comment}
                    <tr>
                      <td width="5%">&nbsp;</td>
                      <td><h4 style="text-decoration:underline;margin-bottom:20px;">Please complete the following steps:</h4></td>
                      <td width="5%">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="5%">&nbsp;</td>
                      <td><ol style="margin-bottom:20px;margin-top:0px;">${nextSteps}</ol></td>
                      <td width="5%">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="5%">&nbsp;</td>
                      <td>
                        <table>
                          <tr>
                            <td style="border-radius: 2px;" bgcolor="#0072ce">
                              <a href="${baseUrl}/${buttonLinkUrl}" target="_blank" style="padding: 10px 30px; border: 1px solid #0072ce;border-radius: 2px;font-family: Helvetica, Arial, sans-serif;font-size: 14px; color: #FFFFFF;text-decoration: none;font-weight:bold;display: inline-block;">
                                  ${buttonText}             
                              </a>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td width="5%">&nbsp;</td>
                    </tr>
                    <tr>
                        <td width="5%">&nbsp;</td>
                        <td><hr style="margin:20px auto;"></td>
                        <td width="5%">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="5%">&nbsp;</td>
                      <td><div style="margin-bottom:20px;">If you need additional assistance, please contact:</div></td>
                      <td width="5%">&nbsp;</td>
                    </tr>         
                    <tr>
                      <td width="5%">&nbsp;</td>
                      <td>
                        <table>                          
                          <tr>
                          <td><h4>Dan Devine</h4></td>
                          <td>&nbsp;</td>
                          <td><div>+1(317) 337-4982</div></td>
                          </tr>
                        <tr>
                          <td><div>Accruals, Programs and Routing</div></td>
                        </tr>
                        <tr>
                          <td style="margin-bottom:40px;"><a style="color: #459AFF; text-decoration: none;" href="mailto:daniel.devine@corteva.com">daniel.devine@corteva.com</a></td>
                        </tr>
                        </table>
                      </td>
                      <td width="5%">&nbsp;</td>
                    </tr>            
                    <tr bgcolor="#555555">
                      <td width="5%" style="padding:20px; 0">&nbsp;</td>
                      <td style="padding:20px 0;">
                      <img src="${baseUrl}/assets/img/corteva-logo.png" />             
                      </td> 
                      <td width="5%" style="padding:20px; 0">&nbsp;</td> 
                    </tr>                      
                  </table>         
                </td>
              </tr>
            </table>
        </center>
      </body>
      </html>
      `;

      this.emailOptions.fromAddress = "progportl-mb@corteva.com";
      this.emailOptions.toAddress = toAddress;
      this.emailOptions.ccAddress = ccAddress;
      this.emailOptions.subject = emailSubject;
      this.emailOptions.body = emailTemplate;

      return this.emailOptions;
   }
     buildActionDateChangeEmailTemplate(description: string,startDates:string,endDates:string,nextSteps: string, buttonLinkUrl: string, buttonText: string, emailSubject: string, toAddress: string[], ccAddress: string[]):EmailOptions {
      var baseUrl = AppSettingsService.variables.clientSide.clientSideBaseUri;

      var emailTemplate = `
      <html lang="en">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <title></title>
        <style type="text/css">
        </style>    
      </head>
      <body style="margin:0; padding:0; background-color:#F2F2F2;">
        <center>
            <table width="100%" align="center" cellpadding="0" cellspacing="0">
              <tr>
                <td bgcolor:#FFFFFF;>
                  <table  width="800" border="0" valign="top" align="center" cellpadding="0" cellspacing="0" style="border: 1px solid #d5dadf; background-color: #FFFFFF;" bgcolor="#FFFFFF">
                    <tr>
                      <td colspan="3">
                        <table border="0" cellpadding="0" cellspacing="0" width="100%">
                            <tr bgcolor="#459AFF" style="line-height: 10px;">
                                <td colspan="4"></td>                          
                                <td><img src="${baseUrl}/assets/img/menu-color-bar-bg.png" /></td>
                            </tr>
                            <tr bgcolor="#0072CE" style="line-height: 40px;">                                                                 
                                <td width="5%"><img src="${baseUrl}/assets/img/growth-ribbon-bg.png" /></td>
                                <td width="5%"><img height="35" src="${baseUrl}/assets/img/corteva-icon.png" /></td>
                                <td><h1 style="color:#FFFFFF;">&nbsp;&nbsp;Program<b style="font-weight:lighter;">Portal</b></h1></td>
                                <td width="5%"></td>
                                <td width="5%"></td>
                            </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td width="5%">&nbsp;</td>
                      <td><h1 style="color:#459AFF;margin: 20px auto;font-weight: lighter;">Action Required!</h1></td>
                      <td width="5%">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="5%">&nbsp;</td>
                      <td><div style="margin-bottom:20px;">${description}</div></td>
                      <td width="5%">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="5%">&nbsp;</td>
                    <td><ul style="margin-bottom:20px;">${startDates}</ul></td>
                    <td width="5%">&nbsp;</td>
                  </tr>
                  <tr>
                    <td width="5%">&nbsp;</td>
                    <td><ul style="margin-bottom:20px;">${endDates}</ul></td>
                    <td width="5%">&nbsp;</td>
                  </tr>
                    <tr>
                      <td width="5%">&nbsp;</td>
                      <td><h4 style="text-decoration:underline;margin-bottom:20px;">Please complete the following steps:</h4></td>
                      <td width="5%">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="5%">&nbsp;</td>
                      <td><ol style="margin-bottom:20px;">${nextSteps}</ol></td>
                      <td width="5%">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="5%">&nbsp;</td>
                      <td>
                        <table>
                          <tr>
                            <td style="border-radius: 2px;" bgcolor="#0072ce">
                              <a href="${baseUrl}/${buttonLinkUrl}" target="_blank" style="padding: 10px 30px; border: 1px solid #0072ce;border-radius: 2px;font-family: Helvetica, Arial, sans-serif;font-size: 14px; color: #FFFFFF;text-decoration: none;font-weight:bold;display: inline-block;">
                                  ${buttonText}             
                              </a>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td width="5%">&nbsp;</td>
                    </tr>
                    <tr>
                        <td width="5%">&nbsp;</td>
                        <td><hr style="margin:20px auto;"></td>
                        <td width="5%">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="5%">&nbsp;</td>
                      <td><div style="margin-bottom:20px;">If you need additional assistance, please contact:</div></td>
                      <td width="5%">&nbsp;</td>
                    </tr>         
                    <tr>
                      <td width="5%">&nbsp;</td>
                      <td>
                        <table>
                          <tr>
                            <td><h4>Annette Thompson</h4></td>
                            <td>&nbsp;</td>
                            <td><div>+1(317) 337-4497</div></td>
                          </tr>
                          <tr>
                            <td><div>Programs and Routing</div></td>
                          </tr>
                          <tr>
                          <td style="margin-bottom:20px;"><a style="color: #459AFF; text-decoration: none;" href="mailto:annette.thompson@corteva.com">annette.thompson@corteva.com</a></td>          
                          </tr>
                          <tr>
                          <td><h4>Dan Devine</h4></td>
                          <td>&nbsp;</td>
                          <td><div>+1(317) 337-4982</div></td>
                          </tr>
                        <tr>
                          <td><div>Accruals</div></td>
                        </tr>
                        <tr>
                          <td style="margin-bottom:40px;"><a style="color: #459AFF; text-decoration: none;" href="mailto:daniel.devine@corteva.com">daniel.devine@corteva.com</a></td>
                        </tr>
                        </table>
                      </td>
                      <td width="5%">&nbsp;</td>
                    </tr>            
                    <tr bgcolor="#555555">
                      <td width="5%" style="padding:20px; 0">&nbsp;</td>
                      <td style="padding:20px 0;">
                      <img src="${baseUrl}/assets/img/corteva-logo.png" />             
                      </td> 
                      <td width="5%" style="padding:20px; 0">&nbsp;</td> 
                    </tr>                      
                  </table>         
                </td>
              </tr>
            </table>
        </center>
      </body>
      </html>
      `;

      this.emailOptions.fromAddress = "progportl-mb@corteva.com";
      this.emailOptions.toAddress = toAddress;
      this.emailOptions.ccAddress = ccAddress;
      this.emailOptions.subject = emailSubject;
      this.emailOptions.body = emailTemplate;

      return this.emailOptions;
   }
     buildDeniedEmailTemplate(description: string, comment: string, buttonLinkUrl: string, buttonText: string, emailSubject: string, toAddress: string[], ccAddress: string[],parentProgram?:string,bundledProgramList?:string):EmailOptions {
      var baseUrl = AppSettingsService.variables.clientSide.clientSideBaseUri;
      var strChar = `.`;

      if(parentProgram){
        parentProgram = `<tr>
        <td width="5%">&nbsp;</td>
        <td><h4 style="text-decoration:underline;margin-bottom:0px;">Parent Program:</h4></td>
        <td width="5%">&nbsp;</td>
      </tr>
        <tr>
        <td width="5%">&nbsp;</td>
        <td><div><ul style="margin-bottom:20px;margin-top:0px;">${parentProgram}</ul></div></td>
        <td width="5%">&nbsp;</td>
      </tr>`
    }else{
      parentProgram = `<tr></tr>`;
     }
     if(bundledProgramList){
      bundledProgramList = `<tr>
      <td width="5%">&nbsp;</td>
      <td><h4 style="text-decoration:underline;margin-bottom:0px;">Bundle Routed Child Programs:</h4></td>
      <td width="5%">&nbsp;</td>
    </tr>
      <tr>
      <td width="5%">&nbsp;</td>
      <td><div><ul style="margin-bottom:20px;margin-top:0px;">${bundledProgramList}</ul></div></td>
      <td width="5%">&nbsp;</td>
    </tr>`
   }else{
    bundledProgramList = `<tr></tr>`;
   }
      var emailTemplate = `
      <html lang="en">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <title></title>
        <style type="text/css">
        </style>    
      </head>
      <body style="margin:0; padding:0; background-color:#F2F2F2;">
        <center>
            <table width="100%" align="center" cellpadding="0" cellspacing="0">
              <tr>
                <td bgcolor:#FFFFFF;>
                  <table  width="800" border="0" valign="top" align="center" cellpadding="0" cellspacing="0" style="border: 1px solid #d5dadf; background-color: #FFFFFF;" bgcolor="#FFFFFF">
                    <tr>
                      <td colspan="3">
                        <table border="0" cellpadding="0" cellspacing="0" width="100%">
                            <tr bgcolor="#459AFF" style="line-height: 10px;">
                                <td colspan="4"></td>                          
                                <td><img src="${baseUrl}/assets/img/menu-color-bar-bg.png" /></td>
                            </tr>
                            <tr bgcolor="#0072CE" style="line-height: 40px;">                                                                 
                                <td width="5%"><img src="${baseUrl}/assets/img/growth-ribbon-bg.png" /></td>
                                <td width="5%"><img height="35" src="${baseUrl}/assets/img/corteva-icon.png" /></td>
                                <td><h1 style="color:#FFFFFF;">&nbsp;&nbsp;Program<b style="font-weight:lighter;">Portal</b></h1></td>
                                <td width="5%"></td>
                                <td width="5%"></td>
                            </tr>
                        </table>
                      </td>
                    </tr> 
                    <tr>
                    <td style="margin-bottom:20px;" width="5%">&nbsp;</td>
                    </tr>                                
                    <tr>
                    <td width="5%">&nbsp;</td>
                    <td><div style="margin-bottom:20px;margin-top:10px;">${description}<a style="color: #459AFF; text-decoration: none;" href="mailto:${ccAddress}">${ccAddress}</a>${strChar}</div></td>
                    <td width="5%">&nbsp;</td>
                  </tr>
                  <tr>
                    <td width="5%">&nbsp;</td>
                    <td><div style="margin-bottom:20px;">${comment}</div></td>
                    <td width="5%">&nbsp;</td>
                  </tr>
                  ${bundledProgramList}
                  ${parentProgram}                
                    <tr>
                      <td width="5%">&nbsp;</td>
                      <td>
                        <table>
                          <tr>
                            <td style="border-radius: 2px;" bgcolor="#0072ce">
                              <a href="${baseUrl}/${buttonLinkUrl}" target="_blank" style="padding: 10px 30px; border: 1px solid #0072ce;border-radius: 2px;font-family: Helvetica, Arial, sans-serif;font-size: 14px; color: #FFFFFF;text-decoration: none;font-weight:bold;display: inline-block;">
                                  ${buttonText}             
                              </a>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td width="5%">&nbsp;</td>
                    </tr>
                    <tr>
                        <td width="5%">&nbsp;</td>
                        <td><hr style="margin:20px auto;"></td>
                        <td width="5%">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="5%">&nbsp;</td>
                      <td><div style="margin-bottom:20px;">If you need additional assistance, please contact:</div></td>
                      <td width="5%">&nbsp;</td>
                    </tr>         
                    <tr>
                      <td width="5%">&nbsp;</td>
                      <td>
                        <table>
                          <tr>
                            <td><h4>Annette Thompson</h4></td>
                            <td>&nbsp;</td>
                            <td><div>+1(317) 337-4497</div></td>
                          </tr>
                          <tr>
                            <td><div>Programs and Routing</div></td>
                          </tr>
                          <tr>
                          <td style="margin-bottom:20px;"><a style="color: #459AFF; text-decoration: none;" href="mailto:annette.thompson@corteva.com">annette.thompson@corteva.com</a></td>          
                          </tr>
                          <tr>
                          <td><h4>Dan Devine</h4></td>
                          <td>&nbsp;</td>
                          <td><div>+1(317) 337-4982</div></td>
                          </tr>
                        <tr>
                          <td><div>Accruals</div></td>
                        </tr>
                        <tr>
                          <td style="margin-bottom:40px;"><a style="color: #459AFF; text-decoration: none;" href="mailto:daniel.devine@corteva.com">daniel.devine@corteva.com</a></td>
                        </tr>
                        </table>
                      </td>
                      <td width="5%">&nbsp;</td>
                    </tr>            
                    <tr bgcolor="#555555">
                      <td width="5%" style="padding:20px; 0">&nbsp;</td>
                      <td style="padding:20px 0;">
                      <img src="${baseUrl}/assets/img/corteva-logo.png" />             
                      </td> 
                      <td width="5%" style="padding:20px; 0">&nbsp;</td> 
                    </tr>                      
                  </table>         
                </td>
              </tr>
            </table>
        </center>
      </body>
      </html>
      `;

      this.emailOptions.fromAddress = "progportl-mb@corteva.com";
      this.emailOptions.toAddress = toAddress;
      this.emailOptions.ccAddress = ccAddress;
      this.emailOptions.subject = emailSubject;
      this.emailOptions.body = emailTemplate;

      return this.emailOptions;
   }
}
