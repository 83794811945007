export enum StatusDefinitionIds{
  ACCRUALS_PENDING = 1,
  ACCRUALS_COMPLETED = 2,
  APPROVAL_ROUTING = 3,
  APPROVED = 4,
  CANCELED = 5,
  DENIED = 6,
  DRAFT = 7
}

export enum StatusDefinitionShortDesc{
  _1 = 'Accruals Pending',
  _2 ='Accruals Completed',
  _3 = 'Approval Routing',
  _4 ='Approved',
  _5 = 'Canceled',
  _6 ='Denied',
  _7 = 'Draft',
}

export enum StatusDefinitionUIShortDesc{
  _1 = 'See Parent Status',
  _2 = 'Admin Approved'
}