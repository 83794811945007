export enum CompanyCodeIds{
  US = 1,
  CANADA = 2,
  PhytoGen = 3,
  Mycogen = 4,
  Brevant = 5
}

export enum CompanyCodeShortDesc{
  _1 = 'US',
  _2 = 'CANADA',
  _3 = 'PhytoGen',
  _4 = 'Mycogen',
  _5 = 'Brevant'
}

