import { Component, Inject, Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {MatRadioChange} from '@angular/material/radio';
import { ProgPortalContextService } from 'src/app/core/services/progportalcontextservice';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { AccrualsAssignRates } from 'src/app/accruals/edit-accruals/edit-accruals.component';
import { MasterMaterialHierarchyLevels } from 'src/app/core/enums/masterMaterialHierarchyEnums';
import { DialogWarningMessageComponent, Messages } from 'src/app/dialogs/dialog-warning-message/dialog-warning-message.component';
import { AccrualsMessages } from 'src/app/core/enums/messageEnums';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-dialog-accruals-assignrates',
  templateUrl: './dialog-accruals-assignrates.component.html',
  styleUrls: ['./dialog-accruals-assignrates.component.scss']
})
export class DialogAccrualsAssignRatesComponent {

  columnsToDisplay = ['programName'];
  innerInnerDisplayedColumns = ['materialName'];
  innerDisplayedColumns = ['name', 'startDate', 'endDate', 'volume', 'payRate', 'accrual'];
  selectedHierarchy: string;
  masterSelectedHierarchyLevel:number;
  accrualsAssignRates: AccrualsAssignRates[] = [];
  nonUpdatedAccrualsAssignRates: AccrualsAssignRates[] = [];
  hierarchyGroupAccrualsAssignRates: AccrualsAssignRates[] = [];
  masterMaterialHierarchyLevels = MasterMaterialHierarchyLevels;
  showHierarchyProducts = false;
  hierarchys: string[] = [this.masterMaterialHierarchyLevels._0, this.masterMaterialHierarchyLevels._1, this.masterMaterialHierarchyLevels._2, this.masterMaterialHierarchyLevels._3,this.masterMaterialHierarchyLevels._4];
  applyupdatedAssignRates: boolean;
  warningMessages: Messages[] = [];
   accrualsMessages= AccrualsMessages;


  constructor(public context: ProgPortalContextService, private spinner: NgxSpinnerService, 
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogAccrualsAssignRatesComponent>,
    public dialog: MatDialog, 
    @Inject(MAT_DIALOG_DATA) public data: AccrualsAssignRates[]
    ) {
    dialogRef.disableClose = true;
    this.nonUpdatedAccrualsAssignRates = JSON.parse(JSON.stringify(data['dataProgramsFilteredAccrualsAssignRates']));
    this.accrualsAssignRates = data['dataProgramsFilteredAccrualsAssignRates'];
    data['dataIsUpdatedAutoAssignRates'] = true;
    this.masterSelectedHierarchyLevel = data['dataMasterSelectedHierarchyLevel'];
    this.selectedHierarchy = this.masterMaterialHierarchyLevels['_' + this.masterSelectedHierarchyLevel];
    this.setHierarchyLevelSelection(this.selectedHierarchy);
    if(this.selectedHierarchy === this.masterMaterialHierarchyLevels['_' + 4]){
    this.showHierarchyProducts = false;
    this.hierarchyGroupAccrualsAssignRates = [];
    this.accrualsAssignRates = JSON.parse(JSON.stringify(this.nonUpdatedAccrualsAssignRates));
    }
  }

  onCancel() {
    this.dialogRef.close({ dataProgramsFilteredAccrualsAssignRates: this.nonUpdatedAccrualsAssignRates, dataIsUpdatedAutoAssignRates: false });
  }

  onApply(){
    this.warningMessages = [];
    this.applyupdatedAssignRates = true;
     let exitForeach = false;
    this.accrualsAssignRates.forEach(aar => {
      aar.autoFillAssignRates.forEach(afar => {
        if(!exitForeach){
          if(afar.volume === null || afar.payRate === null || isNaN(afar.volume) || isNaN(afar.payRate) || afar.volume.toString() =="" || afar.payRate.toString() ==""){
            this.applyupdatedAssignRates = false;
            exitForeach = true;
          } 
        }
      })
    });
     if(this.applyupdatedAssignRates){
       this.dialogRef.close(this.data);
     }
     else{
      this.warningMessages.push({ messageId: 1, message:this.accrualsMessages._7, error: true });
      this.openApplyWarningMessageDialog();
     }
  }

  openApplyWarningMessageDialog(): void {
    const dialog = this.dialog.open(DialogWarningMessageComponent, {
      disableClose: true,
      height: '340px',
      width: '550px',
      data: { dataMessage: this.warningMessages, dataContinueText: 'Continue', dataConfirm: true, dataShowErrorResolveMessage: true, dataShowDetails: false}
    });

    dialog.afterClosed().subscribe(result => {
      if (result != undefined) {
      }
    });
  }

  calculateAccrualRate(volume, payRate, programId, materialCode) {
    this.accrualsAssignRates.forEach(aar => {
      aar.autoFillAssignRates.forEach(afar => {
        if (afar.materialCode === materialCode && afar.programId === programId) {
          if(afar.volume !== null){
            afar.volume = afar.volume.toString().length > 0 ?  parseFloat(afar.volume.toString()): null;
          }
          if(afar.payRate !== null){
            afar.payRate = afar.payRate.toString().length > 0?  parseFloat(afar.payRate.toString()): null;
          }
          if (!isNaN(afar.volume) && !isNaN(afar.payRate) && volume.length > 0 && payRate.length > 0) {
            afar.accrual = (afar.volume * afar.payRate) / 100;
          }
          else {
            afar.accrual = null;
          }
        }
      })
    })
    this.data['dataProgramsFilteredAccrualsAssignRates'] = this.accrualsAssignRates;
  }

  calculateHierarchyAccrualRate(hierarchyVolume, hierarchyPayRate, programId, hierarchyCode,selectedHierarchyLevel) {
    this.hierarchyGroupAccrualsAssignRates.forEach(hgaar => {
      hgaar.autoFillAssignRates.forEach(afar => {
        switch(selectedHierarchyLevel){
          case this.masterMaterialHierarchyLevels._0:
            if (afar.performanceCenterCode === hierarchyCode && afar.programId === programId) {
              if(afar.volume !== null){
                afar.volume = afar.volume.toString().length > 0 ?  parseFloat(afar.volume.toString()): null;
              }
              if(afar.payRate !== null){
                afar.payRate = afar.payRate.toString().length > 0?  parseFloat(afar.payRate.toString()): null;
              }
              if (!isNaN(afar.volume) && !isNaN(afar.payRate) && hierarchyVolume.length > 0 && hierarchyPayRate.length > 0) {
                afar.accrual = (afar.volume * afar.payRate) / 100;
              }
              else {
                afar.accrual = null;
              }
              this.updateAccrualsAssignRates(afar.volume,afar.payRate,afar.accrual,afar.startColumn,afar.endColumn,afar.programId,afar.performanceCenterCode,selectedHierarchyLevel);
            }
            
          break;
          case this.masterMaterialHierarchyLevels._1:
            if (afar.productCenterCode === hierarchyCode && afar.programId === programId) {
              if(afar.volume !== null){
                afar.volume =afar.volume.toString().length > 0?  parseFloat(afar.volume.toString()): null;
              }
              if(afar.payRate !== null){
                afar.payRate = afar.payRate.toString().length > 0?  parseFloat(afar.payRate.toString()): null;
              }
              if (!isNaN(afar.volume) && !isNaN(afar.payRate) && hierarchyVolume.length > 0 && hierarchyPayRate.length > 0) {
                afar.accrual = (afar.volume * afar.payRate) / 100;
              }
              else {
                afar.accrual = null;
              }
              this.updateAccrualsAssignRates(afar.volume,afar.payRate,afar.accrual,afar.startColumn,afar.endColumn,afar.programId,afar.productCenterCode,selectedHierarchyLevel);
            }
          break;
          case this.masterMaterialHierarchyLevels._2:
            if (afar.plannedProductCode === hierarchyCode && afar.programId === programId) {
              if(afar.volume !== null){
                afar.volume =afar.volume.toString().length > 0?  parseFloat(afar.volume.toString()): null;
              }
              if(afar.payRate !== null){
                afar.payRate = afar.payRate.toString().length > 0?  parseFloat(afar.payRate.toString()): null;
              }
              if (!isNaN(afar.volume) && !isNaN(afar.payRate) && hierarchyVolume.length > 0 && hierarchyPayRate.length > 0) {
                afar.accrual = (afar.volume * afar.payRate) / 100;
              }
              else {
                afar.accrual = null;
              }
              this.updateAccrualsAssignRates(afar.volume,afar.payRate,afar.accrual,afar.startColumn,afar.endColumn,afar.programId,afar.plannedProductCode,selectedHierarchyLevel);
            }
          break;
          case this.masterMaterialHierarchyLevels._3:
            if (afar.tradeProductCode === hierarchyCode && afar.programId === programId) {
              if(afar.volume !== null){
                afar.volume = afar.volume.toString().length > 0?  parseFloat(afar.volume.toString()): null;
              }
              if(afar.payRate !== null){
                afar.payRate = afar.payRate.toString().length > 0?  parseFloat(afar.payRate.toString()): null;
              }
              if (!isNaN(afar.volume) && !isNaN(afar.payRate) && hierarchyVolume.length > 0 && hierarchyPayRate.length > 0) {
                afar.accrual = (afar.volume * afar.payRate) / 100;
              }
              else {
                afar.accrual = null;
              }
              this.updateAccrualsAssignRates(afar.volume,afar.payRate,afar.accrual,afar.startColumn,afar.endColumn,afar.programId,afar.tradeProductCode,selectedHierarchyLevel);
            }
          break;
        }
      })
    })
    this.data['dataProgramsFilteredAccrualsAssignRates'] = this.accrualsAssignRates;
  }

  setHierarchyLevelSelection(selectedHierarchyLevel) {
    this.hierarchyGroupAccrualsAssignRates = [];
    this.accrualsAssignRates = [];
    this.hierarchyGroupAccrualsAssignRates = JSON.parse(JSON.stringify(this.nonUpdatedAccrualsAssignRates));
    this.accrualsAssignRates = JSON.parse(JSON.stringify(this.nonUpdatedAccrualsAssignRates));
    this.accrualsAssignRates.forEach(paar => {
      paar.autoFillAssignRates.forEach(afar => {
        afar.selectedHierarchyLevel = selectedHierarchyLevel;
      })
    })

    this.hierarchyGroupAccrualsAssignRates.forEach(hgaar => {
      hgaar.autoFillAssignRates = [];
    })
     
    this.accrualsAssignRates.forEach(arr => {
      arr.autoFillAssignRates.forEach(afar => {
      this.hierarchyGroupAccrualsAssignRates.forEach(hgaar => {
        switch(selectedHierarchyLevel){
          case this.masterMaterialHierarchyLevels._0:
            if(afar.programId === hgaar.programId){
              if(!(hgaar.autoFillAssignRates.some(element => element.programId === afar.programId  && element.performanceCenterCode === afar.performanceCenterCode)))
              {
                afar.assignProducts = [];
                hgaar.autoFillAssignRates.push(afar);
                hgaar.autoFillAssignRates.forEach(hafar => {
                   if(hafar.performanceCenterCode === afar.performanceCenterCode){
                    hafar.assignProducts.push({materialCode:afar.materialCode,materialName:afar.materialName});
                  }
                })
              }
              else{
                hgaar.autoFillAssignRates.forEach(hafar => {
                  if(hafar.performanceCenterCode === afar.performanceCenterCode){
                  hafar.assignProducts.push({materialCode:afar.materialCode,materialName:afar.materialName});
                  }
                  })
              }
            }
          break;
          case this.masterMaterialHierarchyLevels._1:
            if(afar.programId === hgaar.programId){
            if(!(hgaar.autoFillAssignRates.some(element => element.programId === afar.programId && element.productCenterCode === afar.productCenterCode)))
            {
              afar.assignProducts = [];
              hgaar.autoFillAssignRates.push(afar);
              hgaar.autoFillAssignRates.forEach(hafar => {
                if(hafar.productCenterCode === afar.productCenterCode){
              hafar.assignProducts.push({materialCode:afar.materialCode,materialName:afar.materialName});
                }
            })
            }
            else{
              hgaar.autoFillAssignRates.forEach(hafar => {
                if(hafar.productCenterCode === afar.productCenterCode){
                hafar.assignProducts.push({materialCode:afar.materialCode,materialName:afar.materialName})
                }
              })
            }
          }
          break;
          case this.masterMaterialHierarchyLevels._2:
            if(afar.programId === hgaar.programId){
            if(!(hgaar.autoFillAssignRates.some(element => element.programId === afar.programId && element.plannedProductCode === afar.plannedProductCode)))
            {
              afar.assignProducts = [];
              hgaar.autoFillAssignRates.push(afar);
              hgaar.autoFillAssignRates.forEach(hafar => {
                if(hafar.plannedProductCode === afar.plannedProductCode){
              hafar.assignProducts.push({materialCode:afar.materialCode,materialName:afar.materialName})
                }
            })
            }
            else{
              hgaar.autoFillAssignRates.forEach(hafar => {
                if(hafar.plannedProductCode === afar.plannedProductCode){
                hafar.assignProducts.push({materialCode:afar.materialCode,materialName:afar.materialName})
                }
              })
            }
          }
          break;
          case this.masterMaterialHierarchyLevels._3:
            if(afar.programId === hgaar.programId){
            if(!(hgaar.autoFillAssignRates.some(element => element.programId === afar.programId && element.tradeProductCode === afar.tradeProductCode)))
            {
              afar.assignProducts = [];
              hgaar.autoFillAssignRates.push(afar);
              hgaar.autoFillAssignRates.forEach(hafar => {
                if(hafar.tradeProductCode === afar.tradeProductCode){
              hafar.assignProducts.push({materialCode:afar.materialCode,materialName:afar.materialName})
                }
            })
            }
            else{
              hgaar.autoFillAssignRates.forEach(hafar => {
                if(hafar.tradeProductCode === afar.tradeProductCode){
                hafar.assignProducts.push({materialCode:afar.materialCode,materialName:afar.materialName})
}              })
            }
          }
          break;
        }
      })
    })
  })
   }

 updateAccrualsAssignRates(volume,payRate,accrual,startColumn,endColumn,programId,hierarchyCode,selectedHierarchyLevel){
   this.accrualsAssignRates.forEach(aar => {
     aar.autoFillAssignRates.forEach(afar => {
      switch(selectedHierarchyLevel){
        case this.masterMaterialHierarchyLevels._0:
          if (afar.performanceCenterCode === hierarchyCode && afar.programId === programId) {
            afar.volume = volume;
            afar.payRate = payRate;
            afar.accrual = accrual;
            afar.startColumn = startColumn;
            afar.endColumn = endColumn;
          }
        break;
        case this.masterMaterialHierarchyLevels._1:
          if (afar.productCenterCode === hierarchyCode && afar.programId === programId) {
            afar.volume = volume;
            afar.payRate = payRate;
            afar.accrual = accrual;
            afar.startColumn = startColumn;
            afar.endColumn = endColumn;
          }
        break;
        case this.masterMaterialHierarchyLevels._2:
          if (afar.plannedProductCode === hierarchyCode && afar.programId === programId) {
            afar.volume = volume;
            afar.payRate = payRate;
            afar.accrual = accrual;
            afar.startColumn = startColumn;
            afar.endColumn = endColumn;
          }
        break;
        case this.masterMaterialHierarchyLevels._3:
          if (afar.tradeProductCode === hierarchyCode && afar.programId === programId) {
            afar.volume = volume;
            afar.payRate = payRate;
            afar.accrual = accrual;
            afar.startColumn = startColumn;
            afar.endColumn = endColumn;
          }
        break;
      }
     })
   })
 }

  restrictChar(event: KeyboardEvent) {
    return String.fromCharCode(event.charCode).match(/[^0-9.]/g) === null
  }
}
