import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RouteParamsService {
  public programIdsSubject = new BehaviorSubject("");
  public accrualsTabIndexSubject = new BehaviorSubject("");
  public isLinkIdSubject = new BehaviorSubject("");
  public isEditAccrualSubject = new BehaviorSubject("");
  public programEditNavigationSubject = new BehaviorSubject("");
  public adminSelectedProductManagerSubject = new BehaviorSubject("");

  private programIds: string = "Id_Settings";
  private adminSelectedProductManager: string = "adminSelectedProductManager_Settings";
  private accrualsTabIndex: string = "Tab_Index";
  private isLinkId: string = "IsLink_Id";
  private programEditNavigation: string = "EditNavigation_Settings";


  constructor() { }

  // set and get selected or params for programIds
  setProgramIdsSettings(data: any) {
    localStorage.setItem(this.programIds, JSON.stringify(data));
  }

  getProgramIdsSettings() {
    let data = localStorage.getItem(this.programIds);
    return JSON.parse(data);
  }

  clearProgramIdsSettings() {
    localStorage.removeItem(this.programIds);
  }

  sendProgramIdsRouteParams(params: string) {
    this.programIdsSubject.next(params);
  }

  getProgramIdsRouteParams(): Observable<any> {
    return this.programIdsSubject.asObservable();
  }
  clearProgramIdsRouteParams() {
    this.programIdsSubject.next('');
  }

  // set and get selected or params for adminSelectedProductManager
  setAdminSelectedProductManagerSettings(data: any) {
    localStorage.setItem(this.adminSelectedProductManager, JSON.stringify(data));
  }

  getAdminSelectedProductManagerSettings() {
    let data = localStorage.getItem(this.adminSelectedProductManager);
    return JSON.parse(data);
  }

  clearAdminSelectedProductManagerSettings() {
    localStorage.removeItem(this.adminSelectedProductManager);
  }

  sendAdminSelectedProductManagerRouteParams(params: string) {
    this.adminSelectedProductManagerSubject.next(params);
  }

  getAdminSelectedProductManagerRouteParams(): Observable<any> {
    return this.adminSelectedProductManagerSubject.asObservable();
  }
  clearAdminSelectedProductManagerRouteParams() {
    this.adminSelectedProductManagerSubject.next('');
  }

  // set and get changed tab for accruals Tab Index

  setAccrualsTabIndexSettings(data: any) {
    localStorage.setItem(this.accrualsTabIndex, JSON.stringify(data));
  }

  getAccrualsTabIndexSettings() {
    let data = localStorage.getItem(this.accrualsTabIndex);
    return JSON.parse(data);
  }

  clearAccrualsTabIndexSettings() {
    localStorage.removeItem(this.accrualsTabIndex);
  }

  sendAccrualsTabIndexRouteParams(params: string) {
    this.accrualsTabIndexSubject.next(params);
  }

  getAccrualsTabIndexRouteParams(): Observable<any> {
    return this.accrualsTabIndexSubject.asObservable();
  }

  clearAccrualsTabIndexRouteParams() {
    this.accrualsTabIndexSubject.next('');
  }


  // set and get navigation type anchor link or multiselect
  setIsLinkIdSettings(data: any) {
    localStorage.setItem(this.isLinkId, JSON.stringify(data));
  }

  getIsLinkIdSettings() {
    let data = localStorage.getItem(this.isLinkId);
    return JSON.parse(data);
  }

  clearIsLinkIdSettings() {
    localStorage.removeItem(this.isLinkId);
  }

  sendIsLinkIdRouteParams(params: string) {
    this.isLinkIdSubject.next(params);
  }

  getIsLinkIdRouteParams(): Observable<any> {
    return this.isLinkIdSubject.asObservable();
  }

  clearIsLinkIdRouteParams() {
    this.isLinkIdSubject.next('');
  }

  cleanAll() {
    localStorage.clear()
  }
  sendIsEditAccrualsRouteParams(params: string) {
    this.isEditAccrualSubject.next(params);
  }

  getIsEditAccrualsRouteParams(): Observable<any> {
    return this.isEditAccrualSubject.asObservable();
  }
  clearIsEditAccrualsRouteParams() {
    this.isEditAccrualSubject.next('');
  }
   // set and get selected or params for EditNavigation
   setProgramEditNavigationSettings(data: any) {
    localStorage.setItem(this.programEditNavigation, JSON.stringify(data));
  }

  getProgramEditNavigationSettings() {
    let data = localStorage.getItem(this.programEditNavigation);
    return JSON.parse(data);
  }

  clearProgramEditNavigationSettings() {
    localStorage.removeItem(this.programEditNavigation);
  }

  sendProgramEditNavigationRouteParams(params: string) {
    this.programEditNavigationSubject.next(params);
  }

  getProgramEditNavigationRouteParams(): Observable<any> {
    return this.programEditNavigationSubject.asObservable();
  }
  clearProgramEditNavigationRouteParams() {
    this.programEditNavigationSubject.next('');
  }
}
